import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import eventViewActions from 'src/eventView/store/actions';
import selectEventViewEvent from 'src/eventView/store/selectors/selectEventViewEvent';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import floatingModalActions from 'src/floatingModal/store/actions';
import selectFloatingModalOpened from 'src/floatingModal/store/selectors/selectFloatingModalOpened';
import { timeToPixelsInTimeline } from 'src/_utils/timeToPixelsInTimeline';
import { HelpFillVariantIcon, InfoFillVariantIcon } from 'src/_components/Svg';

import { isDatesEqual } from 'src/_utils/date';
import {
  EVENT_STATUS,
  TABLET_MAX_WIDTH,
  USER_PART_STATUS,
} from 'src/_constants';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';

import css from './DayWeekEvent.module.less';

interface DayWeekEventProps {
  column: number;
  width: number;
  timeRange: [string, string];
  summary: string;
  location?: string;
  attendees?: string[];
  position: 'left' | 'middle' | 'right' | 'full';
  event: SelectedEvent;
  onClick?: (v: string) => void;
  inGroup?: boolean;
  type: 'day' | 'week';
}

const DayWeekEvent: FC<DayWeekEventProps> = ({
  column,
  width,
  timeRange,
  summary,
  location,
  attendees,
  position,
  event,
  onClick,
  inGroup,
  type,
}) => {
  const viewEvent = useSelector(selectEventViewEvent);
  const openedFloatingModal = useSelector(selectFloatingModalOpened);
  const { width: windowWidth } = useWindowSize();
  const duration = useMemo(() => {
    const startSplitted = timeRange[0].split(':');
    const endSplitted = timeRange[1].split(':');

    const startInMinutes =
      Number(startSplitted[0]) * 60 + Number(startSplitted[1]);
    const endInMinutes = Number(endSplitted[0]) * 60 + Number(endSplitted[1]);

    return endInMinutes - startInMinutes;
  }, [timeRange]);
  const titleRef = useRef<HTMLParagraphElement>();
  const [linesCount, setLinesCount] = useState(2);
  const maxAttendees = type === 'day' ? (duration > 45 ? 5 : 2) : 1;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!titleRef.current) return;
      if (titleRef.current.getBoundingClientRect().height <= 20) {
        setLinesCount(1);
      }
      clearInterval(interval);
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const ref = useRef<HTMLDivElement>();
  const topPixels = useMemo(
    () =>
      timeToPixelsInTimeline(
        Number(timeRange[0].split(':')[0]),
        Number(timeRange[0].split(':')[1]),
      ),
    [timeRange],
  );

  const heightPixels = useMemo(
    () =>
      timeToPixelsInTimeline(
        Number(timeRange[1].split(':')[0]),
        Number(timeRange[1].split(':')[1]),
      ) -
      topPixels +
      3,
    [topPixels, timeRange],
  );

  const needAction =
    event.id !== 'SHADOW' &&
    event.source.status !== EVENT_STATUS.cancelled &&
    event.userPartStatus === USER_PART_STATUS.needsAction;

  const isTentative = event.userPartStatus === USER_PART_STATUS.tentative;

  const showLocation = !!(
    location &&
    (linesCount === 1 || (linesCount === 2 && duration >= 40) || type === 'day')
  );

  const showAttendees = !!(
    attendees &&
    attendees.length &&
    ((linesCount === 1 && duration >= 30 && !location) ||
      (linesCount === 1 && duration >= 40) ||
      (linesCount === 2 && duration >= 60) ||
      (linesCount === 2 && duration >= 40 && !location) ||
      type === 'day')
  );

  return (
    <div
      style={{
        marginTop: topPixels + 'px',
        height: (heightPixels > 20 ? heightPixels : 15) + 'px',
        gridColumn: `${column} / span ${width}`,
        gridRow: 1,
        paddingRight:
          position === 'left' || position === 'middle'
            ? '2.5px'
            : type === 'week'
              ? '1px'
              : undefined,
        paddingLeft:
          position === 'right' || position === 'middle' ? '2.5px' : undefined,
        cursor: 'pointer',
      }}
      ref={ref}
      onClick={
        event.id === 'SHADOW'
          ? undefined
          : onClick
            ? () => onClick(event.id)
            : () => {
                eventViewActions().setEventView({ event });
                floatingModalActions().openFloatingModal({
                  opened: FLOATING_MODAL_IDS['eventView'],
                  target: ref.current,
                  closeOnScroll: true,
                  center: windowWidth <= TABLET_MAX_WIDTH,
                });
              }
      }
    >
      <div
        className={cn(css.weekEvent, {
          [css.shadow]: event.id === 'SHADOW',
          [css.backgroundGray]:
            event.id === 'SHADOW' && isDatesEqual(new Date(), event.date),
          [css.backgroundWhite]:
            event.id === 'SHADOW' && !isDatesEqual(new Date(), event.date),
          [css.backgroundAquamarine]: event.id !== 'SHADOW',
          [css.partstatNeedAction]: needAction,
          [css.cancelled]: event.source.status === EVENT_STATUS.cancelled,
          [css.day]: type === 'day' && duration < 45,
          [css.inGroup]: inGroup,
          [css.smallBlock]: heightPixels <= 20,
          [css.less30min]: duration < 30,
          [css.viewing]:
            viewEvent?.source.etag === event.source.etag &&
            openedFloatingModal == FLOATING_MODAL_IDS['eventView'],
        })}
      >
        <span className={css.weekEventTitleWrapper}>
          <span
            style={{
              fontWeight: event.id === 'SHADOW' ? 400 : 700,
              whiteSpace: inGroup ? 'nowrap' : undefined,
              display: type === 'week' ? 'block' : undefined,
              overflowY: type === 'week' ? 'hidden' : undefined,
            }}
            ref={titleRef}
          >
            {needAction ? (
              <InfoFillVariantIcon />
            ) : isTentative ? (
              <HelpFillVariantIcon />
            ) : (
              ''
            )}
            {event.source.status === EVENT_STATUS.cancelled ? 'Отменено: ' : ''}
            {summary}
          </span>
          {duration >= 30 ? (
            <>
              {!!(type === 'day' && duration > 45 && showLocation) && <br />}
              {showLocation && (
                <span style={{ fontWeight: 400, whiteSpace: 'nowrap' }}>
                  {type === 'day' && duration < 45 ? ', ' : ''}
                  {location}
                </span>
              )}

              {!!(type === 'day' && duration > 45 && showAttendees) && <br />}

              {type === 'day' && showAttendees && (
                <span className={css.attendees}>
                  {type === 'day' && duration < 45 ? ', ' : ''}
                  {attendees.slice(0, maxAttendees).join(', ')}

                  {attendees.length > maxAttendees
                    ? ` +${attendees.length - maxAttendees}`
                    : ''}
                </span>
              )}
            </>
          ) : (
            ''
          )}
        </span>
        {type === 'week' && showAttendees && (
          <div className={css.attendeesWeek}>
            <p>{attendees.slice(0, maxAttendees).join(', ')}</p>
            {attendees.length > maxAttendees ? (
              <p>+{attendees.length - maxAttendees}</p>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DayWeekEvent;
