import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { timeToPixelsInTimeline } from 'src/_utils/timeToPixelsInTimeline';

import './styles.less';

interface TimeDividerProps {
  show: boolean;
  fullWidth?: boolean;
}

const TimeDivider: FC<TimeDividerProps> = ({ show, fullWidth }) => {
  const today = new Date();
  const [topPixels, setTopPixels] = useState(
    timeToPixelsInTimeline(today.getHours(), today.getMinutes()),
  );

  useEffect(() => {
    updateTopPixels();
    const intervalId = setInterval(updateTopPixels, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const updateTopPixels = () => {
    const tmpToday = new Date();
    setTopPixels(
      timeToPixelsInTimeline(tmpToday.getHours(), tmpToday.getMinutes()),
    );
  };

  if (!show) return null;

  return (
    <div
      className={cn('time-divider', {
        'full-width': fullWidth,
      })}
      style={{
        top: `${topPixels}px`,
      }}
    >
      <div className='time-divider-value'>
        {(today.getHours() + '').length === 1
          ? `0${today.getHours()}`
          : today.getHours()}
        :
        {(today.getMinutes() + '').length === 1
          ? `0${today.getMinutes()}`
          : today.getMinutes()}
      </div>
    </div>
  );
};

export default TimeDivider;
