import { FC, useEffect, useState } from 'react';
import { IProps } from 'packages/tooltip/typings/index';
import Tooltip from '@rambler-components/tooltip';
import { TABLET_MAX_WIDTH } from '../../_constants';
import { useWindowSize } from '../../_utils/hooks/useWindowSize';

export const CalendarTooltip: FC<IProps> = (props) => {
  const { width } = useWindowSize();
  const [forceVisible, setForceVisible] = useState(false);

  useEffect(() => {
    if (width <= TABLET_MAX_WIDTH) {
      setForceVisible(false);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setForceVisible(props?.isVisible);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [width, props?.isVisible]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Tooltip {...props} isVisible={forceVisible} />;
};
