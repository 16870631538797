import { FC } from 'react';
import { AddressbookContact } from 'src/_api/types/mailId/entities';
import { Avatar } from './avatar';
import cn from 'classnames';

import './styles.less';
import HighlightedText from '../../highlightedText';

interface Props {
  contact: AddressbookContact & { firstLetter: string };
  showFirstLetter: boolean;
  onContactClicked: () => void;
  selected?: boolean;
  isSearchMode?: boolean;
  query?: string;
}

export const ContactOverview: FC<Props> = ({
  contact,
  showFirstLetter,
  onContactClicked,
  selected,
  isSearchMode,
  query,
}) => (
  <div
    className={cn('contact-overview__root', {
      active: selected,
    })}
    onClick={onContactClicked}
  >
    <div className={'contact-overview__letter_group'}>
      {showFirstLetter && (
        <HighlightedText text={contact.firstLetter} query={query} />
      )}
    </div>
    <div className={'contact-overview__avatar_wrapper'}>
      <Avatar contact={contact} />
    </div>
    <div className={'contact-overview__display-name'}>
      <span>
        <HighlightedText text={contact?.display_name} query={query} />
      </span>
      <span className={'contact-overview__email'}>
        <HighlightedText text={contact?.email} query={query} />
      </span>
    </div>
    {!isSearchMode && (
      <div className={'contact-overview__job-tittle'}>
        <HighlightedText text={contact?.title} query={query} />
      </div>
    )}
  </div>
);
