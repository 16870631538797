import { memo } from 'react';
import { IS_PUBLIC } from '../../_constants';

import css from './Footer.module.less';

const Footer = () => {
  if (!IS_PUBLIC) {
    return <></>;
  }

  return (
    <div className={css.root}>
      <a
        // eslint-disable-next-line max-len
        href='https://help.rambler.ru/legal/1430/'
        target='_blank'
        rel='noreferrer'
      >
        Условия использования
      </a>
      <a
        // eslint-disable-next-line max-len
        href='https://help.rambler.ru/legal/1142/'
        target='_blank'
        rel='noreferrer'
      >
        Политика конфиденциальности
      </a>
      <a
        // eslint-disable-next-line max-len
        href='https://help.rambler.ru/feedback/mail/form/144/?utm_source=calendar&utm_medium=footer&utm_campaign=self_promo&utm_content=help&utm_term=desk'
        target='_blank'
        rel='noreferrer'
      >
        Связаться с поддержкой
      </a>
      <a href='https://www.rambler.ru/' target='_blank' rel='noreferrer'>
        © Рамблер, 2024
      </a>
    </div>
  );
};

export default memo(Footer);
