import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DayWeekHeaderEvent from 'src/eventsDisplay/components/DayWeekHeaderEvent';
import selectWeekEvents from 'src/eventsDisplay/store/selectors/weekEventsSelector';
import FloatingModal from 'src/floatingModal/component';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { CloseSmallIcon, VectorLeftIcon } from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import { CalendarModeDay } from 'src/pageCalendar/components/Day';
import singleDayModalActions from '../store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import EventViewBlock from 'src/eventView/components/EventBlock';
import IconButton from 'src/_components/IconButton';
import eventViewActions from 'src/eventView/store/actions';
import { CUSTOM_EVENTS } from 'src/_constants';
import './styles.less';

const MonthSingleDayView = () => {
  const { date, weekEvents, opened, target } = useSelector(
    (state: CStoreState) => ({
      date: state.singleDayModal.date || new Date(),
      weekEvents: selectWeekEvents(state, [
        state.singleDayModal.date || new Date(),
        state.singleDayModal.date || new Date(),
      ]),
      opened:
        state.floatingModal.opened === FLOATING_MODAL_IDS['dayPageInModal'],
      target: state.floatingModal.target,
    }),
  );
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [selEv, setSelEv] = useState('');
  const ref = useRef<HTMLDivElement>();

  const currentDay = moment(date).format('YYYY-MM-DD') + 'T00:00:00';

  const events = weekEvents[currentDay] || {
    wholeDay: [],
    regular: [],
  };

  const flatDayEvents = useMemo(
    () => [
      ...events.wholeDay,
      ...events.regular.reduce((prev, cur) => [...prev, ...cur], []),
    ],
    [events],
  );

  useEffect(() => {
    if (!opened) {
      setSelEv('');
    } else {
      window.addEventListener(
        CUSTOM_EVENTS.eventViewLoadError,
        resetSelectedEvent,
      );
    }

    return () => {
      window.removeEventListener(
        CUSTOM_EVENTS.eventViewLoadError,
        resetSelectedEvent,
      );
    };
  }, [opened]);

  // useEffect(() => {
  //   if (!selEv)
  //     setTimeout(() => {
  //       ref.current.scrollTop = 89 * 8; // второй множитель - количество часов, на котором будет скролл
  //     }, 1);
  // }, [selEv]);

  useEffect(() => {
    setSelEv('');
  }, [target]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateWindowHeightChecker();
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [windowHeight]);

  useEffect(() => {
    if (selEv) {
      eventViewActions().setEventView({
        event: flatDayEvents.find((ev) => ev.id === selEv),
      });
    }
  }, [selEv]);

  const updateWindowHeightChecker = () => {
    if (windowHeight === window.innerHeight) return;
    setWindowHeight(window.innerHeight);
  };

  const closeModal = () => {
    eventViewActions().setEventView({ event: null });
    floatingModalActions().closeFloatingModal();
    singleDayModalActions().setDateSingleModal(null);
  };

  const resetSelectedEvent = () => {
    setSelEv('');
    eventViewActions().setEventView({ event: null });
  };

  return (
    <FloatingModal
      id={FLOATING_MODAL_IDS['dayPageInModal']}
      fixedTop={120}
      onClose={closeModal}
    >
      <div
        className='single-day-modal-block-wrapper custom-scroll-bar'
        style={{
          height: `${windowHeight - 140}px`,
        }}
      >
        <div
          className='single-day-modal-block custom-scroll-bar'
          style={{
            transition: 'margin .3s',
            marginLeft: selEv ? '-490px' : '',
          }}
          ref={ref}
        >
          <div className='single-day-view-header-day'>
            <div
              className='flex-between'
              style={{
                marginBottom: events.wholeDay.length ? '20px' : undefined,
              }}
            >
              <p>{moment(date).format('D MMMM, dddd')}</p>
              <IconButton
                style={{
                  position: 'relative',
                  left: 10,
                }}
                onClick={closeModal}
                icon={<CloseSmallIcon />}
              />
            </div>
            {!!events.wholeDay.length &&
              events.wholeDay.map((ev, i) => (
                <div
                  key={`single-event-wholdeay-${i}`}
                  style={{
                    marginBottom:
                      i !== events.wholeDay.length - 1 ? '6px' : '0px',
                  }}
                >
                  <DayWeekHeaderEvent
                    type='day'
                    event={ev}
                    onClick={(evId) => setSelEv(evId)}
                  />
                </div>
              ))}
          </div>
          <CalendarModeDay
            forceDateRange={[date, date]}
            inWindow
            onSelectEvent={(ev) => setSelEv(ev)}
            preOpenCreate={closeModal}
          />
        </div>

        {selEv && (
          <div className='single-day-modal-block-view custom-scroll-bar'>
            <div
              className='flex-between'
              style={{
                marginBottom: '20px',
              }}
            >
              <div className='flex-center'>
                <IconButton
                  onClick={() => {
                    setSelEv('');
                    eventViewActions().setEventView({ event: null });
                  }}
                  style={{
                    marginRight: '10px',
                  }}
                  icon={<VectorLeftIcon />}
                />
                <p className='go-back-header'>
                  К расписанию {moment(date).format('D MMMM')}
                </p>
              </div>
              <IconButton onClick={closeModal} icon={<CloseSmallIcon />} />
            </div>
            <EventViewBlock withClose={false} withoutShadow width='auto' />
          </div>
        )}
      </div>
    </FloatingModal>
  );
};

export default MonthSingleDayView;
