import { FC, useMemo, Fragment } from 'react';

import './styles.less';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import addressbookActions from 'src/addressbook/store/actions';
import { ContactOverview } from './contactOverview';
import { ContactGroups } from '../toggleGroups/types';

interface Props {
  selectedGroup: ContactGroups;
  query?: string;
}

const ContactsList: FC<Props> = ({ query, selectedGroup }) => {
  const { contacts, selectedContact, personalContacts } = useSelector(
    (s: CStoreState) => ({
      contacts: s.addressbook.contacts,
      selectedContact: s.addressbook.selectedContact,
      personalContacts: s.apiCommonData.contacts,
    }),
  );

  const isSearchMode = useMemo(() => query === '', [query]);

  const contactsByGroups = useMemo(() => {
    const groups = {};

    if (selectedGroup !== ContactGroups.Personal) {
      contacts.forEach((contact) => {
        if (
          query &&
          !(
            contact?.email?.toLowerCase().includes(query.toLowerCase()) ||
            contact?.display_name
              ?.toLowerCase()
              .includes(query.toLowerCase()) ||
            contact?.firstname?.toLowerCase().includes(query.toLowerCase()) ||
            contact?.lastname?.toLowerCase().includes(query.toLowerCase())
          )
        ) {
          return;
        }

        const firstLetter = contact.display_name[0].toUpperCase();
        const formatted = {
          ...contact,
          firstLetter,
        };
        if (Array.isArray(groups[firstLetter])) {
          groups[firstLetter].push(formatted);
        } else {
          groups[firstLetter] = [formatted];
        }
      });

      return groups;
    } else if (Array.isArray(personalContacts)) {
      personalContacts
        .map((contact) => {
          let displayName = contact?.last_name || '';
          if (contact?.first_name) {
            if (!displayName) {
              displayName = contact?.first_name;
            } else {
              displayName += ` ${contact?.first_name}`;
            }
          }
          if (!displayName) {
            displayName = contact.email;
          }
          const firstLetter = displayName[0].toUpperCase();

          return {
            ...contact,
            lastname: contact.last_name,
            display_name: displayName,
            firstLetter,
          };
        })
        .filter(
          (contact) =>
            contact?.email.toLowerCase().includes(query.toLowerCase()) ||
            contact?.display_name.toLowerCase().includes(query.toLowerCase()),
        )
        .forEach((contact) => {
          const firstLetter = contact.display_name[0].toUpperCase();
          if (Array.isArray(groups[firstLetter])) {
            groups[firstLetter].push(contact);
          } else {
            groups[firstLetter] = [contact];
          }
        });
    }

    return groups;
  }, [contacts, query, selectedGroup, personalContacts]);

  const onContactClicked = (contact) => {
    if (contact.email === selectedContact?.email) {
      addressbookActions().unsetSelectedContact();
    } else {
      let lookup = 'people';
      if (selectedGroup === ContactGroups.DistributionGroups) {
        lookup = 'mailing';
      }
      addressbookActions().setSelectedContact({
        ...contact,
        lookup,
      });
    }
  };

  return (
    <div className='addressbook__contacts'>
      {Object.keys(contactsByGroups).length === 0 ? (
        <div className='addressbook__contacts__no-found'>Нет результатов</div>
      ) : (
        <>
          {Object.keys(contactsByGroups)
            .sort((a, b) => a.localeCompare(b, 'ru'))
            .map((letter) => (
              <Fragment key={letter}>
                {contactsByGroups[letter].map((contact, id) => (
                  <ContactOverview
                    key={contact.email}
                    contact={contact}
                    showFirstLetter={id === 0}
                    onContactClicked={() => onContactClicked(contact)}
                    selected={contact.email === selectedContact?.email}
                    isSearchMode={isSearchMode}
                    query={query}
                  />
                ))}
              </Fragment>
            ))}
        </>
      )}
    </div>
  );
};

export default ContactsList;
