import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'src/_api/api';
import { SET_SELECTED_CONTACT } from '../store/actionTypes';
import { SetSelectedAction } from '../store/types';
import addressbookActions from '../store/actions';
import { getFormattedContacts } from './utils';

function* fetchAddressbookMailingGroup(action: SetSelectedAction) {
  const contact = action.payload;

  if (contact?.lookup !== 'mailing') {
    return;
  }

  const query = contact.email;

  try {
    yield put(
      addressbookActions().setMailingContacts({
        status: 'loading',
      }),
    );

    const { data } = yield call(api('getAddreebookContacts'), {
      params: {
        lookup: 'members',
        query,
      },
    });

    const items = data?.items || [];

    let next = data.next;

    // const MAX_PAGES = 0;
    const MAX_PAGES = 5;
    // TODO: refactor this shit (бэк пока не может по кайфу юзеров отдавать с пагинацией)
    for (let i = 0; i < MAX_PAGES; i++) {
      if (!next) break;
      const { data } = yield call(api('getAddreebookContacts'), {
        params: {
          lookup: 'members',
          query,
        },
        headers: { next },
      });

      if (data?.items) {
        items.push(...data.items);
      }

      next = data.next;
    }

    yield put(
      addressbookActions().setMailingContacts({
        status: 'success',
        contacts: getFormattedContacts(items),
      }),
    );
  } catch (e) {
    yield put(
      addressbookActions().setMailingContacts({
        status: 'error',
        contacts: [],
      }),
    );
  }
}

export function* fetchAddressbookMailingGroupSaga(): Generator {
  yield takeLatest(SET_SELECTED_CONTACT, fetchAddressbookMailingGroup);
}
