import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import Input from '@rambler-components/input';
import Calendar from '@rambler-components/calendar';
import { CalendarIcon } from '../Svg';
import { useWindowSize } from '../../_utils/hooks/useWindowSize';
import eventCreateEditActions from '../../eventCreateEdit/store/actions';

import '@rambler-components/calendar/styles.css';
import '@rambler-components/input/styles.css';
import './styles.less';
import { useClickOutside } from '../../_utils/hooks/useClickOutside';
import Portal from '../Portal';
import { MOBILE_MAX_WIDTH } from '../../_constants';

interface DateInputProps {
  value?: string;
  onChange?: (v: string) => void;
  onBlur?: (v: string) => void;
  minDate?: Date;
  style?: CSSProperties;
  status?: 'error' | 'warning' | 'success';
  calendarPosition?: 'left' | 'right';
  initOpenMonth?: string; // передаётся как дата первого дня месяца
  closeOnScroll?: string; // передаётся название класса элемента, который будут скроллить
}

const DateInput: FC<DateInputProps> = ({
  value: propsValue,
  onChange,
  onBlur,
  minDate,
  style,
  status,
  calendarPosition = 'left',
  initOpenMonth,
  closeOnScroll,
}) => {
  const [value, setValue] = useState(propsValue);
  const [prevValue, setPrevValue] = useState(propsValue);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const { height, width } = useWindowSize();
  const dateRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>();
  const dropdownRef = useClickOutside<HTMLDivElement>(
    () => setCalendarOpened(false),
    [dateRef],
  );

  useEffect(() => {
    setPrevValue(value);
    if (value.includes('/')) setValue(value.replace('/', '.'));
  }, [value]);

  useEffect(() => {
    if (calendarOpened) return;
    const formatted = moment(value, true).format('DD.MM.YYYY');
    if (formatted === 'Invalid date') return;

    setValue(formatted);

    setCalendarOpened(false);
    onChange && onChange(formatted);
    onBlur && onBlur(formatted);

    eventCreateEditActions().updateEventCreateEditFieldsData({
      key: 'dateEnd',
      value: formatted,
    });
  }, [calendarOpened]);

  useEffect(() => {
    if (!closeOnScroll) return;

    const wrapper = document.querySelector(closeOnScroll);
    if (calendarOpened && wrapper) {
      wrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      wrapper?.removeEventListener('scroll', handleScroll);
    };
  }, [closeOnScroll, calendarOpened]);

  const handleScroll = () => {
    setCalendarOpened(false);
  };

  useEffect(() => {
    setValue(propsValue);
    setPrevValue(propsValue);
  }, [propsValue]);

  const changeHandler = (v: string) => {
    if (!v) {
      setValue('');

      return;
    }

    setValue(v.replace(/[^0-9.]+/g, ''));
  };

  const selectCalendar = (v: Date) => {
    const formatted = moment(v).format('DD.MM.YYYY');
    setValue(formatted);
    setCalendarOpened(false);
    onChange && onChange(formatted);
    onBlur && onBlur(formatted);
  };

  const onBlurHandler = () => {
    const regExp = new RegExp(/\d{1,2}\.\d{1,2}\.\d{4}/g);
    const test = regExp.test(value);
    if (test) {
      let tmp = value;

      if (value.split('.')[0].length === 1) {
        tmp = '0' + value;
      }
      if (value.split('.')[1].length === 1) {
        const splitted = tmp.split('.');
        splitted[1] = '0' + splitted[1];
        tmp = splitted.join('.');
      }

      if (tmp !== value) {
        setValue(tmp);
      }

      setPrevValue(tmp);
      onChange && onChange(tmp);
      onBlur && onBlur(tmp);

      return;
    }
    setValue(prevValue);
    onBlur && onBlur(prevValue);
  };

  const dropdownStyles = useMemo(() => {
    const rect = dateRef.current?.getBoundingClientRect();
    if (!rect) return;

    let top = rect.bottom + 5;
    if (height - rect.top < 350) {
      top = rect.top - 300;
    }

    let left = undefined;
    const right = undefined;
    let transform = undefined;

    if (width <= MOBILE_MAX_WIDTH) {
      left = rect.left;
    } else if (calendarPosition === 'left') {
      left = rect.left;
    } else if (calendarPosition === 'right') {
      left = rect.right;
      transform = 'translate3d(-100%, 0, 0)';
    }

    return {
      top,
      left,
      right,
      transform,
    };
  }, [calendarOpened, width, height]);

  return (
    <div className='date-input' ref={dateRef}>
      <Input
        forwardRef={inputRef}
        status={status}
        placeholder='Дата'
        value={value}
        onClick={() => setCalendarOpened(true)}
        className={cn({
          active: calendarOpened,
          default: value === '',
        })}
        onChange={(e) => changeHandler(e.target.value)}
        icon={<CalendarIcon />}
        onClickIcon={() => setCalendarOpened((prev) => !prev)}
        style={{
          width: '100%',
          ...style,
        }}
        onBlur={onBlurHandler}
      />
      <Portal>
        <div
          ref={dropdownRef}
          className='date-input-dropdown'
          style={dropdownStyles}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {calendarOpened && (
            <Calendar
              today={new Date()}
              minDate={minDate}
              isSelectable
              isAbroadDaysHidden={!!minDate}
              isEdgeDaysActive
              value={moment(value, 'DD.MM.YYYY').toDate()}
              initDate={moment(initOpenMonth || value, 'DD.MM.YYYY').toDate()}
              onChange={selectCalendar}
            />
          )}
        </div>
      </Portal>
    </div>
  );
};

export default DateInput;
