import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { EVENT_CREATE_EDIT_ERRORS } from 'src/eventCreateEdit/constants';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import DateInput from 'src/_components/DateInput';
import NumberInput from 'src/_components/NumberInput';
import {
  parseRruleData,
  generatePeriodRule,
  ParsedPeriod,
  getRruleHumanString,
} from 'src/_utils/recurrence';

interface PeriodTypeProps {
  start: string;
  opened: boolean;
  recurrence: string[] | null;
  setRecurrence: (recurrence: null | string[]) => void;
  isEdit: boolean;
  onlyDisplay: boolean;
  wholeDay: boolean;
}

const PeriodType: FC<PeriodTypeProps> = ({
  start,
  opened,
  recurrence,
  setRecurrence,
  isEdit,
  onlyDisplay,
  wholeDay,
}) => {
  const [count, setCount] = useState(1);
  const [tillDate, setTillDate] = useState<string>('');

  useEffect(() => {
    if (!recurrence || !opened || !isEdit) return;
    const data = parseRruleData(recurrence) as ParsedPeriod;

    setTillDate(data.until || '');
    setCount(data.count || 1);
  }, [opened]);

  useEffect(() => {
    eventCreateEditActions().removeFieldsErrorEventCreateEdit({
      field: 'recurrence',
      error: EVENT_CREATE_EDIT_ERRORS['reccurencyWeekDays'],
    });

    const rule = generatePeriodRule({
      start,
      untilDate: tillDate,
      interval: count,
      wholeDay,
    });

    setRecurrence(rule.toString().split('\n'));
  }, [tillDate, count, wholeDay]);

  return (
    <div
      className='event-create-reccurency-type-block'
      style={{
        marginTop: onlyDisplay ? '0px' : undefined,
      }}
    >
      {!onlyDisplay && (
        <div className='event-create-reccurency-type-head'>
          <span style={{ marginLeft: 0 }}>Повторять событие</span>
          <NumberInput
            value={count}
            onChange={(v) => setCount(v)}
            min={1}
            max={365}
            style={{
              width: '70px',
            }}
          />
          <span>день до</span>
          <DateInput
            value={tillDate}
            initOpenMonth={start}
            minDate={moment(start, 'DD.MM.YYYY').toDate()}
            onChange={setTillDate}
            style={{
              width: '150px',
            }}
            closeOnScroll='.event-create-edit-anchor'
          />
        </div>
      )}
      <p
        className='event-create-reccurency-block-help-text'
        style={{
          marginTop: onlyDisplay ? '0px' : undefined,
        }}
      >
        {getRruleHumanString({
          start,
          tense: onlyDisplay ? 'present' : 'future',
          data: {
            type: 'PERIOD',
            count,
            until: tillDate,
          },
        })}
      </p>
    </div>
  );
};

export default PeriodType;
