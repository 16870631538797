import { call, put, select, takeEvery } from 'redux-saga/effects';
import { EVENT_CREATE_EDIT_CALENDARS_COMPARING } from '../store/actionTypes';
import {
  CalendarsComparingEventCreateEditAction,
  EventCreateEditState,
} from '../store/types';
import { CStoreState } from 'src/_redux/types';
import moment from 'moment';
import api from 'src/_api/api';
import { calendarsComparing } from '../store/actions/calendarsComparing';
import selectProfileEmail from 'src/_apiCommonData/store/selectors/selectProfileEmail';
import { transformCalDavDate } from 'src/_utils/caldav';
import { getEventUserPartStatus } from 'src/_utils/getEventUserPartStatus';
import { USER_PART_STATUS } from 'src/_constants';
import { checkTimeOverlap, getTimezone } from 'src/_utils/date';
import { addSnack } from '../../_redux/slices/snackbar';

function* handleComparingCalendars(
  action: CalendarsComparingEventCreateEditAction,
): Generator {
  if (action.payload.status !== 'START' && action.payload.status !== 'REDO')
    return;

  const isComparing = yield select(
    (s: CStoreState) => s.eventCreateEdit.comparingCalendarsData.isComparing,
  ) as unknown as boolean;

  if (!isComparing) return;

  const { dateStart, dateEnd, attendees } = (yield select(
    (s: CStoreState) => s.eventCreateEdit.comparingCalendarsData.dataToProcess,
  )) as unknown as CStoreState['eventCreateEdit']['comparingCalendarsData']['dataToProcess'];

  const editData = (yield select(
    (s: CStoreState) => s.eventCreateEdit.editData,
  )) as unknown as EventCreateEditState['editData'] | null;

  const start = moment(dateStart, 'DD.MM.YYYY');
  start.subtract('w', 1);
  const end = moment(dateEnd, 'DD.MM.YYYY');
  end.add('w', 1);

  const timezone =
    editData?.masterEvent.start.timeZone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  end.add('d', 1);

  const profileEmail = (yield select(selectProfileEmail)) as unknown as string;

  const from = start.format();
  const to = end.format();

  if (from === 'Invalid date' || to === 'Invalid date') return;

  const emails = attendees.map((at) => at.uri.replace('mailto:', ''));
  if (!emails.includes(profileEmail)) {
    emails.push(profileEmail);
  }

  try {
    const { data }: any = yield call(api('busy'), {
      url: {
        from,
        to,
        emails,
      },
    });
    const { data: organizerEvents }: any = yield call(api('eventList'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        expand: true,
        timeZone: getTimezone(),
        timeMin: from,
        timeMax: to,
      },
    });

    const orgEvents = [];

    for (let i = 0; i < organizerEvents.events.length; i++) {
      const userUri = `mailto:${profileEmail}`;
      const partStatus = getEventUserPartStatus(
        organizerEvents.events[i],
        userUri,
      );

      if (partStatus !== USER_PART_STATUS.declined) {
        orgEvents.push(organizerEvents.events[i]);
      }
    }

    const processedData = Object.fromEntries(
      data.freebusy.map((at) => [
        at.email,
        [...(at.busy || []), ...(at.tentative || [])].map((ob) => ({
          start: moment(transformCalDavDate(ob.start)).tz(timezone).format(),
          end: moment(transformCalDavDate(ob.end)).tz(timezone).format(),
        })),
      ]),
    );

    const organizerEventsToBusy = processedData[profileEmail].map((d, i) => [
      i,
      {
        start: d.start,
        end: d.end,
        events: [],
      },
    ]);

    orgEvents.forEach((ev) => {
      const start = moment(transformCalDavDate(ev.start.value));
      let end;

      if (ev.end?.value) {
        end = moment(transformCalDavDate(ev.end.value));
      } else {
        end = moment(transformCalDavDate(ev.start.value)).endOf('day');
      }

      organizerEventsToBusy.forEach((b: any, i: number) => {
        const busyStart = moment(b[1].start);
        const busyEnd = moment(b[1].end);

        if (
          busyStart.date() === start.date() &&
          busyEnd.date() === end.date() &&
          checkTimeOverlap([
            [start.format('HH:mm'), end.format('HH:mm')],
            [busyStart.format('HH:mm'), busyEnd.format('HH:mm')],
          ])
        ) {
          organizerEventsToBusy[i][1].events.push(ev);
        }
      });
    });

    yield put(
      calendarsComparing.action({
        status: 'SUCCESS',
        data: processedData,
        organizerBusyEvents: Object.fromEntries(organizerEventsToBusy),
      }),
    );
  } catch (e) {
    yield put(
      calendarsComparing.action({
        status: 'FAIL',
      }),
    );

    yield put(
      addSnack({
        message: 'Не удалось загрузить список событий участников',
        type: 'error',
      }),
    );
  }
}

export function* handleComparingCalendarsSaga(): Generator {
  yield takeEvery(
    EVENT_CREATE_EDIT_CALENDARS_COMPARING,
    handleComparingCalendars,
  );
}
