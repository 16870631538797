import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_WINDOW_OPEN } from '../actionTypes';
import { EventCreateEditState, OpenEventCreateWindowAction } from '../types';

export const openEventCreateWindow = createAction<
  OpenEventCreateWindowAction,
  EventCreateEditState
>(EVENT_CREATE_WINDOW_OPEN, (state, payload) =>
  state.mode !== 'none'
    ? state
    : {
        ...state,
        mode: 'create',
        editData: null,
        fieldsData: {
          ...state.fieldsData,
          ...(payload || {}),
        },
      }
);
