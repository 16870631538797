import { FC, useEffect, useRef, useState } from 'react';

import './styles.less';
import { Event } from 'src/_api/types/entities';
import { CloseSmallIcon } from '../../Svg';
import { markAlarmClosed } from '../localstorage';
import { useAppDispatch } from '../../../_redux/hooks';
import { hideAlert } from '../../../_redux/slices/notification';
import { checkIfToShowAlarm, getTimeLeftInfo } from '../utils';
import floatingModalActions from '../../../floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from '../../../floatingModal/constants';
import eventViewActions from '../../../eventView/store/actions';
import { useSelector } from 'react-redux';
import selectProfileEmail from '../../../_apiCommonData/store/selectors/selectProfileEmail';
import { eventToSelectedEvent } from '../../../_utils/event';
import { useNotificationEventsQuery } from '../../../services/calendar';

interface Props {
  event: Event;
}

const Alarm: FC<Props> = ({ event }) => {
  const dispatch = useAppDispatch();

  const alarmMarkerRef = useRef();
  const [innerEvent, setInnerEvent] = useState(event);

  const { data } = useNotificationEventsQuery();

  const profileEmail = useSelector(selectProfileEmail);

  useEffect(() => {
    const events = data?.events;
    if (Array.isArray(events)) {
      const updatedEvent = events.find((e) => e.id === event.id);

      if (updatedEvent) {
        if (checkIfToShowAlarm(updatedEvent)) {
          setInnerEvent(updatedEvent);
        } else {
          dispatch(hideAlert(event.id));
        }
      } else {
        dispatch(hideAlert(event.id));
      }
    }
  }, [data]);

  const onRemove = () => {
    markAlarmClosed(event.id);
    dispatch(hideAlert(event.id));
  };

  const onOpen = () => {
    eventViewActions().setEventView({
      event: eventToSelectedEvent(innerEvent, profileEmail),
    });
    floatingModalActions().openFloatingModal({
      opened: FLOATING_MODAL_IDS['eventView'],
      target: alarmMarkerRef.current,
    });
  };

  return (
    <div className='alarm__notification'>
      <div className='alarm__marker' ref={alarmMarkerRef} />
      <div className='notification__blue' />
      <div className='notification__content'>
        <div className='notification__content__summary'>
          {innerEvent.summary}
        </div>

        <div className='notification__content__info'>
          <div>{getTimeLeftInfo(innerEvent)}</div>
          <div>{innerEvent.location}</div>
          <div className='notification__content__info__more' onClick={onOpen}>
            Подробнее
          </div>
        </div>
      </div>
      <div>
        <div className='notification__close-btn' onClick={onRemove}>
          <CloseSmallIcon />
        </div>
      </div>
    </div>
  );
};

export default Alarm;
