import { createBrowserHistory } from 'history';

export const MONTHS_TITLES = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const WEEKS_TITLES_SHORT = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

export const DAY_TIME = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

export const SEARCH_PARAMS = {
  rangeStart: 'rangeStart',
  rangeEnd: 'rangeEnd',
  month: 'month',
};

export const history = createBrowserHistory({ window });

export const monthsForRecurrenceHelp = [
  'январе',
  'феврале',
  'марте',
  'апреле',
  'мае',
  'июне',
  'июле',
  'августе',
  'сентябре',
  'октябре',
  'ноябре',
  'декабре',
];

export const weekDaysForRecurrenceHelp = {
  MO: {
    short: 'пн',
    long: 'понедельникам',
  },
  TU: {
    short: 'вт',
    long: 'вторникам',
  },
  WE: {
    short: 'ср',
    long: 'средам',
  },
  TH: {
    short: 'чт',
    long: 'четвергам',
  },
  FR: {
    short: 'пт',
    long: 'пятницам',
  },
  SA: {
    short: 'сб',
    long: 'субботам',
  },
  SU: {
    short: 'вс',
    long: 'воскресеньям',
  },
};

export const CUSTOM_EVENTS = {
  floatingModalRecalculate: 'floating-modal-recalculate',
  floatingModalFullScreen: 'floating-modal-fullscreen',
  floatingModalFullScreenOff: 'floating-modal-fullscreen-off',
  eventViewLoadError: 'event-view-load-error',
  scrollEventCreateEditBody: 'scroll-event-create-edit-body',
  fatalError: 'fatal-error-event',
};

export const USER_PART_STATUS = {
  organizer: 'ORGANIZER',
  undefined: 'UNDEFINED',
  needsAction: 'NEEDS-ACTION',
  accepted: 'ACCEPTED',
  declined: 'DECLINED',
  tentative: 'TENTATIVE',
  delegated: 'DELEGATED',
};

export const EVENT_STATUS = {
  cancelled: 'CANCELLED',
};

export const EVENT_UPDATE_INTERVAL = 30000;

export const WRAPPER_CLASS_NAME = 'wrapped-content';

export const IS_CORP = process.env.target === 'corp';
export const IS_PUBLIC = process.env.target === 'public';

export const MOBILE_MAX_WIDTH = 767.4;
export const TABLET_MAX_WIDTH = 1023.4;

export const PUBLIC_SENTRY_DSN: string = `https://d045eabfffde1d8bfe4b10676a1dda1a@sentry-saas.rambler-co.ru/60`;
export const CORP_SENTRY_DSN: string = `https://4f6dff2ca52a7ebfc528d84abe90bcb0@sentry-saas.rambler-co.ru/81`;