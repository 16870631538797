import { CSSProperties, FC, ReactNode, RefObject } from 'react';
import { useClickOutside } from 'src/_utils/hooks/useClickOutside';
import './styles.less';

interface DropdownProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  close?: () => void;
  ignoreButton?: RefObject<any>;
}

const Dropdown: FC<DropdownProps> = ({
  children,
  style,
  className,
  close,
  ignoreButton,
}) => {
  const ref = useClickOutside<HTMLDivElement>(
    () => close && close(),
    ignoreButton ? [ignoreButton] : undefined,
  );

  return (
    <div className={'dropdown ' + className || ''} style={style} ref={ref}>
      {children}
    </div>
  );
};

export default Dropdown;
