/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownWrapper } from '@rambler-components/dropdown';
import '@rambler-components/dropdown/styles.css';

import './styles.less';
import { ArrowDownSmallIcon } from 'src/_components/Svg';
import { CUSTOM_EVENTS } from 'src/_constants';
import DropdownItem from 'src/_components/DropdownItem';
import { ALARM_OPTIONS } from '../../../const';
import { Alarm } from '../../../../_api/types/entities';

interface Props {
  alarms: Alarm[];
  setAlarms: (alarms: Alarm[]) => void;
}

const EventCreateReminder: FC<Props> = ({ alarms, setAlarms }) => {
  const [opened, setOpened] = useState(false);

  const value = useMemo(() => {
    const alarm = alarms?.at(0);

    const res = ALARM_OPTIONS.findIndex(
      (e) => e.value === alarm?.trigger?.relative,
    );

    if (res === -1) return 2;

    return res;
  }, [alarms]);

  const onSelect = (i: number) => {
    setAlarms(
      ALARM_OPTIONS[i].value && [
        {
          action: 'DISPLAY',
          description: 'REMINDER',
          trigger: {
            related: 'START',
            relative: ALARM_OPTIONS[i].value,
          },
        },
      ],
    );
    setOpened(false);
  };

  useEffect(() => {
    window.addEventListener(CUSTOM_EVENTS.scrollEventCreateEditBody, () =>
      setOpened(false),
    );
  }, []);

  return (
    <div className='event-create-reminder'>
      <DropdownWrapper>
        <div
          className='event-create-reminder-value'
          onClick={() => setOpened(!opened)}
        >
          {value === 8 ? (
            <span>Не напоминать</span>
          ) : (
            <span>
              Напомнить за{' '}
              {value === 7 ? 'неделю' : ALARM_OPTIONS[value].option} до начала
            </span>
          )}
          <ArrowDownSmallIcon />
        </div>
        <Dropdown
          isOpen={opened}
          onClose={() => setOpened(false)}
          className={'event-create-reminder-dropdown'}
        >
          {ALARM_OPTIONS.map((r, i) => (
            <DropdownItem
              className='event-create-reminder-select-item'
              key={`event-create-select-reminder-${i}`}
              action={() => onSelect(i)}
              withIcon
              active={i === value}
              title={r.option}
            />
          ))}
        </Dropdown>
      </DropdownWrapper>
    </div>
  );
};

export default EventCreateReminder;
