import { createAction } from 'src/_utils/redux';
import { SET_MAILING_GROUP_CONTACTS } from '../actionTypes';
import { AddressbookState, SetSelectedMailingGroupContacts } from '../types';

export const setMailingContacts = createAction<
  SetSelectedMailingGroupContacts,
  AddressbookState
>(SET_MAILING_GROUP_CONTACTS, (state, p) => ({
  ...state,
  selectedContact: {
    ...state?.selectedContact,
    mailingContacts: {
      ...state?.selectedContact?.mailingContacts,
      ...p,
    },
  },
}));
