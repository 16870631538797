import { FC, ReactNode } from 'react';
import TimeLineWrapper from '../TimeLineWrapper';
import TimeDivider from '../TimeDivider';

import './styles.less';

interface DayProps {
  items: JSX.Element[];
  isToday: boolean;
  fullWidthForTimeDivider?: boolean;
  children: ReactNode;
  noMarginBottom?: boolean;
}

export const Day: FC<DayProps> = ({
  items,
  isToday,
  children,
  fullWidthForTimeDivider,
  noMarginBottom,
}) => (
  <div
    className='calendar-day'
    style={{
      gridTemplateRows: 'repeat(24, minmax(89px, 1fr))',
      marginBottom: noMarginBottom ? '0px' : undefined,
    }}
  >
    <>{items.map((item) => item)}</>
    <TimeLineWrapper>
      {children}
      <TimeDivider show={isToday} fullWidth={fullWidthForTimeDivider} />
    </TimeLineWrapper>
  </div>
);
