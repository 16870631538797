import FloatingModal from 'src/floatingModal/component';
import EventCreateBlock from '../Block';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import './styles.less';

const EventCreateWindow = () => (
  <FloatingModal id={FLOATING_MODAL_IDS['eventCreate']}>
    <EventCreateBlock />
  </FloatingModal>
);

export default EventCreateWindow;
