import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { closeFloatingModal } from './closeFloatingModal';
import { openFloatingModal } from './openFloatingModal';
import { manageBackgroundFloatingModal } from './manageBackgroundFloatingModal';
import { toggleCentered } from './toggleCentered';

const floatingModalActions = () =>
  bindActionCreators(
    {
      closeFloatingModal: closeFloatingModal.action,
      openFloatingModal: openFloatingModal.action,
      manageBackgroundFloatingModal: manageBackgroundFloatingModal.action,
      toggleCentered: toggleCentered.action,
    },
    dispatch,
  );

export default floatingModalActions;
