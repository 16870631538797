import { ChangeEvent, FC, useCallback } from 'react';
import Textarea from '@rambler-components/textarea';
import '@rambler-components/textarea/styles.css';
import './styles.less';

interface Props {
  description: string;
  setDescription: (description: string) => void;
}

const EventCreateDescription: FC<Props> = ({ description, setDescription }) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;

    if (text.length > 3000) return;

    setDescription(text);
  }, []);

  return (
    <div className='event-create-description__wrapper'>
      <Textarea
        placeholder='Описание'
        value={description}
        onChange={handleChange}
        style={{
          marginTop: '20px',
          width: '100%',
        }}
        maxLength={3000}
        rows={4}
      />
    </div>
  );
};

export default EventCreateDescription;
