import { loadScript } from './load-script';

export const isSSR = typeof window === 'undefined';

const PUBLIC_URL = 'https://id.rambler.ru/rambler-id-helper/auth_events.js';
const BIZ_URL = 'https://id.rambler.biz/rambler-id-helper/auth_events.js';

let ramblerIdHelper: any;
let ramblerIdHelperPromise: Promise<void>;

// eslint-disable-next-line max-len
// https://confluence.rambler-co.ru/pages/viewpage.action?pageId=124485978#id-%D0%9E%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%D0%BF%D0%BE%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D0%B8ID-%D0%94%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%BD%D1%8B%D0%B5%D0%BC%D0%B5%D1%82%D0%BE%D0%B4%D1%8B%D1%85%D0%B5%D0%BB%D0%BF%D0%B5%D1%80%D0%B0
// https://gitlab.rambler.ru/-/snippets/1589
// https://jira.rambler-co.ru/browse/MAILCORE-9414
export const getRamblerIdHelper = async (): Promise<any> => {
  if (!ramblerIdHelper || isSSR) {
    if (!ramblerIdHelperPromise) {
      ramblerIdHelperPromise = new Promise<void>((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.ramblerIdHelper = window.ramblerIdHelper || [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.ramblerIdHelper.push(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ramblerIdHelper = window.ramblerIdHelper;
          resolve();
        });

        const hostname = window?.location?.hostname || '';
        if (hostname.endsWith('.ru')) {
          loadScript(PUBLIC_URL).catch((error: Error) => reject(error));
        } else {
          loadScript(BIZ_URL).catch((error: Error) => reject(error));
        }
      });
    }

    try {
      await ramblerIdHelperPromise;
    } catch {
      /* empty */
    }
  }

  return ramblerIdHelper;
};
