import { FC, useRef, useState } from 'react';
import Button from '@rambler-components/button';
import { CopyIcon, MailIcon, PrintIcon, TrashIcon } from 'src/_components/Svg';
import IconButton from 'src/_components/IconButton';
import DeleteConfirm from '../DeleteConfirm';
import eventViewActions from 'src/eventView/store/actions';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import DropdownConfirm from '../DropdownConfirm';
import { isFirstInRecurrence } from '../../../_utils/event';
import cn from 'classnames';

import css from './EventBlock.module.less';
import { CalendarTooltip } from '../../../_components/CalendarTooltip';
import { useLocation } from 'react-router-dom';
import { dinamicRoutes } from 'src/_api/constants';
import { getEventMailLink } from '../../../_utils/mail';

interface EventBlockOrganizerActionsProps {
  event: SelectedEvent;
}

const EventBlockOrganizerActions: FC<EventBlockOrganizerActionsProps> = ({
  event,
}) => {
  const [editDropdown, setEditDropdown] = useState(false);
  const [deleteDropdown, setDeleteDropdown] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<
    'all' | 'single' | 'this-and-future' | 'regular' | ''
  >('');
  const editButtonRef = useRef<HTMLDivElement>();
  const deleteButtonRef = useRef<HTMLDivElement>();
  const location = useLocation();

  const onMail = () => {
    const link = getEventMailLink(event.id);
    window.open(link, '_blank');
  };

  const deleteEvent = () => {
    switch (showDeleteConfirm) {
      case 'regular': {
        eventViewActions().deleteEventView();
        break;
      }
      default: {
        if (
          showDeleteConfirm === 'this-and-future' &&
          isFirstInRecurrence(event)
        ) {
          eventViewActions().deleteEventView({
            recurrenceType: 'all',
          });
        } else {
          eventViewActions().deleteEventView({
            recurrenceType: showDeleteConfirm as 'all',
          });
        }
        break;
      }
    }
    setShowDeleteConfirm('');
  };

  return (
    <div className={cn('flex-between', css.eventActions)}>
      <div style={{ position: 'relative' }} ref={editButtonRef}>
        {editDropdown && (
          <DropdownConfirm
            buttonRef={editButtonRef}
            close={() => setEditDropdown(false)}
            actions={[
              () => {
                eventViewActions().editEventView({
                  recurrenceType: 'single',
                });
                setEditDropdown(false);
              },
              () => {
                eventViewActions().editEventView({
                  recurrenceType: 'this-and-future',
                });
                setEditDropdown(false);
              },
              () => {
                eventViewActions().editEventView({
                  recurrenceType: 'all',
                });
                setEditDropdown(false);
              },
            ]}
          />
        )}
        <Button
          className={editDropdown ? 'event-active-button' : ''}
          type='white'
          onClick={
            event.isRecurrence
              ? () => setEditDropdown(!editDropdown)
              : () =>
                  eventViewActions().editEventView({
                    recurrenceType: 'regular',
                  })
          }
        >
          Изменить событие
        </Button>
      </div>
      <div className={cn('flex', css.buttonsRight)}>
        <CalendarTooltip
          label='Написать участникам'
          position='top'
          autoPosition
          isVisible={deleteDropdown ? false : undefined}
        >
          <div>
            <IconButton icon={<MailIcon />} onClick={onMail} />
          </div>
        </CalendarTooltip>

        <CalendarTooltip
          label='Дублировать событие'
          position='top'
          isAlwaysCentered
          autoPosition
          isVisible={deleteDropdown ? false : undefined}
        >
          <div>
            <IconButton
              icon={<CopyIcon />}
              onClick={() => eventViewActions().copyEventView()}
            />
          </div>
        </CalendarTooltip>

        <CalendarTooltip
          label='Распечатать событие'
          position='top'
          autoPosition
          isVisible={deleteDropdown ? false : undefined}
        >
          <a
            href={`${dinamicRoutes.print('calendar')}?eventId=${
              event.id
            }&date=${event.date.toDateString()}&${location.search.slice(1)}`}
            target='_blank'
          >
            <div>
              <IconButton
                className={cn(css.printIcon)}
                icon={<PrintIcon />}
                onClick={() => null}
              />
            </div>
          </a>
        </CalendarTooltip>
        <div style={{ position: 'relative' }} ref={deleteButtonRef}>
          {deleteDropdown && (
            <DropdownConfirm
              bottom='35px'
              right={0}
              buttonRef={deleteButtonRef}
              close={() => setDeleteDropdown(false)}
              actions={[
                () => {
                  setShowDeleteConfirm('single');
                  setDeleteDropdown(false);
                },
                () => {
                  setShowDeleteConfirm('this-and-future');
                  setDeleteDropdown(false);
                },
                () => {
                  setShowDeleteConfirm('all');
                  setDeleteDropdown(false);
                },
              ]}
            />
          )}
          {showDeleteConfirm && (
            <DeleteConfirm
              type={showDeleteConfirm}
              onDelete={deleteEvent}
              onCancel={() => setShowDeleteConfirm('')}
            />
          )}
          <CalendarTooltip
            label='Удалить событие'
            position='top'
            autoPosition
            isVisible={deleteDropdown || showDeleteConfirm ? false : undefined}
          >
            <div>
              <IconButton
                isActive={deleteDropdown}
                icon={<TrashIcon />}
                onClick={
                  event.isRecurrence
                    ? () => setDeleteDropdown(!deleteDropdown)
                    : () => setShowDeleteConfirm('regular')
                }
              />
            </div>
          </CalendarTooltip>
        </div>
      </div>
    </div>
  );
};

export default EventBlockOrganizerActions;
