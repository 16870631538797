import { createAction } from 'src/_utils/redux';
import { CLEAR_ERROR_CONTACTS_API_COMMON_DATA } from '../actionTypes';
import {
  ApiCommonDataState,
  ClearErrorContactsApiCommonDataAction,
} from '../types';

export const clearErrorContactsApiCommonData = createAction<
  ClearErrorContactsApiCommonDataAction,
  ApiCommonDataState
>(CLEAR_ERROR_CONTACTS_API_COMMON_DATA, (state, p) => ({
  contacts: state.contacts === 'error' ? [] : state.contacts,
  profile: state.profile,
}));
