import { FC, useState } from 'react';

import './styles.less';
import { CloseIcon, SearchIcon } from 'src/_components/Svg';
import Button from '@rambler-components/button';
import IconButton from 'src/_components/IconButton';
import Input from '@rambler-components/input';

interface Props {
  setQuery: (query: string) => void;
}

const SearchInput: FC<Props> = ({ setQuery }) => {
  const [value, setValue] = useState('');

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setQuery(value);
    }
  };

  const onClear = () => {
    setQuery('');
    setValue('');
  };

  return (
    <div className='search-input'>
      <div className='search-input__input'>
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder='Поиск'
          onKeyDown={onKeyDown}
          style={{
            width: '100%',
          }}
          icon={
            <Button
              className='search-input__button'
              onClick={() => {
                setQuery(value);
              }}
            >
              <SearchIcon />
            </Button>
          }
        />
        {value && (
          <IconButton
            className='search-input__input__clear'
            icon={<CloseIcon />}
            onClick={onClear}
          />
        )}
      </div>
    </div>
  );
};

export default SearchInput;
