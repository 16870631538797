import { FC } from 'react';
import Checkbox from '@rambler-components/checkbox';

import '@rambler-components/checkbox/styles.css';
import './styles.less';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';

interface Props {
  wholeDay: boolean;
  setWholeDay: (wholeDay: boolean) => void;
}

const EventCreateWholeDay: FC<Props> = ({ wholeDay, setWholeDay }) => {
  const { mode, recurrenceType } = useSelector((state: CStoreState) => ({
    mode: state.eventCreateEdit.mode,
    recurrenceType: state.eventCreateEdit.editData?.recurrenceType || '',
  }));

  return (
    <Checkbox
      checked={wholeDay}
      disabled={mode == 'edit' && recurrenceType != 'regular'}
      onChange={(e) => setWholeDay(e.target.checked)}
    >
      На весь день
    </Checkbox>
  );
};

export default EventCreateWholeDay;
