import { FC } from 'react';
import FieldStatus from '@rambler-components/field-status';
import '@rambler-components/field-status/styles.css';
import './styles.less';

interface FieldErrorsProps {
  errors?: string[];
  fieldKey: string;
}

const FieldErrors: FC<FieldErrorsProps> = ({ errors, fieldKey }) => (
  <>
    {errors &&
      Array.from(new Set(errors)).map((er, i) => (
        <FieldStatus
          message={er}
          key={`field-${fieldKey}-${i}`}
          status='error'
          children={<></>}
        />
      ))}
  </>
);

export default FieldErrors;
