import { FC, RefObject } from 'react';
import cn from 'classnames';

interface MonthItemProps {
  isActive: boolean;
  isPast: boolean;
  time?: string;
  onClick: (part: 'top' | 'bottom') => void;
  refs: RefObject<HTMLDivElement[]>;
  index: number;
}

const MonthItem: FC<MonthItemProps> = ({
  isActive,
  isPast,
  time,
  onClick,
  refs,
  index,
}) => (
  <div
    className={cn('week-item', {
      active: isActive,
      past: isPast,
    })}
    ref={(el) => (refs.current[index] = el)}
  >
    <div className='week-item-body'>
      <div className='week-item-content'>
        {!!time && <p className='week-item-text'>{time}</p>}
      </div>
      <div
        style={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          cursor: onClick ? 'pointer' : undefined,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
          onClick={() => onClick('top')}
        />
        <div
          style={{
            flex: 1,
          }}
          onClick={() => onClick('bottom')}
        />
      </div>
    </div>
  </div>
);

export default MonthItem;
