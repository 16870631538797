import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { getDatesForRange } from '../../../_utils/date';
import moment from 'moment/moment';

const getHourTitle = (i: number, hours: number, start: string, end: string) => {
  const instance = i + 1;
  if (hours > 23) {
    const datesRange = getDatesForRange(start, end);
    if (instance % 24 === 0) {
      const index = instance / 24;
      if (instance === hours) return '';
      if (index) {
        return moment(datesRange[index], 'DD.MM.YYYY')
          .format('D MMM')
          .replace('.', '');
      }

      return '00';
    }
    const h = instance > 24 ? instance % 24 : instance;

    return h >= 10 ? h : `0${h}`;
  } else {
    return instance >= 10 ? instance : `0${instance}`;
  }
};

interface Props {
  hours: number;
  start: string;
  end: string;
}

export const Heading: FC<Props> = ({ hours, start, end }) => {
  const [number, setNumber] = useState(hours);

  useEffect(() => {
    setNumber(hours > 0 ? hours : 24);
  }, [hours]);

  return (
    <>
      {Array(number)
        .fill(0)
        .map((_, i) => (
          <div
            className={cn('comparing-timeline-header-time', {
              black: (i + 1) % 24 === 0 && (i + 1) / 24 !== hours,
            })}
            key={`comparing-timeline-header-time-${i}`}
          >
            <span>{getHourTitle(i, hours, start, end)}</span>
          </div>
        ))}
    </>
  );
};
