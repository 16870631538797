import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_REQUEST } from '../actionTypes';
import { EventCreateEditState, RequestEventCreateAction } from '../types';

export const requestEventCreate = createAction<
  RequestEventCreateAction,
  EventCreateEditState
>(EVENT_CREATE_REQUEST, (state) => ({
  ...state,
  requestLoading: true,
}));
