import { ERRORS, SIZE } from './const';
import { EventAttendee, Upload } from '../_api/types/entities';

export const transformUploadsToList = (args: {
  [id: string]: Upload;
}): Array<Upload> => {
  const result: Array<Upload> = [];
  for (const u in args) {
    result.push({
      ...args[u],
    });
  }

  return result;
};

export const checkFilesSize = (files: Array<{ size: number }> = []) => {
  let totalSize = 0;

  for (let i = 0; i < files.length; i++) {
    totalSize += files[i].size;

    if (files[i].size > SIZE.MAX_FILESIZE) {
      return ERRORS.FILESIZE;
    }

    if (totalSize > SIZE.MAX_ATTACHSIZE) {
      return ERRORS.ATTACHSIZE;
    }
  }

  return false;
};

export const getEventEmails = (
  attendees: EventAttendee[],
  profileEmail: string,
) => {
  const res = attendees.map((at) => at.uri.replace('mailto:', ''));

  if (!res.includes(profileEmail)) {
    res.unshift(profileEmail);
  } else {
    res.sort((a) => (a === profileEmail ? -1 : 1));
  }

  return res;
};
