import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '@rambler-components/loader';

import './styles.less';
import SearchInput from 'src/addressbook/components/searchInput';
import ToggleGroups from 'src/addressbook/components/toggleGroups';
import { ContactGroups } from 'src/addressbook/components/toggleGroups/types';
import ContactsList from 'src/addressbook/components/contactsList';
import ContactView from '../../components/contactView';
import addressbookActions from '../../store/actions';
import { CStoreState } from 'src/_redux/types';
import apiCommonDataActions from 'src/_apiCommonData/store/actions';

const Contacts = () => {
  const [contactsGroup, setContactsGroup] = useState(ContactGroups.Employees);
  const [query, setQuery] = useState('');

  const [showSpinner, setShowSpinner] = useState(false);
  const timeoutRef = useRef(null);

  const loadingStatus = useSelector(
    (state: CStoreState) => state.addressbook.status,
  );

  useEffect(() => {
    if (contactsGroup === ContactGroups.Personal) {
      apiCommonDataActions().loadContactsApiCommonData();
    }
  }, [contactsGroup]);

  useEffect(() => {
    if (contactsGroup === ContactGroups.Employees) {
      addressbookActions().loadAddressbookContactsApi({
        // query,
        query: '',
        lookup: 'people',
      });
    } else if (contactsGroup === ContactGroups.DistributionGroups) {
      addressbookActions().loadAddressbookContactsApi({
        // query,
        query: '',
        lookup: 'mailing',
      });
    }
  }, [query, contactsGroup]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (loadingStatus === 'loading') {
      if (query) {
        timeoutRef.current = setTimeout(() => setShowSpinner(true), 300);
      } else {
        setShowSpinner(true);
      }
    } else if (
      contactsGroup === ContactGroups.Personal ||
      loadingStatus !== 'init'
    ) {
      setShowSpinner(false);
      clearTimeout(timeoutRef.current);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [query, contactsGroup, loadingStatus]);

  return (
    <div className='contacts-background'>
      <div className='contacts-background__header'>Адресная книга</div>
      <div className='contacts__content__wrapper'>
        <div className='contacts__content'>
          <div className='contacts__search-bar'>
            <SearchInput setQuery={setQuery} />
            {showSpinner && (
              <div className='contacts__loader'>
                <Loader />
              </div>
            )}
          </div>
          <div className='contacts__toggle-groups'>
            <ToggleGroups
              selected={contactsGroup}
              onSelect={(group) => setContactsGroup(group)}
            />
          </div>
          {!showSpinner && (
            <ContactsList query={query} selectedGroup={contactsGroup} />
          )}
        </div>
        <ContactView />
      </div>
    </div>
  );
};

export default Contacts;
