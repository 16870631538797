import { FC } from 'react';
import Input from '@rambler-components/input';
import FieldErrors from '../../FieldErrors';

import '@rambler-components/input/styles.css';

import css from './Title.module.less';

interface Props {
  title: string;
  onChange: (v: string) => void;
  errors?: string[];
}

const EventCreateTitle: FC<Props> = ({ title, onChange, errors }) => (
  <>
    <Input
      className={css.titleInput}
      placeholder='Название события'
      value={title}
      status={errors?.length ? 'error' : undefined}
      onChange={(e) => onChange(e.target.value)}
      maxLength={300}
    />
    <FieldErrors fieldKey='title' errors={errors} />
  </>
);

export default EventCreateTitle;
