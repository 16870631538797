import { createSearchParams, matchPath } from 'react-router-dom';
import { call, put } from 'redux-saga/effects';
import { SEARCH_PARAMS, history, TABLET_MAX_WIDTH } from 'src/_constants';
import {
  getCalendarFullMonthDays,
  getDateWeekRange,
  getFirstDayInMonth,
} from 'src/_utils/date';
import { controlsInit } from '../store/actions/controlsInit';
import { isSSR } from '../../scripts/idHelper/getter';
import { dinamicRoutes } from 'src/_api/constants';

const today = new Date();

const toDefault = () => {
  const params = createSearchParams({
    month: getFirstDayInMonth(today).toISOString(),
  });
  history.push(`/calendar/month?${params}`);
};

export default function* syncUrlDataWithControls(): Generator {
  let currentPage: 'calendar' | 'contacts' | 'rooms' | 'people' | 'print';

  if (location.pathname.startsWith('/calendar')) {
    currentPage = 'calendar';
  } else if (location.pathname.startsWith('/contacts')) {
    currentPage = 'contacts';
  }

  if (location.pathname === '/') {
    if (!isSSR && window.innerWidth <= TABLET_MAX_WIDTH) {
      currentPage = 'calendar';
      yield call(history.push, `/${currentPage}/day`);
    } else {
      toDefault();

      yield put(
        controlsInit.action({
          selectedMonth: getFirstDayInMonth(today),
          selectedDateRange: null,
          mode: 'month',
          page: 'calendar',
        }),
      );

      return;
    }
  }

  const searchParams = new URLSearchParams(location.search);
  const rangeStart = searchParams.get(SEARCH_PARAMS.rangeStart);
  const rangeEnd = searchParams.get(SEARCH_PARAMS.rangeEnd);
  const month = searchParams.get(SEARCH_PARAMS.month);

  const matchRoutes = {
    month: matchPath(dinamicRoutes.month(currentPage), location.pathname),
    week: matchPath(dinamicRoutes.week(currentPage), location.pathname),
    day: matchPath(dinamicRoutes.day(currentPage), location.pathname),
    all: matchPath(dinamicRoutes.all(currentPage), location.pathname),
    print: matchPath(dinamicRoutes.print(currentPage), location.pathname),
  };

  if (!isSSR && window.innerWidth <= TABLET_MAX_WIDTH && !matchRoutes.day) {
    matchRoutes.day = matchPath(`/${currentPage}/day`, `/${currentPage}/day`);
    delete matchRoutes.all;
    delete matchRoutes.month;
    delete matchRoutes.week;
  }

  if (matchRoutes.month) {
    if (!month) {
      const newSearchParams = createSearchParams({
        month: getFirstDayInMonth(today).toISOString(),
      });
      yield call(history.push, `/${currentPage}/month?${newSearchParams}`);

      yield put(
        controlsInit.action({
          selectedMonth: getFirstDayInMonth(today),
          selectedDateRange: null,
          mode: 'month',
          page: 'calendar',
        }),
      );

      return;
    }

    yield put(
      controlsInit.action({
        selectedMonth: new Date(month),
        selectedDateRange: null,
        mode: 'month',
        page: 'calendar',
      }),
    );

    return;
  }

  if (matchRoutes.week) {
    if (!rangeStart || !rangeEnd) {
      const currentWeekRange = getDateWeekRange(today);

      const newSearchParams = createSearchParams({
        rangeStart: currentWeekRange[0].toISOString(),
        rangeEnd: currentWeekRange[1].toISOString(),
      });

      yield call(history.push, `/${currentPage}/week?${newSearchParams}`);

      yield put(
        controlsInit.action({
          selectedMonth: getFirstDayInMonth(today),
          selectedDateRange: [currentWeekRange[0], currentWeekRange[1]],
          mode: 'week',
          page: 'calendar',
        }),
      );

      return;
    }

    const newMonth = getCalendarFullMonthDays(new Date(rangeStart))[0];

    yield put(
      controlsInit.action({
        selectedMonth: month ? new Date(month) : newMonth,
        selectedDateRange: [new Date(rangeStart), new Date(rangeEnd)],
        mode: 'week',
        page: 'calendar',
      }),
    );

    return;
  }

  if (matchRoutes.day) {
    if (!rangeStart || !rangeEnd) {
      const params = createSearchParams({
        rangeStart: today.toISOString(),
        rangeEnd: today.toISOString(),
      });
      yield call(history.push, `/${currentPage}/day?${params}`);

      yield put(
        controlsInit.action({
          selectedMonth: getFirstDayInMonth(today),
          selectedDateRange: [today, today],
          mode: 'day',
          page: 'calendar',
        }),
      );

      return;
    }

    const params = createSearchParams({
      rangeStart: rangeStart,
      rangeEnd: rangeStart,
    });
    yield call(history.push, `/${currentPage}/day?${params}`);

    yield put(
      controlsInit.action({
        selectedMonth: getFirstDayInMonth(new Date(rangeStart)),
        selectedDateRange: [new Date(rangeStart), new Date(rangeEnd)],
        mode: 'day',
        page: 'calendar',
      }),
    );

    return;
  }

  if (matchRoutes.all) {
    yield put(
      controlsInit.action({
        selectedMonth: today,
        selectedDateRange: null,
        mode: 'all',
        page: 'calendar',
      }),
    );

    return;
  }

  if (matchRoutes.print) {
    const link = `${location.pathname}${location.search}`;


    yield call(history.push, link);

    yield put(
      controlsInit.action({
        selectedMonth: new Date(month),
        selectedDateRange: null,
        mode: 'month',
        page: 'calendar',
      }),
    );

    return;
  }

  const newSearchParams = createSearchParams({
    month: getFirstDayInMonth(today).toISOString(),
  });
  yield call(history.push, `/${currentPage}/month?${newSearchParams}`);

  yield put(
    controlsInit.action({
      selectedMonth: getFirstDayInMonth(today),
      selectedDateRange: null,
      mode: 'month',
      page: 'calendar',
    }),
  );

  return;
}
