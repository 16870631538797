import { FC } from 'react';

import './styles.less';
import { ISnack } from '../../../_redux/slices/snackbar';
import classNames from 'classnames';
import { CloseIcon } from '../../Svg';

interface Props {
  snack: ISnack;
  onRemove: (id: string | number) => void;
}

const Snack: FC<Props> = ({ snack, onRemove }) => (
  <div
    className={classNames('snack', {
      ['success']: snack.type === 'success',
      ['prime']: snack.type === 'prime',
      ['error']: snack.type === 'error',
    })}
  >
    <div className='snack-message'>{snack.message}</div>
    {snack.actionButtons?.length > 0 ? (
      <>
        {snack.actionButtons.map((actionButton, id) => (
          <div
            key={id}
            className='snack-action-button'
            onClick={actionButton.onClick}
          >
            {actionButton.title}
          </div>
        ))}
      </>
    ) : (
      <div className={'snack-action-button'} onClick={() => onRemove(snack.id)}>
        <CloseIcon className={'snack-remove'} />
      </div>
    )}
  </div>
);

export default Snack;
