import { FC } from 'react';
import cn from 'classnames';

import './styles.less';

interface MonthProps {
  items: JSX.Element[];
}

export const Month: FC<MonthProps> = ({ items }) => {
  const rows = items.length ? items.length / 7 : 6;

  return (
    <div
      className={cn('calendar-month', {
        'six-rows': rows === 6,
        'five-rows': rows === 5,
        'four-rows': rows === 4,
      })}
    >
      {items.map((item) => item)}
    </div>
  );
};
