import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_EDIT_REMOVE_FIELDS_ERROR } from '../actionTypes';
import {
  EventCreateEditState,
  RemoveFieldsErrorEventCreateEditAction,
} from '../types';

export const removeFieldsErrorEventCreateEdit = createAction<
  RemoveFieldsErrorEventCreateEditAction,
  EventCreateEditState
>(EVENT_CREATE_EDIT_REMOVE_FIELDS_ERROR, (state, payload) => {
  const currentErrors = state.fieldsData.errors[payload.field];
  const errorToSliceIndex = currentErrors.findIndex(
    (er) => er === payload.error,
  );

  if (errorToSliceIndex > -1) currentErrors.splice(errorToSliceIndex, 1);

  return {
    ...state,
    fieldsData: {
      ...state.fieldsData,
      errors: {
        ...state.fieldsData.errors,
        [payload.field]: currentErrors,
      },
    },
  };
});
