import { useState, useRef, FC } from 'react';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { timeToPixelsInTimeline } from 'src/_utils/timeToPixelsInTimeline';
import EventsDropDown from '../EventsDropdown';
import { EVENTS_DROPDOWN_HEIGHTS } from '../EventsDropdown/constants';

import './styles.less';

interface DayWeekEventMoreProps {
  column: number;
  timeRange: [string, string][];
  more: number;
  width?: number;
  events: SelectedEvent[];
}

const DayWeekEventMore: FC<DayWeekEventMoreProps> = ({
  column,
  timeRange,
  more,
  width,
  events,
}) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const right = ref.current?.getBoundingClientRect().right || 0;
  const bottom = ref.current?.getBoundingClientRect().bottom || 0;

  const bottomCollision = bottom + 300 > window.innerHeight;
  const rightCollision = right + 300 > window.innerWidth;

  const firstTopPixels = timeToPixelsInTimeline(
    Number(timeRange[0][0].split(':')[0]),
    Number(timeRange[0][0].split(':')[1]),
  );

  const secondTopPixels = timeToPixelsInTimeline(
    Number(timeRange[1][0].split(':')[0]),
    Number(timeRange[1][0].split(':')[1]),
  );
  const secondHeightPixels =
    timeToPixelsInTimeline(
      Number(timeRange[1][1].split(':')[0]),
      Number(timeRange[1][1].split(':')[1]),
    ) - secondTopPixels;

  const highestTop =
    firstTopPixels < secondTopPixels ? firstTopPixels : secondTopPixels;

  const moreTop =
    (secondTopPixels + secondHeightPixels - highestTop) / 2 + highestTop - 15;

  const topSpace =
    (EVENTS_DROPDOWN_HEIGHTS[events.length] || EVENTS_DROPDOWN_HEIGHTS[10]) +
    10;

  return (
    <div
      className='day-week-event-more'
      style={{
        gridColumn: `${column} / span ${width || 2}`,
        marginTop: moreTop + 'px',
      }}
    >
      <span
        ref={ref}
        className={opened ? 'opened' : ''}
        onClick={() => setOpened(!opened)}
      >
        + {more}
      </span>
      <EventsDropDown
        isOpened={opened}
        onClose={() => setOpened(false)}
        ignoreElRefs={[ref]}
        events={events}
        left={
          rightCollision
            ? `-${right + 350 - window.innerWidth}px`
            : 'calc(50% - 150px)'
        }
        top={bottomCollision ? '-' + topSpace + 'px' : undefined}
      />
    </div>
  );
};

export default DayWeekEventMore;
