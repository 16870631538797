import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA } from '../actionTypes';
import {
  EventCreateEditState,
  UpdateEventCreateEditFieldsDataAction,
} from '../types';

export const updateEventCreateEditFieldsData = createAction<
  UpdateEventCreateEditFieldsDataAction,
  EventCreateEditState
>(EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA, (state, payload) => ({
  ...state,
  fieldsData: {
    ...state.fieldsData,
    [payload.key]: payload.value,
  },
  isEdited: payload.silent ? state.isEdited : true,
}));
