import { useCallback, useEffect } from 'react';
import { useNotificationEventsQuery } from 'src/services/calendar';
import { useAppDispatch } from '../../_redux/hooks';
import { addAlert, hideAlert } from '../../_redux/slices/notification';
import Alarm from './Alarm';
import { ALARM_KEY, getAlarmStorage, removeUnusedAlarms } from './localstorage';
import { checkIfToShowAlarm, getSecondsDiffFromEnd } from './utils';
import { EVENT_UPDATE_INTERVAL, USER_PART_STATUS } from '../../_constants';
import { getEventUserPartStatus } from '../../_utils/getEventUserPartStatus';
import selectProfileEmail from '../../_apiCommonData/store/selectors/selectProfileEmail';
import { useSelector } from 'react-redux';

const Alarms = () => {
  const dispatch = useAppDispatch();
  const profileEmail = useSelector(selectProfileEmail);

  const { data, isFetching } = useNotificationEventsQuery(undefined, {
    pollingInterval: EVENT_UPDATE_INTERVAL,
  });

  const onStorage = useCallback((event: StorageEvent) => {
    if (event.key !== ALARM_KEY) {
      return;
    }
    const alarmStorage = getAlarmStorage();
    alarmStorage.closed.forEach((id) => dispatch(hideAlert(id)));
  }, []);

  useEffect(() => {
    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [onStorage]);

  useEffect(() => {
    if (isFetching) return;

    const eventsWithAlarm = data?.events.filter((e) => {
      if (!e.end || !e.alarms || getSecondsDiffFromEnd(e) < 0) return false;

      const userUri = `mailto:${profileEmail}`;
      const partStatus = getEventUserPartStatus(e, userUri);

      return partStatus !== USER_PART_STATUS.declined;
    });

    if (!Array.isArray(eventsWithAlarm)) return;

    const ids = eventsWithAlarm.map((e) => e.id);
    removeUnusedAlarms(ids);

    const { closed } = getAlarmStorage();

    const filteredEvents = eventsWithAlarm
      .filter((e) => !closed.find((id) => id === e.id))
      .filter(checkIfToShowAlarm);

    filteredEvents.forEach((e) => {
      const id = e.id;

      dispatch(
        addAlert({
          content: <Alarm event={e} />,
          alwaysShow: true,
          context: {
            eventId: id,
          },
        }),
      );
    });
  }, [data?.events, isFetching]);

  return <></>;
};

export default Alarms;
