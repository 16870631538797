import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_MODE_DAY } from '../actionTypes';
import { ControlsState, SelectControlsModeDayAction } from '../types';

export const selectControlsModeDay = createAction<
  SelectControlsModeDayAction,
  ControlsState
>(SELECT_CONTROLS_MODE_DAY, (state) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const month = new Date(state.selectedMonth);

  const isWeekSelected = state.selectedDateRange
    ? state.selectedDateRange[0] !== state.selectedDateRange[1]
    : false;

  const firstDayOfTheWeek: [Date, Date] | null = isWeekSelected
    ? [state.selectedDateRange[0], state.selectedDateRange[0]]
    : null;

  return {
    ...state,
    selectedDateRange:
      firstDayOfTheWeek ||
      (currentMonth === month.getMonth()
        ? [currentDate, currentDate]
        : [month, month]),
    mode: 'day',
  };
});
