import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const busy = (data: Requests['Busy']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  const emailsString = data.url.emails.map((d) => `email=${d}`).join('&');

  return requester.request<'Busy'>({
    additionalUrl: `/me/freebusy?${emailsString}`,
    method: 'GET',
    params: {
      from: data.url.from,
      to: data.url.to,
    },
  });
};
