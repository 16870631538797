import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const calendarGet = (data: Requests['CalendarGet']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'CalendarGet'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}`,
    method: 'GET',
  });
};
