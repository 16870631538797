import { getFirstDayInMonth } from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_PREV_DAY } from '../actionTypes';
import { ControlsState, SelectControlsPrevDayAction } from '../types';

export const selectControlsPrevDay = createAction<
  SelectControlsPrevDayAction,
  ControlsState
>(SELECT_CONTROLS_PREV_DAY, (state) => {
  const curr = new Date(state.selectedDateRange[0]);

  curr.setDate(curr.getDate() - 1);

  return {
    ...state,
    selectedDateRange: [new Date(curr), new Date(curr)],
    selectedMonth: getFirstDayInMonth(curr),
  };
});
