import { createAction } from 'src/_utils/redux';
import { EVENT_VIEW_SET_RAW_DATA } from '../actionTypes';
import { EventViewState, SetRawDataEventViewAction } from '../types';

export const setRawDataEventView = createAction<
  SetRawDataEventViewAction,
  EventViewState
>(EVENT_VIEW_SET_RAW_DATA, (state, p) => ({
  ...state,
  rawEvents: p,
  getLoading: false,
}));
