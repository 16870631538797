import { createAction } from 'src/_utils/redux';
import { EVENT_EDIT_WINDOW_OPEN } from '../actionTypes';
import { EventCreateEditState, OpenEventEditWindowAction } from '../types';

export const openEventEditWindow = createAction<
  OpenEventEditWindowAction,
  EventCreateEditState
>(EVENT_EDIT_WINDOW_OPEN, (state, payload) => ({
  ...state,
  mode: 'edit',
  editData: {
    events: payload.events,
    masterEvent: payload.masterEvent,
    recurrenceId: payload.recurrenceId,
    recurrenceType: payload.recurrenceType,
    currentOverridingEvent: payload.currentOverridingEvent,
  },
  fieldsHidden: false,
  fieldsData: {
    ...state.fieldsData,
    ...(payload.fieldsData || {}),
  },
}));
