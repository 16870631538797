import { FC } from 'react';

import { AddressbookContact } from 'src/_api/types/mailId/entities';
import './styles.less';

interface Props {
  contact: AddressbookContact;
  onClick?: () => void;
}

const BACKGROUND_COLORS = [
  '#AFDE8E',
  '#F3A6DF',
  '#99B6DE',
  '#F3DE8E',
  '#8EB6F3',
  '#DEA6F3',
  '#8EF3D1',
  '#AF8ED5',
  '#D5AF8E',
  '#8EA5F3',
  '#F38E9E',
  '#8EE6F3',
  '#F3C18E',
  '#BD8EF3',
  '#F3EC8E',
  '#8ED7F3',
  '#AE8EF3',
  '#FFB6C1',
  '#FF8C00',
  '#808080',
];

const randomizeColor = (email: string) => {
  let hash = 0;
  for (let i = 0; i < email.length; i++) {
    hash = email.charCodeAt(i) + ((hash << 5) - hash);
  }
  const randomNumber = Math.floor((Math.abs(hash) % 20) + 1);

  return BACKGROUND_COLORS[randomNumber] || '#AFDE8E';
};

export const Avatar: FC<Props> = ({ contact, onClick }) => {
  const letters = contact.display_name
    .split(' ')
    .map((s) => s[0])
    .join('')
    .slice(0, 2);

  return (
    <div
      className={'contact-overview__avatar'}
      style={{
        backgroundColor: randomizeColor(contact.email),
        ...(contact.picture && {
          backgroundImage: `url("data:image/png;base64, ${contact.picture}")`,
        }),
      }}
      onClick={onClick}
    >
      {!contact.picture && letters}
    </div>
  );
};
