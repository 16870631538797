import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { controlsInit } from './controlsInit';
import { selectControlsDay } from './selectControlsDay';
import { selectControlsModeAll } from './selectControlsModeAll';
import { selectControlsModeDay } from './selectControlsModeDay';
import { selectControlsModeMonth } from './selectControlsModeMonth';
import { selectControlsModeWeek } from './selectControlsModeWeek';
import { selectControlsMonth } from './selectControlsMonth';
import { selectControlsNextWeek } from './selectControlsNextWeek';
import { selectControlsPage } from './selectControlsPage';
import { selectControlsPrevWeek } from './selectControlsPrevWeek';
import { selectControlsPrevDay } from './selectControlsPrevDay';
import { selectControlsNextDay } from './selectControlsNextDay';
import { selectControlsTodayDay } from './selectControlsTodayDay';
import { deleteSessionControls } from './deleteSessionControls';
import { deleteSessionSetLoadingControls } from './deleteSessionSetLoadingControls';
import { openDayControls } from './openControlsDay';

const controlsActions = () =>
  bindActionCreators(
    {
      selectControlsMonth: selectControlsMonth.action,
      selectControlsDay: selectControlsDay.action,
      selectControlsModeMonth: selectControlsModeMonth.action,
      selectControlsModeWeek: selectControlsModeWeek.action,
      selectControlsModeDay: selectControlsModeDay.action,
      selectControlsModeAll: selectControlsModeAll.action,
      selectControlsPage: selectControlsPage.action,
      selectControlsNextWeek: selectControlsNextWeek.action,
      selectControlsPrevWeek: selectControlsPrevWeek.action,
      selectControlsPrevDay: selectControlsPrevDay.action,
      selectControlsNextDay: selectControlsNextDay.action,
      selectControlsTodayDay: selectControlsTodayDay.action,
      controlsInit: controlsInit.action,
      deleteSessionControls: deleteSessionControls.action,
      deleteSessionSetLoadingControls: deleteSessionSetLoadingControls.action,
      openDayControls: openDayControls.action,
    },
    dispatch
  );

export default controlsActions;
