import { FC } from 'react';

import './styles.less';

interface Props {
  text: string;
  query?: string;
}

const HighlightedText: FC<Props> = ({ text, query }) => {
  if (!text) return <></>;
  if (!query) return <>{text}</>;

  const index = text.toLowerCase().indexOf(query.toLowerCase());

  if (index === -1) {
    return <>{text}</>;
  }

  const beforeText = text.slice(0, index);
  const matchText = text.slice(index, index + query.length);
  const afterText = text.slice(index + query.length);

  return (
    <>
      {beforeText}
      <span className='addressbook-highlighted-text'>{matchText}</span>
      {afterText}
    </>
  );
};

export default HighlightedText;
