import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { addFieldsErrorEventCreateEdit } from './addFieldsErrorEventCreateEdit';
import { resetEventCreateEdit } from './resetEventCreateEdit';
import { openEventCreateWindow } from './openEventCreateWindow';
import { removeFieldsErrorEventCreateEdit } from './removeFieldsErrorEventCreateEdit';
import { requestEventCreate } from './requestEventCreate';
import { requestEventCreateFail } from './requestEventCreateFail';
import { requestEventCreateSuccess } from './requestEventCreateSuccess';
import { updateEventCreateEditFieldsData } from './updateEventCreateEditFieldsData';
import { openEventEditWindow } from './openEventEditWindow';
import { setRecurrenceTypeEventEdit } from './setRecurrenceTypeEventEdit';
import { requestEventEdit } from './requestEventEdit';
import { requestEventEditFail } from './requestEventEditFail';
import { requestEventEditSuccess } from './requestEventEditSuccess';
import { controlUploads, addAttachi, deleteAttachi } from './attachiActions';
import { showFieldsEventCreateEdit } from './showFieldsEventCreateEdit';
import { calendarsComparing } from './calendarsComparing';
import { setAttachiInfoAction } from './setAttachiInfoAction';

const eventCreateEditActions = () =>
  bindActionCreators(
    {
      addAttachi: addAttachi.action,
      deleteAttachi: deleteAttachi.action,
      controlUploads: controlUploads.action,
      openEventCreateWindow: openEventCreateWindow.action,
      resetEventCreateEdit: resetEventCreateEdit.action,
      updateEventCreateEditFieldsData: updateEventCreateEditFieldsData.action,
      requestEventCreate: requestEventCreate.action,
      requestEventCreateFail: requestEventCreateFail.action,
      requestEventCreateSuccess: requestEventCreateSuccess.action,
      addFieldsErrorEventCreateEdit: addFieldsErrorEventCreateEdit.action,
      removeFieldsErrorEventCreateEdit: removeFieldsErrorEventCreateEdit.action,
      openEventEditWindow: openEventEditWindow.action,
      setRecurrenceTypeEventEdit: setRecurrenceTypeEventEdit.action,
      requestEventEdit: requestEventEdit.action,
      requestEventEditFail: requestEventEditFail.action,
      requestEventEditSuccess: requestEventEditSuccess.action,
      showFieldsEventCreateEdit: showFieldsEventCreateEdit.action,
      calendarsComparing: calendarsComparing.action,
      setAttachiInfoAction: setAttachiInfoAction.action,
    },
    dispatch,
  );

export default eventCreateEditActions;
