// *modulism-group store-reducer
import moment from 'moment';
import { createReducer } from 'src/_utils/redux';
import { addFieldsErrorEventCreateEdit } from './actions/addFieldsErrorEventCreateEdit';
import { resetEventCreateEdit } from './actions/resetEventCreateEdit';
import { openEventCreateWindow } from './actions/openEventCreateWindow';
import { removeFieldsErrorEventCreateEdit } from './actions/removeFieldsErrorEventCreateEdit';
import { requestEventCreate } from './actions/requestEventCreate';
import { requestEventCreateFail } from './actions/requestEventCreateFail';
import { requestEventCreateSuccess } from './actions/requestEventCreateSuccess';
import { updateEventCreateEditFieldsData } from './actions/updateEventCreateEditFieldsData';
import { EventCreateEditState } from './types';
import { openEventEditWindow } from './actions/openEventEditWindow';
import { setRecurrenceTypeEventEdit } from './actions/setRecurrenceTypeEventEdit';
import { requestEventEdit } from './actions/requestEventEdit';
import { requestEventEditFail } from './actions/requestEventEditFail';
import { requestEventEditSuccess } from './actions/requestEventEditSuccess';
import {
  controlUploads,
  addAttachi,
  deleteAttachi,
} from './actions/attachiActions';
import { showFieldsEventCreateEdit } from './actions/showFieldsEventCreateEdit';
import { calendarsComparing } from './actions/calendarsComparing';
import { setAttachiInfoAction } from './actions/setAttachiInfoAction';
import { DateTime } from 'luxon';

const TODAY_DATE_STRING = DateTime.now().toFormat('dd.MM.yyyy');

export const initialState: EventCreateEditState = {
  mode: 'none',
  editData: null,
  fieldsData: {
    attendees: [],
    title: '',
    dateStart: moment().format('DD.MM.YYYY'),
    timeStart: null,
    dateEnd: moment().format('DD.MM.YYYY'),
    timeEnd: null,
    wholeDay: false,
    room: '',
    jazzLink: null,
    description: '',
    reminder: 0,
    recurrence: null,
    transparency: null,
    attachi: {},
    alarms: [
      {
        action: 'DISPLAY',
        trigger: {
          related: 'START',
          relative: -900,
        },
      },
    ],
    errors: {
      attendees: [],
      attachi: [],
      title: [],
      dateStart: [],
      timeStart: [],
      dateEnd: [],
      timeEnd: [],
      wholeDay: [],
      room: [],
      jazzLink: [],
      description: [],
      reminder: [],
      recurrence: [],
      transparency: [],
      alarms: [],
    },
  },
  fieldsHidden: true,
  requestLoading: false,
  isEdited: false,
  comparingCalendarsData: {
    isComparing: false,
    organizerBusyEvents: {},
    busy: {},
    status: 'LOADING',
    dataToProcess: {
      attendees: [],
      dateStart: TODAY_DATE_STRING,
      dateEnd: TODAY_DATE_STRING,
    },
  },
};

export default createReducer(initialState, [
  addAttachi,
  deleteAttachi,
  controlUploads,
  openEventCreateWindow,
  openEventEditWindow,
  resetEventCreateEdit,
  updateEventCreateEditFieldsData,
  requestEventCreate,
  requestEventCreateFail,
  requestEventCreateSuccess,
  addFieldsErrorEventCreateEdit,
  removeFieldsErrorEventCreateEdit,
  setRecurrenceTypeEventEdit,
  requestEventEdit,
  requestEventEditFail,
  requestEventEditSuccess,
  showFieldsEventCreateEdit,
  calendarsComparing,
  setAttachiInfoAction,
]);
