import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_LOAD_FAIL } from '../actionTypes';
import { EventsDisplayState, LoadEventsFailAction } from '../types';

export const loadEventsFail = createAction<
  LoadEventsFailAction,
  EventsDisplayState
>(EVENTS_DISPLAY_LOAD_FAIL, (state) => ({
  ...state,
  loading: false,
}));
