import {
  getDateWeekRange,
  getDateWeekRangeForOneMonth,
  getFirstDayInMonth,
} from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_NEXT_WEEK } from '../actionTypes';
import { ControlsState, SelectControlsNextWeekAction } from '../types';

export const selectControlsNextWeek = createAction<
  SelectControlsNextWeekAction,
  ControlsState
>(SELECT_CONTROLS_NEXT_WEEK, (state) => {
  const curr = state.selectedDateRange
    ? new Date(state.selectedDateRange[0])
    : new Date();

  const currentWeekRange = getDateWeekRange(curr);
  const currentWeekLastDay = new Date(currentWeekRange[1]);
  currentWeekLastDay.setDate(currentWeekLastDay.getDate() + 1);

  const range = getDateWeekRange(currentWeekLastDay);
  const convertedRange = getDateWeekRangeForOneMonth(range[0]);

  return {
    ...state,
    selectedDateRange: convertedRange,
    selectedMonth: getFirstDayInMonth(new Date(convertedRange[0])),
    mode: 'week',
  };
});
