import { call, delay, fork, put } from 'redux-saga/effects';
import { EVENT_UPDATE_INTERVAL } from 'src/_constants';
import { forceLoadNewEventNotifications } from '../store/actions/forceLoadNewEventNotifications';
import { handleDeleteCancelledNewEvent } from './deleteCancelledEvent';
import fetchNotifyEvents from './fetchNotifyEvents';
import { forceLoadNotifyEvents } from './forceLoadNotifyEvents';
import { handleNewEventNotificationReaction } from './handleNewEventNotificationReaction';

export function* newEventNotificationsSaga(): Generator {
  yield call(fetchNotifyEvents);

  yield fork(forceLoadNotifyEvents);
  yield fork(handleNewEventNotificationReaction);
  yield fork(handleDeleteCancelledNewEvent);

  yield delay(EVENT_UPDATE_INTERVAL);
  while (true) {
    yield put(forceLoadNewEventNotifications.action());
    yield delay(EVENT_UPDATE_INTERVAL);
  }
}
