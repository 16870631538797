import PeriodSelectors from '../../PeriodSelectors';

import { useLocation, useNavigate } from 'react-router-dom';
import { useIsCalendarOpen } from '../../../../_utils/hooks/useIsCalendarOpen';
import { useIsAddressbookOpen } from '../../../../_utils/hooks/useIsAddressbookOpen';
import { useRef, useState } from 'react';
import { useClickOutside } from '../../../../_utils/hooks/useClickOutside';
import { MailReadIcon, NoteBookIcon } from '../../../../_components/Svg';
import { ProfileAnchor, ProfileDropdown } from '../../Profile';

import css from './CorpHeader.module.less';

const CorpHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const isCalenderOpen = useIsCalendarOpen();
  const isAddressbookOpen = useIsAddressbookOpen();

  const [showDropdown, setShowDropdown] = useState(false);
  const profileBlockRef = useRef<HTMLDivElement>();
  const profileDropdownRef = useClickOutside<HTMLDivElement>(() => {
    if (showDropdown) setShowDropdown(false);
  }, [profileBlockRef]);

  const openMail = () => {
    window.open(process.env.AUTH_URL, '_blank').focus();
  };

  const getContactsPath = () =>
    `${pathname}${search}`.replace('calendar', 'contacts');

  return (
    <div className={css.header}>
      <div className={css.periodSelectorsContainer}>
        {isCalenderOpen && <PeriodSelectors />}
      </div>
      <div className={css.headerSmallButtons}>
        <div
          onClick={() => navigate(getContactsPath())}
          className={isAddressbookOpen && css.active}
        >
          <NoteBookIcon />
          <span className={css.text}>Адресная книга</span>
        </div>
        <div onClick={openMail}>
          <MailReadIcon />
          <span className={css.text}>К письмам</span>
        </div>
        <ProfileAnchor
          ref={profileBlockRef}
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
        />
      </div>
      <ProfileDropdown show={showDropdown} ref={profileDropdownRef} />
    </div>
  );
};

export default CorpHeader;
