import { FC, useMemo } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import selectProfileEmail from 'src/_apiCommonData/store/selectors/selectProfileEmail';
import { Avatar } from 'src/_components/Avatar';
import { CloseSmallIcon } from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import { EventAttendee } from '../../../_api/types/entities';

interface ComparingAttendeeProps {
  isYou: boolean;
  email: string;
  isHovered: boolean;
  onHover: () => void;
  onBlur: () => void;
  attendees: EventAttendee[];
  onAttendeesChange: (attendees: EventAttendee[]) => void;
}

const ComparingAttendee: FC<ComparingAttendeeProps> = ({
  isYou,
  email,
  isHovered,
  onHover,
  onBlur,
  attendees,
  onAttendeesChange,
}) => {
  const { contacts, profile } = useSelector((state: CStoreState) => ({
    contacts:
      typeof state.apiCommonData.contacts !== 'string'
        ? state.apiCommonData.contacts
        : [],
    profile: state.apiCommonData.profile,
  }));
  const profileEmail = useSelector(selectProfileEmail);

  const contact = contacts.find((c) => c.email === email);

  const name = useMemo(() => {
    if (profile?.info?.email === email) {
      return profile.display.display_name;
    }
    if (contact?.first_name || contact?.last_name) {
      return `${contact?.last_name || ''} ${contact?.first_name || ''}`.trim();
    }

    return '';
  }, [profile, contact]);

  const onRemove = (uri: string) => {
    const findAttendee = attendees.findIndex((at) => at.uri === uri);
    const attendeesArr = [...attendees];
    if (findAttendee > -1) {
      attendeesArr.splice(findAttendee, 1);
      onAttendeesChange([...attendeesArr]);
    }
  };

  return (
    <div
      className={cn('comparing-attendee', {
        hovered: isHovered,
      })}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
    >
      <div>
        <Avatar
          name={name}
          email={email}
          size={30}
          fontSize={11}
          lineHeight={11}
        />
        <div className='comparing-attendee-info'>
          {name ? (
            <p className='comparing-attendee-name'>
              {name} {isYou ? ' (Вы)' : ''}
            </p>
          ) : (
            '(Вы)'
          )}{' '}
          <p className='comparing-attendee-email'>{email}</p>
        </div>
      </div>
      {profileEmail === email ? (
        ''
      ) : (
        <div
          className='comparing-attendee-close'
          onClick={() => onRemove(`mailto:${email}`)}
        >
          <CloseSmallIcon />
        </div>
      )}
    </div>
  );
};

export default ComparingAttendee;
