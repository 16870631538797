import { call, delay, fork, put, select } from 'redux-saga/effects';
import { Calendar } from 'src/_api/types/entities';
import { EVENT_UPDATE_INTERVAL } from 'src/_constants';
import { forceLoadEvents } from '../store/actions/forceLoadEvents';
import calendarsSelector from '../store/selectors/calendarsSelector';
import catchCreatingEventSaga from './catchCreatingEvent';
import fetchCalendars from './fetchCalendars';
import { forceLoadEventsSaga } from './forceLoadEvents';
import { loadEventsOnMonthChangeSaga } from './loadEventsOnMonthChange';

export function* eventsDisplaySaga(): Generator {
  yield call(fetchCalendars, {
    url: {
      owner: 'me',
    },
  });

  const calendarsList = (yield select(calendarsSelector)) as Calendar[];

  if (calendarsList.length) {
    yield fork(loadEventsOnMonthChangeSaga);
    yield fork(forceLoadEventsSaga);
    yield fork(catchCreatingEventSaga);

    yield put(forceLoadEvents.action());

    yield delay(EVENT_UPDATE_INTERVAL);
    while (true) {
      yield put(forceLoadEvents.action(true));
      yield delay(EVENT_UPDATE_INTERVAL);
    }
  }
}
