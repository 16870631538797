import Notification from './notification';
import { useSelector } from 'react-redux';
import { CStoreState } from '../../_redux/types';
import './styles.less';

const NewEventNotifications = () => {
  const events = useSelector(
    (state: CStoreState) => state.newEventNotifications?.events,
  );

  if (events.length === 0) {
    return <></>;
  }

  return (
    <div className={'new-event-notifications'}>
      <div className={'new-event-notifications__title'}>Новое:</div>
      {events.map((event, id) => (
        <Notification
          key={id}
          event={event.event}
          conflicts={event.conflicts}
        />
      ))}
    </div>
  );
};

export default NewEventNotifications;
