import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const eventGet = (data: Requests['EventGet']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'EventGet'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}/events/${data.url.eventId}`,
    method: 'GET',
    headers: data.headers,
    params: {
      recurId: data.url.recurId,
      timeZone: data.url.timeZone,
    },
  });
};
