import { FC } from 'react';
import { wrapTextWithTag } from 'src/_utils/wrapTextWithTag';
import { encodedString } from 'src/_utils/strings';

interface SuggestedValueProps {
  fullName: string;
  text: string;
  email: string;
}

const SuggestedValue: FC<SuggestedValueProps> = ({ fullName, text, email }) => (
  <div className='event-create-suggester-item'>
    <p>
      {!!fullName && (
        <span
          className='event-create-suggester-item-name'
          dangerouslySetInnerHTML={{
            __html: wrapTextWithTag(encodedString(fullName), text),
          }}
        />
      )}
      <span
        className='event-create-suggester-item-email'
        dangerouslySetInnerHTML={{
          __html: wrapTextWithTag(encodedString(email), text),
        }}
      />
    </p>
  </div>
);

export default SuggestedValue;
