import { Alarm, EventAttendee } from '../_api/types/entities';
import { DateTime } from 'luxon';

const TODAY_DATE = DateTime.now().toFormat('dd.MM.yyyy');

export const ERRORS = {
  ATTACHSIZE: 'Размер вложений превышает допустимый объем 72 МБ',
  FILESIZE: 'Размер файла не может превышать 63 МБ',
  LOADING_IN_PROGRESS:
    'Идет загрузка файлов. Дождитесь полной ' +
    'загрузки и повторите отправку письма',
};

export const SIZE = {
  MAX_FILESIZE: 63 * 1024 * 1024,
  MAX_ATTACHSIZE: 72 * 1024 * 1024,
  BYTES_IN_MB: 1048576,
};

export const DEFAULT_ALARM_OPTION: Alarm = {
  action: 'DISPLAY',
  description: 'REMINDER',
  trigger: {
    related: 'START',
    relative: -900,
  },
};

export const ALARM_OPTIONS = [
  {
    option: '5 минут',
    value: -300,
  },
  {
    option: '10 минут',
    value: -600,
  },
  {
    option: '15 минут',
    value: -900,
  },
  {
    option: '30 минут',
    value: -1800,
  },
  {
    option: '1 час',
    value: -3600,
  },
  {
    option: '2 часа',
    value: -7200,
  },
  {
    option: '2 дня',
    value: -172800,
  },
  {
    option: '1 неделя',
    value: -604800,
  },
  {
    option: 'Не напоминать',
    value: undefined,
  },
];

export const DEFAULT_FIELD_VALUES = {
  attendees: [] as EventAttendee[],
  alarms: [DEFAULT_ALARM_OPTION],
  dateEnd: TODAY_DATE,
  dateStart: TODAY_DATE,
  description: '',
  recurrence: null as null | string[],
  room: '',
  title: '',
  timeEnd: '',
  timeStart: '',
  transparency: 'OPAQUE' as 'OPAQUE' | 'TRANSPARENT',
  wholeDay: false,
};

export type SetValueType = <T extends keyof EventCreateFieldsType>(
  key: T,
  value: EventCreateFieldsType[T],
) => void;

export type EventCreateFieldsType = typeof DEFAULT_FIELD_VALUES;

export const MAX_ATTENDEE_COUNT = 100;
