import moment from 'moment';
import { transformCalDavDate } from '../../_utils/caldav';
import { Event } from 'src/_api/types/entities';

export const getSecondsDiffFromEnd = (event: Event) => {
  const specificDate = moment(transformCalDavDate(event.end.value));

  return -moment().diff(specificDate, 'seconds');
};

export const getSecondsDiff = (event: Event) => {
  const specificDate = moment(transformCalDavDate(event.start.value));

  return -moment().diff(specificDate, 'seconds');
};

export const checkIfToShowAlarm = (event: Event) => {
  const secondsToAlarm = -event.alarms?.at(0)?.trigger?.relative;
  if (!secondsToAlarm) return false;

  const secondsDiff = getSecondsDiff(event);

  if (secondsDiff > 0) {
    return secondsToAlarm >= getSecondsDiff(event);
  }

  return getSecondsDiffFromEnd(event) >= 0;
};

const getMinuteSuffix = (minutes) => {
  if (minutes % 10 === 1) return 'минуту';
  if (minutes % 10 >= 2 && minutes % 10 <= 4) return 'минуты';

  return 'минут';
};

const getHourSuffix = (hours) => {
  if (hours % 10 === 1) return 'час';
  if (hours % 10 >= 2 && hours % 10 <= 4) return 'часа';

  return 'часов';
};

const getDaySuffix = (days) => {
  if (days % 10 === 1) return 'день';
  if (days % 10 >= 2 && days % 10 <= 4) return 'дня';

  return 'дней';
};

export const getTimeLeftInfo = (event: Event) => {
  const secondsDiff = getSecondsDiff(event);

  if (secondsDiff <= 0) return 'Cейчас';
  if (secondsDiff < 60) return 'Менее чем через минуту';

  const minutesDiff = Math.trunc(secondsDiff / 60);

  if (minutesDiff < 60) {
    return `Через ${minutesDiff} ${getMinuteSuffix(minutesDiff)}, в ${moment(
      transformCalDavDate(event.start.value)
    ).format('HH:mm')}`;
  }

  const hoursDiff = Math.trunc(secondsDiff / 3600);

  if (hoursDiff <= 24) {
    return `Через ${hoursDiff} ${getHourSuffix(hoursDiff)} ${
      minutesDiff % 60
    } ${getMinuteSuffix(minutesDiff % 60)}, в ${moment(
      transformCalDavDate(event.start.value)
    ).format('HH:mm')}`;
  }

  const daysDiff = Math.trunc(secondsDiff / 86400);

  return `Через ${daysDiff} ${getDaySuffix(daysDiff)} ${
    hoursDiff % 24
  } ${getHourSuffix(hoursDiff % 24)}, ${moment(
    transformCalDavDate(event.start.value)
  ).format('DD.MM в HH:mm')}`;
};
