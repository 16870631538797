import { getMinTop } from './mins';

// *modulism-group calcModalPositions
export const calcModalPositions = (target: HTMLElement, modal: HTMLElement) => {
  const rect = target.getBoundingClientRect();
  const modalRect = modal.getBoundingClientRect();
  const margin = 6;
  const marginTop = 0;

  const leftFromLeft = rect.left + rect.width + margin;
  const leftFromRight = rect.left - modalRect.width - margin;

  let resTop =
    rect.top + modalRect.height + marginTop > window.innerHeight
      ? rect.top - (rect.top + modalRect.height - window.innerHeight + 50)
      : rect.top + marginTop;

  if (resTop === rect.top) {
    resTop -= 15;
  }

  if (resTop <= 110) {
    resTop = getMinTop();
  }

  const resLeft =
    leftFromLeft + modalRect.width > window.innerWidth
      ? leftFromRight <= 25
        ? window.innerWidth < 1366
          ? 110
          : target.getBoundingClientRect().left + 100
        : leftFromRight
      : leftFromLeft;

  return {
    top: resTop,
    left: resLeft,
  };
};
