import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { clearErrorContactsApiCommonData } from './clearErrorContacts';
import { loadContactsApiCommonData } from './loadContacts';
import { setContactsApiCommonData } from './setContacts';
import { setProfileApiCommonData } from './setProfile';

const apiCommonDataActions = () =>
  bindActionCreators(
    {
      loadContactsApiCommonData: loadContactsApiCommonData.action,
      setContactsApiCommonData: setContactsApiCommonData.action,
      clearErrorContactsApiCommonData: clearErrorContactsApiCommonData.action,
      setProfileApiCommonData: setProfileApiCommonData.action,
    },
    dispatch,
  );

export default apiCommonDataActions;
