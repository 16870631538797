import { FC, useMemo, useRef, useState } from 'react';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import {
  CloseIcon,
  HelpIcon,
  PrintIcon,
  MailIcon,
  SuccessIcon,
} from 'src/_components/Svg';
import IconButton from 'src/_components/IconButton';
import eventViewActions from 'src/eventView/store/actions';
import { EVENT_STATUS, USER_PART_STATUS } from 'src/_constants';
import floatingModalActions from 'src/floatingModal/store/actions';
import Button from '@rambler-components/button';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import DropdownConfirm from '../DropdownConfirm';
import cn from 'classnames';
import { CalendarTooltip } from '../../../_components/CalendarTooltip';
import { getEventMailLink } from '../../../_utils/mail';
import '@rambler-components/dropdown/styles.css';
import { Dropdown, DropdownWrapper } from '@rambler-components/dropdown';

import DropdownItem from '../../../_components/DropdownItem';
import Tooltip from '@rambler-components/tooltip';
import css from './EventBlock.module.less';
import { dinamicRoutes } from 'src/_api/constants';

interface EventBlockAttendeeActionsProps {
  event: SelectedEvent;
}

const EventBlockAttendeeActions: FC<EventBlockAttendeeActionsProps> = ({
  event,
}) => {
  const profileEmail = useSelector(
    (s: CStoreState) => s.apiCommonData.profile?.info?.email.toLowerCase(),
  );
  const userPartStatus = useMemo(() => {
    const userUri = `mailto:${profileEmail}`;

    return (
      event.attendee.find((at) => at.uri.toLowerCase() === userUri)?.status ||
      USER_PART_STATUS.undefined
    );
  }, [event]);
  const [acceptedDropdown, setAcceptedDropdown] = useState(false);
  const [declinedDropdown, setDeclinedDropdown] = useState(false);
  const [tentativeDropdown, setTentativeDropdown] = useState(false);
  const [mailOpen, setMailOpen] = useState(false);

  const acceptButtonRef = useRef<HTMLDivElement>();
  const declineButtonRef = useRef<HTMLDivElement>();
  const tentativeButtonRef = useRef<HTMLDivElement>();

  const isCancelled = event.source.status === EVENT_STATUS.cancelled;

  const react = (
    v: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE',
    recurrenceType: 'single' | 'all' | 'regular',
  ) => {
    eventViewActions().attendeeReactionEventView({
      status: v,
      recurrenceType,
    });
    eventViewActions().setEventView({ event: null });
    floatingModalActions().closeFloatingModal();
  };

  const onMail = (organizerOnly?: boolean) => {
    const link = getEventMailLink(event.id, organizerOnly);
    window.open(link, '_blank');
  };

  return (
    <div className={cn('flex-between', css.eventActions)}>
      <div className='flex-center' style={{ position: 'relative' }}>
        {!isCancelled ? (
          <>
            <div style={{ position: 'relative' }} ref={acceptButtonRef}>
              {acceptedDropdown && (
                <DropdownConfirm
                  buttonRef={acceptButtonRef}
                  close={() => setAcceptedDropdown(false)}
                  left={0}
                  actions={[
                    () => {
                      react('ACCEPTED', 'single');
                      setAcceptedDropdown(false);
                    },
                    () => {
                      react('ACCEPTED', 'all');
                      setAcceptedDropdown(false);
                    },
                  ]}
                />
              )}
              <CalendarTooltip
                label='Принять'
                position='top'
                autoPosition
                isVisible={acceptedDropdown ? false : undefined}
              >
                <div style={{ marginRight: '10px' }}>
                  <IconButton
                    size={40}
                    withBackground
                    icon={<SuccessIcon />}
                    className={
                      userPartStatus === USER_PART_STATUS.accepted
                        ? css.eventViewAttendeeActionButtonAccepted
                        : undefined
                    }
                    onClick={
                      userPartStatus === USER_PART_STATUS.accepted
                        ? undefined
                        : () =>
                            userPartStatus === USER_PART_STATUS.undefined ||
                            !event.isRecurrence
                              ? react('ACCEPTED', 'regular')
                              : setAcceptedDropdown(true)
                    }
                  />
                </div>
              </CalendarTooltip>
            </div>
            <div style={{ position: 'relative' }} ref={declineButtonRef}>
              {declinedDropdown && (
                <DropdownConfirm
                  buttonRef={declineButtonRef}
                  close={() => setDeclinedDropdown(false)}
                  left={0}
                  actions={[
                    () => {
                      react('DECLINED', 'single');
                      setDeclinedDropdown(false);
                    },
                    () => {
                      react('DECLINED', 'all');
                      setDeclinedDropdown(false);
                    },
                  ]}
                />
              )}
              <CalendarTooltip
                label='Отклонить'
                position='top'
                autoPosition
                isVisible={declinedDropdown ? false : undefined}
              >
                <div style={{ marginRight: '10px' }}>
                  <IconButton
                    size={40}
                    withBackground
                    icon={<CloseIcon />}
                    className={
                      userPartStatus === USER_PART_STATUS.declined
                        ? css.eventViewAttendeeActionButtonDeclined
                        : undefined
                    }
                    onClick={
                      userPartStatus === USER_PART_STATUS.declined
                        ? undefined
                        : () =>
                            userPartStatus === USER_PART_STATUS.undefined ||
                            !event.isRecurrence
                              ? react('DECLINED', 'regular')
                              : setDeclinedDropdown(true)
                    }
                  />
                </div>
              </CalendarTooltip>
            </div>
            <div style={{ position: 'relative' }} ref={tentativeButtonRef}>
              {tentativeDropdown && (
                <DropdownConfirm
                  buttonRef={tentativeButtonRef}
                  close={() => setTentativeDropdown(false)}
                  left={0}
                  actions={[
                    () => {
                      react('TENTATIVE', 'single');
                      setTentativeDropdown(false);
                    },
                    () => {
                      react('TENTATIVE', 'all');
                      setTentativeDropdown(false);
                    },
                  ]}
                />
              )}
              <CalendarTooltip
                label='Возможно'
                position='top'
                autoPosition
                isVisible={tentativeDropdown ? false : undefined}
              >
                <div style={{ marginRight: '10px' }}>
                  <IconButton
                    size={40}
                    withBackground
                    icon={<HelpIcon />}
                    className={
                      userPartStatus === USER_PART_STATUS.tentative
                        ? css.eventViewAttendeeActionButtonTentative
                        : undefined
                    }
                    onClick={
                      userPartStatus === USER_PART_STATUS.tentative
                        ? undefined
                        : () =>
                            userPartStatus === USER_PART_STATUS.undefined ||
                            !event.isRecurrence
                              ? react('TENTATIVE', 'regular')
                              : setTentativeDropdown(true)
                    }
                  />
                </div>
              </CalendarTooltip>
            </div>
          </>
        ) : (
          <Button
            className={css.eventViewAttendeeActionButtonDelete}
            onClick={() => eventViewActions().deleteCancelledEventView()}
          >
            Удалить из календаря
          </Button>
        )}
      </div>

      <div className={cn('flex', css.buttonsRight)}>
        <div className={cn('flex-center')} style={{ position: 'relative' }}>
          <DropdownWrapper>
            {mailOpen ? (
              <div>
                <IconButton
                  icon={<MailIcon />}
                  onClick={() => setMailOpen(true)}
                />
              </div>
            ) : (
              <Tooltip label='Ответить' position='top'>
                <div>
                  <IconButton
                    icon={<MailIcon />}
                    onClick={() => setMailOpen(true)}
                  />
                </div>
              </Tooltip>
            )}
            <Dropdown
              isOpen={mailOpen}
              onClose={() => setMailOpen(false)}
              className={css.mailDropdown}
              horizontalAlign={'left'}
            >
              <DropdownItem
                action={() => onMail(true)}
                title={'Ответить организатору'}
              />
              <DropdownItem action={() => onMail()} title={'Ответить всем'} />
            </Dropdown>
          </DropdownWrapper>
          {/*MAILCORE-9761 .15 - комментируем пока нет функционала*/}
          {/*{!isCancelled && (*/}
          {/*  <Tooltip label='Переслать встречу' position='top' autoPosition>*/}
          {/*    <div style={{ marginRight: '20px' }}>*/}
          {/*      <IconButton icon={<ReplyIcon />} onClick={() => null} />*/}
          {/*    </div>*/}
          {/*  </Tooltip>*/}
          {/*)}*/}
          {/*<Tooltip label='Распечатать' position='top' autoPosition>*/}
          {/*  <div style={{ marginRight: '20px' }}>*/}
          {/*    <IconButton icon={<PrintIcon />} onClick={() => null} />*/}
          {/*  </div>*/}
          {/*</Tooltip>*/}
          {/*{!isCancelled && (*/}
          {/*  <Tooltip label='Экспортировать' position='top' autoPosition>*/}
          {/*    <div>*/}
          {/*      <IconButton icon={<DownloadIcon />} onClick={() => null} />*/}
          {/*    </div>*/}
          {/*  </Tooltip>*/}
          {/*)}*/}
        </div>
        <CalendarTooltip
          label='Распечатать событие'
          position='top'
          autoPosition
        >
          <a
            href={`${dinamicRoutes.print('calendar')}?eventId=${
              event.id
            }&date=${event.date.toDateString()}&${location.search.slice(1)}`}
            target='_blank'
          >
            <div>
              <IconButton
                className={cn(css.printIcon)}
                icon={<PrintIcon />}
                onClick={() => null}
              />
            </div>
          </a>
        </CalendarTooltip>
      </div>
    </div>
  );
};

export default EventBlockAttendeeActions;
