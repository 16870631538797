// *modulism-group store-reducer
import { createReducer } from 'src/_utils/redux';
import { addEvent } from './actions/addEvent';
import { addShadowEvent } from './actions/addShadowEvent';
import { deleteEvent } from './actions/deleteEvent';
import { deleteShadowEvent } from './actions/deleteShadowEvent';
import { editEventAttendees } from './actions/editEventAttendees';
import { loadEvents } from './actions/loadEvents';
import { loadEventsFail } from './actions/loadEventsFail';
import { loadEventsSuccess } from './actions/loadEventsSuccess';
import { setCalendars } from './actions/setCalendars';
import { updateShadowEvent } from './actions/updateShadowEvent';
import { EventsDisplayState } from './types';

const initialState: EventsDisplayState = {
  calendars: [],
  events: [],
  loading: true,
};

export default createReducer(initialState, [
  loadEvents,
  loadEventsFail,
  loadEventsSuccess,
  addEvent,
  setCalendars,
  addShadowEvent,
  updateShadowEvent,
  deleteShadowEvent,
  editEventAttendees,
  deleteEvent,
]);
