import { createAction } from 'src/_utils/redux';
import { ATTENDEE_REACTION_NEW_EVENT_NOTIFICATIONS } from '../actionTypes';
import {
  AttendeeReactionNewEventNotificationsAction,
  NewEventsState,
} from '../types';

export const attendeeReactionNewEventNotifications = createAction<
  AttendeeReactionNewEventNotificationsAction,
  NewEventsState
>(ATTENDEE_REACTION_NEW_EVENT_NOTIFICATIONS);
