import { useEffect, useRef } from 'react';
import { Month } from 'src/_components/Calendar/Month';
import MonthItem from 'src/_components/Calendar/Month/Item';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import { getCalendarFullMonthDays, isDatesEqual } from 'src/_utils/date';

import pageCalendarWrapper from '../../HOC/pageCalendarWrapper';
import selectMonthEvents from 'src/eventsDisplay/store/selectors/monthEventsSelector';
import moment from 'moment';

import MonthItemEventsWrapper from 'src/eventsDisplay/components/MonthItemEventsWrapper/indext';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import MonthSingleDayView from 'src/singleDayModal/component';
import singleDayModalActions from 'src/singleDayModal/store/actions';
import './styles.less';

const CalendarModeMonth = () => {
  const elRefs = useRef<HTMLDivElement[]>([]);
  const { selectedMonth } = useSelector((s: CStoreState) => ({
    selectedMonth: s.controls.selectedMonth,
  }));
  const events = useSelector(selectMonthEvents);

  useEffect(
    () => () => {
      singleDayModalActions().setDateSingleModal(null);
      floatingModalActions().closeFloatingModal();
    },
    [],
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let days = getCalendarFullMonthDays(selectedMonth).map((date, i) => ({
    date,
    isActive: isDatesEqual(today, date),
    isPast: today > date,
    isNotCurMonth: date.getMonth() !== selectedMonth.getMonth(),
    withHeader: i <= 6,
  }));

  if (days[days.length - 7].isNotCurMonth) {
    days = days.slice(0, -7);
  }
  if (days[days.length - 7].isNotCurMonth) {
    days = days.slice(0, -7);
  }

  const getDayPosition = (index: number) => {
    switch (index) {
      case 0:
        return 'topLeft';
      case 6:
        return 'topRight';
      case 35:
        return 'bottomLeft';
      case 41:
        return 'bottomRight';
      default:
        return null;
    }
  };

  return (
    <>
      <MonthSingleDayView />
      <Month
        items={days.map((date, index) => {
          const dayKey = moment(date.date).format().slice(0, -6);

          return (
            <MonthItem
              fullDate={date.date}
              date={date.date.getDate()}
              isActive={date.isActive}
              isPast={date.isPast}
              isNotCurMonth={date.isNotCurMonth}
              withHeader={date.withHeader}
              weekDay={date.date.getDay() || 7}
              key={`calendar-month-item-${index}`}
              position={getDayPosition(index)}
              refs={elRefs}
              index={index}
              onClickCreate={() => {
                floatingModalActions().openFloatingModal({
                  opened: FLOATING_MODAL_IDS['eventCreate'],
                  target: elRefs.current[index],
                });
                eventCreateEditActions().openEventCreateWindow({
                  wholeDay: true,
                  dateStart: moment(date.date).format('DD.MM.YYYY'),
                  dateEnd: moment(date.date).format('DD.MM.YYYY'),
                  transparency: 'OPAQUE',
                });
              }}
            >
              <MonthItemEventsWrapper
                events={events[dayKey]}
                onClickEmptyEvents={() => {
                  floatingModalActions().openFloatingModal({
                    opened: FLOATING_MODAL_IDS['eventCreate'],
                    target: elRefs.current[index],
                  });
                  eventCreateEditActions().openEventCreateWindow({
                    dateStart: moment(date.date).format('DD.MM.YYYY'),
                    dateEnd: moment(date.date).format('DD.MM.YYYY'),
                    transparency: 'OPAQUE',
                  });
                }}
              />
            </MonthItem>
          );
        })}
      />
    </>
  );
};

export default pageCalendarWrapper(CalendarModeMonth);
