// *modulism-group store-actionTypes
export const EVENTS_DISPLAY_LOAD = 'EVENTS_DISPLAY_LOAD';
export const EVENTS_DISPLAY_LOAD_SUCCESS = 'EVENTS_DISPLAY_LOAD_SUCCESS';
export const EVENTS_DISPLAY_LOAD_FAIL = 'EVENTS_DISPLAY_LOAD_FAIL';
export const EVENTS_DISPLAY_ADD_EVENT = 'EVENTS_DISPLAY_ADD_EVENT';
export const EVENTS_DISPLAY_DELETE_EVENT = 'EVENTS_DISPLAY_DELETE_EVENT';
export const EVENTS_DISPLAY_EDIT_EVENT_ATTENDEES =
  'EVENTS_DISPLAY_EDIT_EVENT_ATTENDEES';
export const EVENTS_DISPLAY_FORCE_LOAD = 'EVENTS_DISPLAY_FORCE_LOAD';
export const EVENTS_DISPALY_SET_CALENDARS = 'EVENTS_DISPALY_SET_CALENDARS';

export const EVENTS_DISPLAY_ADD_SHADOW_EVENT =
  'EVENTS_DISPLAY_ADD_SHADOW_EVENT';
export const EVENTS_DISPLAY_UPDATE_SHADOW_EVENT =
  'EVENTS_DISPLAY_UPDATE_SHADOW_EVENT';
export const EVENTS_DISPLAY_DELETE_SHADOW_EVENT =
  'EVENTS_DISPLAY_DELETE_SHADOW_EVENT';
