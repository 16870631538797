// *modulism-group store
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from './saga';
import { configureStore } from '@reduxjs/toolkit';
import calendarApi from 'src/services/calendar';

function makeStore() {
  const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: {
      ...rootReducer,
      // Add the generated reducer as a specific top-level slice
      [calendarApi.reducerPath]: calendarApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(sagaMiddleware, calendarApi.middleware),
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

const store = makeStore();

export const dispatch = store.dispatch;
export default store;

type Store = ReturnType<typeof makeStore>;
export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
