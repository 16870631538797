import { createAction } from 'src/_utils/redux';
import { EVENT_EDIT_REQUEST_FAIL } from '../actionTypes';
import { EventCreateEditState, RequestEventEditFailAction } from '../types';

export const requestEventEditFail = createAction<
  RequestEventEditFailAction,
  EventCreateEditState
>(EVENT_EDIT_REQUEST_FAIL, (state) => ({
  ...state,
  requestLoading: false,
}));
