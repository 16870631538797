import { FC } from 'react';
import { useSelector } from 'react-redux';
import Loader from '@rambler-components/loader';
import { CStoreState } from 'src/_redux/types';
import '@rambler-components/loader/styles.css';
import './styles.less';

interface EventCreateLoadingScreenProps {
  height: string;
}

const EventCreateLoadingScreen: FC<EventCreateLoadingScreenProps> = ({
  height,
}) => {
  const loading = useSelector(
    (state: CStoreState) => state.eventCreateEdit.requestLoading,
  );

  if (!loading) return <></>;

  return (
    <div
      className='event-create-edit-loading'
      style={{
        height,
      }}
    >
      <Loader size='small' />
    </div>
  );
};

export default EventCreateLoadingScreen;
