import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { attendeeReactionEventView } from './attendeeReactionEventView';
import { attendeeTransparencyChangeEventView } from './attendeeTransparencyChangeEventView';
import { copyEventView } from './copyEventView';
import { deleteCancelledEventView } from './deleteCancelledEventView';
import { deleteEventView } from './deleteEventView';
import { editEventView } from './editEventView';
import { setEventView } from './setEventView';
import { setRawDataEventView } from './setRawDataEventView';
import { loadFileInfoAction } from './loaFileInfo.action';

const eventViewActions = () =>
  bindActionCreators(
    {
      loadFileInfo: loadFileInfoAction.action,
      setEventView: setEventView.action,
      deleteEventView: deleteEventView.action,
      deleteCancelledEventView: deleteCancelledEventView.action,
      copyEventView: copyEventView.action,
      editEventView: editEventView.action,
      setRawDataEventView: setRawDataEventView.action,
      attendeeReactionEventView: attendeeReactionEventView.action,
      attendeeTransparencyChangeEventView:
        attendeeTransparencyChangeEventView.action,
    },
    dispatch,
  );

export default eventViewActions;
