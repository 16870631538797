import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import ComparingEvent from '../event';
import css from './Grid.module.less';

interface Props {
  hours: number;
  hoveredAttendee: number;
  emailsLength: number;
  setHoveredAttendee: (_: number) => void;
  busyItems: any[];
}

export const Grid: FC<Props> = ({
  hours,
  hoveredAttendee,
  emailsLength,
  setHoveredAttendee,
  busyItems,
}) => {
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  useEffect(() => {
    if (hours * (emailsLength + 1) > 0) {
      setCols([...Array(hours)]);
      setRows([...Array(emailsLength + 1)]);
    } else {
      setCols([...Array(24)]);
      setRows([...Array(1)]);
    }
  }, [hours, emailsLength]);

  return (
    <div className={css.root} onMouseLeave={() => setHoveredAttendee(-1)}>
      <div className={css.cols}>
        {cols.map((_, i) => (
          <div key={i} className={css.col}></div>
        ))}
      </div>
      {rows.map((_, i) => (
        <div
          key={i}
          className={cn(css.row, {
            [css.hovered]: hoveredAttendee === i,
          })}
          onMouseEnter={() =>
            i < rows.length - 1 ? setHoveredAttendee(i) : setHoveredAttendee(-1)
          }
          style={{
            borderBottom:
              hours * (emailsLength + 1) - 1 - i <= hours - 1
                ? 'none'
                : undefined,
          }}
        />
      ))}
      {busyItems.map((bI, i) => (
        <ComparingEvent
          top={bI.top}
          left={bI.left}
          width={bI.width}
          key={`ComparingItem-key-${i}`}
          events={bI.events}
        />
      ))}
    </div>
  );
};
