import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_REQUEST_SUCCESS } from '../actionTypes';
import {
  EventCreateEditState,
  RequestEventCreateSuccessAction,
} from '../types';

export const requestEventCreateSuccess = createAction<
  RequestEventCreateSuccessAction,
  EventCreateEditState
>(EVENT_CREATE_REQUEST_SUCCESS, (state) => ({
  ...state,
  requestLoading: false,
}));
