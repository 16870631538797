import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { setDateSingleModal } from './setDateSingleModal';

const singleDayModalActions = () =>
  bindActionCreators(
    {
      setDateSingleModal: setDateSingleModal.action,
    },
    dispatch
  );

export default singleDayModalActions;
