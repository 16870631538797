import { FC } from 'react';

import './styles.less';
import Button from '@rambler-components/button';
import { MailIcon, PlusIcon } from 'src/_components/Svg';
import { AddressbookState } from '../../../store/types';
import { MailingContactsList } from './mailingContactsList';

interface Props {
  contact: AddressbookState['selectedContact'];
  onCreateEventClick: () => void;
}

const getMailLink = (contact: AddressbookState['selectedContact']) => {
  const url = new URL('https://mail.corp.rambler.ru/compose');
  contact?.mailingContacts?.contacts?.forEach((c) => {
    url.searchParams.append('user', c.email);
  });

  return url.href;
};

const MailingContact: FC<Props> = ({ contact, onCreateEventClick }) => (
  <div className='addressbook__mailing-group__wrapper'>
    <div className='addressbook__mailing-group__header-info'>
      <div className='addressbook__mailing-group__name'>
        {contact.firstname || contact.lastname ? (
          <span>
            {contact.lastname} {contact.firstname}
          </span>
        ) : (
          <span> {contact?.display_name}</span>
        )}
        <span className='addressbook__mailing-group__name-email'>
          {contact?.email}
        </span>
      </div>
      <div className='addressbook__mailing-group__controls'>
        <a href={getMailLink(contact)} target='_blank' rel='noreferrer'>
          <Button
            className='addressbook__mailing-group__control'
            isRound
            isMobile
            icon={<MailIcon />}
            iconPosition='left'
            type='secondary'
          >
            Написать группе
          </Button>
        </a>
        <Button
          className='addressbook__mailing-group__control'
          onClick={onCreateEventClick}
          isRound
          icon={<PlusIcon />}
          iconPosition='left'
          isMobile
          type='secondary'
        >
          Событие
        </Button>
      </div>
    </div>
    <MailingContactsList
      contacts={contact?.mailingContacts?.contacts}
      status={contact?.mailingContacts?.status}
    />
  </div>
);

export default MailingContact;
