import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const eventDelete = (data: Requests['EventDelete']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'EventDelete'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}/events/${data.url.eventId}`,
    method: 'DELETE',
    headers: data.headers,
  });
};
