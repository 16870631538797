import { takeLatest } from 'redux-saga/effects';
import proccessEventReaction from 'src/_redux/commonSagas/proccessEventReaction';
import { EVENT_VIEW_ATTENDEE_REACTION } from '../store/actionTypes';
import { AttendeeReactionEventViewAction } from '../store/types';

export function* handleEventAttendeeReactionSaga(): Generator {
  yield takeLatest(
    EVENT_VIEW_ATTENDEE_REACTION,
    (a: AttendeeReactionEventViewAction) =>
      proccessEventReaction(a.payload, 'eventView'),
  );
}
