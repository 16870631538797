import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_ADD_SHADOW_EVENT } from '../actionTypes';
import {
  EventsDisplayState,
  AddShadowEventEventsDisplayAction,
} from '../types';

export const addShadowEvent = createAction<
  AddShadowEventEventsDisplayAction,
  EventsDisplayState
>(EVENTS_DISPLAY_ADD_SHADOW_EVENT, (state, payload) => {
  const events = state.events;
  const index = state.events.findIndex((e) => e.id === 'SHADOW');
  if (index > -1) {
    events[index] = payload;
  } else {
    events.push(payload);
  }

  return {
    ...state,
    events,
  };
});
