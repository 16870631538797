// *modulism-group hook-useClickOutside
import { RefObject, SyntheticEvent, useEffect, useRef } from 'react';

export function useClickOutside<T extends HTMLElement>(
  handler: (event: SyntheticEvent) => void,
  ignoreElRefs?: RefObject<HTMLElement>[],
  ignoreClassNames?: string[],
  inUse?: boolean, // Используется щас или нет
): RefObject<T> {
  const domNode = useRef<T>();

  const listener = (event) => {
    if (ignoreClassNames && ignoreClassNames.length) {
      const targetClassName = event.target.className;
      if (targetClassName && targetClassName.includes) {
        for (const ignoredClassName in ignoreClassNames) {
          if (targetClassName.includes(ignoreClassNames[ignoredClassName])) {
            return;
          }
        }
      }
    }

    if (!domNode.current || domNode.current?.contains(event.target)) return;
    if (
      Array.isArray(ignoreElRefs) &&
      ignoreElRefs.some((elRef) => elRef.current?.contains(event.target))
    )
      return;
    handler(event);
  };

  useEffect(() => {
    if ((typeof inUse === 'boolean' && inUse) || typeof inUse === 'undefined') {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [domNode, handler, ignoreElRefs, inUse]);

  return domNode;
}
