import BaseRequester from '../../requesters/baseRequester';

export const getEmployees = () => {
  const requester = new BaseRequester('/api/directory/v1');

  return requester.request<'GetEmployee'>({
    additionalUrl: '/organization/users',
    method: 'GET',
  });
};
