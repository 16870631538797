import { FC } from 'react';

import { AddressbookContact } from 'src/_api/types/mailId/entities';
import './styles.less';

interface Props {
  contact: AddressbookContact;
}

const BACKGROUND_COLORS = ['#AFDE8E', '#F3A6DF', '#99B6DE'];
const randomizeColor = (email: string) => {
  if (email[0]?.toLowerCase() < 'j') {
    return BACKGROUND_COLORS[0];
  }
  if (email[0]?.toLowerCase() < 's') {
    return BACKGROUND_COLORS[1];
  }

  return BACKGROUND_COLORS[2];
};

export const Avatar: FC<Props> = ({ contact }) => {
  const letters = contact?.display_name
    .split(' ')
    .map((s) => s[0])
    .join('')
    .slice(0, 2);

  return (
    <div
      className={'addressbook__contact__avatar'}
      style={{
        backgroundColor: randomizeColor(contact.email),
        ...(contact?.picture && {
          backgroundImage: `url("data:image/png;base64, ${contact?.picture}")`,
        }),
      }}
    >
      {!contact?.picture && letters}
    </div>
  );
};
