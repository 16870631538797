import { select, call, put } from 'redux-saga/effects';
import api from 'src/_api/api';
import { CStoreState } from 'src/_redux/types';
import { isDatesEqual, timeToMinutes } from 'src/_utils/date';
import { loadEventsSuccess } from '../store/actions/loadEventsSuccess';
import { loadEventsFail } from '../store/actions/loadEventsFail';
import { transformCalDavDate } from 'src/_utils/caldav';
import { getEventUserPartStatus } from 'src/_utils/getEventUserPartStatus';
import { USER_PART_STATUS } from 'src/_constants';
import { addSnack } from '../../_redux/slices/snackbar';

export const fetchEventDataSelector = (s: CStoreState) => ({
  curMonth: s.controls.selectedMonth,
  profileEmail: s.apiCommonData.profile?.info?.email.toLowerCase(),
});

function* fetchEvents(param, month: Date) {
  try {
    const { data } = yield call(api('eventList'), param);
    const { curMonth, profileEmail } = yield select(fetchEventDataSelector);

    const userUri = `mailto:${profileEmail}`;

    if (isDatesEqual(curMonth, month)) {
      const clonedEvents = [...data.events];

      clonedEvents.sort((ev1, ev2) => {
        const start1 = new Date(transformCalDavDate(ev1.start.value));
        const start2 = new Date(transformCalDavDate(ev2.start.value));

        const start1Time = `${start1.getHours()}:${start1.getMinutes()}`;
        const start2Time = `${start2.getHours()}:${start2.getMinutes()}`;

        return timeToMinutes(start1Time) - timeToMinutes(start2Time);
      });

      clonedEvents.sort((ev1) => {
        const ev1Status = getEventUserPartStatus(ev1, userUri);

        return ev1Status === USER_PART_STATUS.needsAction &&
          ev1.status !== 'cancelled'
          ? -1
          : 0;
      });

      yield put(loadEventsSuccess.action(clonedEvents));
    }
  } catch (e) {
    yield put(
      addSnack({
        message: 'Не удалось загрузить события — ошибка сервера',
        type: 'error',
      }),
    );
    yield put(loadEventsFail.action());
  }
}

export default fetchEvents;
