import { FC } from 'react';

import './styles.less';
import { hashString } from 'src/_utils/strings';

interface Props {
  name: string;
  email: string;
  base64image?: string;
  size: number;
  fontSize: number;
  lineHeight: number;
}

const BACKGROUND_COLORS = [
  '#99B6DE',
  '#AFDE8E',
  '#F3A6DF',
  '#E18199',
  '#FFCC82',
  '#E6DA7A',
];
const randomizeColor = (email: string) =>
  BACKGROUND_COLORS[hashString(email) % 6];

export const Avatar: FC<Props> = ({
  name,
  email,
  base64image,
  size,
  fontSize,
  lineHeight,
}) => {
  const letters = (name || email)
    .split(' ')
    .map((s) => s[0])
    .join('')
    .slice(0, 2);

  return (
    <div
      className='avatar-comp'
      style={{
        width: size + 'px',
        height: size + 'px',
        minWidth: size + 'px',
        minHeight: size + 'px',
        backgroundColor: randomizeColor(email),
        fontSize: fontSize + 'px',
        lineHeight: lineHeight + 'px',
        ...(base64image && {
          backgroundImage: `url("data:image/png;base64, ${base64image}")`,
        }),
      }}
    >
      {!base64image && letters}
    </div>
  );
};
