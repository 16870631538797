import { FC, useState } from 'react';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import EventView from '../eventView';

import './styles.less';

interface Props {
  conflicts: SelectedEvent[];
}

const Conflict: FC<Props> = ({ conflicts }) => {
  const [isHidden, setIsHidden] = useState(true);

  const toggleConflicts = () => {
    setIsHidden((prev) => !prev);
  };

  return (
    <div className={'new-event__conflicts'}>
      <div className={'new-event__conflicts-header'}>
        <div>Конфликт в вашем календаре</div>
        {conflicts.length > 1 && (
          <div>
            {`(${conflicts.length})`}{' '}
            <span
              className={'new-event__conflicts-toggle'}
              onClick={toggleConflicts}
            >
              {isHidden ? 'Развернуть' : 'Свернуть'}
            </span>
          </div>
        )}
      </div>
      {conflicts.length === 1 && <EventView event={conflicts[0]} />}
      {conflicts.length > 1 && (
        <div
          className={
            isHidden
              ? 'new-event__conflicts__list-hide'
              : 'new-event__conflicts__list'
          }
        >
          {conflicts.map((conflict, i) => (
            <div
              className={'new-event__conflict'}
              key={conflict.description + i}
            >
              <EventView event={conflict} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Conflict;
