import { memo, useState } from 'react';
import '@ramail-components/topline/topline_styles.css';

import {
  BurgerIcon,
  CloseIcon,
  CopyIcon,
  LogoIcon,
  MailIcon,
  ToplineAddressbookIcon,
  ToplineCalendarIcon,
  ToplineMailIcon,
} from '../Svg';
import IconButton from '../IconButton';
import FullScreenDialog from '../FullScreenDialog';
import css from './MobileTopline.module.less';
import { useSelector } from 'react-redux';
import { CStoreState } from '../../_redux/types';
import { getRamblerIdHelper } from '../../scripts/idHelper/getter';
import Button from '@rambler-components/button';
import { copyTextToClipboard } from '../../_utils/copyTextToClipboard';
import { IS_PUBLIC } from '../../_constants';

const MobileTopline = () => {
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);

  const profile = useSelector((s: CStoreState) => s.apiCommonData.profile);

  const onBurger = () => {
    setIsUserDialogOpen(true);
  };

  const onMail = () => {
    if (IS_PUBLIC) {
      window.open('https://mail.rambler.ru/', '_self');
    } else {
      window.open('https://mail.rambler.biz/', '_self');
    }
  };

  const onLogout = async () => {
    const idHelper = await getRamblerIdHelper();
    if (!idHelper) return;
    await idHelper.logout();

    await idHelper.redirectToAuth({
      back: window.location.href,
    });
  };

  const onCopy = () => {
    copyTextToClipboard(profile?.info?.email || '');
  };

  return (
    <div className={css.customTopline}>
      <div onClick={onBurger}>
        <IconButton onClick={onBurger} icon={<BurgerIcon />} />
      </div>
      <div>
        <LogoIcon />
      </div>
      <div>
        <IconButton
          onClick={onMail}
          icon={<MailIcon />}
          className={css.mailIcon}
        />
      </div>
      <FullScreenDialog open={isUserDialogOpen}>
        <div className={css.dialog}>
          <IconButton
            size={40}
            icon={<CloseIcon />}
            className={css.closeIcon}
            onClick={() => setIsUserDialogOpen(false)}
          />
          <div className={css.card}>
            <div className={css.profile}>
              <img
                className={css.avatar}
                src={profile?.display?.avatar?.url}
                alt={'avatar'}
              />
              <div className={css.description}>
                <div className={css.displayName}>
                  {profile?.display?.display_name}
                </div>
                <div className={css.email}>
                  <span>{profile?.info?.email}</span>
                  <IconButton
                    className={css.copy}
                    icon={<CopyIcon />}
                    onClick={onCopy}
                  />
                </div>
                <Button type='white' className={css.logout} onClick={onLogout}>
                  Выйти
                </Button>
              </div>
            </div>
          </div>
          <div className={css.card}>
            <div className={css.resources}>
              <a
                className={css.resource}
                href={
                  IS_PUBLIC
                    ? 'https://mail.rambler.ru/'
                    : 'https://mail.rambler.biz/'
                }
                target='_blank'
                rel='noreferrer'
              >
                <ToplineMailIcon />
                Почта
              </a>
              <a
                className={css.resource}
                onClick={() => setIsUserDialogOpen(false)}
              >
                <ToplineCalendarIcon />
                Календарь
              </a>
              <a
                className={css.resource}
                href={
                  IS_PUBLIC
                    ? 'https://mail.rambler.ru/contacts/mine'
                    : 'https://addressbook.rambler.biz/'
                }
                target='_blank'
                rel='noreferrer'
              >
                <ToplineAddressbookIcon />
                Адресная книга
              </a>
            </div>
          </div>
        </div>
      </FullScreenDialog>
    </div>
  );
};

export default memo(MobileTopline);
