import { FC, useRef, useState } from 'react';

import EventsDropDown from '../EventsDropdown';
import { EVENTS_DROPDOWN_HEIGHTS } from '../EventsDropdown/constants';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import singleDayModalActions from 'src/singleDayModal/store/actions';

import './styles.less';

interface MonthItemEventsMoreProps {
  events: SelectedEvent[];
  maxEventsToDisplay: number;
}

const MonthItemEventsMore: FC<MonthItemEventsMoreProps> = ({
  events,
  maxEventsToDisplay,
}) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();
  const right = ref.current?.getBoundingClientRect().right || 0;
  const bottom = ref.current?.getBoundingClientRect().bottom || 0;

  const bottomCollision = bottom + 300 > window.innerHeight;
  const rightCollision = right + 300 > window.innerWidth;

  if (events.length <= maxEventsToDisplay) return null;

  const topSpace =
    (EVENTS_DROPDOWN_HEIGHTS[events.length] || EVENTS_DROPDOWN_HEIGHTS[10]) +
    10;

  return (
    <div className='month-item-more-events' ref={wrapperRef}>
      <span
        className={opened ? 'opened' : ''}
        onClick={() => {
          // setOpened(!opened);
          singleDayModalActions().setDateSingleModal(events[0].date);
          floatingModalActions().openFloatingModal({
            target: wrapperRef.current,
            opened: FLOATING_MODAL_IDS['dayPageInModal'],
          });
        }}
        ref={ref}
      >
        + eщё {events.length - (maxEventsToDisplay - 1)}
      </span>

      <EventsDropDown
        isOpened={opened}
        ignoreElRefs={[ref]}
        onClose={() => setOpened(false)}
        left={
          rightCollision ? `-${right + 300 - window.innerWidth}px` : '-25px'
        }
        top={bottomCollision ? `-${topSpace}px` : undefined}
        events={events.slice(maxEventsToDisplay - 1)}
      />
    </div>
  );
};

export default MonthItemEventsMore;
