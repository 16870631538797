import { createAction } from 'src/_utils/redux';
import { FLOATING_MODAL_TOGGLE_CENTERED } from '../actionTypes';
import {
  FloatingModalState,
  ToggleCenteredFloatingModalAction,
} from '../types';

export const toggleCentered = createAction<
  ToggleCenteredFloatingModalAction,
  FloatingModalState
>(FLOATING_MODAL_TOGGLE_CENTERED, (state, p) => ({
  ...state,
  center: p,
}));
