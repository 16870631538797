import axios, { AxiosPromise } from 'axios';
import { Requests } from '../types/requests';
import { baseHeaders } from '../constants';

type IRequest<KEY extends keyof Requests> = {
  additionalUrl?: string;
  params?: { [x: string]: unknown };
  data?: Requests[KEY]['params']['body'];
  headers?: Requests[KEY]['params']['headers'];
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'HEAD';
};

class BaseRequester {
  url: string = '';

  constructor(url: string) {
    this.url = url;
  }

  request<KEY extends keyof Requests>({
    additionalUrl = '',
    params = {},
    data,
    headers,
    method,
  }: IRequest<KEY>): AxiosPromise<Requests[KEY]['response']> {
    headers = { ...baseHeaders, ...headers };

    return axios({
      params,
      method,
      url: this.url + additionalUrl,
      data,
      headers,
    });
  }
}

export default BaseRequester;
