export const deepEqual = (obj1: any, obj2: any) => {
  const stack = [[obj1, obj2]];

  while (stack.length) {
    const [a, b] = stack.pop();

    if (a === b) {
      continue;
    }

    if (
      typeof a !== 'object' ||
      a === null ||
      typeof b !== 'object' ||
      b === null
    ) {
      return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!keysB.includes(key)) {
        return false;
      }
      stack.push([a[key], b[key]]);
    }
  }

  return true;
};
