import { createAction } from 'src/_utils/redux';
import { SET_SELECTED_CONTACT } from '../actionTypes';
import { AddressbookState, SetSelectedAction } from '../types';

export const setSelectedContact = createAction<
  SetSelectedAction,
  AddressbookState
>(SET_SELECTED_CONTACT, (state, p) => ({
  ...state,
  selectedContact: {
    mailingContacts: state?.selectedContact?.mailingContacts,
    ...p,
  },
}));
