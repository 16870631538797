import { createSelector } from 'reselect';
import moment from 'moment';
import selectMonthEvents from './monthEventsSelector';
import { CStoreState } from 'src/_redux/types';
import {
  getDurationDays,
  checkTimeOverlap,
  getDateWeekRange,
} from 'src/_utils/date';
import { SelectedEvent } from '../types';

const selectWeekEvents = createSelector(
  [
    selectMonthEvents,
    (state: CStoreState, forceDateRange: [Date, Date]) =>
      forceDateRange || state.controls.selectedDateRange,
  ],
  (events, selectedDateRange) => {
    const weekRange = getDateWeekRange(selectedDateRange[0]);
    const durationDays = getDurationDays(weekRange[0], weekRange[1]).map((d) =>
      moment(d).format().slice(0, -6),
    );

    const convertedWeekEvents: Record<
      string,
      {
        wholeDay: SelectedEvent[];
        regular: Array<SelectedEvent[]>;
      }
    > = {};

    for (let i = 0; i < durationDays.length; i++) {
      const tmpWholeDayEvents: SelectedEvent[] = [];
      const tmpRegularEvents: Array<SelectedEvent[]> = [];
      const regularEventsConnectedToGroups = {};
      const dayEvents = events[durationDays[i]] || [];

      dayEvents.forEach((e) => {
        if (e.type === 'wholeDay' || e.continious) {
          tmpWholeDayEvents.push(e);

          return;
        }

        const tmpGroup = [e];
        dayEvents.forEach((e2) => {
          if (e.id === e2.id || e2.type === 'wholeDay' || e2.continious) return;

          const diffMinutes1 = Math.abs(
            moment(e.start).diff(moment(e2.start), 'minutes'),
          );

          if (
            diffMinutes1 <= 10 ||
            checkTimeOverlap([
              [moment(e.start).format('HH:mm'), moment(e.end).format('HH:mm')],
              [
                moment(e2.start).format('HH:mm'),
                moment(e2.end).format('HH:mm'),
              ],
            ])
          ) {
            tmpGroup.push(e2);
          }
        });

        let groupIndex = null;
        for (let k = 0; k < tmpGroup.length; k++) {
          if (regularEventsConnectedToGroups[tmpGroup[k].id] !== undefined) {
            groupIndex = regularEventsConnectedToGroups[tmpGroup[k].id];

            break;
          }
        }

        if (groupIndex === null) {
          groupIndex = tmpRegularEvents.length;
          tmpRegularEvents.push(tmpGroup);
          for (let k = 0; k < tmpGroup.length; k++) {
            regularEventsConnectedToGroups[tmpGroup[k].id] = groupIndex;
          }

          return;
        }

        for (let k = 0; k < tmpGroup.length; k++) {
          if (regularEventsConnectedToGroups[tmpGroup[k].id] === undefined) {
            tmpRegularEvents[groupIndex].push(tmpGroup[k]);
            regularEventsConnectedToGroups[tmpGroup[k].id] = groupIndex;
          }
        }
      });

      convertedWeekEvents[durationDays[i]] = {
        wholeDay: tmpWholeDayEvents,
        regular: tmpRegularEvents,
      };
    }

    return convertedWeekEvents;
  },
);

export default selectWeekEvents;
