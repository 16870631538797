import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsCalendarOpen = () => {
  const location = useLocation();

  return useMemo(
    () => location.pathname.startsWith('/calendar'),
    [location.pathname],
  );
};
