import { createAction } from 'src/_utils/redux';
import { EVENT_EDIT_REQUEST } from '../actionTypes';
import { EventCreateEditState, RequestEventEditAction } from '../types';

export const requestEventEdit = createAction<
  RequestEventEditAction,
  EventCreateEditState
>(EVENT_EDIT_REQUEST, (state) => ({
  ...state,
  requestLoading: true,
}));
