import { FC, useEffect, useRef, useState } from 'react';
import { EventAttendee, EventOrganizer } from '../../../_api/types/entities';
import { encodedString } from '../../../_utils/strings';
import cn from 'classnames';

import './styles.less';

interface Props {
  isCancelled: boolean;
  attendees: EventAttendee[];
  organizer: EventOrganizer;
}

const Participants: FC<Props> = ({ isCancelled, attendees, organizer }) => {
  const [additionalCount, setAdditionalCount] = useState<number>(0);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const newAt = [...attendees].sort((at) =>
      at.uri == organizer?.uri ? -1 : 1,
    );
    if (!ref.current) return;

    const el = ref.current;
    el.innerText = '';

    for (let i = 0; i < newAt.length; i++) {
      newAt[i].name = encodedString(newAt[i].name || '');
      el.innerHTML += newAt[i].name || newAt[i].uri.replace('mailto:', '');
      if (i < newAt.length - 1) el.innerHTML += ', ';

      if (el.offsetWidth >= el.scrollWidth) continue;

      if (i === newAt.length - 1) {
        setAdditionalCount(1);
      } else {
        setAdditionalCount(newAt.length - i);

        return;
      }
    }
  }, [attendees]);

  return (
    <div
      className={cn('new-event__participants', {
        cancelled: isCancelled,
      })}
    >
      <div className={'participants__few-names'} ref={ref} />
      {additionalCount > 0 && (
        <div className={'participants__additional-count'}>
          {`+${additionalCount}`}
        </div>
      )}
    </div>
  );
};

export default Participants;
