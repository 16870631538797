import moment from 'moment';
import { call, put, select } from 'redux-saga/effects';
import { Event } from 'src/_api/types/entities';
import api from 'src/_api/api';
import { USER_PART_STATUS } from 'src/_constants';
import { addNewEventNotifications } from '../store/actions/addNewEventNotifications';
import { transformCalDavDate } from 'src/_utils/caldav';
import { getEventUserPartStatus } from 'src/_utils/getEventUserPartStatus';
import selectProfileEmail from 'src/_apiCommonData/store/selectors/selectProfileEmail';
import { getTimezone } from '../../_utils/date';

function* fetchNotifyEvents() {
  try {
    const today = moment();
    const todayPlus30 = moment();
    todayPlus30.add(30, 'd');
    const { data } = yield call(api('eventList'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        timeZone: getTimezone(),
        timeMin: today.format(),
        timeMax: todayPlus30.format(),
      },
    });

    const profileEmail = yield select(selectProfileEmail);

    const userUri = `mailto:${profileEmail}`;

    const events = (data.events as Event[]).filter((ev) => {
      const userPartStatus = getEventUserPartStatus(ev, userUri);

      return userPartStatus === USER_PART_STATUS.needsAction;
    });

    yield put(
      addNewEventNotifications.action(
        events.map((ev) => {
          const start = new Date(transformCalDavDate(ev.start.value));
          const end = ev.end
            ? new Date(transformCalDavDate(ev.end.value))
            : new Date(transformCalDavDate(ev.start.value));

          const momentStart = ev.start.timeZone
            ? moment(start).tz(ev.start.timeZone)
            : moment(start);
          const momentEnd = ev.start.timeZone
            ? moment(end).tz(ev.start.timeZone)
            : moment(start);

          const eventType = ev.start.value.includes('T')
            ? 'regular'
            : 'wholeDay';

          const attendee = (ev.attendee || []).map((att) => ({
            ...att,
            uri: decodeURI(att.uri),
          }));

          const userInAttendees = (ev.attendee || []).find(
            (at) => at.uri.toLowerCase() === userUri
          );
          const isOrganizer =
            (ev.organizer || {}).uri?.toLowerCase() === userUri;

          return {
            event: {
              id: ev.id,
              summary: ev.summary,
              description: ev.description,
              location: ev.location,
              type: eventType,
              continious: false, // загулшка
              date: start,
              start: momentStart.toDate(),
              end: momentEnd.toDate(),
              attendee,
              isRecurrence: !!(ev.recurrenceId || ev.recurrence),
              userPartStatus:
                ev.organizer && ev.organizer.uri.toLowerCase() === userUri
                  ? USER_PART_STATUS.organizer
                  : userInAttendees?.status ||
                    (isOrganizer
                      ? USER_PART_STATUS.accepted
                      : USER_PART_STATUS.needsAction),
              source: ev,
              sourceArrayIndex: -1, // заглушка
              alarms: ev.alarms,
            },
            conflicts: [],
          };
        })
      )
    );
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

export default fetchNotifyEvents;
