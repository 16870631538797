import axios, { AxiosPromise } from 'axios';
import { baseParams } from '../constants';
import { RpcRequests } from '../types/rpc/requests';

export class RpcRequester {
  private url = '/api/v2';

  constructor(customUrl?: string) {
    if (customUrl) {
      this.url = customUrl;
    }
  }

  public request = <METHOD extends keyof RpcRequests>(
    method: string,
    params?: { [x: string]: any }
  ): AxiosPromise<RpcRequests[METHOD]['response']> => {
    const requestParams: { [x: string]: any } = { ...baseParams };
    requestParams.headers['X-Rambler-Mail-Method'] = method;

    const body = JSON.stringify({
      method,
      params: params ? [params] : undefined, // special RPC format to put params in an array [...]
      id: `${Date.now()}`,
      rpc: '2.0',
    });

    return axios({
      ...requestParams,
      method: 'POST',
      url: this.url,
      data: body,
    });
  };
}

const rpcRequester = new RpcRequester();

export default rpcRequester;
