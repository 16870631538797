import { FC, useEffect, useRef } from 'react';
import { Day } from 'src/_components/Calendar/Day';
import DayItem from 'src/_components/Calendar/Day/Item';
import { DAY_TIME, TABLET_MAX_WIDTH } from 'src/_constants';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import { scrollContent } from 'src/_utils/scrollContent';
import { isDatesEqual } from 'src/_utils/date';

import pageCalendarWrapper from 'src/pageCalendar/HOC/pageCalendarWrapper';
import selectWeekEvents from 'src/eventsDisplay/store/selectors/weekEventsSelector';
import moment from 'moment';
import DayEventsWrapper from 'src/eventsDisplay/components/DayEventsWrapper';
import processRegularEvents from './processRegularEvents';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import DayWeekHeaderEvents from 'src/eventsDisplay/components/DayWeekHeaderEvents';
import { DayWeekHeader } from 'src/_components/Calendar/DayWeekHeader';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';

interface CalendarModeDayProps {
  forceDateRange?: [Date, Date];
  inWindow?: boolean;
  onSelectEvent?: (evId: string) => void;
  preOpenCreate?: () => void;
}

export const CalendarModeDay: FC<CalendarModeDayProps> = ({
  forceDateRange,
  inWindow,
  onSelectEvent,
  preOpenCreate,
}) => {
  const { selectedDateRange } = useSelector((s: CStoreState) => ({
    selectedDateRange: s.controls.selectedDateRange,
  }));

  const dateRange = forceDateRange || selectedDateRange;

  const weekEvents = useSelector((state: CStoreState) =>
    selectWeekEvents(state, dateRange),
  );
  const elRefs = useRef<HTMLDivElement[]>([]);
  const headerEventsBlockRef = useRef<HTMLDivElement>();
  const currentDay = moment(dateRange[0]).format('YYYY-MM-DD') + 'T00:00:00';
  const events = weekEvents[currentDay];
  const { width } = useWindowSize();

  useEffect(() => {
    if (!inWindow) scrollContent(89 * 8);
  }, []);

  const items = Array(48)
    .fill('.')
    .map((v, i) => ({
      weekDay: dateRange[0].getDay() || 7,
      date: dateRange[0].getDate(),
      isTime: i && i % 2 === 0,
      time: DAY_TIME[i / 2],
      lineTime: DAY_TIME[(i - (i % 2)) / 2],
    }));

  const headerDays = Array(1)
    .fill('.')
    .map(() => ({
      date: dateRange[0],
      weekDay: dateRange[0].getDay() || 7,
      isPast: dateRange[0] < new Date(),
      isCurrent: isDatesEqual(new Date(), dateRange[0]),
    }));

  return (
    <>
      {inWindow ? (
        ''
      ) : (
        <DayWeekHeader
          items={headerDays}
          headerEventsBlockRef={headerEventsBlockRef}
          noTopMargin={!!forceDateRange}
          highlightToday={false}
        >
          <DayWeekHeaderEvents
            dates={headerDays.map((d) => d.date)}
            blockRef={headerEventsBlockRef}
            events={events.wholeDay}
            type='day'
          />
        </DayWeekHeader>
      )}
      <Day
        items={items.map((date, i) => (
          <DayItem
            key={`day-item-${i}`}
            inWindow={inWindow}
            {...date}
            onClick={
              !(i && !(i % 2)) && i
                ? (part: 'top' | 'bottom') => {
                    preOpenCreate && preOpenCreate();
                    floatingModalActions().openFloatingModal({
                      opened: FLOATING_MODAL_IDS['eventCreate'],
                      target: preOpenCreate ? undefined : elRefs.current[i],
                      center: width <= TABLET_MAX_WIDTH,
                    });

                    let timeEnd = date.lineTime.split(':')[0] + ':30';
                    let dateEnd = moment(dateRange[0]).format('DD.MM.YYYY');
                    if (part === 'bottom') {
                      if (Number(date.lineTime.split(':')[0]) + 1 === 24) {
                        timeEnd = '00:00';
                        dateEnd = moment(dateRange[0])
                          .add(1, 'd')
                          .format('DD.MM.YYYY');
                      } else {
                        timeEnd =
                          (Number(date.lineTime.split(':')[0]) + 1)
                            .toString()
                            .padStart(2, '0') + ':00';
                      }
                    }
                    eventCreateEditActions().openEventCreateWindow({
                      dateStart: moment(dateRange[0]).format('DD.MM.YYYY'),
                      dateEnd,
                      timeStart:
                        part === 'top'
                          ? date.lineTime
                          : date.lineTime.split(':')[0] + ':30',
                      timeEnd,
                      transparency: 'OPAQUE',
                    });
                  }
                : null
            }
            refs={elRefs}
            index={i}
          />
        ))}
        fullWidthForTimeDivider={inWindow}
        isToday={isDatesEqual(new Date(), dateRange[0])}
        noMarginBottom={!!inWindow}
      >
        <DayEventsWrapper
          width={
            width > TABLET_MAX_WIDTH
              ? inWindow
                ? 'calc(100% - 70px)'
                : 'calc(100% - 90px)'
              : 'calc(100% - 50px)'
          }
        >
          {events ? processRegularEvents(1, events.regular, onSelectEvent) : ''}
        </DayEventsWrapper>
      </Day>
    </>
  );
};

export default pageCalendarWrapper(CalendarModeDay);
