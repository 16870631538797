// *modulism-group date
import moment from 'moment';

export const getTimezone = () => moment.tz.guess();

export function getFirstDayInMonth(d: Date) {
  const thisDate = new Date(d);
  thisDate.setDate(1);

  return thisDate;
}

export function getFirstDayInPreviousMonth(d: Date) {
  const thisDate = new Date(d);
  thisDate.setDate(1);
  thisDate.setMonth(thisDate.getMonth() - 1);

  return thisDate;
}

export function getFirstDayInNextMonth(d: Date) {
  const thisDate = new Date(d);
  thisDate.setDate(1);
  thisDate.setMonth(thisDate.getMonth() + 1);

  return thisDate;
}

export function getFirstWeekPreviousMonth(d: Date) {
  const previousMonthFirstDay = getFirstDayInPreviousMonth(d);

  return getDateWeekRange(previousMonthFirstDay);
}

export function getFirstWeekNextMonth(d: Date) {
  const nextMonthFirstDay = getFirstDayInNextMonth(d);

  return getDateWeekRange(nextMonthFirstDay);
}

export function getDateWeekRange(d: Date): [Date, Date] {
  const thisDate = new Date(d);
  const first = moment(thisDate).isoWeekday(1);
  const last = moment(thisDate).isoWeekday(7);

  const firstday = first.toDate();
  const lastday = last.toDate();

  return [firstday, lastday];
}

export function getDateWeekRangeForOneMonth(
  firstDate: Date,
  lockMonth?: number
): [Date, Date] {
  const initialDate = new Date(firstDate);

  const months: { [x: string]: { days: number; dates: Date[] } } = {};
  for (let i = 0; i < 7; i++) {
    const monthInMonths = months[initialDate.getMonth()];
    months[initialDate.getMonth()] = {
      days: ((monthInMonths && monthInMonths.days) || 0) + 1,
      dates: [
        ...((monthInMonths && monthInMonths.dates) || []),
        new Date(initialDate),
      ],
    };
    initialDate.setDate(initialDate.getDate() + 1);
  }

  const monthsEntries = Object.entries(months);
  let selectedRange = monthsEntries[0][1].dates;

  if (monthsEntries.length > 1) {
    if (lockMonth !== undefined) {
      selectedRange = months[lockMonth].dates;
    } else if (monthsEntries[0][1].days > monthsEntries[1][1].days) {
      selectedRange = monthsEntries[0][1].dates;
    } else {
      selectedRange = monthsEntries[1][1].dates;
    }
  }

  return [selectedRange[0], selectedRange[selectedRange.length - 1]];
}

export function isDatesEqual(d1: Date, d2: Date): boolean {
  const fulld1 = `${d1.getDate()}-${d1.getMonth()}-${d1.getFullYear()}`;
  const fulld2 = `${d2.getDate()}-${d2.getMonth()}-${d2.getFullYear()}`;

  return fulld1 === fulld2;
}

export function getCalendarFullMonthDays(firstDay: Date) {
  const tmp = new Date(firstDay);
  tmp.setHours(0, 0, 0, 0);
  const firstWeek = getDateWeekRange(tmp);

  const days = Array(42)
    .fill(1)
    .map((d, i) => {
      const date = new Date(firstWeek[0]);
      date.setDate(date.getDate() + i);

      return date;
    });

  return days;
}

export function getDurationDays(start: Date, end: Date) {
  let diffDays = moment(end).diff(moment(start), 'days');
  diffDays = diffDays ? diffDays + 1 : 1;

  const tmp = new Date(start);
  tmp.setHours(0, 0, 0, 0);
  const res = [];
  for (let i = 0; i < diffDays; i++) {
    res.push(new Date(tmp));
    tmp.setDate(tmp.getDate() + 1);
  }

  return res;
}

// Пример аргументов: [ start, end ]
// [ ["11:00", "12:00"], ["13:00", "14:00"] ],
// [ ["11:00", "11:00"] ],
export function checkTimeOverlap(timeSegments: [string, string][]) {
  if (timeSegments.length === 1) return false;

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1[0].localeCompare(timeSegment2[0])
  );

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1];
    const nextStartTime = timeSegments[i + 1][0];

    if (currentEndTime > nextStartTime) {
      return true;
    }
  }

  return false;
}

// Проверяет больше ли одно время другого
// Формат времени "hours:minutes"
export function isTimeBefore(time1: string, time2: string): boolean {
  if (time1 === null || time2 === null) return true;

  const [time1Splitted, time2Splitted] = [time1, time2].map(el => el.split(':').map((n) => Number(n)));

  if (time1Splitted[0] > time2Splitted[0] || (time1Splitted[0] == time2Splitted[0] && time1Splitted[1] >= time2Splitted[1])) return false;

  return true
}

// Переводит время в формате "hours:munutes" в минуты
export function timeToMinutes(time: string): number {
  const splitted = time.split(':');
  let minutes = Number(splitted[1]);
  minutes += Number(splitted[0]) * 60;

  return minutes;
}

// Переводит минуты во время в формате "hours:munutes"
export function minutesToTime(v: number): string {
  const hours = Math.floor(v / 60);
  const minutes = v - hours * 60;

  return `${hours > 9 ? hours : '0' + hours}:${
    minutes > 9 ? minutes : '0' + minutes
  }`;
}

export function getDatesForRange(d1: string, d2: string) {
  const start = moment(d1, 'DD.MM.YYYY');
  const end = moment(d2, 'DD.MM.YYYY');
  const diff = end.diff(start, 'days') + 1;

  const res = [];

  for (let i = 0; i < diff; i++) {
    const tmp = moment(start).add('d', i);
    res.push(tmp.format('DD.MM.YYYY'));
  }

  return res;
}
