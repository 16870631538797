// *modulism-group store-reducer
import { createReducer } from 'src/_utils/redux';
import { addNewEventNotifications } from './actions/addNewEventNotifications';
import { NewEventsState } from './types';
import { removeNewEventNotification } from './actions/removeNewEventNotification';
import { deleteNewEventNotifications } from './actions/deleteNewEventNotifications';

export const initialState: NewEventsState = {
  events: [],
};

export default createReducer(initialState, [
  addNewEventNotifications,
  removeNewEventNotification,
  deleteNewEventNotifications,
]);
