import { createSearchParams } from 'react-router-dom';
import { call, delay, select, take } from 'redux-saga/effects';
import { SEARCH_PARAMS, history } from 'src/_constants';
import { CStoreState } from 'src/_redux/types';
import controlsModeSelector from '../store/selectors/controlsModeSelector';
import controlsPageSelector from '../store/selectors/controlsPageSelector';
import controlsSelectedMonthSelector from '../store/selectors/controlsSelectedMonthSelector';

export const previousRangeSelector = (s: CStoreState) =>
  s.controls.selectedDateRange;

export const nextRangeSelector = (s: CStoreState) =>
  s.controls.selectedDateRange;

export function* syncDateRangeChangesWithUrlSaga(): Generator {
  let previous = yield select(previousRangeSelector);
  while (true) {
    yield take();
    const next = yield select(nextRangeSelector);
    const mode = yield select(controlsModeSelector);
    const page = yield select(controlsPageSelector);
    if (JSON.stringify(next) !== JSON.stringify(previous)) {
      const searchParams = new URLSearchParams(location.search);
      const rangeStart = searchParams.get(SEARCH_PARAMS.rangeStart);
      const rangeEnd = searchParams.get(SEARCH_PARAMS.rangeEnd);
      const month = searchParams.get(SEARCH_PARAMS.month);
      const selectedMonth = (yield select(
        controlsSelectedMonthSelector,
      )) as Date;

      if (
        next &&
        (rangeStart !== next[0].toISOString() ||
          rangeEnd !== next[1].toISOString())
      ) {
        const params = createSearchParams(
          Object.assign(
            {
              rangeStart: next[0].toISOString(),
              rangeEnd: next[1].toISOString(),
            },
            month ? { month: selectedMonth.toISOString() } : {},
          ),
        );
        const newString = `/${page}/${mode}?${params}`;

        // КОСТЫЛЬ
        // Почему-то не работает изменения истории без delay
        yield delay(0);
        yield call(history.push, newString);
      }

      previous = next;
    }
  }
}
