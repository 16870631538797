import { createAction } from 'src/_utils/redux';
import {
  EVENT_ATTACHI_CONTORL_URLOADS,
  EVENT_ATTACHI_DELETE_FILE,
  EVENT_ATTACHI_ADD_FILE,
} from '../actionTypes';
import {
  AddAttachiAction,
  ControlUploadsAction,
  DeleteAttachiAction,
  EventCreateEditState,
} from '../types';

export const controlUploads = createAction<
  ControlUploadsAction,
  EventCreateEditState
>(EVENT_ATTACHI_CONTORL_URLOADS, (state, payload) => ({
  ...state,
  isEdited: true,
  fieldsData: {
    ...state.fieldsData,
    attachi: payload,
  },
}));

/*
 ** This is useless as reducer since no state chage is needed for this kind of action
 */
export const addAttachi = createAction<AddAttachiAction, EventCreateEditState>(
  EVENT_ATTACHI_ADD_FILE,
  (state) => state,
);

/*
 ** This is useless as reducer since no state chage is needed for this kind of action
 */
export const deleteAttachi = createAction<
  DeleteAttachiAction,
  EventCreateEditState
>(EVENT_ATTACHI_DELETE_FILE, (state) => ({
  ...state,
  isEdited: true,
}));
