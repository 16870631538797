import { FC, useCallback, useMemo, useState } from 'react';

import css from './ContactsList.module.less';
import { ToggleGroup, ToggleButton } from '@rambler-components/toggle';
import Checkbox from '@rambler-components/checkbox';
import Switcher from '@rambler-components/switcher';
import '@rambler-components/switcher/styles.css';
import { ContactType } from '../../_apiCommonData/store/types';
import { EventAttendee } from '../../_api/types/entities';
import { PersonalIcon } from '../Svg';
import { useSelector } from 'react-redux';
import selectProfileEmail from '../../_apiCommonData/store/selectors/selectProfileEmail';
import { IS_PUBLIC } from '../../_constants';
import classNames from 'classnames';

interface ContactsListProps {
  contacts: ContactType[];
  attendees: EventAttendee[];
  onSelect?: (email: string) => void;
  onRemove?: (v: string) => void;
}

const getUri = (email: string) => `mailto:${email}`;

const ContactsList: FC<ContactsListProps> = ({
  contacts,
  attendees,
  onSelect,
  onRemove,
}) => {
  const profileEmail = useSelector(selectProfileEmail);
  const [isPersonalOnly, setIsPersonalOnly] = useState(false);

  const attendeeMap = useMemo(
    () =>
      attendees.reduce(
        (acc, attendee) => acc.set(attendee.uri, true),
        new Map(),
      ),
    [attendees],
  );

  const groups = useMemo(() => {
    const res: Record<string, ContactType[]> = {};

    contacts.forEach((c) => {
      if (isPersonalOnly && c.contactType !== 'personal') {
        return;
      }
      let firstLetter = c?.last_name?.at(0);
      if (!firstLetter) {
        firstLetter = c?.first_name?.at(0);
      }
      if (!firstLetter) {
        firstLetter = c?.email?.at(0);
      }

      res[firstLetter] = res[firstLetter] ? [...res[firstLetter], c] : [c];
    });

    return Object.entries(res).sort((a, b) => a[0].localeCompare(b[0]));
  }, [contacts, isPersonalOnly]);

  const onToggle = useCallback(
    (email: string) => {
      const uri = getUri(email);
      if (attendeeMap.get(uri)) {
        onRemove && onRemove(uri);
      } else {
        onSelect && onSelect(email);
      }
    },
    [attendeeMap],
  );

  return (
    <div className={css.contactsList}>
      <div className={css.header}>
        <ToggleGroup
          size={'small'}
          value={'contacts'}
          display='bright'
          onChange={() => {}}
          className={css.toggleContactGroups}
        >
          <ToggleButton value='contacts'>Контакты</ToggleButton>
          <></>
        </ToggleGroup>
        {!IS_PUBLIC && (
          <div className={css.contactSwitcherWrapper}>
            <Switcher
              checked={isPersonalOnly}
              onChange={(event) => setIsPersonalOnly(event.target.checked)}
            >
              Только личные
            </Switcher>
          </div>
        )}
      </div>
      <div>
        {groups.map((gr) => (
          <div className={css.group} key={gr[0]}>
            <div className={css.groupContacts}>
              {gr[1].map((c, j) => {
                const fullName = `${c.last_name || ''} ${
                  c.first_name || ''
                }`.trim();

                if (c.email === profileEmail) return;

                return (
                  <div
                    className={css.contactWrapper}
                    key={c.email}
                    onClick={(e) => {
                      e.preventDefault();
                      onToggle(c.email);
                    }}
                  >
                    <div className={css.groupLetter}>{j === 0 && gr[0]}</div>
                    <div className={css.contact}>
                      <div className={css.contactInfo}>
                        {!!fullName && (
                          <span className={css.fullName}>{fullName}</span>
                        )}
                        <span className={css.contactEmail}>{c.email}</span>
                      </div>
                      <div className={css.control}>
                        {c.contactType === 'personal' && <PersonalIcon />}
                        <Checkbox
                          type={'border'}
                          checked={attendeeMap.get(getUri(c.email))}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onToggle(c.email);
                          }}
                          className={classNames(css.checkbox, {
                            [css.checked]: attendeeMap.get(getUri(c.email)),
                          })}
                          style={{
                            width: 20,
                          }}
                        >
                          {''}
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsList;
