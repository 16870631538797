import { CSSProperties, FC, useRef, useState } from 'react';
import Input from '@rambler-components/input';
import '@rambler-components/input/styles.css';

interface NumberInputProps {
  value: number;
  onChange: (v: number) => void;
  style: CSSProperties;
  max?: number;
  min?: number;
}

const NumberInput: FC<NumberInputProps> = ({
  value,
  onChange,
  style,
  max,
  min,
}) => {
  const [curr, setCurr] = useState(value + '');
  const prevValue = useRef(value + '');

  const blurHandler = () => {
    const trimmed = curr.trim();
    if (trimmed === '') {
      setCurr(typeof min === 'number' ? min + '' : '0');
      onChange(typeof min === 'number' ? min : 0);

      return;
    }
    const number = Number(trimmed);
    if (isNaN(number)) {
      setCurr(prevValue.current);

      return;
    }

    if (typeof max === 'number' && number > max) {
      setCurr(prevValue.current);

      return;
    }

    if (typeof min === 'number' && number < min) {
      setCurr(prevValue.current);

      return;
    }

    setCurr(trimmed);
    onChange(number);
    prevValue.current = trimmed;
  };

  const changeHandler = (v: string) => {
    if (isNaN(Number(v.trim()))) return;
    setCurr(v);
  };

  return (
    <Input
      value={curr + ''}
      onChange={(e) => changeHandler(e.target.value)}
      onBlur={blurHandler}
      style={style}
    />
  );
};

export default NumberInput;
