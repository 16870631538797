import { call, select, takeEvery } from 'redux-saga/effects';
import 'moment-timezone';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { EVENT_VIEW_DELETE_CANCELLED_ACTION } from '../store/actionTypes';
import requestToDeleteCancelledEvent from 'src/_redux/commonSagas/requestToDeleteCanelledEvent';
import selectEventViewEvent from '../store/selectors/selectEventViewEvent';

export function* deleteCancelledEvent(): Generator {
  const event = (yield select(selectEventViewEvent)) as SelectedEvent;

  yield call(requestToDeleteCancelledEvent, event, 'eventView');
}

export function* handleEventDeleteCanelledSaga(): Generator {
  yield takeEvery(EVENT_VIEW_DELETE_CANCELLED_ACTION, deleteCancelledEvent);
}
