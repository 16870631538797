import PeriodSelectors from '../../PeriodSelectors';

import css from './DefaultHeader.module.less';

const Header = () => (
  <div className={css.header}>
    <div className={css.periodSelectorsContainer}>
      <PeriodSelectors />
    </div>
  </div>
);

export default Header;
