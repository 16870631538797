import { call, put, select, takeEvery } from 'redux-saga/effects';
import { forceLoadEvents } from 'src/eventsDisplay/store/actions/forceLoadEvents';
import api from 'src/_api/api';
import { resetEventCreateEdit } from '../store/actions/resetEventCreateEdit';
import { EVENT_EDIT_REQUEST } from '../store/actionTypes';
import { RequestEventCreateAction } from '../store/types';
import { closeFloatingModal } from 'src/floatingModal/store/actions/closeFloatingModal';
import { requestEventEditSuccess } from '../store/actions/requestEventEditSuccess';
import { requestEventEditFail } from '../store/actions/requestEventEditFail';
import { CStoreState } from 'src/_redux/types';
import { Event } from 'src/_api/types/entities';
import eventCreateEditActions from '../store/actions';
import { addSnack } from '../../_redux/slices/snackbar';
import calendarApi from '../../services/calendar';
import { parseRruleData, NO_SUPPORT_RESULT } from 'src/_utils/recurrence';

export const editDataSelector = (s: CStoreState) => s.eventCreateEdit.editData;

export function* sendEventEditRequest(
  action: RequestEventCreateAction,
): Generator {
  const editData = (yield select(
    editDataSelector,
  )) as CStoreState['eventCreateEdit']['editData'];

  const { payload } = action;

  const prevRecurrence = editData?.masterEvent?.recurrence;
  const keepPreviousRecurrence =
    parseRruleData(prevRecurrence) === NO_SUPPORT_RESULT;

  const { recurrence } = payload;

  const noNeedToSendRec =
    recurrence &&
    recurrence[1].includes('FREQ=MONTHLY') &&
    !recurrence[1].includes('BYSETPOS=') &&
    !recurrence[1].includes('INTERVAL=1') &&
    !recurrence[1].includes('BYDAY=');

  let newRecurrence: string[] | undefined;

  if (keepPreviousRecurrence) {
    newRecurrence = editData?.masterEvent?.recurrence;
  } else if (!noNeedToSendRec) {
    newRecurrence = recurrence;
  }

  // currentOverridingEvent - только для single типа
  const mergingEvent = editData.currentOverridingEvent || editData.masterEvent;

  // Нужно чтобы при редактировании не потерялись доп параметры участников (Role, UserType и тд)
  let newAttendees = [...action.payload.attendee];
  if (newAttendees && mergingEvent.attendee) {
    newAttendees = newAttendees.map((at) => {
      const prevAt = mergingEvent.attendee.find((at2) => at2.uri === at.uri);

      const newAt = {
        ...(prevAt || {}),
        ...at,
      };

      return {
        ...newAt,
        status:
          editData.recurrenceType === 'this-and-future'
            ? undefined
            : newAt.status,
      };
    });
  }

  const newAttendeesWithoutOrganizer = newAttendees.filter(
    (at) => at.uri !== action.payload.organizer?.uri,
  );
  const oldAttendees = mergingEvent.attendee || [];

  const newEvent = {
    ...mergingEvent,
    ...action.payload,
    attendee: newAttendees,
    recurrence: newRecurrence,
    recurrenceId: undefined,
    recurrenceRange: undefined,
  };

  try {
    if (
      editData.recurrenceType === 'regular' ||
      editData.recurrenceType === 'all'
    ) {
      yield call(api('eventUpdate'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: editData.masterEvent.id,
        },
        headers: {},
        body: {
          events: [newEvent as Event],
        },
      });
    } else if (editData.recurrenceType === 'single') {
      delete newEvent['recurrence'];
      newEvent['recurrenceId'] = editData.recurrenceId;
      yield call(api('eventOverride'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: editData.masterEvent.id,
        },
        body: {
          event: newEvent as Event,
          subsequent: false,
        },
      });
    } else if (editData.recurrenceType === 'this-and-future') {
      if (!oldAttendees.length && !newAttendeesWithoutOrganizer.length) {
        delete newEvent['recurrence'];
        newEvent['recurrenceId'] = editData.recurrenceId;
        yield call(api('eventOverride'), {
          url: {
            owner: 'me',
            calendarId: 'private',
            eventId: editData.masterEvent.id,
          },
          body: {
            event: {
              ...newEvent,
              recurrenceRange: 'thisandfuture',
            } as Event,
            subsequent: true,
          },
        });
      } else {
        const newMaster = { ...editData.masterEvent };
        const recurSplitted = newMaster.recurrence[1].split(';');
        if (newMaster.recurrence[1].includes('UNTIL')) {
          const untilIndex = recurSplitted.findIndex((r) =>
            r.startsWith('UNTIL='),
          );
          recurSplitted[untilIndex] = `UNTIL=${newEvent.start.value}`;
        } else {
          recurSplitted.push(`UNTIL=${newEvent.start.value}`);
        }
        newMaster.recurrence[1] = recurSplitted.join(';');
        if (newMaster.recurrence[2]) {
          newMaster.recurrence[2] = `${newMaster.recurrence[2]},${newEvent.start.value}`;
        } else {
          newMaster.recurrence.push(
            `EXDATE${newMaster.start.type === 'DATE' ? ';VALUE=DATE' : ''}:${
              newEvent.start.value
            }`,
          );
        }

        yield call(api('eventOverride'), {
          url: {
            owner: 'me',
            calendarId: 'private',
            eventId: editData.masterEvent.id,
          },
          body: {
            event: newMaster,
            subsequent: false,
          },
        });

        delete newEvent['id'];
        delete newEvent['etag'];
        delete newEvent['uid'];
        delete newEvent['instanceId'];

        yield call(api('eventCreate'), {
          url: {
            owner: 'me',
            calendarId: 'private',
          },
          body: {
            events: [newEvent],
          },
        });
      }
    }

    yield put(forceLoadEvents.action(true));

    yield put(requestEventEditSuccess.action());
    yield put(resetEventCreateEdit.action());
    yield put(closeFloatingModal.action());
    yield put(
      addSnack({
        message:
          editData.recurrenceType === 'all' ||
          editData.recurrenceType === 'this-and-future'
            ? 'Цепочка событий обновлена'
            : 'Событие успешно обновлено',
        type: 'success',
      }),
    );
    yield put(calendarApi.util.invalidateTags(['notification-events']));
  } catch (e) {
    yield put(
      addSnack({
        message: 'Не удалось обновить событие — попробуйте ещё раз',
        type: 'error',
        actionButtons: [
          {
            title: 'Повторить',
            onClick: () => {
              eventCreateEditActions().requestEventEdit(action.payload);
            },
          },
        ],
      }),
    );

    yield put(requestEventEditFail.action());
  }
}

export function* handleEventEditRequestSaga(): Generator {
  yield takeEvery(EVENT_EDIT_REQUEST, sendEventEditRequest);
}
