import { FC } from 'react';
import cn from 'classnames';

import IconButton from 'src/_components/IconButton';
import { CloseIcon, HelpIcon, SuccessIcon } from 'src/_components/Svg';
import Conflicts from '../conflicts';
import { EVENT_STATUS } from 'src/_constants';
import Button from '@rambler-components/button';

import EventView from '../eventView';
import newEventNotificationActions from '../../store/actions';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import './styles.less';

interface Props {
  event: SelectedEvent;
  conflicts?: SelectedEvent[];
}

const Notification: FC<Props> = ({ event, conflicts }) => {
  const onAccept = () => {
    newEventNotificationActions().attendeeReactionNewEventNotifications({
      status: 'ACCEPTED',
      event,
    });
  };

  const onReject = () => {
    newEventNotificationActions().attendeeReactionNewEventNotifications({
      status: 'DECLINED',
      event,
    });
  };

  const onTentativeClicked = () => {
    newEventNotificationActions().attendeeReactionNewEventNotifications({
      status: 'TENTATIVE',
      event,
    });
  };

  return (
    <div
      className={cn('new-event-notification', {
        cancelled: event.source.status === EVENT_STATUS.cancelled,
      })}
    >
      <EventView event={event} />
      {conflicts?.length > 0 && <Conflicts conflicts={conflicts} />}
      <div className={'new-event__controls'}>
        {event.source.status === EVENT_STATUS.cancelled ? (
          <Button
            className='new-event__delete-button'
            onClick={() =>
              newEventNotificationActions().deleteCancelledNewEventNotifications(
                event,
              )
            }
          >
            Удалить
          </Button>
        ) : (
          <>
            <IconButton
              size={40}
              withBackground
              className='new-event__control'
              icon={<SuccessIcon />}
              onClick={onAccept}
            />

            <IconButton
              size={40}
              withBackground
              className='new-event__control'
              icon={<CloseIcon />}
              onClick={onReject}
            />

            <IconButton
              size={40}
              withBackground
              className='new-event__control'
              icon={<HelpIcon />}
              onClick={onTentativeClicked}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Notification;
