import { FC, useRef, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { isDatesEqual } from 'src/_utils/date';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import eventViewActions from 'src/eventView/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';

import selectEventViewEvent from 'src/eventView/store/selectors/selectEventViewEvent';
import selectFloatingModalOpened from 'src/floatingModal/store/selectors/selectFloatingModalOpened';
import { EVENT_STATUS, USER_PART_STATUS } from 'src/_constants';
import { HelpFillIcon, InfoFillIcon } from 'src/_components/Svg';
import { CalendarTooltip } from '../../../_components/CalendarTooltip';

import './styles.less';
import '@rambler-components/tooltip/styles.css';

interface MonthItemEventProps {
  event: SelectedEvent;
  isDatePast?: boolean;
}

const MonthItemEvent: FC<MonthItemEventProps> = ({ event, isDatePast }) => {
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const tooltipTimeout = useRef(null);
  const viewEvent = useSelector(selectEventViewEvent);
  const openedFloatingModal = useSelector(selectFloatingModalOpened);
  const ref = useRef<HTMLDivElement>();

  const tooltipArr = [];
  if (event.summary) {
    if (event.summary.length < 28) {
      tooltipArr.push(event.summary);
    } else {
      event.summary.split('').reduce((s, el, i) => {
        s += el;
        if (s.length === 28 || i === event.summary.length - 1) {
          tooltipArr.push(s);
          s = '';
        }

        return s;
      }, '');
    }
  }
  const tooltipText = tooltipArr.join('\n');

  const needsAction =
    event.id !== 'SHADOW' &&
    event.source.status !== EVENT_STATUS.cancelled &&
    event.userPartStatus === USER_PART_STATUS.needsAction;

  const isTentative = event.userPartStatus === USER_PART_STATUS.tentative;

  const isPast =
    isDatePast ||
    (isDatesEqual(new Date(), event.date) &&
      new Date() > event.start &&
      event.type != 'wholeDay');

  const onHover = () => {
    tooltipTimeout.current = setTimeout(() => {
      setTooltipVisibility(true);
    }, 1500);
  };

  const onBlur = () => {
    clearTimeout(tooltipTimeout.current);
    setTooltipVisibility(false);
  };

  return (
    <div ref={ref}>
      <CalendarTooltip
        label={`${
          event.type === 'regular'
            ? moment(event.start).format('HH:mm')
            : 'Весь день'
        } — ${tooltipText}`}
        isVisible={tooltipVisibility}
        position='top'
      >
        <div
          className={cn('month-item-event', {
            shadow: event.id === 'SHADOW',
            'whole-day':
              event.type === 'wholeDay' &&
              !needsAction &&
              event.id !== 'SHADOW',
            regular: event.type === 'regular',
            'partstat-need-action': needsAction,
            'whole-day-partstat-need-action':
              needsAction && event.type === 'wholeDay',
            past: isPast,
            cancelled: event.source.status === EVENT_STATUS.cancelled,
            viewing:
              viewEvent?.source.etag === event.source.etag &&
              isDatesEqual(viewEvent.date, event.date) &&
              openedFloatingModal == FLOATING_MODAL_IDS['eventView'],
            tentative: isTentative,
          })}
          onMouseEnter={onHover}
          onMouseLeave={onBlur}
          onClick={
            event.id === 'SHADOW'
              ? undefined
              : () => {
                  eventViewActions().setEventView({ event });
                  floatingModalActions().openFloatingModal({
                    opened: FLOATING_MODAL_IDS['eventView'],
                    target: ref.current,
                    closeOnScroll: true,
                  });
                }
          }
        >
          {event.id === 'SHADOW' || event.type === 'wholeDay' ? (
            ''
          ) : (
            <span
              className='month-item-event-time'
              style={{
                marginRight: event.type === 'regular' ? '5px' : null,
              }}
            >
              {moment(event.start).format('HH:mm')}
            </span>
          )}
          {needsAction && event.type === 'wholeDay' ? (
            <div className='month-item-event-icon'>
              <InfoFillIcon />
            </div>
          ) : isTentative ? (
            <div
              className={cn('month-item-event-icon', {
                'white-icon': event.type === 'wholeDay' && !isPast,
              })}
            >
              <HelpFillIcon />
            </div>
          ) : (
            ''
          )}
          <span className='month-item-event-title'>
            {event.source.status === EVENT_STATUS.cancelled ? 'Отменено: ' : ''}
            {event.summary}
          </span>
        </div>
      </CalendarTooltip>
    </div>
  );
};

export default MonthItemEvent;
