import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import { useClickOutside } from 'src/_utils/hooks/useClickOutside';
import { calcModalPositions } from '../utils/calcModalPositions';
import floatingModalActions from '../store/actions';
import { CUSTOM_EVENTS, WRAPPER_CLASS_NAME } from 'src/_constants';
import { getMinTop, getMinLeft } from '../utils/mins';
import cn from 'classnames';

import './styles.less';

interface FloatingModalProps {
  id: string;
  onClose?: () => void;
  children: ReactNode;
  fixedTop?: number;
}

const FloatingModal: FC<FloatingModalProps> = ({
  id,
  children,
  fixedTop,
  onClose,
}) => {
  const [data, setData] = useState({
    val: false,
    top: getMinTop(),
    left: getMinLeft(),
  });
  const isFullScreen = useRef(false);
  const { opened, target, fixedLeft, closeOnScroll, withBackground, center } =
    useSelector((s: CStoreState) => ({
      opened: s.floatingModal.opened === id,
      target: s.floatingModal.target,
      fixedLeft: s.floatingModal.fixedLeft,
      closeOnScroll: s.floatingModal.closeOnScroll,
      withBackground: s.floatingModal.withBackground,
      center: s.floatingModal.center,
    }));
  const ref = useClickOutside<HTMLDivElement>(() => {
    if (opened && onClose) {
      floatingModalActions().closeFloatingModal();
      onClose();
    }
  });
  const scrollTimoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const wrapper = document.querySelector('.' + WRAPPER_CLASS_NAME);
    if (opened) {
      wrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      isFullScreen.current = false;
      wrapper?.removeEventListener('scroll', handleScroll);
    };
  }, [opened]);

  const handleScroll = () => {
    if (!closeOnScroll) return;

    scrollTimoutRef.current && clearTimeout(scrollTimoutRef.current);

    const timeout = setTimeout(() => {
      floatingModalActions().closeFloatingModal();
      onClose();
    }, 500);

    scrollTimoutRef.current = timeout;
  };

  useEffect(() => {
    if (opened) {
      if (target) {
        setTimeout(() => {
          const { top, left } = calcModalPositions(target, ref.current);
          setData({
            val: true,
            top: fixedTop || top,
            left: fixedLeft || left,
          });
        });
      } else {
        setData({
          val: true,
          top: fixedTop || getMinTop(),
          left: fixedLeft || getMinLeft(),
        });
      }
    } else
      setData({
        val: false,
        top: fixedTop || getMinTop(),
        left: fixedLeft || getMinLeft(),
      });
  }, [opened, target]);

  const resizeHandler = () => {
    if (target && ref.current && isFullScreen.current === false) {
      const { top, left } = calcModalPositions(target, ref.current);
      setData({
        val: opened,
        top: fixedTop || top,
        left: fixedLeft || left,
      });
    }
  };

  useEffect(() => {
    if (opened && target) {
      window.addEventListener('resize', resizeHandler);
      window.addEventListener(
        CUSTOM_EVENTS.floatingModalRecalculate,
        resizeHandler,
      );
    }

    return () => {
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener(
        CUSTOM_EVENTS.floatingModalRecalculate,
        resizeHandler,
      );
    };
  }, [opened, target, resizeHandler]);

  const fullscreenHandler = () => {
    isFullScreen.current = true;
    setData({
      val: true,
      top: 20,
      // eslint-disable-next-line max-len
      left: (window.innerWidth - 1260) / 2, // !!! Хардкод ширины окна создания/редактирования с сравнением календарей. Пофиксить когда надо будет!
      // Чтобы пофиксить можно передавать значение 1260 в кастомное событие и брать оттуда.
    });
  };
  const fullscreenOffHandler = () => {
    isFullScreen.current = false;
  };

  useEffect(() => {
    if (opened) {
      window.addEventListener(
        CUSTOM_EVENTS.floatingModalFullScreen,
        fullscreenHandler,
      );
      window.addEventListener(
        CUSTOM_EVENTS.floatingModalFullScreenOff,
        fullscreenOffHandler,
      );
    }

    return () => {
      window.removeEventListener(
        CUSTOM_EVENTS.floatingModalFullScreen,
        fullscreenHandler,
      );
      window.removeEventListener(
        CUSTOM_EVENTS.floatingModalFullScreenOff,
        fullscreenOffHandler,
      );
    };
  }, [opened, fullscreenHandler]);

  const rootStyle = useMemo(() => {
    if (center) {
      return {
        top: '50%',
        left: '50%',
        transform: 'translate3D(-50%, -50%, 0)',
      };
    }

    return {
      top: data.top,
      left: data.left,
    };
  }, [center, data]);

  return (
    <>
      <div
        className={cn('floating-modal', {
          opened: data.val,
        })}
        style={rootStyle}
        ref={ref}
      >
        {children}
      </div>
      <div
        className={cn('floating-modal-background', {
          opened: data.val && withBackground,
        })}
      />
    </>
  );
};

export default FloatingModal;
