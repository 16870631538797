import { FC, useEffect, useRef, useState } from 'react';
import './styles.less';
import { AddressbookState } from 'src/addressbook/store/types';
import Loader from '@rambler-components/loader';
import { MailingContactOverview } from './mailingContactOverview';
import addressbookActions from '../../../../store/actions';
import { AddressbookContact } from '../../../../../_api/types/mailId/entities';

interface Props {
  contacts: AddressbookState['selectedContact']['mailingContacts']['contacts'];
  status: AddressbookState['selectedContact']['mailingContacts']['status'];
}

const getContactsCountTitle = (count: number) => {
  let label;
  if (count % 10 === 1) {
    // 1, 21, 31...
    label = 'участник';
  } else if (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) {
    if (count % 100 <= 19 && count % 100 >= 5) {
      // 5..19, 105...119
      label = 'участников';
    } else {
      // 2..4, 22..24 ...
      label = 'участника';
    }
  } else {
    label = 'участников';
  }

  return `${count} ${label}`;
};

export const MailingContactsList: FC<Props> = ({ contacts, status }) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (status === 'loading') {
      timeoutRef.current = setTimeout(() => setShowSpinner(true), 150);
    } else {
      setShowSpinner(false);
      clearTimeout(timeoutRef.current);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [contacts, status]);

  const onContactClicked = (contact: AddressbookContact) => {
    addressbookActions().setSelectedContact({
      ...contact,
      lookup: 'people',
    });
  };

  if (showSpinner) {
    return (
      <div className='mailing-contacts-list__root'>
        <div className='mailing-contacts__loader'>
          <Loader size='medium' style={{ marginTop: 20 }} />
        </div>
      </div>
    );
  }

  if (!Array.isArray(contacts) || contacts?.length === 0) {
    return <></>;
  }

  return (
    <div className='mailing-contacts-list__root'>
      <div className='mailing-contacts-list__content'>
        <div className='mailing-contacts-list__count'>
          {getContactsCountTitle(contacts.length)}
        </div>
        <div className='mailing-contacts-list__contacts-wrapper'>
          {contacts
            .sort((a, b) => a.display_name.localeCompare(b.display_name, 'ru'))
            .map((contact) => (
              <MailingContactOverview
                key={contact.email}
                contact={contact}
                onContactClicked={onContactClicked}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
