export const calendarIdentificationHeaders = {
  'X-Rambler-Mail-Client-Type': 'b2b/calendar/web',
  'X-Rambler-Mail-Client-Version': process.env.version,
};

export const baseHeaders = {
  Accept: 'application/json',
  'Cache-Control': 'no-cache',
  ...calendarIdentificationHeaders,
};

export const baseParams = {
  headers: {
    ...baseHeaders,
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

export const rpcMethods = {
  GET_CONTACTS: 'Rambler::Mail::get_contacts',
};

export const dinamicRoutes = {
  month: (currentPage) => `/${currentPage}/month`,
  week: (currentPage) => `/${currentPage}/week`,
  day: (currentPage) => `/${currentPage}/day`,
  all: (currentPage) => `/${currentPage}/all`,
  print: (currentPage) => `/${currentPage}/print`,
};
