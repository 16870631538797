import { takeEvery } from 'redux-saga/effects';
import proccessEventReaction from 'src/_redux/commonSagas/proccessEventReaction';
import { ATTENDEE_REACTION_NEW_EVENT_NOTIFICATIONS } from '../store/actionTypes';
import { AttendeeReactionNewEventNotificationsAction } from '../store/types';

export function* handleNewEventNotificationReaction(): Generator {
  yield takeEvery(
    ATTENDEE_REACTION_NEW_EVENT_NOTIFICATIONS,
    (a: AttendeeReactionNewEventNotificationsAction) =>
      proccessEventReaction(
        {
          status: a.payload.status,
          recurrenceType: 'regular',
        },
        'newEvents',
        a.payload.event
      )
  );
}
