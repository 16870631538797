import { useEffect, useState } from 'react';

import './styles.less';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import Contact from './contact';
import MailingContact from './mailingContact';

const ContactView = () => {
  const [flex, setFlex] = useState('0');
  const contact = useSelector(
    (s: CStoreState) => s.addressbook.selectedContact,
  );

  useEffect(() => {
    if (contact) {
      setFlex('0.8');
    } else {
      setFlex('0');
    }
  }, [contact]);

  useEffect(
    () => () => {
      floatingModalActions().closeFloatingModal();
    },
    [],
  );

  const onCreateEventClick = () => {
    const attendees = [];
    if (contact.lookup === 'mailing') {
      if (contact?.mailingContacts?.contacts) {
        attendees.push(
          ...contact.mailingContacts.contacts.map((c) => ({
            uri: `mailto:${c.email}`,
            name: c.display_name,
          })),
        );
      }
    } else {
      attendees.push({
        uri: `mailto:${contact.email}`,
        name: contact.display_name,
      });
    }
    eventCreateEditActions().openEventCreateWindow({ attendees });
    floatingModalActions().openFloatingModal({
      opened: FLOATING_MODAL_IDS['eventCreate'],
      target: undefined,
    });
  };

  if (!contact) {
    return <></>;
  }

  return (
    <div className='addressbook__contact' style={{ flex }}>
      {contact.lookup === 'mailing' ? (
        <MailingContact
          contact={contact}
          onCreateEventClick={onCreateEventClick}
        />
      ) : (
        <Contact contact={contact} onCreateEventClick={onCreateEventClick} />
      )}
    </div>
  );
};

export default ContactView;
