import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_DELETE_SHADOW_EVENT } from '../actionTypes';
import {
  EventsDisplayState,
  DeleteShadowEventEventsDisplayAction,
} from '../types';

export const deleteShadowEvent = createAction<
  DeleteShadowEventEventsDisplayAction,
  EventsDisplayState
>(EVENTS_DISPLAY_DELETE_SHADOW_EVENT, (state) => {
  const events = state.events;
  const index = state.events.findIndex((e) => e.id === 'SHADOW');
  if (index > -1) {
    events.splice(index, 1);
  }

  return {
    ...state,
    events,
  };
});
