import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsAddressbookOpen = () => {
  const location = useLocation();

  return useMemo(
    () => location.pathname.startsWith('/contacts'),
    [location.pathname],
  );
};
