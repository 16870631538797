import moment from 'moment';
import { take, select, put } from 'redux-saga/effects';
import {
  EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA,
  EVENT_CREATE_EDIT_RESET,
  EVENT_CREATE_WINDOW_OPEN,
} from 'src/eventCreateEdit/store/actionTypes';
import { Event } from 'src/_api/types/entities';
import { CStoreState } from 'src/_redux/types';
import { addShadowEvent } from '../store/actions/addShadowEvent';
import { deleteShadowEvent } from '../store/actions/deleteShadowEvent';
import { updateShadowEvent } from '../store/actions/updateShadowEvent';
import { getTimezone } from '../../_utils/date';

export const catchDataSelector = (s: CStoreState) => ({
  title: s.eventCreateEdit.fieldsData.title || 'Новое событие',
  dateStart: s.eventCreateEdit.fieldsData.dateStart,
  timeStart: s.eventCreateEdit.fieldsData.timeStart,
  dateEnd: s.eventCreateEdit.fieldsData.dateEnd,
  timeEnd: s.eventCreateEdit.fieldsData.timeEnd,
  wholeDay: s.eventCreateEdit.fieldsData.wholeDay,
  mode: s.eventCreateEdit.mode,
});

function* catchCreatingEventSaga() {
  while (true) {
    const action = yield take([
      EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA,
      EVENT_CREATE_EDIT_RESET,
      EVENT_CREATE_WINDOW_OPEN,
    ]);

    const data = yield select(catchDataSelector);

    const startDate = moment(data.dateStart, 'DD.MM.YYYY');
    const endDate = moment(data.dateEnd, 'DD.MM.YYYY');

    const event = {
      id: 'SHADOW',
      etag: 'SHADOW',
      instanceId: 'SHADOW',
      summary: data.title,
      start: {
        value: `${startDate.format('YYYYMMDD')}${
          data.timeStart ? `T${data.timeStart.replace(':', '')}00` : 'T000000'
        }`,
        type: data.wholeDay ? 'DATE' : 'DATE-TIME',
        timeZone: getTimezone(),
      },
      end: {
        value: `${endDate.format('YYYYMMDD')}${
          data.timeEnd ? `T${data.timeEnd.replace(':', '')}00` : 'T000000'
        }`,
        type: data.wholeDay ? 'DATE' : 'DATE-TIME',
        timeZone: getTimezone(),
      },
    };

    switch (action.type) {
      case EVENT_CREATE_WINDOW_OPEN: {
        if (data.mode === 'create') {
          yield put(addShadowEvent.action(event as Event));
        }
        break;
      }
      case EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA: {
        if (data.mode === 'create')
          yield put(updateShadowEvent.action(event as Event));
        break;
      }
      case EVENT_CREATE_EDIT_RESET: {
        yield put(deleteShadowEvent.action());
        break;
      }
    }
  }
}

export default catchCreatingEventSaga;
