import { FC, ReactNode } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { IS_CORP, WRAPPER_CLASS_NAME } from 'src/_constants';

import './styles.less';

interface Props {
  children: ReactNode;
}

const Wrapper: FC<Props> = (props) => (
  <div className='wrapper-block'>
    <Sidebar />

    <div
      className={WRAPPER_CLASS_NAME}
      style={{
        ...(IS_CORP && {
          height: '100vh',
        }),
      }}
    >
      <Header />
      {props.children}
    </div>
  </div>
);

export default Wrapper;
