import { put, select, take } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import api from 'src/_api/api';
import {
  EVENT_ATTACHI_ADD_FILE,
  EVENT_ATTACHI_DELETE_FILE,
} from '../store/actionTypes';
import { CStoreState } from 'src/_redux/types';

import { controlUploads } from '../store/actions/attachiActions';
import { Upload } from '../components/Attachments';
import { checkFilesSize, transformUploadsToList } from '../utils';
import { addSnack } from '../../_redux/slices/snackbar';
import { dispatch } from '../../_redux';

const downloadFileChannel = channel();

/*
 * To fire an action we need to import it, and we should know that action exists
 * There is no limitation which action we can fire and which we can listen to from this saga
 */
export function* uploadAttachment(): Generator {
  while (true) {
    const action: any = yield take([
      EVENT_ATTACHI_ADD_FILE,
      EVENT_ATTACHI_DELETE_FILE,
    ]);

    /*
     ** No type infering
     */
    const uploads = (yield select(
      (s: CStoreState) => s.eventCreateEdit.fieldsData.attachi || {}
    )) as { [id: number]: Upload };

    if (action.type === 'EVENT_ATTACHI_DELETE_FILE') {
      const id = action.payload;

      /*
       * This stuff can not be moved into separate function
       */
      delete uploads[id];
      yield put(controlUploads.action(uploads));
      continue;
      //removeUploadHandler(id, uploads);
    }

    const files = action.payload as Array<File>;
    const filesToCheck = files.concat(transformUploadsToList(uploads) as any);

    const sizeError = checkFilesSize(filesToCheck);
    if (sizeError) {
      yield put(
        addSnack({
          message: sizeError,
          type: 'error',
        })
      );
      continue;
    }

    const forms: Array<{ id: number; formData: any }> = files
      .map(fileToFormData)
      .map((formData, i) => {
        const { name, size } = files[i];
        const filetype = name.split('.')[name.split('.').length - 1];
        const id = Math.random();
        uploads[id] = {
          id,
          name,
          size,
          loadedPercent: 0,
          loadedAt: Date.now(),
          type: filetype,
        };

        return {
          id,
          formData,
        };
      });

    yield put(controlUploads.action(uploads));

    const erroredFileIds: Array<number> = [];
    let fileIndex = 0;
    for (const f of forms) {
      api('upload')(f.formData, (e) => {
        uploadProgressUpdate(e, f.id, uploads);
      }).then(
        ({ key }) => {
          uploadProgressFinish(key, f.id, uploads);
          if (fileIndex === forms.length - 1 && erroredFileIds.length) {
            fileUploadErrorHandler(erroredFileIds, uploads);
          }
          fileIndex++;
        },
        () => {
          erroredFileIds.push(f.id);
          if (fileIndex === forms.length - 1 && erroredFileIds.length) {
            fileUploadErrorHandler(erroredFileIds, uploads);
          }
          fileIndex++;
        }
      );
    }
  }
}

export function* watchAttachiChannel() {
  while (true) {
    const action = yield take(downloadFileChannel);
    yield put(action);
  }
}

function uploadProgressUpdate(e, id: number, uploads) {
  const truePercent = (e.loaded / e.total) * 100;
  const fakePertent = truePercent > 0.01 ? truePercent - 0.01 : truePercent;
  uploads[id].loadedPercent = fakePertent;
  downloadFileChannel.put(controlUploads.action(uploads));
}

function uploadProgressFinish(key: string, id: number, uploads) {
  if (uploads[id]) {
    uploads[id] = {
      ...uploads[id],
      key,
      downloadUrl: `/file/${key}`,
      loadedPercent: 100,
    };

    downloadFileChannel.put(controlUploads.action(uploads));
  }
}

function fileToFormData(file) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('local', 'yes');

  return formData;
}

function fileUploadErrorHandler(ids: Array<number>, uploads) {
  const message = `Ошибка загрузки ${ids.length > 1 ? 'файлов' : 'файла'}`;
  dispatch(
    addSnack({
      message,
      type: 'error',
    })
  );

  ids.forEach((i) => {
    delete uploads[i];
    downloadFileChannel.put(controlUploads.action(uploads));
  });
}
