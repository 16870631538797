import { FC } from 'react';

interface ComparingFreeMarkerProps {
  left: number;
  width: number;
}

const ComparingBusyMarker: FC<ComparingFreeMarkerProps> = ({ left, width }) => (
  <div
    className='comparing-busy-marker'
    style={{
      left,
      width,
    }}
  />
);
export default ComparingBusyMarker;
