import { useCallback } from 'react';
import Notifier, { IConfig } from '@ramail-components/notifier';
import '@ramail-components/notifier/notifier_styles.css';

import { useAppDispatch, useAppSelector } from '../../_redux/hooks';
import { onRemoveNotification } from 'src/_redux/slices/notification';

import css from './notifications.module.less';
import { useLocation } from 'react-router-dom';
import { dinamicRoutes } from 'src/_api/constants';

const NOTIFIER_CONFIG: IConfig = {
  hasUnlimitedStack: true,
  defaultTimeoutDelay: 5000,
  stackSize: 3,
};

const Notifications = () => {
  const notifications = useAppSelector((s) => s.notification.notifications);
  const dispatch = useAppDispatch();

  const location = useLocation();

  const isNeedDisableNotifies = location.pathname.includes(
    dinamicRoutes.print('calendar'),
  );

  if (isNeedDisableNotifies) {
    return null;
  }

  const _onRemove = useCallback(
    (id: string | number) => {
      dispatch(onRemoveNotification(id));
    },
    [dispatch],
  );

  return (
    <Notifier
      className={css.notifierNotifications}
      notifications={notifications}
      config={NOTIFIER_CONFIG}
      onRemove={_onRemove}
    />
  );
};

export default Notifications;
