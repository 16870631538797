import { Fragment } from 'react';
import DayWeekEvent from 'src/eventsDisplay/components/DayWeekEvent';
import moment from 'moment';
import DayWeekEventMore from 'src/eventsDisplay/components/DayWeekEventMore';
import { SelectedEvent } from 'src/eventsDisplay/store/types';

const EVENTS_WIDTHS = {
  1: 60,
  2: 30,
  3: 20,
  4: 15,
  5: 12,
  6: 10,
};

const processRegularEvents = (
  startColumn: number,
  eGs: SelectedEvent[][],
  onSelectEvent: (v: string) => void,
) =>
  eGs.map((eG, key) => {
    const compInfoArr = [];
    eG.forEach((e, i) => {
      if (i === 0 && eG.length === 1) {
        compInfoArr.push({
          event: eG[i],
          column: startColumn,
          width: 60,
          position: 'full',
        });

        return;
      }
      compInfoArr.push({
        event: eG[i],
        column: startColumn + i * (EVENTS_WIDTHS[eG.length] || 10),
        width: EVENTS_WIDTHS[eG.length] || 10,
        position: !i ? 'left' : i === eG.length - 1 ? 'right' : 'middle',
      });
    });

    return (
      <Fragment key={key}>
        {(compInfoArr.length > 6 ? compInfoArr.slice(0, 5) : compInfoArr).map(
          (cI, i) => (
            <DayWeekEvent
              key={`week-regular-event-proc-${i}`}
              timeRange={[
                moment(cI.event.start).format('HH:mm'),
                moment(cI.event.end).format('HH:mm'),
              ]}
              column={cI.column}
              summary={cI.event.summary}
              width={cI.width}
              position={cI.position}
              event={cI.event}
              onClick={onSelectEvent}
              inGroup={compInfoArr.length > 1}
              location={cI.event.source.location}
              attendees={cI.event.attendee.map(
                (at) => at.name || at.uri.replace('mailto:', ''),
              )}
              type='day'
            />
          ),
        )}
        {compInfoArr.length > 6 ? (
          <DayWeekEventMore
            column={compInfoArr[5].column}
            timeRange={[
              [
                moment(eG[4].start).format('HH:mm'),
                moment(eG[4].end).format('HH:mm'),
              ],
              [
                moment(eG[5].start).format('HH:mm'),
                moment(eG[5].end).format('HH:mm'),
              ],
            ]}
            more={eG.length - 5}
            events={eG.slice(5)}
            width={12}
          />
        ) : (
          ''
        )}
      </Fragment>
    );
  });

export default processRegularEvents;
