import { FC, memo, useRef } from 'react';
import ToplineRamail from '@ramail-components/topline';
import ToplineRambler from '@rambler-components/topline-mail';

import '@ramail-components/topline/topline_styles.css';
import '@rambler-components/topline-mail/styles.css';

import { useWindowSize } from 'src/_utils/hooks/useWindowSize';
import MobileTopline from '../MobileTopline';
import { TABLET_MAX_WIDTH, IS_PUBLIC, IS_CORP } from 'src/_constants';

import css from './Topline.module.less';

const Topline: FC = () => {
  const containerRef = useRef<HTMLDivElement>();
  const { width } = useWindowSize();
  const onUserState = (state) => {
    if (state === 'logout') {
      window.location.reload();
    }
  };

  if (IS_CORP) {
    return <></>;
  }

  return (
    <div className={css.toplineWrapper} ref={containerRef}>
      {width > TABLET_MAX_WIDTH ? (
        <>
          {IS_PUBLIC ? (
            <ToplineRambler onUserState={onUserState} projectCode='calendar' />
          ) : (
            <ToplineRamail projectKey={'calendar'} />
          )}
        </>
      ) : (
        <MobileTopline />
      )}
    </div>
  );
};

export default memo(Topline);
