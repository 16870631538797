import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import './styles.less';

const WhiteLoadingScreen = () => {
  const opened = useSelector((s: CStoreState) => s.whiteLoadingScreen.opened);

  return opened ? <div className='white-loading-screen' /> : null;
};

export default WhiteLoadingScreen;
