import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_DELETE_EVENT } from '../actionTypes';
import { EventsDisplayState, DeleteEventEventsDisplayAction } from '../types';

export const deleteEvent = createAction<
  DeleteEventEventsDisplayAction,
  EventsDisplayState
>(EVENTS_DISPLAY_DELETE_EVENT, (state, payload) => ({
  ...state,
  events: state.events.filter((e) => e.id !== payload),
}));
