import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPALY_SET_CALENDARS } from '../actionTypes';
import { EventsDisplayState, SetCalendarsEventsDisplayAction } from '../types';

export const setCalendars = createAction<
  SetCalendarsEventsDisplayAction,
  EventsDisplayState
>(EVENTS_DISPALY_SET_CALENDARS, (state, payload) => ({
  ...state,
  calendars: payload,
}));
