import { FC, useEffect, useState } from 'react';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import MonthItemEvent from '../MonthItemEvent';
import MonthItemEventsMore from '../MonthItemEventsMore';

interface MonthItemEventsWrapperProps {
  events: SelectedEvent[];
  isDatePast?: boolean;
  onClickEmptyEvents: () => void;
}

const MonthItemEventsWrapper: FC<MonthItemEventsWrapperProps> = ({
  events,
  isDatePast,
  onClickEmptyEvents,
}) => {
  const [maxEls, setMaxEls] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      const someMonthItem = document.querySelector('.month-item');
      if (someMonthItem) {
        let height = someMonthItem.getBoundingClientRect().height;
        height -= 20; // padding
        height -= 20; // head height
        setMaxEls(Math.floor(height / 25));
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {(events.length > maxEls ? events.slice(0, maxEls - 1) : events).map(
        (e, i) => (
          <MonthItemEvent
            isDatePast={isDatePast}
            event={e}
            key={`month-item-event-${i}`}
          />
        ),
      )}
      {events.length <= maxEls ? (
        <div
          style={{
            width: '100%',
            height: (maxEls - events.length) * 25 + 15 + 'px',
            cursor: 'pointer',
          }}
          onClick={onClickEmptyEvents}
        />
      ) : (
        ''
      )}
      <MonthItemEventsMore events={events} maxEventsToDisplay={maxEls} />
    </>
  );
};

export default MonthItemEventsWrapper;
