import { createAction } from 'src/_utils/redux';
import { WHITE_LOADING_SCREEN_OPEN } from '../actionTypes';
import {
  OpenWhiteLoadingScreenAction,
  WhiteLoadingScreenState,
} from '../types';

export const openWhiteLoadingScreen = createAction<
  OpenWhiteLoadingScreenAction,
  WhiteLoadingScreenState
>(WHITE_LOADING_SCREEN_OPEN, () => ({
  opened: true,
}));
