import { datetime, RRule } from 'rrule';

const getUntil = (untilDate: string, wholeDay?: boolean): Date | undefined => {
  if (untilDate) {
    const untilArr = untilDate.split('.').map((n) => Number(n));

    if (wholeDay) {
      return new Date(
        Date.UTC(untilArr[2], untilArr[1] - 1, untilArr[0], 0, 0, 0),
      );
    }

    return datetime(untilArr[2], untilArr[1], untilArr[0], 23, 59, 59);
  }
};

const getDtstart = (start: string): Date => {
  const startArr = start.split('.').map((n) => Number(n));

  return datetime(startArr[2], startArr[1], startArr[0]);
};

export const generateWeekRule = ({
  start,
  untilDate,
  selectedDays,
  selectedWeeks,
  selectedMonths,
  wholeDay,
}: {
  start: string;
  untilDate: string;
  selectedDays: string[];
  selectedWeeks: number[];
  selectedMonths: number[];
  wholeDay?: boolean;
}): RRule => {
  const dtstart = getDtstart(start);
  const until = getUntil(untilDate, wholeDay);
  const byweekday = selectedDays.map((day) => RRule[day]);
  const bysetpos = selectedWeeks.length ? selectedWeeks : undefined;
  const bymonth = selectedMonths.length ? selectedMonths : undefined;
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const interval = 1;
  const freq = selectedWeeks.length ? RRule.MONTHLY : RRule.WEEKLY;

  return new RRule({
    freq,
    dtstart,
    until,
    byweekday,
    bysetpos,
    bymonth,
    tzid,
    interval,
  });
};

export const generatePeriodRule = ({
  start,
  untilDate,
  interval,
  wholeDay,
}: {
  start: string;
  untilDate: string;
  interval: number;
  wholeDay?: boolean;
}): RRule => {
  const dtstart = getDtstart(start);
  const until = getUntil(untilDate, wholeDay);
  const freq = RRule.DAILY;
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new RRule({
    freq,
    dtstart,
    until,
    interval,
    tzid,
  });
};

export const generateDateRule = ({
  start,
  date,
  untilDate,
  interval,
  selectedMonths,
  wholeDay,
}: {
  start: string;
  date: number;
  untilDate: string;
  interval: number;
  selectedMonths?: number[];
  wholeDay?: boolean;
}) => {
  const dtstart = getDtstart(start);
  const until = getUntil(untilDate, wholeDay);
  const freq = RRule.MONTHLY;
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new RRule({
    freq,
    dtstart,
    until,
    bymonthday: [date],
    interval,
    bymonth: selectedMonths?.length ? selectedMonths : undefined,
    tzid,
  });
};
