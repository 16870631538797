import moment from 'moment';
import { take, select, fork, put } from 'redux-saga/effects';
import controlsSelectedMonthSelector from 'src/controls/store/selectors/controlsSelectedMonthSelector';
import {
  getCalendarFullMonthDays,
  getTimezone,
  isDatesEqual,
} from 'src/_utils/date';
import { loadEvents } from '../store/actions/loadEvents';
import fetchEvents from './fetchEvents';

export function* loadEventsOnMonthChangeSaga(): Generator {
  let previous = (yield select(controlsSelectedMonthSelector)) as Date;

  while (true) {
    yield take();

    const next = (yield select(controlsSelectedMonthSelector)) as Date;

    if (!isDatesEqual(previous, next)) {
      yield put(loadEvents.action());
      const allCalendarDays = getCalendarFullMonthDays(next);

      // const storeCalendarsList = (yield select(
      //   (s: CStoreState) => s.eventsDisplay.calendars
      // )) as Calendar[];

      // TODO
      // Сделать подгрузку событий для всех календарей пользователя
      // На момент написания комметария бэк ещё не готов

      yield fork(
        fetchEvents,
        {
          url: {
            owner: 'me',
            calendarId: 'private',
            expand: true,
            timeZone: getTimezone(),
            timeMin: moment(allCalendarDays[0]).format(),
            timeMax: moment(allCalendarDays[allCalendarDays.length - 1])
              .endOf('day')
              .format(),
          },
        },
        next
      );
    }
    previous = next;
  }
}
