/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleGroup } from '@rambler-components/toggle';
import {
  NextSmallIcon,
  PrevousSmallIcon,
  ToplineAddressbookIcon,
  ToplineCalendarIcon,
  ToplineMailIcon,
} from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import controlsActions from 'src/controls/store/actions';
import IconButton from 'src/_components/IconButton';
import { getDateWeekRange } from 'src/_utils/date';
import moment from 'moment';

import '@rambler-components/toggle/styles.css';
import css from './PeroidSelectors.module.less';
import { IS_PUBLIC } from '../../../_constants';
import cn from 'classnames';
import { dinamicRoutes } from 'src/_api/constants';

const capitalizeFirstLetter = (v: string) =>
  v.charAt(0).toUpperCase() + v.slice(1);

const PeriodSelectors = () => {
  const { selectedMonth, selectedDateRange, mode } = useSelector(
    (s: CStoreState) => s.controls,
  );
  const dates = useMemo(() => {
    switch (mode) {
      case 'month':
        return capitalizeFirstLetter(moment(selectedMonth).format('MMMM YYYY'));
      case 'week': {
        const range = getDateWeekRange(selectedDateRange[0]);

        return `${moment(range[0])
          .format('ll')
          .split(' ')
          .slice(0, 2)
          .map((item) =>
            isNaN(Number(item)) && item.length > 3 ? item.slice(0, 3) : item,
          )
          .join(' ')} — ${moment(range[range.length - 1])
          .format('ll')
          .split(' ')
          .slice(0, 2)
          .map((item) =>
            isNaN(Number(item)) && item.length > 3 ? item.slice(0, 3) : item,
          )
          .join(' ')}`.replaceAll('.', '');
      }
      default:
        return moment(selectedDateRange[0]).format('LL').replace('г.', '');
    }
  }, [selectedMonth, selectedDateRange, mode]);
  const navigate = useNavigate();

  const handlerMode = (value) => {
    switch (value) {
      case 'month':
        controlsActions().selectControlsModeMonth();
        navigate(dinamicRoutes.month('calendar'));
        break;
      case 'week':
        controlsActions().selectControlsModeWeek();
        navigate(dinamicRoutes.week('calendar'));
        break;
      case 'day':
        controlsActions().selectControlsModeDay();
        navigate(dinamicRoutes.day('calendar'));
        break;
      case 'all':
        controlsActions().selectControlsModeAll();
        navigate(dinamicRoutes.all('calendar'));
        break;
    }
  };

  const selectPrev = () => {
    switch (mode) {
      case 'month': {
        const month = new Date(selectedMonth);
        month.setMonth(month.getMonth() - 1);

        controlsActions().selectControlsMonth(month);
        break;
      }
      case 'week': {
        controlsActions().selectControlsPrevWeek();
        break;
      }
      case 'day': {
        controlsActions().selectControlsPrevDay();
        break;
      }
    }
  };

  const selectNext = () => {
    switch (mode) {
      case 'month': {
        const month = new Date(selectedMonth);
        month.setMonth(month.getMonth() + 1);

        controlsActions().selectControlsMonth(month);
        break;
      }
      case 'week': {
        controlsActions().selectControlsNextWeek();
        break;
      }
      case 'day': {
        controlsActions().selectControlsNextDay();
        break;
      }
    }
  };

  return (
    <div className={css.periodSelectors}>
      <div className={css.datas}>
        <IconButton onClick={selectPrev} icon={<PrevousSmallIcon />} />
        <IconButton onClick={selectNext} icon={<NextSmallIcon />} />
        <span>{dates}</span>
      </div>
      <div className={css.togglers}>
        <ToggleGroup value={mode} onChange={handlerMode} display='bright'>
          <ToggleButton value='month'>Месяц</ToggleButton>
          <ToggleButton value='week'>Неделя</ToggleButton>
          <ToggleButton value='day'>День</ToggleButton>
        </ToggleGroup>
        {IS_PUBLIC && (
          <div className={css.icons}>
            <a
              className={css.icon}
              href='https://mail.rambler.ru'
              target='_self'
            >
              <ToplineMailIcon />
            </a>
            <div className={cn(css.icon, css.selected)}>
              <ToplineCalendarIcon />
            </div>
            <a
              className={css.icon}
              href='https://mail.rambler.ru/contacts/mine'
              target='_self'
            >
              <ToplineAddressbookIcon />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PeriodSelectors;
