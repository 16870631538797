// *modulism-group root-saga
import { all, call } from 'redux-saga/effects';
import { syncDateRangeChangesWithUrlSaga } from 'src/controls/sagas/syncDateRangeChangesWithUrl';
import { syncMonthChangesWithUrlSaga } from 'src/controls/sagas/syncMonthChangesWithUrl';
import syncUrlDataWithControls from 'src/controls/sagas/syncUrlDataWithControls';
import { loadContactsApiCommonDataSaga } from 'src/_apiCommonData/sagas/loadContacts';
import { handleEventCreateRequestSaga } from 'src/eventCreateEdit/sagas/handleEventCreateRequest';
import { handleEventCopySaga } from 'src/eventView/sagas/handleEventCopy';
import { handleEventDeleteSaga } from 'src/eventView/sagas/handleEventDelete';
import { handleEventViewEditSaga } from 'src/eventView/sagas/handleEventViewEdit';
import { handleEventEditRequestSaga } from 'src/eventCreateEdit/sagas/handleEvenEditRequest';
import { handleEventAttendeeReactionSaga } from 'src/eventView/sagas/handleEventAttendeeReaction';
import { newEventNotificationsSaga } from 'src/newEventNotifications/sagas/newEventNotificationsSaga';
import { handleEventAttendeeTransparencyChangeSaga } from 'src/eventView/sagas/handleEventAttendeeTransparencyChange';
import { handleEventDeleteCanelledSaga } from 'src/eventView/sagas/handleEventDeleteCancelled';
import { loadProfileApiCommonDataSaga } from 'src/_apiCommonData/sagas/loadProfile';
import { eventsDisplaySaga } from 'src/eventsDisplay/sagas/eventsDisplaySaga';
import { deleteSessionSaga } from 'src/controls/sagas/deleteSessionSaga';
import {
  uploadAttachment,
  watchAttachiChannel,
} from 'src/eventCreateEdit/sagas/addAttachment';
import { handleComparingCalendarsSaga } from 'src/eventCreateEdit/sagas/handleComparingCalendars';
import { fetchAddressbookContactsSaga } from '../addressbook/sagas/fetchAddressbookContacts';
import { fetchAddressbookMailingGroupSaga } from '../addressbook/sagas/fetchAddressbookMailingGroup';

export default function* rootSaga() {
  yield call(loadProfileApiCommonDataSaga);
  yield call(syncUrlDataWithControls);

  yield all([
    watchAttachiChannel(),
    uploadAttachment(),
    syncDateRangeChangesWithUrlSaga(),
    syncMonthChangesWithUrlSaga(),
    handleEventCreateRequestSaga(),
    loadContactsApiCommonDataSaga(),
    handleEventDeleteSaga(),
    eventsDisplaySaga(),
    handleEventDeleteCanelledSaga(),
    handleEventCopySaga(),
    handleEventViewEditSaga(),
    handleEventEditRequestSaga(),
    handleEventAttendeeReactionSaga(),
    newEventNotificationsSaga(),
    handleEventAttendeeTransparencyChangeSaga(),
    deleteSessionSaga(),
    handleComparingCalendarsSaga(),
    fetchAddressbookContactsSaga(),
    fetchAddressbookMailingGroupSaga(),
  ]);
}
