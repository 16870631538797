import { createAction } from 'src/_utils/redux';
import { SET_ADDRESSBOOK_CONTACTS_API } from '../actionTypes';
import { AddressbookState, SetAddressbookContactsApiAction } from '../types';

export const setAddresbookContactsApi = createAction<
  SetAddressbookContactsApiAction,
  AddressbookState
>(SET_ADDRESSBOOK_CONTACTS_API, (state, p) => ({
  ...state,
  ...p,
  cache: {
    ...state.cache,
    ...p.cache,
  },
}));
