import { FC, RefObject } from 'react';

interface MonthItemProps {
  time: string;
  onClick: (part: 'top' | 'bottom') => void;
  refs: RefObject<HTMLDivElement[]>;
  inWindow?: boolean;
  index: number;
}

const DayItem: FC<MonthItemProps> = ({
  time,
  onClick,
  refs,
  inWindow,
  index,
}) => (
  <div
    className='day-item'
    ref={(el) => (refs.current[index] = el)}
    style={{
      paddingLeft: inWindow ? '20px' : '10px',
    }}
  >
    <div
      className='day-item-body'
      style={{
        cursor: onClick ? 'pointer' : undefined,
      }}
    >
      <div className='day-item-content'>
        <p className='day-item-text'>{time}</p>
      </div>
      <div className='day-item-click-zone'>
        <div onClick={() => onClick('top')}></div>
        <div onClick={() => onClick('bottom')}></div>
      </div>
    </div>
  </div>
);

export default DayItem;
