import { IS_CORP, IS_PUBLIC } from '../_constants';

export const getEventMailLink = (eventId: string, organizerOnly?: boolean) => {
  let root = 'https://mail.rambler.biz';
  if (IS_CORP) {
    root = 'https://mail.corp.rambler.ru';
  } else if (IS_PUBLIC) {
    root = 'https://mail.rambler.ru';
  }

  if (organizerOnly) {
    return `${root}/compose?calendar_event_id=${eventId}&organizer_only=true`;
  }

  return `${root}/compose?calendar_event_id=${eventId}`;
};
