export const convertFromBytesToKB = (bytes) => Math.round(bytes / 1024);

export const convertFromKBToBytes = (KB) => KB * 1024;

export const printSize = (number) => {
  const humanized = convertFromBytesToKB(number);

  return humanized < 1024
    ? `${humanized} KB`
    : `${convertFromBytesToKB(humanized)} MB`;
};
