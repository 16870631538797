import * as calendarApi from './calendarApi';
import * as rpcApi from './rpcApi';
import * as upload from './attachi/uploadAttachi';
import * as loadFileInfo from './attachi/loadFileInfo';
import * as directoryApi from './directoryApi';
import * as mailId from './mailIdApi';
import * as idApi from './idApi';

const requests = {
  ...calendarApi,
  ...rpcApi,
  ...upload,
  ...loadFileInfo,
  ...directoryApi,
  ...mailId,
  ...idApi,
};

export default requests;
