import { FC, MutableRefObject, ReactNode, useEffect, useState } from 'react';
import { TABLET_MAX_WIDTH, WEEKS_TITLES_SHORT } from 'src/_constants';
import DayWeekHeaderItem from './item';

import './styles.less';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';

interface DayWeekHeaderProps {
  items: {
    date: Date;
    isPast: boolean;
    isCurrent: boolean;
    weekDay: number;
  }[];
  children: ReactNode;
  headerEventsBlockRef: MutableRefObject<HTMLDivElement>;
  noTopMargin?: boolean;
  highlightToday: boolean;
}

export const DayWeekHeader: FC<DayWeekHeaderProps> = ({
  items,
  children,
  headerEventsBlockRef,
  noTopMargin,
  highlightToday,
}) => {
  const [eventsBlockHeight, setEventsBlockHeight] = useState(0);

  const { width } = useWindowSize();

  useEffect(() => {
    const interval = setInterval(() => {
      if (headerEventsBlockRef.current) {
        const rect = headerEventsBlockRef.current.getBoundingClientRect();
        const height = rect.height - 35;
        if (eventsBlockHeight !== height) {
          setEventsBlockHeight(height);
        }
      }
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [headerEventsBlockRef.current, eventsBlockHeight]);

  return (
    <div
      className='calendar-day-week-header'
      style={{
        height: `${
          60 + eventsBlockHeight > 60 ? 60 + eventsBlockHeight + 5 : 60
        }px`,
        gridTemplateRows: `${
          60 + eventsBlockHeight > 60 ? 60 + eventsBlockHeight + 5 : 60
        }px`,
        gridTemplateColumns: `${
          width > TABLET_MAX_WIDTH ? '70px' : '40px'
        } repeat(${items.length}, minmax(50px, 1fr))`,
        top: noTopMargin ? '0px' : '60px',
      }}
    >
      <DayWeekHeaderItem />
      {items.map((item, i) => (
        <DayWeekHeaderItem
          highlightToday={highlightToday}
          isActive={item.isCurrent}
          isPast={item.isPast}
          weekDay={WEEKS_TITLES_SHORT[item.weekDay - 1]}
          date={item.date.getDate() + ''}
          key={`calendar-day-week-${i}`}
          style={
            i === items.length - 1
              ? {
                  borderTopRightRadius: '10px',
                  borderRight: '0',
                }
              : undefined
          }
        />
      ))}
      {children}
    </div>
  );
};
