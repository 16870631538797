/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useState } from 'react';
import Progressbar from 'src/_components/Progressbar';
import { CornerIcon } from './CornerIcon';
import { UploadFileWrapper } from '../UploadFileWrapper';
import classnames from 'classnames';
import { CloseSmallIcon } from '../../Svg';
import { CalendarTooltip } from '../../CalendarTooltip';

import css from './UploadFile.module.less';

/**
 * TODO:
 * use the same component for both
 * UploadFile and AttachmentItem
 */

interface UploadFileProps {
  onClick?: () => void;
  loadedPercent?: number;
  size?: string;
  type?: string;
  name?: string;
  downloadUrl?: string;
}

const UploadFile: FC<UploadFileProps> = ({
  onClick,
  loadedPercent,
  type,
  size,
  name,
  downloadUrl,
}) => {
  const [showNameTip, setShowNameTip] = useState(false);

  const onRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <UploadFileWrapper
      name={name}
      downloadUrl={downloadUrl}
      className={css.uploadFileWrap}
      linkClassName={css.link}
    >
      <div className={css.uploadFileCorner}>
        <CornerIcon fileType={type} />
      </div>
      <div
        className={css.uploadFileContent}
        onMouseEnter={() => setShowNameTip(true)}
        onMouseLeave={() => setShowNameTip(false)}
      >
        {/* <UploadTooltipView
          content={name}
          show={showNameTip && !showRemoveTip}
        /> */}
        <div className={css.uploadFileContentDescription}>
          <span
            className={classnames(css.name, { [css.hover]: showNameTip })}
            style={
              loadedPercent < 100
                ? {
                    maxWidth: 270,
                  }
                : {}
            }
          >
            {name.replace(/\.[^/.]+$/, '')}
          </span>
          {type}, {size}
        </div>
        {loadedPercent < 100 && (
          <div className={css.uploadFileProgressWrap}>
            <Progressbar filledPercent={loadedPercent} />
          </div>
        )}
        <div className={css.uploadFileRemove} onClick={onRemove}>
          <CalendarTooltip label='Удалить' position='top' autoPosition>
            <div className={css.wrap}>
              <CloseSmallIcon />
            </div>
          </CalendarTooltip>
        </div>
      </div>
    </UploadFileWrapper>
  );
};

export default UploadFile;
