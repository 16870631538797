import { FC } from 'react';
import { EventAttendee, EventOrganizer } from 'src/_api/types/entities';
import { CheckboxIcon, HelpFillIcon, SpamIcon } from 'src/_components/Svg';
import { CUSTOM_EVENTS, USER_PART_STATUS } from 'src/_constants';

import css from './EventBlock.module.less';

interface EventBlockAttendeesProps {
  attendees: EventAttendee[];
  expanded: boolean;
  setExpand: (v: boolean) => void;
  organizer: EventOrganizer;
}

const EventBlockAttendees: FC<EventBlockAttendeesProps> = ({
  attendees,
  expanded,
  setExpand,
  organizer,
}) => {
  const expandAttendees = () => {
    setExpand(true);
    setTimeout(() => {
      const ev = new Event(CUSTOM_EVENTS.floatingModalRecalculate, {
        bubbles: true,
      });
      window.dispatchEvent(ev);
    });
  };

  if (attendees.length > 1) {
    return (
      <div>
        <p className={css.eventStrongText}>Участники</p>
        {attendees
          .sort((at) => (at.uri == organizer?.uri ? -1 : 1))
          .slice(0, expanded ? attendees.length : 5)
          .map((attend, i) => (
            <div key={`event-view-attend-${i}`} className={css.eventAttendee}>
              <div>
                <div className={css.name}>
                  {attend.name || attend.uri.replace('mailto:', '')}
                </div>
                {attend.uri === organizer.uri && (
                  <p className={css.organizer}>Организатор</p>
                )}
              </div>
              {attend.status &&
                (attend.status === USER_PART_STATUS.accepted ? (
                  <CheckboxIcon />
                ) : attend.status === USER_PART_STATUS.declined ? (
                  <SpamIcon />
                ) : (
                  attend.status === USER_PART_STATUS.tentative && (
                    <HelpFillIcon />
                  )
                ))}
            </div>
          ))}
        {attendees.length > 5 && !expanded && (
          <p className={css.eventLink} onClick={expandAttendees}>
            Все участники ({attendees.length})
          </p>
        )}
      </div>
    );
  }

  return <></>;
};

export default EventBlockAttendees;
