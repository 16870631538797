import { createAction } from 'src/_utils/redux';
import { FLOATING_MODAL_MANAGE_BACKGROUND } from '../actionTypes';
import {
  FloatingModalState,
  ManageBackgroundFloatingModalAction,
} from '../types';

export const manageBackgroundFloatingModal = createAction<
  ManageBackgroundFloatingModalAction,
  FloatingModalState
>(FLOATING_MODAL_MANAGE_BACKGROUND, (state, p) => ({
  ...state,
  withBackground: p,
}));
