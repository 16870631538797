import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { CUSTOM_EVENTS } from 'src/_constants';
import { FatalErrorIcon } from '../Svg';
import './styles.less';

interface ErrorBoundaryProps {
  onError?: () => void;
  fallback?: string | JSX.Element;
  children: JSX.Element;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  componentDidMount() {
    window.addEventListener(CUSTOM_EVENTS.fatalError, () => {
      this.setState({ hasError: true });
    });
  }

  componentDidCatch(error, info) {
    console.log('ERROR', error, info);

    /**
     * logging JS fail into Sentry
     */
    Sentry.withScope((scope) => {
      scope.setExtra('errorDetails', {
        path: window.location && window.location.hash,
        info,
      });
      Sentry.captureException(error);
    });

    this.props.onError && this.props.onError();

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError)
      return (
        <div className='fatal-error-root'>
          <div className='fatal-error-icon'>
            <FatalErrorIcon />
          </div>
          <div className='fatal-error-textBody'>
            <h1 className='fatal-error-title'>Произошла ошибка</h1>
            <p className='fatal-error-description'>
              Кажется, вы поймали пролетающую мимо ошибку.
              <br />
              Попробуйте перезагрузить страницу
            </p>
          </div>
          <div className='fatal-error-btnWrapper'>
            <button
              className='fatal-error-btnReload'
              onClick={() => window.location.reload()}
            >
              Перезагрузить
            </button>
          </div>
        </div>
      );

    return this.props.children;
  }
}

export default ErrorBoundary;
