import { createAction } from 'src/_utils/redux';
import { REMOVE_NEW_EVENT_NOTIFICATION } from '../actionTypes';
import { NewEventsState, RemoveNewEventNotificationAction } from '../types';

export const removeNewEventNotification = createAction<
  RemoveNewEventNotificationAction,
  NewEventsState
>(REMOVE_NEW_EVENT_NOTIFICATION, (s, p) => ({
  events: s.events.filter(
    (newEventNotification) => newEventNotification.event.id !== p.id
  ),
}));
