export const declOfNum = (titles, number) => {
  number = Math.abs(number);
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const deleteSpaceAtTheBeginningOfLine = (str) =>
  str ? str.replace(/^\s*/, '') : '';

export const hash = (str) => {
  let hashedString = 5381;
  let i = str.length;

  while (i) {
    hashedString = (hashedString * 33) ^ str.charCodeAt(--i);
  }

  /**
   * JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift.
   */
  return hashedString >>> 0;
};

/**
 * Returns a random string.
 * http://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 * @param {Number} N.
 * @returns {String} random string.
 */
export const getRandomString = (N = 17) =>
  new Array(N + 1)
    .join(`${Math.random().toString(36)}00000000000000000`.slice(2, 18))
    .slice(0, N);

/**
 * Sanitizes html input to prevent xss attacks.
 * @param {String} html - html string to sanitize
 * @returns {String} - sanitized htmlm string
 */
export const sanitizeString = (html) =>
  html &&
  html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');

export const unSanitizeString = (html) =>
  html &&
  html
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"');

export const getSender = (from) => {
  if (!from) return '(не указан)';

  const fromFirst = from[0];
  const sender = fromFirst[0] || fromFirst[1] || fromFirst[2];

  return sender || '(не указан)';
};

export const getSenderEmail = (from) => {
  if (!from) return null;

  const fromFirst = from[0];
  const sender = `${fromFirst[1]}@${fromFirst[2]}`;

  return sender || '(не указан)';
};

/**
 * Replace arrays with email string
 * @param {Array} to
 * @returns {Array} array with emails
 */
export const getEmailArray = (to) =>
  to.map((sender) => `${sender[1]}@${sender[2]}`);

/**
 * Removes white spaces
 * @param {string} str
 * @returns {string} text without spaces
 */
export const removeWhiteSpaces = (str) => str.replace(/\s/g, '');

/**
 * Removes slashes
 * @param {string} str
 * @returns {string} text without slashes
 */
export const removeSlashes = (str) => str.replace(/\//gi, '');

/**
 * Removes HTML tags
 * @param {string} str
 * @returns {string} text without HTML tags
 */
export const removeHTMLTags = (str) => str.replace(/(<([^>]+)>)/gi, '');

/**
 * Removes br tags
 * @param {string} str
 * @returns {string} text without br tags
 */
export const removeBrTags = (str) =>
  str.replace(/(<|&lt;)br\s*\/*(>|&gt;)/gi, '');

/**
 * Removes a tags
 * @param {string} str
 * @returns {string} text without a tags
 */
export const removeATags = (str) => str.replace(/(<(|\/)a([^>]+)>)/gi, '');

/**
 * Removes blockquote tags
 * @param {string} str
 * @returns {string} text without blockquote tags
 */
export const removeBlockquoteTags = (str) =>
  str.replace(/(<(|\/)blockquote([^>]+)>)/gi, '');

/**
 * Removes class attributes
 * @param {string} str
 * @returns {string} text without class attributes
 */
export const removeClassAttrs = (str) =>
  str.replace(/class="[a-zA-Z0-9:;\.\s\(\)\-,]*"/gi, '');

/**
 * Removes id attributes
 * @param {string} str
 * @returns {string} text without id attributes
 */
export const removeIdAttrs = (str) =>
  str.replace(/id="[a-zA-Z0-9:;\.\s\(\)\-,]*"/gi, '');

/**
 * Removes data attributes
 * @param {string} str
 * @returns {string} text without data attributes
 */
export const removeDataAttrs = (str) =>
  str.replace(
    /data[a-zA-Z0-9:;\.\s\(\)\-,]*="[a-zA-Z0-9:;\.\s\(\)\-,]*"/gi,
    ''
  );

export const shrinkStringByMax = (str, max) =>
  str.length > max ? `${str.slice(0, max)}...` : str;

// $FlowFixMe
export const removeChars = (string) =>
  string.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');

export const wrapTextWithTag = (text, searchTerm, tag = 'mark') => {
  const regex = new RegExp(`${searchTerm}`, 'gi');

  return searchTerm === ''
    ? `<${tag}> </${tag}>${text}`
    : text.replace(regex, (match) => `<${tag}>${match}</${tag}>`);
};

export function dynamicContactSort(property) {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    const result =
      a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;

    return result * sortOrder;
  };
}

export const getNoun = (number, one, two, five) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }

  return five;
};

// Хеш функция создаёт из строки число.
export const hashString = (v: string) => {
  let hash = 0;
  let i;
  let chr;

  if (v.length === 0) return hash;
  for (i = 0; i < v.length; i++) {
    chr = v.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash);
};

export const encodedString = (str: string) =>
  str.replace(
    /[\u00A0-\u9999<>\&]/g, i => '&#'+i.charCodeAt(0)+';'
  );
