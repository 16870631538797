import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CloseSmallIcon } from 'src/_components/Svg';
import EventCreateFields from '../Fields';
import EventCreateButtons from '../Buttons';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import IconButton from 'src/_components/IconButton';
import apiCommonDataActions from 'src/_apiCommonData/store/actions';
import { CStoreState } from 'src/_redux/types';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import NotificationBlock from 'src/_components/NotificationBlock';
import EventCreateLoadingScreen from '../LoadingScreen';
import { useClickOutside } from 'src/_utils/hooks/useClickOutside';
import { CUSTOM_EVENTS, MOBILE_MAX_WIDTH } from 'src/_constants';
import { Attachments } from '../Attachments';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';
import cn from 'classnames';

import { useForm } from '../../../_utils/hooks/useForm';
import { DEFAULT_FIELD_VALUES } from '../../const';
import css from './Block.module.less';

const DESKTOP_NOTIFICATION_POSITION = {
  right: 0,
  top: 30,
  width: 320,
};

const MOBILE_NOTIFICATION_POSITION = {
  left: 0,
  right: 0,
  top: 30,
  gap: 5,
  padding: 10,
  fontSize: 13,
  borderRadius: 5,
};

const EventCreateBlock = () => {
  const {
    opened,
    mode,
    recurrenceType,
    fieldsHidden,
    comparingData,
    initFieldsData,
  } = useSelector(
    (state: CStoreState) => ({
      opened: state.floatingModal.opened === FLOATING_MODAL_IDS['eventCreate'],
      mode: state.eventCreateEdit.mode,
      recurrenceType: state.eventCreateEdit.editData?.recurrenceType || '',
      fieldsHidden: state.eventCreateEdit.fieldsHidden,
      comparingData: state.eventCreateEdit.comparingCalendarsData,
      initFieldsData: state.eventCreateEdit.fieldsData,
    }),
    shallowEqual,
  );

  const { values, errors, setValue, setInitialValues, getIsEdited } = useForm({
    defaultValues: DEFAULT_FIELD_VALUES,
  });

  const { height, width } = useWindowSize();

  const [isInitDataReady, setIsInitDataReady] = useState(false);
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState<boolean>();

  const blockRef = useRef<HTMLDivElement>();
  const [hasScrollbar, setHasScrollbar] = useState(false);

  const windowRef = useClickOutside<HTMLDivElement>(
    () => {
      if (opened) {
        handleClose();
      }
    },
    [],
    [
      'date-input-dropdown',
      'event-create-reminder-select-item',
      'event-create-transparency-select-item',
    ],
    opened,
  );

  useEffect(() => {
    if (opened) {
      setInitialValues(initFieldsData);
      setIsInitDataReady(true);
    } else {
      setInitialValues(DEFAULT_FIELD_VALUES);
      setIsInitDataReady(false);
    }
  }, [opened]);

  useEffect(() => {
    if (opened) apiCommonDataActions().loadContactsApiCommonData();

    return () => {
      setShowConfirmClose(false);
    };
  }, [opened]);

  useEffect(() => {
    const interval = setInterval(() => {
      const test = blockRef.current
        ? blockRef.current.scrollHeight > blockRef.current.clientHeight
        : false;

      if (test !== hasScrollbar) {
        setHasScrollbar(test);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [hasScrollbar]);

  const onScroll = () => {
    if (blockRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = blockRef.current;

      setIsNearBottom(scrollTop + clientHeight + 10 >= scrollHeight);
    }
  };

  const close = () => {
    setShowConfirmClose(false);
    floatingModalActions().closeFloatingModal();
    setTimeout(() => {
      eventCreateEditActions().resetEventCreateEdit();
    }, 20);
  };

  const handleClose = () => {
    if (getIsEdited()) {
      setTimeout(close, 0);
    } else {
      setShowConfirmClose(true);
    }
  };

  const handleScroll = () => {
    const ev = new Event(CUSTOM_EVENTS.scrollEventCreateEditBody, {
      bubbles: false,
    });
    window.dispatchEvent(ev);
  };

  const maxHeight = useMemo(() => {
    if (width <= MOBILE_MAX_WIDTH) {
      return '100vh';
    }

    if (comparingData.isComparing) {
      return 'calc(100vh - 40px)';
    }
    if (fieldsHidden) {
      return '430px';
    }

    if (height > 800) {
      return '710px';
    }

    return height - 150 + 'px';
  }, [comparingData, fieldsHidden, height, width]);

  if (!isInitDataReady) {
    // TODO: remove this comment after refactoring modal component
    // впадлу править нормально, нужно рефачить модалку, чтобы таких приколов не было
    // в момент открытия модалки выссчитываются ширина/высота children, которая нужна для позиционирования
    // если они по нулям, то будет не оч
    // + хочется чтобы у children последующих элементов сработал onUnmount
    return <div className={css.mockBlock}></div>;
  }

  return (
    <div
      className={css.root}
      style={{
        height: width <= MOBILE_MAX_WIDTH ? '100vh' : 'auto',
        maxWidth: comparingData.isComparing
          ? Math.min(1260, width) + 'px'
          : undefined,
      }}
    >
      <div
        className={cn(css.eventCreateEditBlock, {
          [css.blockComparing]: comparingData.isComparing,
        })}
        style={{
          maxHeight,
          ...(width <= MOBILE_MAX_WIDTH && {
            boxShadow: 'unset',
          }),
        }}
        onScroll={handleScroll}
        ref={windowRef}
      >
        <EventCreateLoadingScreen height={'100%'} />
        <div
          ref={blockRef}
          className={cn(
            css.eventCreateEditBlockContent,
            'event-create-edit-anchor',
            'hide-scrollbar',
            {
              [css.comparingFields]: comparingData.isComparing,
            },
          )}
          onScroll={onScroll}
        >
          <div
            className={cn(css.eventCreateEditBlockHeader, {
              [css.comparing]: comparingData.isComparing,
            })}
          >
            <h3>
              {mode === 'create' ? 'Новое событие' : 'Редактировать событие'}
            </h3>
            <IconButton icon={<CloseSmallIcon />} onClick={handleClose} />

            {showConfirmClose && (
              <NotificationBlock
                title={`Отменить ${
                  mode === 'edit' ? 'редактирование' : 'создание'
                } события?`}
                options={[
                  {
                    title: 'Да, отменить',
                    action: close,
                  },
                  {
                    title: 'Нет, остаться',
                    action: () => setShowConfirmClose(false),
                  },
                ]}
                positions={
                  width <= MOBILE_MAX_WIDTH
                    ? MOBILE_NOTIFICATION_POSITION
                    : DESKTOP_NOTIFICATION_POSITION
                }
              />
            )}
          </div>
          {recurrenceType === 'single' && (
            <div className={css.eventCreateEditBlockRecurrenceAsk}>
              <p>Вы редактируете одно событие из серии повторяющихся.</p>
              <p
                className={'clickable'}
                onClick={() => {
                  eventCreateEditActions().setRecurrenceTypeEventEdit('all');
                }}
              >
                Редактировать все события
              </p>
              <p
                className={'clickable'}
                onClick={() => {
                  eventCreateEditActions().setRecurrenceTypeEventEdit(
                    'this-and-future',
                  );
                }}
              >
                Редактировать это и все последующие
              </p>
            </div>
          )}
          <EventCreateFields
            values={values}
            errors={errors}
            setValue={setValue}
          />
          <Attachments />
        </div>
        <EventCreateButtons
          isNearBottom={isNearBottom}
          hasScrollbar={hasScrollbar}
          values={values}
        />
      </div>
    </div>
  );
};

export default EventCreateBlock;
