import DayWeekEvent from 'src/eventsDisplay/components/DayWeekEvent';
import moment from 'moment';
import DayWeekEventMore from 'src/eventsDisplay/components/DayWeekEventMore';
import { SelectedEvent } from 'src/eventsDisplay/store/types';

const processRegularEvents = (startColumn: number, eGs: SelectedEvent[][]) =>
  eGs.map((eG) => {
    if (eG.length === 1)
      return (
        <DayWeekEvent
          timeRange={[
            moment(eG[0].start).format('HH:mm'),
            moment(eG[0].end).format('HH:mm'),
          ]}
          column={startColumn}
          summary={eG[0].summary}
          event={eG[0]}
          width={6}
          position='full'
          location={eG[0].location}
          attendees={eG[0].attendee.map(
            (at) => at.name || at.uri.replace('mailto:', ''),
          )}
          type='week'
        />
      );
    if (eG.length === 2)
      return (
        <>
          <DayWeekEvent
            timeRange={[
              moment(eG[0].start).format('HH:mm'),
              moment(eG[0].end).format('HH:mm'),
            ]}
            column={startColumn}
            summary={eG[0].summary}
            event={eG[0]}
            width={3}
            position='left'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
          <DayWeekEvent
            timeRange={[
              moment(eG[1].start).format('HH:mm'),
              moment(eG[1].end).format('HH:mm'),
            ]}
            column={startColumn + 3}
            summary={eG[1].summary}
            event={eG[1]}
            width={3}
            position='right'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
        </>
      );
    if (eG.length === 3) {
      return (
        <>
          <DayWeekEvent
            timeRange={[
              moment(eG[0].start).format('HH:mm'),
              moment(eG[0].end).format('HH:mm'),
            ]}
            column={startColumn}
            summary={eG[0].summary}
            event={eG[0]}
            width={2}
            position='left'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
          <DayWeekEvent
            timeRange={[
              moment(eG[1].start).format('HH:mm'),
              moment(eG[1].end).format('HH:mm'),
            ]}
            column={startColumn + 2}
            summary={eG[1].summary}
            event={eG[1]}
            width={2}
            position='middle'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
          <DayWeekEvent
            timeRange={[
              moment(eG[2].start).format('HH:mm'),
              moment(eG[2].end).format('HH:mm'),
            ]}
            column={startColumn + 4}
            summary={eG[2].summary}
            event={eG[2]}
            width={2}
            position='right'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
        </>
      );
    }
    if (eG.length > 3)
      return (
        <>
          <DayWeekEvent
            timeRange={[
              moment(eG[0].start).format('HH:mm'),
              moment(eG[0].end).format('HH:mm'),
            ]}
            column={startColumn}
            summary={eG[0].summary}
            event={eG[0]}
            width={2}
            position='left'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
          <DayWeekEvent
            timeRange={[
              moment(eG[1].start).format('HH:mm'),
              moment(eG[1].end).format('HH:mm'),
            ]}
            column={startColumn + 2}
            summary={eG[1].summary}
            event={eG[1]}
            width={2}
            position='middle'
            inGroup
            location={eG[0].location}
            attendees={eG[0].attendee.map(
              (at) => at.name || at.uri.replace('mailto:', ''),
            )}
            type='week'
          />
          <DayWeekEventMore
            column={startColumn + 4}
            timeRange={[
              [
                moment(eG[0].start).format('HH:mm'),
                moment(eG[0].end).format('HH:mm'),
              ],
              [
                moment(eG[1].start).format('HH:mm'),
                moment(eG[1].end).format('HH:mm'),
              ],
            ]}
            more={eG.length - 2}
            events={eG.slice(2)}
          />
        </>
      );
  });

export default processRegularEvents;
