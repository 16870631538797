import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_EDIT_ADD_FIELDS_ERROR } from '../actionTypes';
import {
  EventCreateEditState,
  AddFieldsErrorEventCreateEditAction,
} from '../types';

export const addFieldsErrorEventCreateEdit = createAction<
  AddFieldsErrorEventCreateEditAction,
  EventCreateEditState
>(EVENT_CREATE_EDIT_ADD_FIELDS_ERROR, (state, payload) => {
  const errorsToAdd = [];

  payload.errors.forEach((er) => {
    const isErrorAlreadyThere =
      state.fieldsData.errors[payload.field].includes(er);

    if (isErrorAlreadyThere) return;

    errorsToAdd.push(er);
  });

  return {
    ...state,
    fieldsData: {
      ...state.fieldsData,
      errors: {
        ...state.fieldsData.errors,
        [payload.field]: [
          ...errorsToAdd,
          ...state.fieldsData.errors[payload.field],
        ],
      },
    },
  };
});
