import { useCallback, useMemo } from 'react';
import Notifier, { IConfig } from '@ramail-components/notifier';
import '@ramail-components/notifier/notifier_styles.css';

import { useAppDispatch, useAppSelector } from '../../_redux/hooks';
import Snack from './Snack';
import { onRemoveSnack } from 'src/_redux/slices/snackbar';

import './styles.less';

const NOTIFIER_CONFIG: IConfig = {
  hasUnlimitedStack: true,
  defaultTimeoutDelay: 5000,
  stackSize: 3,
};

const Snackbar = () => {
  const snacks = useAppSelector((s) => s.snackbar.snacks);
  const dispatch = useAppDispatch();

  const _onRemove = useCallback(
    (id: string | number) => {
      dispatch(onRemoveSnack(id));
    },
    [dispatch, onRemoveSnack],
  );

  const snacksItems = useMemo(
    () =>
      (snacks || []).map((snack) => ({
        ...snack,
        content: <Snack snack={snack} onRemove={_onRemove} />,
      })),
    [snacks, _onRemove],
  );

  return (
    <Notifier
      className={'snackbar-notifier'}
      notifications={snacksItems}
      config={NOTIFIER_CONFIG}
      onRemove={_onRemove}
    />
  );
};

export default Snackbar;
