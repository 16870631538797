import { createAction } from 'src/_utils/redux';
import { SINGLE_DAY_MODAL_DATE_SET } from '../actionTypes';
import { SingleDayModalState, SetDateSingleModalAction } from '../types';

export const setDateSingleModal = createAction<
  SetDateSingleModalAction,
  SingleDayModalState
>(SINGLE_DAY_MODAL_DATE_SET, (s, p) => ({
  date: p,
}));
