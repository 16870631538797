import { SelectedEvent } from '../eventsDisplay/store/types';
import { transformCalDavDate } from './caldav';
import moment from 'moment';
import { USER_PART_STATUS } from '../_constants';
import { Event } from '../_api/types/entities';
import { rrulestr } from 'rrule';

export const eventToSelectedEvent = (
  event: Event,
  profileEmail: string,
): SelectedEvent => {
  const userUri = `mailto:${profileEmail}`;

  const start = new Date(transformCalDavDate(event.start.value));
  const end = event.end
    ? new Date(transformCalDavDate(event.end.value))
    : new Date(start.getTime() + 86400);

  const momentStart = event.start.timeZone
    ? moment(start).tz(event.start.timeZone)
    : moment(start);
  const momentEnd = event.start.timeZone
    ? moment(end).tz(event.start.timeZone)
    : moment(end);

  const eventType = event.start.value.includes('T') ? 'regular' : 'wholeDay';

  const attendee = (event.attendee || []).map((att) => ({
    ...att,
    uri: decodeURI(att.uri),
  }));

  const userInAttendees = (event.attendee || []).find(
    (at) => at.uri.toLowerCase() === userUri,
  );
  const isOrganizer = (event.organizer || {}).uri?.toLowerCase() === userUri;

  return {
    id: event.id,
    summary: event.summary,
    description: event.description,
    location: event.location,
    type: eventType,
    continious: false, // загулшка
    date: start,
    start: momentStart.toDate(),
    end: momentEnd.toDate(),
    attendee,
    isRecurrence: !!(event.recurrenceId || event.recurrence),
    userPartStatus:
      event.organizer && event.organizer.uri.toLowerCase() === userUri
        ? USER_PART_STATUS.organizer
        : userInAttendees?.status ||
          (isOrganizer
            ? USER_PART_STATUS.accepted
            : USER_PART_STATUS.needsAction),
    source: event,
    sourceArrayIndex: -1, // заглушка
    alarms: event.alarms,
  };
};

export const isFirstInRecurrence = (event: SelectedEvent) => {
  const rec = [...event.source.recurrence];
  if (rec[0]) {
    rec[0] = rec[0].replace(';VALUE=DATE', '');
  }
  const rruleParsedObj = rrulestr(rec.join('\n'));
  const parseStart = rruleParsedObj.origOptions.dtstart;
  const start = moment(parseStart);

  if (!start) {
    return false;
  }

  if (start.toDate().getTime() !== event.start.getTime()) {
    return false;
  }

  if (event.source.start.type === 'DATE') {
    //<- тут ошибка, мы хотим сравнивать старт события с началом цепочки, а не с самим собой
    //по дефолту тут всегда true
    return event.source.start.value === start.format('YYYYMMDD');
  }

  return true;
};
