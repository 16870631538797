import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const eventUpdate = (data: Requests['EventUpdate']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'EventUpdate'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}/events/${data.url.eventId}`,
    method: 'PUT',
    data: data.body,
  });
};
