// *modulism-group store-reducer
import { createReducer } from 'src/_utils/redux';
import { setDateSingleModal } from './actions/setDateSingleModal';
import { SingleDayModalState } from './types';

export const initialState: SingleDayModalState = {
  date: null,
};

export default createReducer(initialState, [setDateSingleModal]);
