import { getFirstDayInMonth } from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_PAGE } from '../actionTypes';
import { ControlsState, SelectControlsPageAction } from '../types';

export const selectControlsPage = createAction<
  SelectControlsPageAction,
  ControlsState
>(SELECT_CONTROLS_PAGE, (state, payload) => ({
  ...state,
  selectedMonth: getFirstDayInMonth(new Date()),
  selectedDateRange: null,
  mode: 'month',
  page: payload,
}));
