import { createAction } from 'src/_utils/redux';
import { EVENT_EDIT_SET_RECURRENCE_TYPE } from '../actionTypes';
import {
  EventCreateEditState,
  SetRecurrenceTypeEventEditAction,
} from '../types';

export const setRecurrenceTypeEventEdit = createAction<
  SetRecurrenceTypeEventEditAction,
  EventCreateEditState
>(EVENT_EDIT_SET_RECURRENCE_TYPE, (s, p) => ({
  ...s,
  editData: {
    ...s.editData,
    recurrenceType: p,
  },
}));
