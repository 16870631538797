import { getFirstDayInMonth } from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { OPEN_DAY_CONTROLS } from '../actionTypes';
import { ControlsState, OpenDayControlsAction } from '../types';

export const openDayControls = createAction<
  OpenDayControlsAction,
  ControlsState
>(OPEN_DAY_CONTROLS, (state, payload) => {
  const range: [Date, Date] = [payload, payload];

  return {
    ...state,
    selectedDateRange: range,
    selectedMonth: getFirstDayInMonth(new Date(payload)),
    mode: 'day',
  };
});
