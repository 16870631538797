import { createReducer } from 'src/_utils/redux';
import { clearErrorContactsApiCommonData } from './actions/clearErrorContacts';
import { setContactsApiCommonData } from './actions/setContacts';
import { setProfileApiCommonData } from './actions/setProfile';
import { ApiCommonDataState } from './types';

// *modulism-group store-reducer
const initialState: ApiCommonDataState = {
  contacts: [],
  profile: null,
};

export default createReducer(initialState, [
  setContactsApiCommonData,
  clearErrorContactsApiCommonData,
  setProfileApiCommonData,
]);
