// *modulism-group caldav

export const transformCalDavDate = (date: string) => {
  const splitted = date.split('T');

  const year = splitted[0].slice(0, 4);
  const month = splitted[0].slice(4, 6);
  const day = splitted[0].slice(6, 8);

  const hours = splitted[1] ? splitted[1].slice(0, 2) : '00';
  const minutes = splitted[1] ? splitted[1].slice(2, 4) : '00';
  const seconds = splitted[1] ? splitted[1].slice(4, 6) : '00';

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${
    date.includes('Z') ? 'Z' : ''
  }`;
};

// Функция для перевода даты и времени в формат value у DATE-TIME в CalDav
// Дата в формате DD.MM.YYYY
// Время в формате HH:mm
export function transformDateAndTime(
  date: string,
  time?: string,
  withZ?: boolean
) {
  const splittedDate = date.split('.');

  return `${splittedDate[2]}${splittedDate[1]}${splittedDate[0]}${
    time ? `T${time.replace(':', '')}00${withZ ? 'Z' : ''}` : ''
  }`;
}
