import { getDateWeekRange, getDateWeekRangeForOneMonth } from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_MONTH } from '../actionTypes';
import { SelectControlsMonthAction, ControlsState } from '../types';

export const selectControlsMonth = createAction<
  SelectControlsMonthAction,
  ControlsState
>(SELECT_CONTROLS_MONTH, (state, payload) => {
  let range = null;
  switch (state.mode) {
    case 'day':
      range = [payload, payload];
      break;
    case 'week': {
      const preRange = getDateWeekRange(new Date(payload));
      range = getDateWeekRangeForOneMonth(preRange[0], payload.getMonth());
      break;
    }
  }

  return {
    ...state,
    selectedMonth: payload,
    selectedDateRange: range,
  };
});
