import moment from 'moment';
import { take, select, fork, put } from 'redux-saga/effects';
import controlsSelectedMonthSelector from 'src/controls/store/selectors/controlsSelectedMonthSelector';
import { getCalendarFullMonthDays, getTimezone } from 'src/_utils/date';
import { loadEvents } from '../store/actions/loadEvents';
import { EVENTS_DISPLAY_FORCE_LOAD } from '../store/actionTypes';
import { ForceLoadEventsAction } from '../store/types';
import fetchEvents from './fetchEvents';

export function* forceLoadEventsSaga(): Generator {
  while (true) {
    const action = (yield take(
      EVENTS_DISPLAY_FORCE_LOAD,
    )) as ForceLoadEventsAction;

    // const storeCalendarsList = (yield select(
    //   (s: CStoreState) => s.eventsDisplay.calendars
    // )) as Calendar[];

    // const calendarsListToFetch = action.payload || storeCalendarsList;

    // TODO
    // Сделать подгрузку событий для всех календарей пользователя
    // На момент написания комметария бэк ещё не готов

    const month = (yield select(controlsSelectedMonthSelector)) as Date;

    if (!action.payload) {
      yield put(loadEvents.action());
    }
    const allCalendarDays = getCalendarFullMonthDays(month);

    yield fork(
      fetchEvents,
      {
        url: {
          owner: 'me',
          calendarId: 'private',
          expand: true,
          timeZone: getTimezone(),
          timeMin: moment(allCalendarDays[0]).format(),
          timeMax: moment(allCalendarDays[allCalendarDays.length - 1])
            .endOf('day')
            .format(),
        },
      },
      month,
    );
  }
}
