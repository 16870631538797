import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { INotification } from './notification.types';
import { getUniqueId } from 'src/_utils/uniqueIds';

export interface CounterState {
  notifications: INotification[];
}

const initialState: CounterState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<Omit<INotification, 'id'>>
    ) => {
      const { payload } = action;

      const id = getUniqueId();

      state.notifications.push({
        id,
        ...payload,
      });
    },
    onRemoveNotification: (state, action: PayloadAction<string | number>) => {
      const { payload } = action;

      state.notifications = state.notifications.filter((n) => n.id !== payload);
    },
    hideNotification: (
      state,
      action: PayloadAction<string | { uId: string }>
    ) => {
      const { payload } = action;
      const uId = typeof payload === 'string' ? payload : payload.uId;

      state.notifications = state.notifications.filter((n) => n.uId !== uId);
    },
    addAlert: (state, action: PayloadAction<Omit<INotification, 'id'>>) => {
      const { payload: notification } = action;

      if (
        !state.notifications.find(
          (e) => e?.context?.eventId === notification?.context?.eventId
        )
      ) {
        const id = getUniqueId();
        state.notifications.push({
          id,
          ...notification,
        });
      }
    },
    hideAlert: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.notifications = state.notifications.filter(
        (n) => payload !== n?.context?.eventId
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addNotification,
  hideNotification,
  onRemoveNotification,
  addAlert,
  hideAlert,
} = notificationSlice.actions;

export default notificationSlice.reducer;
