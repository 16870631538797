import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_ADD_EVENT } from '../actionTypes';
import { EventsDisplayState, AddEventEventsDisplayAction } from '../types';

export const addEvent = createAction<
  AddEventEventsDisplayAction,
  EventsDisplayState
>(EVENTS_DISPLAY_ADD_EVENT, (state, payload) => ({
  ...state,
  events: [...state.events, payload],
}));
