import moment from 'moment';
import { Task } from 'redux-saga';
import { call, cancel, delay, fork, put, select } from 'redux-saga/effects';
import { forceLoadEvents } from 'src/eventsDisplay/store/actions/forceLoadEvents';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { setEventView } from 'src/eventView/store/actions/setEventView';
import selectEventViewEvent from 'src/eventView/store/selectors/selectEventViewEvent';
import { deleteNewEventNotifications } from 'src/newEventNotifications/store/actions/deleteNewEventNotifications';
import { closeWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/closeWhiteLoadingScreen';
import { openWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/openWhiteLoadingScreen';
import api from 'src/_api/api';
import { Event } from 'src/_api/types/entities';
import selectProfileEmail from 'src/_apiCommonData/store/selectors/selectProfileEmail';
import { transformCalDavDate, transformDateAndTime } from 'src/_utils/caldav';
import { addSnack } from '../slices/snackbar';

export function* whiteScreenTimeout() {
  yield delay(1000);
  yield put(openWhiteLoadingScreen.action());
}

function* proccessEventReaction(
  payload: {
    status: 'ACCEPTED' | 'DECLINED' | 'TENTATIVE';
    recurrenceType: 'all' | 'single' | 'this-and-future' | 'regular';
  },
  from: 'eventView' | 'newEvents',
  customEvent?: SelectedEvent,
): Generator {
  const event = (yield select(
    selectEventViewEvent,
  )) as unknown as SelectedEvent;
  const workingEvent = from === 'eventView' ? event : customEvent;

  const whiteLoadingScreenTimeout = (yield fork(
    whiteScreenTimeout,
  )) as unknown as Task;
  let events: Event[] = [];
  try {
    const { data }: any = yield call(api('eventGet'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        eventId: workingEvent.id,
      },
      headers: {},
    });
    events = data.events;
  } catch (e) {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Не удалось отреагировать на событие',
        type: 'error',
      }),
    );

    return;
  }

  const masterEvent = events.find((d) => !d.recurrenceId);
  const masterEventIndex = events.findIndex((d) => !d.recurrenceId);

  const sourceRecurenceId = workingEvent.source.recurrenceId;
  const isTypeDate = masterEvent.start.type === 'DATE';

  let currentOverridingEvent: Event | null = null;
  let currentOverridingEventIndex = -1;
  if (events.length > 1 && sourceRecurenceId) {
    const recurIdTimeStartUtc =
      isTypeDate || !sourceRecurenceId
        ? undefined
        : moment(transformCalDavDate(sourceRecurenceId.value)).utc();
    if (recurIdTimeStartUtc) {
      for (const i in events) {
        if (!events[i].recurrenceId) continue;
        const recurIdTimeStartUtcFormatted = transformDateAndTime(
          recurIdTimeStartUtc
            .tz(events[i].recurrenceId.timeZone)
            .format('DD.MM.YYYY'),
          recurIdTimeStartUtc
            .tz(events[i].recurrenceId.timeZone)
            .format('HH:mm'),
        );
        if (recurIdTimeStartUtcFormatted === events[i].recurrenceId.value) {
          currentOverridingEvent = { ...events[i] };
          currentOverridingEventIndex = Number(i);
          break;
        }
      }
    }
  }

  const profileEmail = (yield select(selectProfileEmail)) as unknown as string;
  let newEvent: Event;
  let attendees = [];
  if (currentOverridingEvent) {
    attendees = currentOverridingEvent.attendee || [];
    const userUri = `mailto:${profileEmail}`;

    const foundInAttendee = attendees.findIndex(
      (at) => at.uri.toLowerCase() === userUri,
    );
    if (foundInAttendee > -1) {
      attendees[foundInAttendee].status = payload.status;
    } else {
      attendees.push({
        uri: userUri,
        status: payload.status,
      });
    }
    events[currentOverridingEventIndex] = {
      ...currentOverridingEvent,
      attendee: attendees,
    };
    newEvent = { ...events[currentOverridingEventIndex] };
  } else {
    attendees = masterEvent.attendee || [];
    const userUri = `mailto:${profileEmail}`;

    const foundInAttendee = attendees.findIndex(
      (at) => at.uri.toLowerCase() === userUri,
    );
    if (foundInAttendee > -1) {
      attendees[foundInAttendee].status = payload.status;
    } else {
      attendees.push({
        uri: userUri,
        status: payload.status,
      });
    }
    events[masterEventIndex] = {
      ...masterEvent,
      attendee: [...attendees],
    };
    newEvent = { ...events[masterEventIndex] };
  }

  let recurIdTime = '';

  if (sourceRecurenceId?.value) {
    recurIdTime = transformCalDavDate(sourceRecurenceId.value);
  } else if (masterEvent.start.type === 'DATE') {
    recurIdTime = transformCalDavDate(workingEvent.source.start.value);
  } else {
    if (!masterEvent.start.timeZone && !masterEvent.start.value.includes('Z')) {
      // проверка плавающей даты
      recurIdTime = transformCalDavDate(workingEvent.source.start.value);
    } else if (masterEvent.start.timeZone) {
      recurIdTime = moment(workingEvent.start)
        .tz(masterEvent.start.timeZone)
        .format()
        .slice(0, -6);
    } else if (masterEvent.start.value.includes('Z')) {
      recurIdTime = moment(workingEvent.start).utc().format().slice(0, -1);
    }
  }

  const timeZone = isTypeDate
    ? undefined
    : masterEvent.start.timeZone || undefined;

  newEvent.recurrenceId = {
    value: sourceRecurenceId?.value
      ? transformDateAndTime(
          moment(transformCalDavDate(sourceRecurenceId?.value)).format(
            'DD.MM.YYYY',
          ),
          !isTypeDate
            ? moment(transformCalDavDate(sourceRecurenceId?.value)).format(
                'HH:mm',
              )
            : undefined,
          !timeZone,
        )
      : transformDateAndTime(
          moment(recurIdTime).format('DD.MM.YYYY'),
          !isTypeDate ? moment(recurIdTime).format('HH:mm') : undefined,
          !timeZone,
        ),
    type: isTypeDate ? 'DATE' : workingEvent.source.start.type,
    timeZone,
  };

  const fieldEndTime = moment(workingEvent.end);
  if (isTypeDate) fieldEndTime.subtract(1, 'd');

  const fieldStartTime = moment(workingEvent.start);

  const dateStart = moment(fieldStartTime).format('DD.MM.YYYY');
  const timeStart = isTypeDate ? null : moment(fieldStartTime).format('HH:mm');

  const dateEnd = moment(fieldEndTime).format('DD.MM.YYYY');
  const timeEnd = isTypeDate ? null : moment(fieldEndTime).format('HH:mm');

  const endDate = moment(dateEnd, 'DD.MM.YYYY');
  if (isTypeDate) endDate.add(1, 'd');

  newEvent.start = {
    value: transformDateAndTime(dateStart, timeStart, !timeZone),
    type: isTypeDate ? 'DATE' : 'DATE-TIME',
    timeZone,
  };

  const isSameTime =
    !isTypeDate && dateStart === dateEnd && timeStart === timeEnd;

  newEvent.end = isSameTime
    ? undefined
    : {
        value: transformDateAndTime(
          endDate.format('DD.MM.YYYY'),
          timeEnd,
          !timeZone,
        ),
        type: isTypeDate ? 'DATE' : 'DATE-TIME',
        timeZone,
      };

  try {
    if (payload.recurrenceType === 'regular') {
      yield call(api('eventUpdate'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: masterEvent.id,
        },
        headers: {},
        body: {
          events: [...events],
        },
      });
    } else if (payload.recurrenceType == 'all') {
      yield call(api('eventUpdate'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: masterEvent.id,
        },
        headers: {},
        body: {
          events: [events[masterEventIndex]],
        },
      });
    } else if (payload.recurrenceType === 'single') {
      delete newEvent['recurrence'];

      yield call(api('eventOverride'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: masterEvent.id,
        },
        body: {
          event: newEvent as Event,
          subsequent: false,
        },
      });
    }

    if (from === 'eventView') {
      yield put(
        setEventView.action({
          event: {
            ...workingEvent,
            attendee: [...attendees],
            source: {
              ...workingEvent.source,
              attendee: [...attendees],
            },
          },
        }),
      );
      yield put(forceLoadEvents.action(true));
      yield put(deleteNewEventNotifications.action(workingEvent.id));
    } else {
      yield put(forceLoadEvents.action(true));
      yield put(deleteNewEventNotifications.action(customEvent.id));
    }
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
  } catch (e) {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Не удалось отреагировать на событие',
        type: 'error',
      }),
    );
  }
}

export default proccessEventReaction;
