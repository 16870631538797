import { useSelector } from 'react-redux';
import Uploads from 'src/_components/Uploads';
import { CStoreState } from '../../../_redux/types';
import eventCreateEditActions from '../../store/actions';

export interface Upload {
  key?: string;
  downloadUrl?: string;
  id: number;
  name: string;
  size: number;
  loadedPercent: number;
  loadedAt: number;
  type: string;
  file?: File;
}

const uploadsToArray = (obj) => Object.keys(obj).map((key) => obj[key]);

export const Attachments = () => {
  const { uploads } = useSelector((state: CStoreState) => ({
    uploads: state.eventCreateEdit.fieldsData.attachi,
  }));

  if (!uploads || !Object.keys(uploads).length) {
    return null;
  }

  return (
    <Uploads
      removeFileHandler={(id) => eventCreateEditActions().deleteAttachi(id)}
      uploads={uploadsToArray(uploads)}
    />
  );
};
