/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { PUBLIC_SENTRY_DSN, CORP_SENTRY_DSN, IS_PUBLIC } from 'src/_constants';

class SentryModule {
  public init() {
    Sentry.init({
      beforeSend(event, hint) {
        /*
          filter out errors here based on your needs
        */
        return event;
      },
      dsn: IS_PUBLIC ? PUBLIC_SENTRY_DSN : CORP_SENTRY_DSN,
      sampleRate: 1,
      release: 'latest',
      environment: process.env.NODE_ENV,
      whitelistUrls: IS_PUBLIC ? [/https?:\/\/calendar\.rambler\.ru/] : [/https?:\/\/calendar\.rambler\.biz/],
      integrations: [
        new Integrations.BrowserTracing({ traceFetch: false, traceXHR: false }),
      ],
      ignoreErrors: [
        'Unexpected token <',
        'Не удается выполнить программу',
        'execute code from a freed script',
        'Разрешение отклонено',
        'Permission denied',
        'InvalidAccessError',
        'safeframe-bundles',
        'NS_ERROR_FILE_CORRUPTED',
        'NS_ERROR_DOM_QUOTA_REACHED',
        'Failed to read',
        'kaspersky',
        'KasperskyLab',
        // eslint-disable-next-line max-len
        /SyntaxError JSON syntax error [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/main in ThrowError/,
        // eslint-disable-next-line max-len
        /Cannot read property 'getAttribute' of null [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/main/,
        'getWotReputation',
        'w-display',
        'launcher.4.multi',
        'reachGoal',
        'm_miner',
        'k2o',
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
      ],
    });
  }
}

export default new SentryModule();
