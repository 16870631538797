import DOMPurify from 'dompurify';

export const wrapLinksInAnchorTags = (text: string) => {
  if (!text) {
    return '';
  }

  const urlRegex = /((https?):\/\/[^\s/$.?#].[^\s]*)/gi;

  // Replace URLs with anchor tags
  return DOMPurify.sanitize(
    text.replace(
      urlRegex,
      '<a target="_blank" id="me" rel="noopener noreferrer nofollow" href="$1" target="_blank">$1</a>',
    ),
    { ADD_ATTR: ['target'] },
  );
};
