import { FC, MutableRefObject, useMemo, useRef, useState } from 'react';
import { SelectedEvent } from 'src/eventsDisplay/store/types';

import './styles.less';
import DayWeekHeaderEvent from '../DayWeekHeaderEvent';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import moment from 'moment';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { TABLET_MAX_WIDTH } from '../../../_constants';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';

interface DayWeekHeaderEventsProps {
  dates: Date[];
  blockRef: MutableRefObject<HTMLDivElement>;
  events: SelectedEvent[];
  type: 'day' | 'week';
}

const DayWeekHeaderEvents: FC<DayWeekHeaderEventsProps> = ({
  dates,
  blockRef,
  events,
  type,
}) => {
  const [foldedRows, setFoldedRows] = useState(true);
  const { width } = useWindowSize();
  const elRefs = useRef<HTMLDivElement[]>([]);
  const mergedEvents = useMemo(() => {
    let maxRow = 1;
    const res = {};

    events.forEach((ev) => {
      const column =
        dates.findIndex((d) => d.getDate() === ev.date.getDate()) + 1;
      res[ev.id] = {
        event: ev,
        startDate: res[ev.id]
          ? res[ev.id].startDate > ev.date.getDate()
            ? ev.date.getDate()
            : res[ev.id].startDate
          : ev.date.getDate(),
        startColumn: res[ev.id]
          ? res[ev.id].startColumn > column
            ? column
            : res[ev.id].startColumn
          : column,
        width: res[ev.id] ? res[ev.id].width + 1 : 1,
      };
    });

    const entries = Object.entries(res) as Record<string, any>;
    const entriesSorted = entries.sort((a, b) => b[1].width - a[1].width);

    // Формат 'col:row'
    let bookedPositions: string[] = [];
    const entriesWithRows = entriesSorted.map((ev, i) => {
      let row = i + 1;
      let tmpPositions = [];

      for (let k = row; k > 0; k--) {
        const positions = Array(ev[1].width)
          .fill('.')
          .map((emm, j) => `${ev[1].startColumn + j}:${k}`);
        if (!positions.some((v) => bookedPositions.includes(v))) {
          row = k;
          tmpPositions = positions;
        } else break;
      }

      bookedPositions = [...bookedPositions, ...tmpPositions];

      // const row

      if (maxRow < row) {
        maxRow = row;
      }

      return [
        ev[0],
        {
          ...ev[1],
          row,
        },
      ];
    });

    return {
      events: entriesWithRows,
      maxRow,
    };
  }, [events]);

  const hiddenRows = useMemo(() => {
    const data = Array(dates.length).fill(0);

    mergedEvents.events.forEach((d) => {
      if (d[1].row <= 2) return;

      const columns = Array(d[1].width)
        .fill('.')
        .map((b, i) => d[1].startColumn + i);
      columns.forEach((c) => {
        data[c - 1] += 1;
      });
    });

    return data;
  }, [mergedEvents]);

  const emptyPositions = useMemo(() => {
    const events = mergedEvents.events.filter((e) =>
      foldedRows ? e[1].row <= 2 : true,
    );

    const rows = foldedRows
      ? mergedEvents.maxRow >= 2
        ? 2
        : 1
      : mergedEvents.maxRow;

    const res: number[][] = [];

    for (let i = 1; i < rows + 1; i++) {
      for (let k = 1; k < dates.length + 1; k++) {
        res.push([k, i]);
      }
    }

    events.forEach((ev) => {
      const positions = Array(ev[1].width)
        .fill('.')
        .map((b, i) => [ev[1].startColumn + i, ev[1].row]);

      positions.forEach((pos) => {
        const index = res.findIndex((v) => v[0] === pos[0] && v[1] === pos[1]);
        if (index === -1) return;
        res.splice(index, 1);
      });
    });

    return res;
  }, [mergedEvents, foldedRows]);

  return (
    <div
      ref={blockRef}
      className='day-week-header-events'
      style={{
        gridTemplateRows: `repeat(${
          foldedRows ? (mergedEvents.maxRow >= 2 ? 2 : 1) : mergedEvents.maxRow
        }, 30px) ${mergedEvents.maxRow > 2 ? '24px' : ''}`,
        gridTemplateColumns: `repeat(${dates.length}, minmax(50px, 1fr))`,
      }}
    >
      {mergedEvents.events
        .filter((e) => (foldedRows ? e[1].row <= 2 : true))
        .map((ev) => (
          <div
            key={`header-event-${ev[0]}`}
            style={{
              gridColumn: `${ev[1].startColumn} / span ${ev[1].width}`,
              gridRow: ev[1].row,
              padding: '0 5px 0 5px',
            }}
          >
            <DayWeekHeaderEvent type={type} event={ev[1].event} />
          </div>
        ))}
      {mergedEvents.maxRow > 2
        ? hiddenRows.map(
            (h, i) =>
              !!h && (
                <div
                  key={`header-weekday-fold-${i}`}
                  className='day-week-header-events-fold'
                  style={{
                    gridColumn: `${i + 1} / span 1`,
                    gridRow: foldedRows ? 3 : mergedEvents.maxRow + 1,
                    padding: '0 5px 0 5px',
                  }}
                  onClick={() => setFoldedRows(!foldedRows)}
                >
                  {foldedRows ? `Показать ещё ${h}` : 'Свернуть'}
                </div>
              ),
          )
        : ''}
      {emptyPositions.map((pos, i) => (
        <div
          key={`header-weekday-empty-${i}`}
          ref={(el) => (elRefs.current[i] = el)}
          style={{
            gridColumn: `${pos[0]} / span 1`,
            gridRow: pos[1],
            padding: '0 5px 0 5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            floatingModalActions().openFloatingModal({
              opened: FLOATING_MODAL_IDS['eventCreate'],
              target: elRefs.current[i],
              center: width <= TABLET_MAX_WIDTH,
            });
            eventCreateEditActions().openEventCreateWindow({
              dateStart: moment(dates[pos[0] - 1]).format('DD.MM.YYYY'),
              dateEnd: moment(dates[pos[0] - 1]).format('DD.MM.YYYY'),
              wholeDay: true,
              transparency: 'OPAQUE',
            });
          }}
        />
      ))}
    </div>
  );
};

export default DayWeekHeaderEvents;
