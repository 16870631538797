import { Upload } from '../_api/types/entities';

export const attachToUploads = (
  attachi: Array<{
    url: string;
    type?: string;
    format?: string;
    size?: number;
    name?: string;
  }>,
  attachInfo: Array<{ name?: string; url: string; size?: number }>
): { [key: number]: Upload } => {
  const uploads: { [key: number]: Upload } = {};
  const findInfo = (url: string) => attachInfo.find((ai) => ai.url === url);
  attachi.forEach((a, i) => {
    const filetype = a.url
      ? a.url.split('.')[a.url.split('.').length - 1]
      : a.format;
    const id = Math.random();
    uploads[id] = {
      id,
      name:
        a.name ||
        findInfo(a.url)?.name ||
        decodeURI(a.url.split('/')[a.url.split('/').length - 1]),
      size: a.size || findInfo(a.url)?.size || 0,
      downloadUrl: a.url,
      loadedPercent: 100,
      loadedAt: Date.now(),
      type: a.name ? a.format : filetype, //filetype.split('/')[filetype.split('/').length - 1].toUpperCase(),
      //'pdf', //filetype, //'pdf', //path.extname(name).replace('.', ''),
    };
  });

  return uploads;
};
