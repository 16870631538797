import { createReducer } from 'src/_utils/redux';
import { AddressbookState } from './types';
import { setAddresbookContactsApi } from './actions/setAddressbookContacts';
import { setSelectedContact } from './actions/setSelectedContact';
import { unsetSelectedContact } from './actions/unsetSelectedContact';
import { setMailingContacts } from './actions/setMailingContacts';

// *modulism-group store-reducer
const initialState: AddressbookState = {
  contacts: [],
  status: 'init',
  cache: {},
};

export default createReducer(initialState, [
  setAddresbookContactsApi,
  setSelectedContact,
  unsetSelectedContact,
  setMailingContacts,
]);
