// *modulism-group validateEmail
import { EventAttendee } from '../_api/types/entities';

export const getUniqueAttendeeList = (
  attendees: EventAttendee[],
): EventAttendee[] => {
  const res = [];

  const hash = {};

  attendees.forEach((attende) => {
    if (hash[attende.uri]) return;

    hash[attende.uri] = true;
    res.push(attende);
  });

  return res;
};

export const getEmailFromAttendee = (attendee: EventAttendee) =>
  attendee?.uri?.replace('mailto:', '') || '';
