import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactNode,
  RefObject,
} from 'react';
import cn from 'classnames';
import { WEEKS_TITLES_SHORT } from 'src/_constants';
import controlsActions from 'src/controls/store/actions';

interface MonthItemProps {
  fullDate: Date;
  date: number;
  isActive: boolean;
  isNotCurMonth: boolean;
  isPast: boolean;
  withHeader: boolean;
  weekDay: number;
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  children?: ReactNode;
  refs: RefObject<HTMLDivElement[]>;
  index: number;
  onClickCreate: () => void;
}

const MonthItem: FC<MonthItemProps> = ({
  fullDate,
  date,
  isActive,
  isNotCurMonth,
  withHeader,
  weekDay,
  isPast,
  position,
  children,
  refs,
  index,
  onClickCreate,
}) => (
  <div
    className={cn('month-item', {
      'not-cur-month': isNotCurMonth,
      [position]: !!position,
      active: isActive,
    })}
    ref={(el) => (refs.current[index] = el)}
  >
    <div className='month-item-header'>
      <p onClick={() => controlsActions().openDayControls(fullDate)}>
        <span>{withHeader ? `${WEEKS_TITLES_SHORT[weekDay - 1]}, ` : ''}</span>
        <span>{date}</span>
      </p>
      <div onClick={onClickCreate} />
    </div>
    {Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          isDatePast: isPast,
        } as Partial<unknown>);
      }

      return child;
    })}
  </div>
);

export default MonthItem;
