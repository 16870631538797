import { createAction } from 'src/_utils/redux';
import { DELETE_SESSION_SET_LOADING_CONTROLS } from '../actionTypes';
import { ControlsState, DeleteSessionSetLoadingControlsAction } from '../types';

export const deleteSessionSetLoadingControls = createAction<
  DeleteSessionSetLoadingControlsAction,
  ControlsState
>(DELETE_SESSION_SET_LOADING_CONTROLS, (state, p) => ({
  ...state,
  deleteSessionLoading: p,
}));
