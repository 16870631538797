// *modulism-group redux
interface Action {
  type: unknown;
  payload?: unknown;
}

interface CreatedAction {
  type: unknown;
  action: unknown;
  reducer: Function;
}

export const createAction = <ACTION extends Action, STATE>(
  type: ACTION['type'],
  reducer?: (state: STATE, p?: ACTION['payload']) => STATE,
): {
  type: ACTION['type'];
  action: (p?: ACTION['payload']) => ACTION;
  reducer: typeof reducer;
} => ({
  type,
  action: (p: ACTION['payload']) =>
    ({
      type,
      payload: p,
    }) as ACTION,
  reducer,
});

export const createReducer =
  <S, ACS extends CreatedAction[]>(initialState: S, actions: ACS) =>
  <A extends Action>(st: S = initialState, action: A) => {
    const neededAction = actions.find((ac) => ac.type === action.type);
    if (neededAction) {
      return neededAction.reducer(st, action.payload);
    }

    return st;
  };
