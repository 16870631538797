import { baseHeaders } from '../constants';

class UploadRequester {
  uploadUrl: string = '';

  constructor(uploadUrl?: string) {
    this.uploadUrl = uploadUrl;
  }
  uploadRequest({
    data,
    onProgress,
  }: {
    onProgress?: () => void;
    data: any;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.onreadystatechange = this.uploadReadyStateChangeHandler(
        request,
        resolve,
        reject
      );
      if (onProgress)
        request.upload.addEventListener('progress', onProgress, false);

      request.open('POST', this.uploadUrl);
      request.setRequestHeader('X-Rambler-Mail-Client-Type', baseHeaders['X-Rambler-Mail-Client-Type']);
      request.setRequestHeader('X-Rambler-Mail-Client-Version', baseHeaders['X-Rambler-Mail-Client-Version']);
      request.send(data);
    });
  }

  private uploadReadyStateChangeHandler =
    (
      request: XMLHttpRequest,
      resolve: (v: any) => void,
      reject: (v: any) => void
    ): any =>
    () => {
      if (request.readyState === 4) {
        // Successfull requests
        if (request.status !== 200) {
          //   try {
          //     //processErrorResponse(request.statusText);
          //   } catch (error) {
          reject(request.status);
          //}
          // Other status codes
        } else {
          const response = JSON.parse(request.response);
          // try {
          //   response = JSON.parse(request.response);
          // } catch (error) {
          //   reject(error);
          // }
          if (response) {
            resolve(response);
          }
        }
      }
    };
}

export default UploadRequester;
