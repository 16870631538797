import { FC, useRef, useState } from 'react';
import Checkbox from '@rambler-components/checkbox';
import { ArrowDownSmallIcon, ArrowUpSmallIcon, CloseSmallIcon } from '../Svg';

import css from './MultiSelect.module.less';
import '@rambler-components/checkbox/styles.css';
import { useClickOutside } from 'src/_utils/hooks/useClickOutside';
import classNames from 'classnames';

interface MultiSelectProps {
  placeholder: string;
  options: {
    value: string;
    dropdownTitle: string;
    selectTitle: string;
  }[];
  values: string[];
  onChange: (v: string[]) => void;
  onClick?: () => void;
  maxValuesToShow?: number;
  isError?: boolean;
}

const MultiSelect: FC<MultiSelectProps> = ({
  placeholder,
  options,
  values,
  onChange,
  onClick,
  maxValuesToShow = 3,
  isError,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useClickOutside<HTMLDivElement>(
    () => dropdownOpened && setDropdownOpened(false),
    [selectRef],
  );

  const handleChange = (value: string) => {
    const index = values.findIndex((f) => f === value);
    const tmp = [...values];
    if (index > -1) tmp.splice(index, 1);
    else tmp.push(value);
    onChange(tmp);
  };

  const handleClick = () => {
    setDropdownOpened(!dropdownOpened);
    onClick && onClick();
  };

  return (
    <div style={{ position: 'relative' }} className={css.multiselectWrapper}>
      <div
        ref={selectRef}
        className={classNames(css.multiselect, {
          error: isError,
        })}
        onClick={handleClick}
      >
        {values.length ? (
          <div className={css.multiselectSelectedValuesBlock}>
            {values.slice(0, maxValuesToShow).map((val) => (
              <div
                className={css.multiselectSelectedValue}
                key={`multiselect-selected-val-${val}`}
              >
                {options.find((opt) => opt.value === val).selectTitle}
                <div
                  onClick={(e) => {
                    handleChange(val);
                    e.stopPropagation();
                  }}
                  className={css.closeIcon}
                >
                  <CloseSmallIcon />
                </div>
              </div>
            ))}
            {values.length > maxValuesToShow ? (
              <div
                className={css.multiselectSelectedValue}
                style={{ padding: '5px 10px' }}
              >
                +{values.length - maxValuesToShow}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <span className={css.multiselectPlaceholder}>{placeholder}</span>
        )}
        <div className={css.multiselectArrow}>
          {dropdownOpened ? <ArrowUpSmallIcon /> : <ArrowDownSmallIcon />}
        </div>
      </div>
      <div
        ref={dropdownRef}
        className={classNames(css.multiselectDropdown, {
          [css.opened]: dropdownOpened,
        })}
      >
        {options.map((opt) => {
          const selected = values.includes(opt.value);

          return (
            <div
              className={classNames({
                [css.selected]: selected,
              })}
              key={`multiselect-${opt.value}`}
            >
              <Checkbox
                className={css.checkbox}
                type={'border'}
                checked={selected}
                onChange={() => handleChange(opt.value)}
              >
                {opt.dropdownTitle}
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelect;
