import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import './styles.less';
import moment from 'moment';
import ComparingAttendee from './attendee';
import selectProfileEmail from 'src/_apiCommonData/store/selectors/selectProfileEmail';
import ComparingTimeline from './timeline';
import { AttendeesField } from '../Fields/AttendeesField';
import Button from '@rambler-components/button';
import { LoaderIcon } from 'src/_components/Svg';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import { EventCreateFieldsType, SetValueType } from '../../const';
import { EventAttendee } from '../../../_api/types/entities';

moment().locale('ru');

interface EventCreateComparingProps {
  values: EventCreateFieldsType;
  setValue: SetValueType;
}

const EventCreateComparing: FC<EventCreateComparingProps> = ({
  values,
  setValue,
}) => {
  const { dateStart1, dateEnd1, error } = useSelector((state: CStoreState) => ({
    dateStart1: state.eventCreateEdit.fieldsData.dateStart,
    dateEnd1: state.eventCreateEdit.fieldsData.dateEnd,
    error: state.eventCreateEdit.comparingCalendarsData.status === 'FAILED',
  }));
  const [dateStart, setDateStart] = useState(dateStart1);
  const [dateEnd, setDateEnd] = useState(dateEnd1);
  useEffect(() => {
    const st = moment(dateStart1, 'DD.MM.YYYY');
    const en = moment(dateEnd1, 'DD.MM.YYYY');

    if (st.format() !== 'Invalid date' && en.format() !== 'Invalid date') {
      setDateStart(dateStart1);
      setDateEnd(dateEnd1);
    }
  }, [dateStart1, dateEnd1]);

  const { attendees } = values;

  const profileEmail = useSelector(selectProfileEmail);
  const emails = useMemo(() => {
    const res = [profileEmail];

    for (let i = 0; i < attendees.length; i++) {
      const email = attendees[i].uri.replace('mailto:', '');
      if (email !== profileEmail) {
        res.push(email);
      }
    }

    return res;
  }, [attendees]);
  const [hoveredAttendee, setHoveredAttendee] = useState(-1);
  const [selectedView, setSelectedView] = useState([dateStart, dateEnd]);

  const timeString = useMemo(() => {
    if (selectedView[0] !== selectedView[1]) {
      const st = moment(selectedView[0], 'DD.MM.YYYY');
      const en = moment(selectedView[1], 'DD.MM.YYYY');

      if (st.get('month') !== en.get('month'))
        return `${st.format('ll').slice(0, -8)}-${en.format('ll')}`;

      return `${st.format('D')}-${en.format('ll')}`;
    }

    return moment(selectedView[0], 'DD.MM.YYYY').format('LL');
  }, [selectedView]);

  const onAttendeesChange = useCallback(
    (attendees: EventAttendee[]) => setValue('attendees', attendees),
    [],
  );

  return (
    <div className='event-create-edit-block-comparing'>
      <p className='comparing-title'>Расписание участников на {timeString}</p>

      <div className='comparing-block-wrapper custom-scroll-bar'>
        <div className='comparing-block'>
          <div className='comparing-attendees'>
            <div className='comparing-attendees-header'>
              <p>
                Участники<span>{emails.length}</span>
              </p>
            </div>
            {emails.map((b, i) => (
              <ComparingAttendee
                isYou={profileEmail === b}
                email={b}
                key={`${b[0]}-${i}`}
                isHovered={hoveredAttendee === i}
                onHover={() => setHoveredAttendee(i)}
                onBlur={() => setHoveredAttendee(-1)}
                attendees={attendees}
                onAttendeesChange={onAttendeesChange}
              />
            ))}
            <div className='comparing-attendee-add'>
              <AttendeesField
                attendees={attendees}
                onChange={onAttendeesChange}
                noSelected
                staticPlaceholder='Добавить участника'
              />
            </div>
          </div>
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              userSelect: 'none',
            }}
          >
            {error && (
              <div className='redo-comparing-button-wrapper'>
                <Button
                  className='redo-comparing-button'
                  onClick={() => {
                    eventCreateEditActions().calendarsComparing({
                      status: 'REDO',
                      attendees,
                    });
                  }}
                >
                  <LoaderIcon />
                </Button>
              </div>
            )}
            <ComparingTimeline
              emails={emails}
              hoveredAttendee={hoveredAttendee}
              setSelectedView={setSelectedView}
              setHoveredAttendee={(i) => setHoveredAttendee(i)}
              values={values}
              setValue={setValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCreateComparing;
