import { CSSProperties, FC, RefObject } from 'react';
import Dropdown from 'src/_components/Dropdown';
import DropdownItem from 'src/_components/DropdownItem';

interface DropdownConfirmProps {
  buttonRef: RefObject<any>;
  close: () => void;
  actions: (() => void)[];
  bottom?: CSSProperties['bottom'];
  right?: CSSProperties['right'];
  left?: CSSProperties['left'];
}

const DropdownConfirm: FC<DropdownConfirmProps> = ({
  buttonRef,
  close,
  actions,
  bottom = '45px',
  right,
  left,
}) => (
  <Dropdown
    style={{
      right,
      left,
      bottom,
      width: '242px',
      padding: 0,
      overflow: 'hidden',
    }}
    ignoreButton={buttonRef}
    close={close}
  >
    <DropdownItem action={actions[0]} title='Только это событие' />
    {actions.length === 3 && (
      <DropdownItem action={actions[1]} title='Это и все последующие' />
    )}
    <DropdownItem action={actions.at(-1)} title='Все повторяющиеся события' />
  </Dropdown>
);

export default DropdownConfirm;
