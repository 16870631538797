import { FC } from 'react';
import NotificationBlock from 'src/_components/NotificationBlock';

import './styles.less';

interface DeleteConfirmProps {
  type: 'all' | 'single' | 'this-and-future' | 'regular';
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteConfirm: FC<DeleteConfirmProps> = ({
  type,
  onDelete,
  onCancel,
}) => (
  <NotificationBlock
    title={
      type === 'all' || type === 'this-and-future'
        ? 'Вы уверены, что хотите удалить всю цепочку событий?'
        : 'Вы уверены, что хотите удалить событие?'
    }
    options={[
      {
        title: 'Да, удалить',
        action: onDelete,
      },
      {
        title: 'Не удалять',
        action: onCancel,
      },
    ]}
    positions={{
      right: 0,
      top: -125,
      width: 340,
    }}
  />
);

export default DeleteConfirm;
