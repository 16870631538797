import { useEffect, useMemo, useState } from 'react';
import Button from '@rambler-components/button';
import Calendar from '@rambler-components/calendar';
import { CalendarTooltip } from '../../../_components/CalendarTooltip';
import { useSelector } from 'react-redux';
import {
  InfoFillIcon,
  PlusIcon,
  TodayIcon,
  VectorLeftIcon,
  VectorRightIcon,
} from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import controlsActions from '../../store/actions';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import NewEventNotifications from '../../../newEventNotifications/components';
import { getDateWeekRange } from 'src/_utils/date';
import Footer from '../../../_components/Footer';

import '@rambler-components/tooltip/styles.css';
import '@rambler-components/button/styles.css';
import '@rambler-components/calendar/styles.css';
import './styles.less';
import { IS_CORP, TABLET_MAX_WIDTH } from '../../../_constants';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';

const Sidebar = () => {
  const [hardCalendarRange, setHardCalendarRange] = useState<
    [Date, Date] | Date | null
  >(null);
  const { width } = useWindowSize();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [isSmallDisplay, setIsSmallDisplay] = useState(
    window.innerWidth < 1366,
  );
  const { selectedDateRange, mode, selectedMonth } = useSelector(
    (s: CStoreState) => s.controls,
  );

  const notificationsExists = useSelector(
    (state: CStoreState) => !!state.newEventNotifications?.events?.length,
  );

  useEffect(() => {
    const onresize = () => {
      if (window.innerWidth < 1366 && !isSmallDisplay) {
        setSidebarCollapsed(true);
        setIsSmallDisplay(true);
      }
      if (window.innerWidth >= 1366 && isSmallDisplay) {
        setIsSmallDisplay(false);
      }
    };

    onresize();

    window.addEventListener('resize', onresize);

    return () => {
      window.removeEventListener('resize', onresize);
    };
  }, [isSmallDisplay]);

  const selectDay = (d: Date | Date[]) => {
    setHardCalendarRange(null);
    controlsActions().selectControlsDay(Array.isArray(d) ? d[0] : d);
  };

  const returnToToday = () => {
    // КОСТЫЛЬ
    // для того чтобы можно было вернуться к текущему месяцу, если ты не выбирал дату в календаре
    const date = new Date();
    setHardCalendarRange([date, date]);
    setTimeout(() => {
      controlsActions().selectControlsTodayDay();
      setHardCalendarRange(null);
    }, 0);
  };

  const calendarDate = useMemo(() => {
    if (hardCalendarRange) {
      return hardCalendarRange;
    }
    if (mode === 'week' && selectedDateRange[0]) {
      return getDateWeekRange(selectedDateRange[0]);
    }
  }, [hardCalendarRange, mode, selectedDateRange]);

  const initDate = useMemo(() => {
    if (hardCalendarRange) {
      return selectedMonth;
    }
    if (selectedDateRange) {
      return selectedDateRange[0];
    }

    return selectedMonth;
  }, [hardCalendarRange, selectedDateRange, selectedMonth]);

  return (
    <>
      {isSmallDisplay && (
        <div
          style={{
            width: '70px',
            background: '#eff5ff',
          }}
        />
      )}
      <div
        className={`sidebar ${
          sidebarCollapsed ? '' : 'full-width'
        } custom-scroll-bar`}
        style={{
          height: IS_CORP && '100vh',
        }}
      >
        <div>
          <Button
            className='create-event-button sidebar-icon-button'
            onClick={() => {
              eventCreateEditActions().openEventCreateWindow({});
              floatingModalActions().openFloatingModal({
                opened: FLOATING_MODAL_IDS['eventCreate'],
                target: null,
                center: width <= TABLET_MAX_WIDTH,
              });
            }}
            style={{
              width: '100%',
            }}
          >
            {!isSmallDisplay || (isSmallDisplay && !sidebarCollapsed) ? (
              'Создать событие'
            ) : (
              <PlusIcon />
            )}
          </Button>

          {!isSmallDisplay || (isSmallDisplay && !sidebarCollapsed) ? (
            <>
              <div
                className='sidebar-divider'
                style={{
                  width: '220px',
                }}
              />

              <Calendar
                className='calendar-2'
                initDate={initDate}
                today={new Date()}
                value={calendarDate}
                isRange={mode === 'week'}
                isSelectable
                isSmallWidth
                onChange={selectDay}
              />
              <Button
                type='secondary'
                onClick={returnToToday}
                style={{
                  padding: 10,
                  width: '100%',
                }}
              >
                К сегодняшнему дню
              </Button>
              <NewEventNotifications />
            </>
          ) : (
            <>
              <div className='sidebar-divider' />

              <CalendarTooltip label={'К сегодняшнему дню'} position='right'>
                <div>
                  <Button
                    type='secondary'
                    className='sidebar-icon-button'
                    onClick={returnToToday}
                    style={{
                      marginTop: '10px',
                      padding: 10,
                      width: '100%',
                    }}
                  >
                    <TodayIcon />
                  </Button>
                </div>
              </CalendarTooltip>
              {notificationsExists && (
                <div>
                  <Button
                    type='secondary'
                    className='openNotification'
                    onClick={() => setSidebarCollapsed(false)}
                    style={{
                      marginTop: '20px',
                      padding: 10,
                      width: '100%',
                    }}
                  >
                    <InfoFillIcon />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {(!isSmallDisplay || (isSmallDisplay && !sidebarCollapsed)) && (
          <div style={{ marginTop: 'auto' }}>
            <Footer />
          </div>
        )}
        {isSmallDisplay && (
          <Button
            className='sidebar-collapse-button'
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          >
            {!sidebarCollapsed ? <VectorLeftIcon /> : <VectorRightIcon />}
          </Button>
        )}
      </div>
    </>
  );
};

export default Sidebar;
