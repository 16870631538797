import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Checkbox from '@rambler-components/checkbox';
import DateInput from 'src/_components/DateInput';
import { PlusIcon } from 'src/_components/Svg';
import { EVENT_CREATE_EDIT_ERRORS, months } from '../../../constants';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import {
  parseRruleData,
  generateDateRule,
  ParsedDate,
  getRruleHumanString,
} from 'src/_utils/recurrence';
import NumberInput from 'src/_components/NumberInput';

interface DateTypeProps {
  start: string;
  opened: boolean;
  recurrence: string[] | null;
  setRecurrence: (recurrence: null | string[]) => void;
  isEdit: boolean;
  onlyDisplay: boolean;
  wholeDay: boolean;
}

const DateType: FC<DateTypeProps> = ({
  start,
  opened,
  recurrence,
  setRecurrence,
  isEdit,
  onlyDisplay,
  wholeDay,
}) => {
  const [tillDate, setTillDate] = useState<string>('');
  const [date, setDate] = useState(
    Number(moment(start, 'DD.MM.YYYY').format('DD')),
  );
  const [ndate, setNDate] = useState(1);
  const [selectedMonths, setSelectedMonths] = useState<number[]>([]);

  const [selectingMonths, setSelectingMonths] = useState(false);

  useEffect(() => {
    if (!recurrence || !opened || !isEdit) return;
    const data = parseRruleData(recurrence) as ParsedDate;

    setTillDate(data.until || '');
    setDate(data.monthDay || Number(moment(start, 'DD.MM.YYYY').format('DD')));
    setNDate(data.ndate || 1);
    setSelectedMonths(data.selectedMonths || []);
    setSelectingMonths(data.selectingMonths || false);
  }, [opened]);

  useEffect(() => {
    if (Number(start.split('.')[0]) !== date) {
      eventCreateEditActions().addFieldsErrorEventCreateEdit({
        field: 'dateStart',
        errors: [EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence']],
      });
    } else {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'dateStart',
        error: EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence'],
      });
    }

    return () => {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'dateStart',
        error: EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence'],
      });
    };
  }, [start, date]);

  useEffect(() => {
    eventCreateEditActions().removeFieldsErrorEventCreateEdit({
      field: 'recurrence',
      error: EVENT_CREATE_EDIT_ERRORS['reccurencyWeekDays'],
    });

    const rule = generateDateRule({
      start,
      date,
      untilDate: tillDate,
      interval: selectedMonths.length ? 1 : ndate,
      selectedMonths,
      wholeDay,
    });

    setRecurrence(rule.toString().split('\n'));
  }, [tillDate, selectedMonths, ndate, date, wholeDay]);

  const handleChangeMonth = (i: number) => {
    const index = selectedMonths.findIndex((f) => f === i);
    const tmp = [...selectedMonths];
    if (index > -1) tmp.splice(index, 1);
    else tmp.push(i);

    setSelectedMonths(tmp);
  };

  return (
    <div
      className='event-create-reccurency-type-block'
      style={{
        marginTop: onlyDisplay ? '0px' : undefined,
      }}
    >
      {!onlyDisplay && (
        <>
          <div className='event-create-reccurency-type-head'>
            <NumberInput
              value={date}
              onChange={(v) => setDate(v)}
              min={1}
              max={31}
              style={{
                width: '60px',
              }}
            />
            <span>{selectingMonths ? 'числа до' : 'числа каждого'}</span>
            {!selectingMonths && (
              <>
                <NumberInput
                  value={ndate}
                  onChange={(v) => setNDate(v)}
                  min={1}
                  max={12}
                  style={{
                    width: '60px',
                  }}
                />
                <span>месяца до</span>
              </>
            )}
            <DateInput
              value={tillDate}
              onChange={setTillDate}
              minDate={moment(start, 'DD.MM.YYYY').toDate()}
              initOpenMonth={start}
              style={{
                width: '150px',
              }}
              closeOnScroll='.event-create-edit-anchor'
            />
          </div>
          {selectingMonths ? (
            <div className='event-create-reccurency-type-subselect'>
              <div className='event-create-reccurency-type-subselect-head'>
                <span>Повторять по месяцам</span>
                <span
                  onClick={() => {
                    setSelectingMonths(false);
                    setSelectedMonths([]);
                    setNDate(1);
                  }}
                >
                  Отменить
                </span>
              </div>
              <div className='event-create-reccurency-months-select'>
                {months.map((month, i) => (
                  <div key={`event-create-reccurency-months-select-month-${i}`}>
                    <Checkbox
                      checked={selectedMonths.includes(i + 1)}
                      onChange={() => handleChangeMonth(i + 1)}
                    >
                      {month}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              className='event-create-reccurency-type-select'
              onClick={() => setSelectingMonths(true)}
            >
              <PlusIcon />
              <span>Выбрать месяцы</span>
            </div>
          )}
        </>
      )}
      {!!date && (
        <p
          className='event-create-reccurency-block-help-text'
          style={{
            marginTop: onlyDisplay ? '0px' : undefined,
          }}
        >
          {getRruleHumanString({
            start,
            tense: onlyDisplay ? 'present' : 'future',
            data: {
              type: 'DATE',
              ndate,
              selectingMonths,
              selectedMonths,
              monthDay: date,
              until: tillDate,
            },
          })}
        </p>
      )}
    </div>
  );
};

export default DateType;
