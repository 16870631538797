import {
  FC,
  CSSProperties,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CStoreState } from '../../_redux/types';
import Input from '@rambler-components/input';

import '@rambler-components/input/styles.css';

interface TimeInputProps {
  value?: string;
  onChange?: (v: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  status?: 'error' | 'warning' | 'success';
  iref?: MutableRefObject<HTMLInputElement>;
  selectOnFull?: boolean;
}

const TimeInput: FC<TimeInputProps> = ({
  value: propsValue,
  onChange,
  onFocus,
  placeholder,
  style,
  disabled,
  status,
  iref,
  selectOnFull,
}) => {
  const { opened: isOpened } = useSelector((s: CStoreState) => ({
    opened: s.floatingModal.opened === 'eventCreate',
  }));

  const [maskValue, setMaskValue] = useState('');
  const [value, setValue] = useState(propsValue || '');
  const [prevValue, setPrevValue] = useState(propsValue || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        setValue(propsValue || '');
        setPrevValue(propsValue || '');
      });
    }
  }, [isOpened]);

  useEffect(() => {
    setValue(propsValue || '');
    setPrevValue(propsValue || '');
  }, [propsValue]);

  const setSelection = (pos: number) => {
    const inp = iref || inputRef;
    if (inp.current && inp.current === document.activeElement) {
      inp.current.selectionStart = pos;
      inp.current.selectionEnd = pos;
    }
  };

  const changeHandler = (v: string) => {
    if (!v) {
      setValue(v);
      setMaskValue('__:__');
      onChange(v);
    }

    const regExp = new RegExp(/^[\d_]{1,3}:[\d_]{1,3}$/g);
    const test = regExp.test(v);
    const inp = iref || inputRef;
    const selection = inp.current.selectionStart;
    if (test) {
      const arr = v.replace(':', '').split('').slice(0, 4);
      let removed = false;
      if (arr.length < 4) {
        removed = true;
        arr.push('_');
      }
      const newV = `${arr[0]}${arr[1]}:${arr[2]}${arr[3]}`;
      setMaskValue(newV);
      if (!newV.includes('_') && selectOnFull) {
        inp.current.blur();
        onChange && onChange(newV);
      }

      setTimeout(() =>
        setSelection(selection === 3 && !removed ? selection + 1 : selection),
      );

      return;
    }
    const arr = maskValue.split('');
    if (selection === 2) {
      const newArr = v.replace(':', '').split('').slice(0, 4);
      newArr[1] = '_';
      setMaskValue(`${newArr[0]}${newArr[1]}:${newArr[2]}${newArr[3]}`);

      setTimeout(() => setSelection(1));

      return;
    }
    const index = arr.reverse().findIndex((v) => Number(v));
    setTimeout(() => setSelection(index > -1 ? arr.length - index : 0));
  };

  const onBlurHandler = () => {
    setMaskValue('');
    const regExp = new RegExp(/^\d{2}:\d{2}$/g);
    if (maskValue === '__:__') {
      setPrevValue('');
      setValue('');
      onChange && onChange('');

      return;
    }
    if (regExp.test(maskValue)) {
      setPrevValue(maskValue);
      setValue(maskValue);
      onChange && onChange(maskValue);

      return;
    }

    setValue(prevValue);
  };

  const onFocusHandler = () => {
    onFocus();
    const val = value || '__:__';

    if (val !== '__:__') return;

    setMaskValue(val);
    setTimeout(() => setSelection(0));
  };

  return (
    <Input
      style={{
        width: '100%',
        ...style,
      }}
      disabled={disabled}
      placeholder={placeholder || 'Время'}
      value={maskValue || value}
      onChange={(e) => {
        e.preventDefault();
        changeHandler(e.target.value);
      }}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      status={status}
      forwardRef={iref || inputRef}
    />
  );
};

export default TimeInput;
