import { createSearchParams } from 'react-router-dom';
import { select, take, call, delay } from 'redux-saga/effects';
import { history } from 'src/_constants';
import {
  CONTROLS_INIT,
  SELECT_CONTROLS_DAY,
  SELECT_CONTROLS_MODE_MONTH,
  SELECT_CONTROLS_MODE_WEEK,
  SELECT_CONTROLS_MONTH,
  SELECT_CONTROLS_NEXT_DAY,
  SELECT_CONTROLS_NEXT_WEEK,
  SELECT_CONTROLS_PAGE,
  SELECT_CONTROLS_PREV_DAY,
  SELECT_CONTROLS_PREV_WEEK,
  SELECT_CONTROLS_TODAY_DAY,
} from '../store/actionTypes';
import controlsModeSelector from '../store/selectors/controlsModeSelector';
import controlsPageSelector from '../store/selectors/controlsPageSelector';
import controlsSelectedMonthSelector from '../store/selectors/controlsSelectedMonthSelector';

export function* syncMonthChangesWithUrlSaga(): Generator {
  while (true) {
    yield take([
      CONTROLS_INIT,
      SELECT_CONTROLS_DAY,
      SELECT_CONTROLS_MODE_MONTH,
      SELECT_CONTROLS_MODE_WEEK,
      SELECT_CONTROLS_MONTH,
      SELECT_CONTROLS_NEXT_DAY,
      SELECT_CONTROLS_NEXT_WEEK,
      SELECT_CONTROLS_PAGE,
      SELECT_CONTROLS_PREV_DAY,
      SELECT_CONTROLS_PREV_WEEK,
      SELECT_CONTROLS_TODAY_DAY,
    ]);
    const mode = yield select(controlsModeSelector);
    const page = yield select(controlsPageSelector);
    if (mode === 'month') {
      const selectedMonth = (yield select(
        controlsSelectedMonthSelector,
      )) as unknown as Date;

      const params = createSearchParams({
        month: selectedMonth.toISOString(),
      });
      const newString = `/${page}/${mode}?${params}`;

      // КОСТЫЛЬ
      // Почему-то не работает изменения истории без помещения в setTimeout
      yield delay(0);
      yield call(history.push, newString);
    }
  }
}
