// *modulism-group getEventUserPartStatus
import { Event } from 'src/_api/types/entities';
import { USER_PART_STATUS } from '../_constants';

export function getEventUserPartStatus(event: Event, userUri: string) {
  const attendee = (event.attendee || []).map((att) => ({
    ...att,
    uri: decodeURI(att.uri),
  }));
  const findUserInAttendee = attendee.find(
    (us) => us.uri.toLowerCase() === userUri
  );
  const isUserOriganizer = userUri === event.organizer?.uri.toLowerCase();
  const userPartStatus =
    !event.organizer && (!event.attendee || !event.attendee?.length)
      ? USER_PART_STATUS.organizer
      : isUserOriganizer
      ? USER_PART_STATUS.organizer
      : findUserInAttendee?.status || USER_PART_STATUS.needsAction;

  return userPartStatus;
}
