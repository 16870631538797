import { createSelector } from 'reselect';
import moment from 'moment';
import { CStoreState } from 'src/_redux/types';
import { getCalendarFullMonthDays, getDurationDays } from 'src/_utils/date';
import { transformCalDavDate } from 'src/_utils/caldav';
import { SelectedEvent } from '../types';
import { USER_PART_STATUS } from 'src/_constants';
import { getEventUserPartStatus } from 'src/_utils/getEventUserPartStatus';

const getTimeEnd = (start: Date, end: { value: string }) => {
  if (end?.value) {
    return new Date(transformCalDavDate(end.value));
  }

  return new Date(start.getTime() + 86400 * 1000);
};

function convertEvents(days: Date[], events: Record<string, SelectedEvent[]>) {
  const res = { ...events };
  for (let i = 0; i < days.length; i++) {
    const dateKey = moment(days[i]).format().slice(0, -6);
    if (!res[dateKey]) {
      res[dateKey] = [];
    }
    res[dateKey] = res[dateKey].sort((a, b) => {
      const first = a.type === 'wholeDay' ? 1 : 0;
      const second = b.type === 'wholeDay' ? 1 : 0;

      return second - first;
    });
  }

  return res;
}

const selectMonthEvents = createSelector(
  (state: CStoreState) => ({
    events: state.eventsDisplay.events,
    selectedMonth: state.controls.selectedMonth,
    profileEmail: state.apiCommonData.profile?.info?.email.toLowerCase(),
  }),
  ({ events, selectedMonth, profileEmail }) => {
    const days = getCalendarFullMonthDays(selectedMonth);
    const res: Record<string, SelectedEvent[]> = {};
    const userUri = `mailto:${profileEmail}`;

    for (let i = 0; i < events.length; i++) {
      const start = new Date(transformCalDavDate(events[i].start.value));
      start.setHours(0, 0, 0, 0);
      const end = events[i].end
        ? new Date(transformCalDavDate(events[i].end.value))
        : new Date(transformCalDavDate(events[i].start.value));
      end.setHours(0, 0, 0, 0);

      if (!events[i].end) {
        if (events[i].duration !== undefined && events[i].duration !== null) {
          end.setSeconds(end.getSeconds() + events[i].duration);
        } else if (events[i].start.type == 'DATE') {
          end.setDate(end.getDate() + 1);
        }
      }

      for (let k = 0; k < days.length; k++) {
        if (moment(days[k]).isBetween(start, end, undefined, '[]')) {
          const eventType = events[i].start.value.includes('T')
            ? 'regular'
            : 'wholeDay';

          const endMinusOne = new Date(end);
          endMinusOne.setDate(endMinusOne.getDate() - 1);

          const datesInRange =
            start.getDate() === end.getDate() || eventType === 'regular'
              ? getDurationDays(start, end)
              : getDurationDays(start, endMinusOne);

          for (let j = 0; j < datesInRange.length; j++) {
            const resDayKey = moment(datesInRange[j]).format().slice(0, -6);
            const userPartStatus = getEventUserPartStatus(events[i], userUri);

            if (userPartStatus !== USER_PART_STATUS.declined) {
              res[resDayKey] = [
                ...(res[resDayKey] || []),
                {
                  id: events[i].id,
                  summary: events[i].summary,
                  description: events[i].description,
                  location: events[i].location,
                  type: j >= 1 ? 'wholeDay' : eventType,
                  continious: datesInRange.length > 1,
                  date: new Date(datesInRange[j]),
                  start: new Date(transformCalDavDate(events[i].start.value)),
                  end: getTimeEnd(
                    new Date(transformCalDavDate(events[i].start.value)),
                    events[i].end,
                  ),
                  attendee: (events[i].attendee || []).map((att) => ({
                    ...att,
                    uri: decodeURI(att.uri),
                  })),
                  isRecurrence: !!(
                    events[i].recurrenceId || events[i].recurrence
                  ),
                  userPartStatus,
                  source: events[i],
                  sourceArrayIndex: i,
                  alarms: events[i].alarms,
                },
              ];
            }
          }
          break;
        }
      }
    }

    return convertEvents(days, res);
  },
);

export default selectMonthEvents;
