import { Requests } from 'src/_api/types/requests';
import UploadRequester from '../../requesters/uploadRequester';

export const upload = (params, onProgress) => {
  const requester = new UploadRequester('/upload');

  return requester.uploadRequest({
    data: params,
    onProgress,
  });
};
