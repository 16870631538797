import { FC } from 'react';
import cn from 'classnames';

import './styles.less';

import { ContactGroups } from './types';

const Groups = [
  {
    type: ContactGroups.Employees,
    title: 'Сотрудники',
  },
  {
    type: ContactGroups.DistributionGroups,
    title: 'Группы рассылки',
  },
  // {
  //   type: ContactGroups.External,
  //   title: 'Внешние контакты',
  // },
  {
    type: ContactGroups.Personal,
    title: 'Личные контакты',
  },
];

interface Props {
  selected: ContactGroups;
  onSelect: (contactGroup: ContactGroups) => void;
}

const ToggleGroups: FC<Props> = ({ selected, onSelect }) => (
  <div className='toggle-groups__root'>
    {Groups.map((group) => (
      <span
        className={cn({
          ['active']: selected === group.type,
        })}
        key={group.title}
        onClick={() => onSelect(group.type)}
      >
        {group.title}
      </span>
    ))}
  </div>
);

export default ToggleGroups;
