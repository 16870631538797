import {
  getDateWeekRange,
  getDateWeekRangeForOneMonth,
  getFirstDayInMonth,
} from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_DAY } from '../actionTypes';
import { ControlsState, SelectControlsDayAction } from '../types';

export const selectControlsDay = createAction<
  SelectControlsDayAction,
  ControlsState
>(SELECT_CONTROLS_DAY, (state, payload) => {
  if (
    state.mode === 'month' &&
    payload.getMonth() === state.selectedMonth.getMonth()
  ) {
    return state;
  }

  let range: [Date, Date] = [payload, payload];
  if (state.mode === 'week') {
    const preRange = getDateWeekRange(new Date(payload));
    range = getDateWeekRangeForOneMonth(preRange[0], payload.getMonth());
  }

  return {
    ...state,
    selectedDateRange: range,
    selectedMonth: getFirstDayInMonth(new Date(payload)),
    mode: state.mode === 'week' ? 'week' : 'day',
  };
});
