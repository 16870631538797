/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useRef, useState } from 'react';
//@ts-ignore
import './Progressbar.less';

interface ProgressbarProps {
  filledPercent: number;
}

const Progressbar: FC<ProgressbarProps> = ({ filledPercent }) => {
  const progressRef = useRef();

  const [progressBarWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    if (progressRef?.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setProgressWidth((filledPercent / 100) * progressRef.current.clientWidth);
    }
  }, [filledPercent]);

  return (
    <div className={'progressbar-progress'} ref={progressRef}>
      <div
        style={{ width: `${progressBarWidth}px` }}
        className={'progressbar-loaded'}
      />
    </div>
  );
};

export default Progressbar;
