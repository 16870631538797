import { FC, ReactNode } from 'react';
import '@ramail-components/topline/topline_styles.css';

import css from './FullScreenDialog.module.less';
import classnames from 'classnames';
import Portal from '../Portal';

interface Props {
  children: ReactNode;
  open: boolean;
}

const FullScreenDialog: FC<Props> = ({ children, open }) => (
  <Portal>
    <div
      className={classnames(css.root, {
        [css.open]: open,
      })}
    >
      {children}
    </div>
  </Portal>
);

export default FullScreenDialog;
