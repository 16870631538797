import moment from 'moment';
import 'moment-timezone';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { closeFloatingModal } from 'src/floatingModal/store/actions/closeFloatingModal';
import { closeWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/closeWhiteLoadingScreen';
import api from 'src/_api/api';
import { EVENT_VIEW_DELETE_ACTION } from '../store/actionTypes';
import { DeleteEventViewAction } from '../store/types';
import { transformCalDavDate } from 'src/_utils/caldav';
import { forceLoadEvents } from 'src/eventsDisplay/store/actions/forceLoadEvents';
import eventViewActions from '../store/actions';
import selectEventViewEvent from '../store/selectors/selectEventViewEvent';
import { openWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/openWhiteLoadingScreen';
import { Task } from 'redux-saga';
import { addSnack } from '../../_redux/slices/snackbar';
import calendarApi from '../../services/calendar';

function* whiteLoadingScreenDelay() {
  yield delay(1000);
  yield put(openWhiteLoadingScreen.action());
  yield put(
    addSnack({
      message: 'Событие удаляется...',
    }),
  );
}

export function* requestToDeleteEvent(
  action: DeleteEventViewAction,
): Generator {
  const event = (yield select(selectEventViewEvent)) as SelectedEvent;

  const recurrenceType = action.payload?.recurrenceType || null;
  yield put(closeFloatingModal.action());

  const whiteLoadingScreenTimeout = (yield fork(
    whiteLoadingScreenDelay,
  )) as Task;
  try {
    const { data: eventGetData }: any = yield call(api('eventGet'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        eventId: event.id,
      },
      headers: {},
    });
    const masterEvent = (eventGetData.events || []).find(
      (d) => !d.recurrenceId,
    );

    if (recurrenceType === 'single') {
      let edited = false;

      const transformedCalDavDate = event.source.recurrenceId
        ? transformCalDavDate(event.source.recurrenceId.value)
        : transformCalDavDate(event.source.start.value);

      const recurIdValue =
        masterEvent.start.type === 'DATE'
          ? moment(transformedCalDavDate).format('YYYYMMDD')
          : masterEvent.start.timeZone
            ? moment(transformedCalDavDate)
                .tz(masterEvent.start.timeZone)
                .format('YYYYMMDDTHHmmSS')
            : `${moment(transformedCalDavDate).format('YYYYMMDDTHHmmSS')}${
                masterEvent.start.value.includes('Z') ? 'Z' : ''
              }`;

      const recArr = masterEvent.recurrence.map((ed) => {
        if (!ed.includes('EXDATE')) return ed;
        edited = true;

        return ed + ',' + recurIdValue;
      });

      if (!edited) {
        recArr.push(
          `EXDATE${
            event.source.start.type === 'DATE' ? ';VALUE=DATE' : ''
          }:${recurIdValue}`,
        );
      }

      masterEvent.recurrence = recArr;

      yield call(api('eventOverride'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: event.id,
        },
        body: {
          event: masterEvent,
        },
      });
    } else if (recurrenceType === 'this-and-future') {
      const transformedCalDavDate = event.source.recurrenceId
        ? transformCalDavDate(event.source.recurrenceId.value)
        : transformCalDavDate(event.source.start.value);

      const recurIdValue =
        masterEvent.start.type === 'DATE'
          ? moment(transformedCalDavDate).format('YYYYMMDD')
          : masterEvent.start.timeZone
            ? moment(transformedCalDavDate)
                .tz(masterEvent.start.timeZone)
                .format('YYYYMMDDTHHmmSS')
            : `${moment(transformedCalDavDate).format('YYYYMMDDTHHmmSS')}${
                masterEvent.start.value.includes('Z') ? 'Z' : ''
              }`;

      const newMaster = { ...masterEvent };
      const recurSplitted = newMaster.recurrence[1].split(';');
      if (newMaster.recurrence[1].includes('UNTIL')) {
        const untilIndex = recurSplitted.findIndex((r) =>
          r.startsWith('UNTIL='),
        );
        recurSplitted[untilIndex] = `UNTIL=${recurIdValue}`;
      } else {
        recurSplitted.push(`UNTIL=${recurIdValue}`);
      }
      newMaster.recurrence[1] = recurSplitted.join(';');
      if (newMaster.recurrence[2]) {
        newMaster.recurrence[2] = `${newMaster.recurrence[2]},${recurIdValue}`;
      } else {
        newMaster.recurrence.push(
          `EXDATE${
            newMaster.start.type === 'DATE' ? ';VALUE=DATE' : ''
          }:${recurIdValue}`,
        );
      }

      yield call(api('eventOverride'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: event.id,
        },
        body: {
          event: newMaster,
        },
      });
    } else {
      yield call(api('eventDelete'), {
        url: {
          owner: 'me',
          calendarId: 'private',
          eventId: event.id,
        },
        headers: {},
      });
    }

    yield put(forceLoadEvents.action(true));

    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Событие удалено',
        type: 'success',
      }),
    );
    yield put(calendarApi.util.invalidateTags(['notification-events']));
  } catch (e) {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    if (e?.code === 'ERR_BAD_REQUEST' && recurrenceType === 'this-and-future') {
      yield put(eventViewActions().deleteEventView({
        recurrenceType: 'all',
      }));
    } else if (e?.response?.statusText !== "Not Found"){
      yield put(
        addSnack({
          message: 'Не удалось удалить событие111',
          type: 'error',
          actionButtons: [
            {
              title: 'Повторить',
              onClick: () => {
                eventViewActions().deleteEventView({
                  recurrenceType,
                });
              },
            },
          ],
        }),
      );
    }
  } finally {
    yield put(closeWhiteLoadingScreen.action());
  }
}

export function* handleEventDeleteSaga(): Generator {
  yield takeEvery(EVENT_VIEW_DELETE_ACTION, requestToDeleteEvent);
}
