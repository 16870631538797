// *modulism-group store-reducer
import { createReducer } from 'src/_utils/redux';
import { closeWhiteLoadingScreen } from './actions/closeWhiteLoadingScreen';
import { openWhiteLoadingScreen } from './actions/openWhiteLoadingScreen';
import { WhiteLoadingScreenState } from './types';

export const initialState: WhiteLoadingScreenState = {
  opened: false,
};

export default createReducer(initialState, [
  openWhiteLoadingScreen,
  closeWhiteLoadingScreen,
]);
