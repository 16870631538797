import { FC } from 'react';
import Input from '@rambler-components/input';
import FieldErrors from '../../FieldErrors';
import '@rambler-components/input/styles.css';
import './styles.less';

interface Props {
  room: string;
  setRoom: (room: string) => void;
}

const EventCreateRoom: FC<Props> = ({ room, setRoom }) => (
  <>
    <Input
      placeholder='Место проведения'
      value={room}
      status={undefined}
      onChange={(e) => setRoom(e.target.value)}
      maxLength={300}
      style={{
        width: '100%',
        marginTop: '20px',
      }}
    />
    <FieldErrors fieldKey='title' />
  </>
);

export default EventCreateRoom;
