// *modulism-group store-reducer
import { createReducer } from 'src/_utils/redux';
import { closeFloatingModal } from './actions/closeFloatingModal';
import { openFloatingModal } from './actions/openFloatingModal';
import { FloatingModalState } from './types';
import { manageBackgroundFloatingModal } from './actions/manageBackgroundFloatingModal';
import { toggleCentered } from './actions/toggleCentered';

export const initialState: FloatingModalState = {
  opened: '',
  target: null,
  fixedLeft: 0,
  closeOnScroll: false,
  withBackground: false,
  center: false,
};

export default createReducer(initialState, [
  openFloatingModal,
  closeFloatingModal,
  manageBackgroundFloatingModal,
  toggleCentered,
]);
