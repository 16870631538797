import { createAction } from 'src/_utils/redux';
import { ADD_NEW_EVENT_NOTIFICATIONS } from '../actionTypes';
import { NewEventsState, AddNewEventNotificationsAction } from '../types';

export const addNewEventNotifications = createAction<
  AddNewEventNotificationsAction,
  NewEventsState
>(ADD_NEW_EVENT_NOTIFICATIONS, (s, p) => ({
  events: [...p],
}));
