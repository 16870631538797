import { FC, useRef } from 'react';
import DateInput from 'src/_components/DateInput';

import TimeInput from 'src/_components/TimeInput';

import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import FieldErrors from '../../FieldErrors';
import { EVENT_CREATE_EDIT_ERRORS } from 'src/eventCreateEdit/constants';
import moment from 'moment';
import { isTimeBefore, minutesToTime, timeToMinutes } from 'src/_utils/date';

import css from './Fields.module.less';

interface Props {
  dateStart: string;
  timeStart: string;
  dateEnd: string;
  timeEnd: string;
  wholeDay: boolean;
  setValue: (key: string, value: string) => void;
}

const EventCreateRange: FC<Props> = ({
  dateStart,
  timeStart,
  dateEnd,
  timeEnd,
  wholeDay,
  setValue,
}) => {
  const { errors, mergedErrors } = useSelector((state: CStoreState) => ({
    errors: state.eventCreateEdit.fieldsData.errors,
    mergedErrors: Object.entries(state.eventCreateEdit.fieldsData.errors)
      .filter(
        (err) =>
          err[0] === 'dateStart' ||
          err[0] === 'timeStart' ||
          err[0] === 'dateEnd' ||
          err[0] === 'timeEnd' ||
          err[0] === 'wholeDay',
      )
      .map((err) => err[1])
      .flat()
      .map((e, i, arr) => {
        if (
          e === EVENT_CREATE_EDIT_ERRORS['noTimeStart'] &&
          arr.includes(EVENT_CREATE_EDIT_ERRORS['noTimeEnd'])
        )
          return EVENT_CREATE_EDIT_ERRORS['noTimeStartEnd'];
        if (
          e === EVENT_CREATE_EDIT_ERRORS['noTimeEnd'] &&
          arr.includes(EVENT_CREATE_EDIT_ERRORS['noTimeStart'])
        )
          return '0';

        return e;
      })
      .filter((e) => e !== '0'),
  }));

  const startTimeInput = useRef<HTMLInputElement>(null);
  const endTimeInput = useRef<HTMLInputElement>(null);

  const removeErrTime = (error, type: 'start' | 'end') =>
    eventCreateEditActions().removeFieldsErrorEventCreateEdit({
      field: type === 'start' ? 'timeStart' : 'timeEnd',
      error,
    });

  const addErrTime = (error, type: 'start' | 'end') =>
    eventCreateEditActions().addFieldsErrorEventCreateEdit({
      field: type === 'start' ? 'timeStart' : 'timeEnd',
      errors: [error],
    });

  const removeErrDate = (error, type: 'start' | 'end') =>
    eventCreateEditActions().removeFieldsErrorEventCreateEdit({
      field: type === 'start' ? 'dateStart' : 'dateEnd',
      error,
    });

  const addErrDate = (error, type: 'start' | 'end') =>
    eventCreateEditActions().addFieldsErrorEventCreateEdit({
      field: type === 'start' ? 'dateStart' : 'dateEnd',
      errors: [error],
    });

  const timeStartChangeHandler = (v: string) => {
    if (v.length) {
      removeErrTime(EVENT_CREATE_EDIT_ERRORS['noTimeStart'], 'start');

      setTimeout(() => {
        endTimeInput.current.focus();
        endTimeInput.current.selectionStart = 0;
        endTimeInput.current.selectionEnd = 0;
      });
    }
    const splitted = v.split(':');
    if (Number(splitted[0]) >= 24 || Number(splitted[1]) >= 60) {
      addErrTime(EVENT_CREATE_EDIT_ERRORS['incorrectTimeStart'], 'start');
      removeErrTime(EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'], 'end');
    } else {
      removeErrTime(EVENT_CREATE_EDIT_ERRORS['incorrectTimeStart'], 'start');

      if (
        !(
          !wholeDay &&
          timeEnd &&
          v &&
          isTimeBefore(timeEnd, v) &&
          dateStart === dateEnd
        )
      ) {
        removeErrTime(
          EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'],
          'end',
        );
      }
    }

    setValue('timeStart', v);

    if (v && timeEnd && timeStart) {
      const vStart = timeToMinutes(v);
      const minsStart = timeToMinutes(timeStart);
      const minsEnd = timeToMinutes(timeEnd);
      if (minsEnd < minsStart) return;

      setValue('timeEnd', minutesToTime(vStart + (minsEnd - minsStart)));
    }
  };

  const timeEndChangeHandler = (v: string) => {
    if (v.length) removeErrTime(EVENT_CREATE_EDIT_ERRORS['noTimeEnd'], 'end');
    if (!isTimeBefore(v, timeStart)) {
      removeErrTime(EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'], 'end');
    }
    const splitted = v.split(':');
    if (Number(splitted[0]) >= 24 || Number(splitted[1]) >= 60) {
      addErrTime(EVENT_CREATE_EDIT_ERRORS['incorrectTimeEnd'], 'end');
      removeErrTime(EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'], 'end');
    } else {
      removeErrTime(EVENT_CREATE_EDIT_ERRORS['incorrectTimeEnd'], 'end');

      if (
        !wholeDay &&
        timeStart &&
        v &&
        isTimeBefore(v, timeStart) &&
        dateStart === dateEnd
      ) {
        addErrTime(EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'], 'end');
      } else {
        removeErrTime(
          EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'],
          'end',
        );
      }
    }

    setValue('timeEnd', v);
  };

  const dateStartChangeHandler = (v: string) => {
    const splitted = v.split('.').map((s) => Number(s));
    if (splitted[0] > 31 || splitted[1] > 12) {
      addErrDate(EVENT_CREATE_EDIT_ERRORS['incorrectDateStart'], 'start');
      removeErrDate(EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'], 'end');
    } else {
      removeErrDate(EVENT_CREATE_EDIT_ERRORS['incorrectDateStart'], 'start');

      const isTimeEndBeforeStart =
        !wholeDay &&
        timeStart &&
        timeEnd &&
        isTimeBefore(timeEnd, timeStart) &&
        v === dateEnd;

      if (isTimeEndBeforeStart) {
        addErrTime(EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'], 'end');
      } else {
        removeErrTime(
          EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'],
          'end',
        );
      }

      const momentDateEnd = moment(dateEnd, 'DD.MM.YYYY');
      const momentDateStart = moment(v, 'DD.MM.YYYY');
      if (!momentDateEnd.isBefore(momentDateStart)) {
        removeErrDate(
          EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'],
          'end',
        );
      } else if (dateEnd !== dateStart) {
        addErrDate(EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'], 'end');
      }
    }

    setValue('dateStart', v);
    if (dateEnd === dateStart) {
      setValue('dateEnd', v);
    }
    eventCreateEditActions().calendarsComparing({
      status: 'REDO',
      dateStart,
    });
  };

  const dateEndChangeHandler = (v: string) => {
    const splitted = v.split('.').map((s) => Number(s));
    if (splitted[0] > 31 || splitted[1] > 12) {
      addErrDate(EVENT_CREATE_EDIT_ERRORS['incorrectDateEnd'], 'end');
      removeErrDate(EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'], 'end');
    } else {
      removeErrDate(EVENT_CREATE_EDIT_ERRORS['incorrectDateEnd'], 'end');

      const isTimeEndBeforeStart =
        !wholeDay &&
        timeStart &&
        timeEnd &&
        isTimeBefore(timeEnd, timeStart) &&
        dateStart === v;

      if (isTimeEndBeforeStart) {
        addErrTime(EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'], 'end');
      } else {
        removeErrTime(
          EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'],
          'end',
        );
      }

      const momentDateEnd = moment(v, 'DD.MM.YYYY');
      const momentDateStart = moment(dateStart, 'DD.MM.YYYY');
      if (!momentDateEnd.isBefore(momentDateStart)) {
        removeErrDate(
          EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'],
          'end',
        );
      } else {
        addErrDate(EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'], 'end');
      }
    }

    setValue('dateEnd', v);
    eventCreateEditActions().calendarsComparing({
      status: 'REDO',
      dateEnd,
    });
  };

  const timeStartFocusHandler = () =>
    removeErrTime(EVENT_CREATE_EDIT_ERRORS['noTimeStart'], 'start');
  const timeEndFocusHandler = () =>
    removeErrTime(EVENT_CREATE_EDIT_ERRORS['noTimeEnd'], 'end');

  return (
    <>
      <div className={css.eventCreateRange}>
        <div className={css.start}>
          <DateInput
            status={errors.dateStart.length ? 'error' : undefined}
            value={dateStart}
            onChange={dateStartChangeHandler}
            style={{
              width: '150px',
              marginRight: 5,
            }}
            closeOnScroll='.event-create-edit-anchor'
          />
          <TimeInput
            status={errors.timeStart.length ? 'error' : undefined}
            value={timeStart}
            onChange={timeStartChangeHandler}
            onFocus={timeStartFocusHandler}
            disabled={wholeDay}
            placeholder='Начало'
            iref={startTimeInput}
            selectOnFull
            style={{
              width: '105px',
            }}
          />
          <div className={css.splitter}>ー</div>
        </div>
        <div className={css.end}>
          <TimeInput
            status={errors.timeEnd.length ? 'error' : undefined}
            value={timeEnd}
            onChange={timeEndChangeHandler}
            onFocus={timeEndFocusHandler}
            disabled={wholeDay}
            placeholder='Конец'
            iref={endTimeInput}
            style={{
              width: '105px',
            }}
          />
          <DateInput
            status={errors.dateEnd.length ? 'error' : undefined}
            value={dateEnd}
            calendarPosition='right'
            onChange={dateEndChangeHandler}
            minDate={moment(dateStart, 'DD.MM.YYYY').toDate()}
            style={{
              width: '150px',
            }}
            closeOnScroll='.event-create-edit-anchor'
          />
        </div>
      </div>
      <FieldErrors fieldKey='range' errors={mergedErrors.slice(0, 1)} />
    </>
  );
};

export default EventCreateRange;
