import { call, put, takeEvery } from 'redux-saga/effects';
import { deleteSessionSetLoadingControls } from '../store/actions/deleteSessionSetLoadingControls';
import { DELETE_SESSION_CONTROLS } from '../store/actionTypes';
import { getRamblerIdHelper } from '../../scripts/idHelper/getter';
import { IS_CORP } from '../../_constants';
import api from '../../_api/api';

export function* deleteSession() {
  if (IS_CORP) {
    try {
      yield put(deleteSessionSetLoadingControls.action(true));
      yield call(api('deleteSession'));
      window.location.reload();
    } catch (e) {
      yield put(deleteSessionSetLoadingControls.action(false));
    }

    return;
  }

  try {
    yield put(deleteSessionSetLoadingControls.action(true));
    const ramblerIdHelper = yield getRamblerIdHelper();
    yield ramblerIdHelper.logout();
    yield ramblerIdHelper.redirectToAuth({
      back: window.location.href,
    });
  } catch (e) {
    yield put(deleteSessionSetLoadingControls.action(false));
  }
}

export function* deleteSessionSaga(): Generator {
  yield takeEvery(DELETE_SESSION_CONTROLS, deleteSession);
}
