import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { loadAddressbookContactsApi } from './loadAddressbookContacts';
import { setAddresbookContactsApi } from './setAddressbookContacts';
import { setSelectedContact } from './setSelectedContact';
import { unsetSelectedContact } from './unsetSelectedContact';
import { setMailingContacts } from './setMailingContacts';

const addressbookActions = () =>
  bindActionCreators(
    {
      loadAddressbookContactsApi: loadAddressbookContactsApi.action,
      setAddresbookContactsApi: setAddresbookContactsApi.action,
      setSelectedContact: setSelectedContact.action,
      unsetSelectedContact: unsetSelectedContact.action,
      setMailingContacts: setMailingContacts.action,
    },
    dispatch,
  );

export default addressbookActions;
