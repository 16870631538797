import { FC } from 'react';
import cn from 'classnames';
import { SelectedIcon } from '../Svg';
import './styles.less';

interface DropdownItemProps {
  className?: string;
  withIcon?: boolean;
  action: () => void;
  active?: boolean;
  title: string;
}

const DropdownItem: FC<DropdownItemProps> = ({
  className,
  withIcon,
  action,
  active,
  title,
}) => (
  <div
    className={cn('dropdown-item', className || '', {
      active,
    })}
    onClick={action}
  >
    {withIcon && active && <SelectedIcon />}
    <p>{title}</p>
  </div>
);

export default DropdownItem;
