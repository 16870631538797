import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ISnack } from './snackbar.types';
import { getUniqueId } from 'src/_utils/uniqueIds';

export interface CounterState {
  snacks: ISnack[];
}

const initialState: CounterState = {
  snacks: [],
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addSnack: (state, action: PayloadAction<Omit<ISnack, 'id'> | string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { payload } = action;

      const id = getUniqueId();
      let snack: ISnack = { id, type: 'default', message: '' };
      if (typeof payload === 'string') {
        snack.message = payload;
      } else {
        snack = { ...snack, ...payload };
      }

      state.snacks.push(snack);
    },
    onRemoveSnack: (state, action: PayloadAction<string | number>) => {
      const { payload } = action;

      state.snacks = state.snacks.filter((snack) => snack.id !== payload);
    },
    hideSnack: (state, action: PayloadAction<string | { uId: string }>) => {
      const { payload } = action;
      const uId = typeof payload === 'string' ? payload : payload.uId;

      state.snacks = state.snacks.filter((snack) => snack.uId !== uId);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSnack, hideSnack, onRemoveSnack } = snackbarSlice.actions;

export default snackbarSlice.reducer;
