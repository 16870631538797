import { createAction } from 'src/_utils/redux';
import { EVENT_VIEW_SET } from '../actionTypes';
import { EventViewState, SetEventViewAction } from '../types';

const GOOGLE_EVENT_TECHNICAL_STRING =
  '-::~:~::~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~::~:~::-';

const clearEvent = (
  event: EventViewState['event'],
): EventViewState['event'] => {
  if (!event?.description) return event;

  const res = { ...event };
  if (res.description) {
    res.description = res.description.replaceAll(
      GOOGLE_EVENT_TECHNICAL_STRING,
      '',
    );
  }

  return res;
};

export const setEventView = createAction<SetEventViewAction, EventViewState>(
  EVENT_VIEW_SET,
  (state, p) => ({
    ...state,
    event: clearEvent(p.event),
    getLoading: true,
  }),
);
