// *modulism-group store-actionTypes

// COMMON
export const EVENT_CREATE_EDIT_RESET = 'EVENT_CREATE_EDIT_RESET';
export const EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA =
  'EVENT_CREATE_EDIT_UPDATE_FIELDS_DATA';
export const EVENT_CREATE_EDIT_ADD_FIELDS_ERROR =
  'EVENT_CREATE_EDIT_ADD_FIELDS_ERROR';
export const EVENT_CREATE_EDIT_REMOVE_FIELDS_ERROR =
  'EVENT_CREATE_EDIT_REMOVE_FIELDS_ERROR';
export const EVENT_CREATE_EDIT_SHOW_FIELDS = 'EVENT_CREATE_EDIT_SHOW_FIELDS';
export const EVENT_CREATE_EDIT_CALENDARS_COMPARING =
  'EVENT_CREATE_EDIT_CALENDARS_COMPARING';

// CREATE
export const EVENT_CREATE_WINDOW_OPEN = 'EVENT_CREATE_WINDOW_OPEN';
export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST';
export const EVENT_CREATE_REQUEST_SUCCESS = 'EVENT_CREATE_REQUEST_SUCCESS';
export const EVENT_CREATE_REQUEST_FAIL = 'EVENT_CREATE_REQUEST_FAIL';

// EDIT
export const EVENT_EDIT_WINDOW_OPEN = 'EVENT_EDIT_WINDOW_OPEN';
export const EVENT_EDIT_REQUEST = 'EVENT_EDIT_REQUEST';
export const EVENT_EDIT_REQUEST_SUCCESS = 'EVENT_EDIT_REQUEST_SUCCESS';
export const EVENT_EDIT_REQUEST_FAIL = 'EVENT_EDIT_REQUEST_FAIL';
export const EVENT_EDIT_SET_RECURRENCE_TYPE = 'EVENT_EDIT_SET_RECURRENCE_TYPE';

///ATTACHI
export const EVENT_ATTACHI_DELETE_FILE = 'EVENT_ATTACHI_DELETE_FILE';
export const EVENT_ATTACHI_ADD_FILE = 'EVENT_ATTACHI_ADD_FILE';
export const EVENT_ATTACHI_CONTORL_URLOADS = 'EVENT_ATTACHI_CONTORL_URLOADS';
export const EVENT_ATTACHI_SET_INFO = 'EVENT_ATTACHI_SET_INFO';
