import './styles.less';

const DayEventsWrapper = ({ children, width }) => (
  <div
    className='day-events-wrapper'
    style={{
      width,
    }}
  >
    {children}
  </div>
);

export default DayEventsWrapper;
