/* eslint-disable @typescript-eslint/ban-ts-comment */
import { call, put } from 'redux-saga/effects';
import { CUSTOM_EVENTS, IS_CORP } from 'src/_constants';
import { setProfileApiCommonData } from '../store/actions/setProfile';
import { getRamblerIdHelper } from '../../scripts/idHelper/getter';
import { getRegistrationLink } from '../../_utils/auth';
import api from '../../_api/api';

export function* loadProfileApiCommonDataSaga(): Generator {
  if (IS_CORP) {
    try {
      // @ts-ignore
      const { data } = yield call(api('profile'));
      yield put(setProfileApiCommonData.action(data.profile));
      // eslint-disable-next-line no-empty
    } catch (e) {
      try {
        // @ts-ignore
        const { data } = yield call(api('profile'));
        yield put(setProfileApiCommonData.action(data.profile));
      } catch (e) {
        const ev = new Event(CUSTOM_EVENTS.fatalError, {
          bubbles: true,
        });
        window.dispatchEvent(ev);
      }
    }

    return;
  }
  try {
    const ramblerIdHelper = yield getRamblerIdHelper();

    const loadProfile = new Promise((resolve) => {
      // @ts-ignore
      ramblerIdHelper.getProfileInfo({}, async (profileInfo, error) => {
        resolve({ profileInfo, error });
      });
    });

    // @ts-ignore
    const { profileInfo, error } = yield loadProfile;
    if (error) {
      // @ts-ignore
      yield ramblerIdHelper.redirectToAuth({
        back: window.location.href,
      });
    } else if (!profileInfo?.info?.email) {
      window.location.href = getRegistrationLink();

      return;
    }
    yield put(setProfileApiCommonData.action(profileInfo));
  } catch (e) {
    const ev = new Event(CUSTOM_EVENTS.fatalError, {
      bubbles: true,
    });
    window.dispatchEvent(ev);
  }
}
