import { useCallback, useState } from 'react';
import { deepEqual } from '../deepEqual';

type FormValues<T> = {
  [K in keyof T]: T[K];
};

type ValidationRules<T> = {
  [K in keyof T]?: Array<(value: T[K], values?: T) => string>;
};

export const useForm = <T>({
  defaultValues,
  validationRules,
}: {
  defaultValues: FormValues<T>;
  validationRules?: ValidationRules<T>;
}) => {
  const [values, setValues] = useState<FormValues<T>>(defaultValues);
  const [_initialValues, _setInitialValues] =
    useState<FormValues<T>>(defaultValues);
  const [errors, setErrors] = useState<{ [K in keyof T]?: string[] }>({});

  const setValue = useCallback(<K extends keyof T>(name: K, value: T[K]) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const validateFields = useCallback(() => {
    if (!validationRules) return true;

    const newErrors: { [K in keyof T]?: string[] } = {};

    Object.keys(validationRules).forEach((key) => {
      validationRules[key as keyof T].forEach((rule) => {
        const error = rule(values[key as keyof T], values);
        if (error) {
          if (newErrors[key]) {
            newErrors[key].push(error);
          } else {
            newErrors[key] = [error];
          }
        }
      });
    });

    setErrors(errors);

    return !Object.keys(newErrors).length;
  }, [validationRules, values]);

  const setInitialValues = (values: FormValues<T>) => {
    setValues({ ...values });
    _setInitialValues({ ...values });
  };

  const getIsEdited = () => deepEqual(values, _initialValues);

  return {
    values,
    errors,
    setValue,
    validateFields,
    setInitialValues,
    getIsEdited,
  };
};
