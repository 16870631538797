import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Week } from 'src/_components/Calendar/Week';
import WeekItem from 'src/_components/Calendar/Week/Item';
import { CStoreState } from 'src/_redux/types';
import {
  getDateWeekRange,
  getDurationDays,
  isDatesEqual,
} from 'src/_utils/date';
import { scrollContent } from 'src/_utils/scrollContent';
import { DAY_TIME } from 'src/_constants';

import pageCalendarWrapper from 'src/pageCalendar/HOC/pageCalendarWrapper';
import WeekEventsWrapper from 'src/eventsDisplay/components/WeekEventsWrapper';
import selectWeekEvents from 'src/eventsDisplay/store/selectors/weekEventsSelector';
import moment from 'moment';
import processRegularEvents from './processRegularEvents';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { DayWeekHeader } from 'src/_components/Calendar/DayWeekHeader';
import DayWeekHeaderEvents from 'src/eventsDisplay/components/DayWeekHeaderEvents';

const CalendarModeWeek = () => {
  const { selectedDateRange } = useSelector((s: CStoreState) => ({
    selectedDateRange: s.controls.selectedDateRange,
  }));
  const events = useSelector((state: CStoreState) =>
    selectWeekEvents(state, selectedDateRange),
  );
  const wholeDayEvents = useMemo(
    () =>
      Object.entries(events)
        .map((e) => e[1].wholeDay)
        .reduce((prev, cur) => [...prev, ...cur], []),
    [events],
  );
  const weekRange = getDateWeekRange(selectedDateRange[0]);
  const durationDays = getDurationDays(weekRange[0], weekRange[1]).map((d) =>
    moment(d).format().slice(0, -6),
  );
  const elRefs = useRef<HTMLDivElement[]>([]);
  const headerEventsBlockRef = useRef<HTMLDivElement>();

  useEffect(() => {
    scrollContent(89 * 8);
  }, []);

  const weekDays = Array(7)
    .fill('.')
    .map((d, i) => {
      const startDate = new Date(weekRange[0]);
      startDate.setDate(startDate.getDate() + i);

      return startDate;
    });

  const items = Array(192)
    .fill('.')
    .map((d, i) => {
      const startDate = new Date(weekRange[0]);
      startDate.setDate(startDate.getDate() + i - 1);

      const column = (i % 8) - 1;
      const day = column >= 0 ? weekDays[column] : null;

      return {
        day,
        weekDay: day ? day.getDay() || 7 : 7,
        isActive: isDatesEqual(new Date(), day || new Date(1)),
        isPast: day < new Date(),
        time: DAY_TIME[i / 8],
        lineTime: DAY_TIME[(i - (i % 8)) / 8],
      };
    });

  const headerDays = Array(7)
    .fill('.')
    .map((d, i) => {
      const startDate = new Date(weekRange[0]);
      startDate.setDate(startDate.getDate() + i);
      const date = new Date(startDate);

      return {
        date: date,
        weekDay: startDate.getDay() || 7,
        isPast: startDate < new Date(),
        isCurrent: isDatesEqual(new Date(), startDate),
      };
    });

  const isCurrentWeek = !!items.filter((d) => d.isActive).length;

  return (
    <>
      <DayWeekHeader
        items={headerDays}
        headerEventsBlockRef={headerEventsBlockRef}
        highlightToday
      >
        <DayWeekHeaderEvents
          dates={headerDays.map((d) => d.date)}
          blockRef={headerEventsBlockRef}
          events={wholeDayEvents}
          type='week'
        />
      </DayWeekHeader>
      <Week
        isCurrentWeek={isCurrentWeek}
        items={items.map((date, index) => (
          <WeekItem
            key={`week-item-${index}`}
            refs={elRefs}
            index={index}
            isActive={date.isActive}
            isPast={date.isPast}
            time={date.time}
            onClick={
              !(index && !(index % 8)) && index
                ? (part: 'top' | 'bottom') => {
                    {
                      floatingModalActions().openFloatingModal({
                        opened: FLOATING_MODAL_IDS['eventCreate'],
                        target: elRefs.current[index],
                      });

                      let timeEnd = date.lineTime.split(':')[0] + ':30';
                      let dateEnd = moment(date.day).format('DD.MM.YYYY');
                      if (part === 'bottom') {
                        if (Number(date.lineTime.split(':')[0]) + 1 === 24) {
                          timeEnd = '00:00';
                          dateEnd = moment(date.day)
                            .add(1, 'd')
                            .format('DD.MM.YYYY');
                        } else {
                          timeEnd =
                            (Number(date.lineTime.split(':')[0]) + 1)
                              .toString()
                              .padStart(2, '0') + ':00';
                        }
                      }
                      eventCreateEditActions().openEventCreateWindow({
                        timeStart:
                          part === 'top'
                            ? date.lineTime
                            : date.lineTime.split(':')[0] + ':30',
                        timeEnd,
                        dateStart: moment(date.day).format('DD.MM.YYYY'),
                        dateEnd,
                        transparency: 'OPAQUE',
                      });
                    }
                  }
                : null
            }
          />
        ))}
      >
        <WeekEventsWrapper>
          {durationDays.map((dD, i) =>
            processRegularEvents(i * 6 + 1, events[dD].regular),
          )}
        </WeekEventsWrapper>
      </Week>
    </>
  );
};

export default pageCalendarWrapper(CalendarModeWeek);
