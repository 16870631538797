import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompareIcon, NoteBookIcon } from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import Suggester from '@ramail-components/suggester';

import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import { EventAttendee } from 'src/_api/types/entities';
import { isValidEmail } from 'src/_utils/validateEmail';
import ContactsList from 'src/_components/ContactsList';
import { EVENT_CREATE_EDIT_ERRORS } from 'src/eventCreateEdit/constants';
import FieldErrors from '../../FieldErrors';
import SelectedValue from './selectedValue';
import SuggestedValue from './suggestedValue';
import { CUSTOM_EVENTS } from 'src/_constants';
import floatingModalActions from 'src/floatingModal/store/actions';

import cn from 'classnames';
import '@ramail-components/suggester/suggester_styles.css';
import './styles.less';
import {
  getEmailFromAttendee,
  getUniqueAttendeeList,
} from 'src/_utils/attendee';
import { MAX_ATTENDEE_COUNT } from '../../../const';
import { useWindowSize } from '../../../../_utils/hooks/useWindowSize';

const DEFAULT_INPUT_MIN_WIDTH = 100;

interface EventCreateAttendeesProps {
  attendees: EventAttendee[];
  onChange: (attendees: EventAttendee[]) => void;
  noSelected?: boolean;
  staticPlaceholder?: string;
}

export const AttendeesField: FC<EventCreateAttendeesProps> = ({
  attendees,
  onChange,
  noSelected = false,
  staticPlaceholder,
}) => {
  const { rawContacts, errors, profileInfo, open, editData } = useSelector(
    (state: CStoreState) => ({
      open: open,
      rawContacts: state.apiCommonData.contacts,
      errors: state.eventCreateEdit.fieldsData.errors['attendees'],
      profileInfo: state.apiCommonData?.profile?.info,
      editData: state.eventCreateEdit.editData,
    }),
    shallowEqual,
  );

  const contacts = useMemo(
    () => (typeof rawContacts === 'string' ? [] : rawContacts),
    [rawContacts],
  );
  const profileEmail = profileInfo?.email.toLowerCase();
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [wrapperFocused, setWrapperFocused] = useState(false);
  const [text, setText] = useState('');
  const [dropdownTop, setDropdownTop] = useState(5);
  const blockRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();
  const attendeesDropdownRef = useRef<HTMLDivElement>();
  const attendeesDropdownButtonRef = useRef<HTMLDivElement>();
  const launched = useRef(false);
  const callOnBlur = useRef(true);
  const measureCanvas = useRef<HTMLCanvasElement>(null);
  const { width } = useWindowSize();

  const isNeedPlaceholder =
    attendees.length === 0 ||
    (attendees.length === 1 &&
      getEmailFromAttendee(attendees[0]) === profileEmail);

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      !attendeesDropdownRef.current?.contains(e.target as any) &&
      !attendeesDropdownButtonRef.current.contains(e.target as any)
    )
      setOpenedDropdown(false);

    if (!blockRef.current?.contains(e.target as any)) {
      setInputFocused(false);
      setWrapperFocused(false);
    }
  };

  useEffect(
    () => () => {
      callOnBlur.current = false;
    },
    [],
  );

  useEffect(() => {
    if (!launched.current) {
      launched.current = true;

      return;
    }
    eventCreateEditActions().calendarsComparing({
      status: 'REDO',
      attendees,
    });
  }, [attendees]);

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const rect = blockRef.current?.getBoundingClientRect();
    setDropdownTop((rect?.height || 0) + 5);
  }, [attendees, wrapperFocused]);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 100);
  }, []);

  useEffect(() => {
    measureCanvas.current = document.createElement('canvas');

    return () => {
      if (measureCanvas.current) {
        measureCanvas.current.remove();
      }
    };
  }, []);

  const inputMinWidth = useMemo(() => {
    if (!measureCanvas.current || !text) return null;

    const context = measureCanvas.current.getContext('2d');
    context.font = 'normal 15px Manrope, sans-serif';

    const width = context.measureText(text)?.width + 2 || 0;

    const inputMaxWidth = inputRef.current?.parentNode
      ?.parentNode as HTMLDivElement;

    return Math.min(inputMaxWidth?.clientWidth, width);
  }, [text, width]);

  const possibleContacts = useMemo(
    () =>
      text.trim()
        ? contacts.filter((c) => {
            const loweredText = text.trim().toLowerCase();
            const fullName = `${c.first_name || ''} ${c.last_name || ''}`
              .trim()
              .toLowerCase();

            return (
              ((fullName && fullName.includes(loweredText)) ||
                c.email.toLowerCase().includes(loweredText)) &&
              !attendees.find((at) => at.uri === `mailto:${c.email}`) &&
              c.email !== profileEmail
            );
          })
        : [],
    [contacts, text, attendees],
  );

  const onInputEmails = (emails: string[], cleanPreviousList?: boolean) => {
    const newAttendees = cleanPreviousList ? [] : [...attendees];
    const invalidEmails: string[] = [];

    if (
      emails
        .filter((email) => {
          const organizer = editData?.masterEvent?.organizer;
          if (organizer) return getEmailFromAttendee(organizer) !== email;

          return true;
        })
        .some((email) => email === profileEmail)
    ) {
      eventCreateEditActions().addFieldsErrorEventCreateEdit({
        field: 'attendees',
        errors: [EVENT_CREATE_EDIT_ERRORS['incorrectUser']],
      });

      return false;
    } else {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'attendees',
        error: EVENT_CREATE_EDIT_ERRORS['incorrectUser'],
      });
    }

    emails.forEach((email) => {
      if (!email) {
        return;
      }

      if (!isValidEmail(email)) {
        invalidEmails.push(email);

        return;
      }

      const attendeeContact = contacts.find((c) => c.email === email);

      const fullName = `${attendeeContact?.first_name || ''} ${
        attendeeContact?.last_name || ''
      }`.trim();

      newAttendees.push({
        uri: `mailto:${email}`,
        name: fullName || undefined,
      });
    });

    const uniqueAttendeeList = getUniqueAttendeeList(newAttendees);

    // attendees + owner <= MAX_ATTENDEE_COUNT;
    if (uniqueAttendeeList.length + 1 <= MAX_ATTENDEE_COUNT) {
      onChange(uniqueAttendeeList);

      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'attendees',
        error: EVENT_CREATE_EDIT_ERRORS['attendeeLimit'],
      });
    } else {
      eventCreateEditActions().addFieldsErrorEventCreateEdit({
        field: 'attendees',
        errors: [EVENT_CREATE_EDIT_ERRORS['attendeeLimit']],
      });

      return;
    }

    if (invalidEmails.length > 0) {
      setText((prevText) => {
        let spaces = '';
        for (let i = prevText.length - 1; i >= 0; i--) {
          if (prevText[i] !== ' ') break;
          spaces += ' ';
        }

        return invalidEmails.join(' ') + spaces;
      });
      eventCreateEditActions().addFieldsErrorEventCreateEdit({
        field: 'attendees',
        errors: [EVENT_CREATE_EDIT_ERRORS['attendeesIncorrect']],
      });
    } else {
      setText('');
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'attendees',
        error: EVENT_CREATE_EDIT_ERRORS['attendeesIncorrect'],
      });
    }

    return !invalidEmails.length;
  };

  const onSelect = (input: string) => {
    callOnBlur.current = false;

    if (!input) {
      return;
    }

    const possibleEmails = input.split(/[,\s]+/).filter((s) => s);

    onInputEmails(possibleEmails);
  };

  const onRemove = (uri: string) => {
    callOnBlur.current = false;

    const findAttendee = attendees.findIndex((at) => at.uri === uri);
    const attendeesArr = [...attendees];
    if (findAttendee > -1) {
      attendeesArr.splice(findAttendee, 1);

      onChange(attendeesArr);
    }
  };

  const onBlur = () => {
    callOnBlur.current = true;

    setTimeout(() => {
      if (!callOnBlur.current) {
        return;
      }
      const possibleEmails = text.split(/[,\s]+/).filter((s) => s);

      onInputEmails(possibleEmails);
    }, 120);
  };

  const selectAttendeeForEdit = useCallback(
    (attendee: EventAttendee) => {
      callOnBlur.current = false;

      const previousTextEmails = text
        .split(/[,\s]+/)
        .filter((s) => s)
        .filter((s) => s !== getEmailFromAttendee(attendee));

      if (
        previousTextEmails
          .filter((email) => {
            const organizer = editData?.masterEvent?.organizer;
            if (organizer) return getEmailFromAttendee(organizer) !== email;

            return true;
          })
          .some((email) => email === profileEmail)
      ) {
        eventCreateEditActions().addFieldsErrorEventCreateEdit({
          field: 'attendees',
          errors: [EVENT_CREATE_EDIT_ERRORS['incorrectUser']],
        });

        return;
      }

      if (previousTextEmails.some((email) => !isValidEmail(email))) {
        eventCreateEditActions().addFieldsErrorEventCreateEdit({
          field: 'attendees',
          errors: [EVENT_CREATE_EDIT_ERRORS['attendeesIncorrect']],
        });

        return;
      }

      const newEmailList = [...attendees]
        .filter((_attendee) => _attendee.uri !== attendee.uri)
        .map((_a) => _a.uri.replace('mailto:', ''));

      newEmailList.push(...previousTextEmails);

      onInputEmails(newEmailList, true);

      setText(attendee.uri.replace('mailto:', ''));

      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current.selectionEnd = 1000;
        inputRef.current.selectionStart = 1000;
      });
    },
    [attendees, text, editData?.masterEvent?.organizer],
  );

  const showFields = () => {
    eventCreateEditActions().showFieldsEventCreateEdit();
    setTimeout(() => {
      const ev = new Event(CUSTOM_EVENTS.floatingModalRecalculate, {
        bubbles: false,
      });
      window.dispatchEvent(ev);
    });
  };

  const startComparing = () => {
    eventCreateEditActions().calendarsComparing({
      status: 'START',
      attendees,
    });
    floatingModalActions().manageBackgroundFloatingModal(true);
    floatingModalActions().toggleCentered(true);
    showFields();
    const ev = new Event(CUSTOM_EVENTS.floatingModalFullScreen, {
      bubbles: true,
    });
    window.dispatchEvent(ev);
  };

  const selectedValues = useMemo(() => {
    if (noSelected) return [];

    return attendees
      .filter((at) => {
        const organizer = editData?.masterEvent?.organizer?.uri;
        if (organizer) return at.uri !== organizer;

        return true;
      })
      .map((at) => (
        <SelectedValue
          key={at?.uri}
          uri={at.uri}
          name={at.name}
          onRemove={onRemove}
          selectForEdit={selectAttendeeForEdit}
        />
      ));
  }, [noSelected, attendees, onRemove, selectAttendeeForEdit, editData]);

  const onInputChange = (input: string) => {
    setText(input);
    if (input.endsWith(' ') && input.trim()) {
      setTimeout(() => onSelect(input));
    } else {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'attendees',
        error: EVENT_CREATE_EDIT_ERRORS['attendeesIncorrect'],
      });
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'attendees',
        error: EVENT_CREATE_EDIT_ERRORS['incorrectUser'],
      });
    }
  };

  return (
    <>
      <div
        ref={blockRef}
        className={cn('event-create-attendees', {
          focused: inputFocused,
          error: errors.length,
        })}
        style={{
          marginBottom: openedDropdown ? 40 : undefined,
        }}
        onClick={(e: any) => {
          if (e.target.nodeName === 'INPUT') return;
          setWrapperFocused(true);
          setTimeout(() => {
            inputRef.current && inputRef.current.focus();
          });
        }}
      >
        <Suggester
          value={text}
          onChange={onInputChange}
          placeholder={
            staticPlaceholder || (isNeedPlaceholder ? 'Участники' : '')
          }
          selectedValuesClassName='event-create-attendees-selected-values'
          icon={
            <div
              className={cn('attendees-icon', {
                active: openedDropdown,
              })}
              ref={attendeesDropdownButtonRef}
            >
              <NoteBookIcon />
            </div>
          }
          onFocus={() => setInputFocused(true)}
          onBlur={onBlur}
          onSelect={onSelect}
          wrapperStyle={{
            padding: '0',
            width: '100%',
            backgroundColor: 'unset',
          }}
          inputStyle={{
            minWidth: inputMinWidth || DEFAULT_INPUT_MIN_WIDTH,
            display: staticPlaceholder
              ? undefined
              : !text && !wrapperFocused && attendees.length
                ? 'none'
                : undefined,
          }}
          onClickIcon={() => {
            if (contacts.length || openedDropdown) {
              setOpenedDropdown(!openedDropdown);
            }
          }}
          selectedValues={selectedValues}
          posibleValues={possibleContacts.map((c, i) => {
            const fullName = `${c.last_name || ''} ${
              c.first_name || ''
            }`.trim();

            return {
              node: (
                <SuggestedValue
                  key={`event-create-suggester-item-${i}`}
                  fullName={fullName}
                  text={text}
                  email={c.email}
                />
              ),
              key: c.email,
            };
          })}
          dropdownStyle={{
            zIndex: 2,
            width: 'calc(100% + 40px)',
          }}
          inputRef={inputRef}
        />
        {openedDropdown && (
          <div
            className={'attendees-dropdown'}
            style={{
              transform: `translate3d(0, ${dropdownTop}px, 0)`,
              width: noSelected ? '560px' : undefined,
            }}
            ref={attendeesDropdownRef}
          >
            <ContactsList
              contacts={contacts}
              attendees={attendees}
              onSelect={onSelect}
              onRemove={onRemove}
            />
          </div>
        )}
      </div>
      <FieldErrors fieldKey='attendees' errors={errors} />
      {!staticPlaceholder && attendees.length ? (
        <div>
          <div className='attendees-compare-suggest' onClick={startComparing}>
            <CompareIcon />
            Перейти к сравнению календарей
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
