export const NO_RECURRENCE_FOUND = {
  type: 'NO_RECURRENCE_FOUND',
} as const;

export const NO_SUPPORT_RESULT = {
  type: 'NO_SUPPORT',
} as const;

export type ParsedWeekly = {
  type: 'WEEK';
  until: string;
  selectedDays: string[];
  selectedMonths: number[];
  selectingMonths: boolean;
  selectedWeeks: number[];
  selectingWeeks: boolean;
};

export type ParsedPeriod = {
  type: 'PERIOD';
  until?: string;
  count?: number;
};

export type ParsedDate = {
  type: 'DATE';
  until: string;
  monthDay: number;
  ndate: number;
  selectedMonths: number[];
  selectingMonths: boolean;
};

export type ParseDRRule =
  | ParsedWeekly
  | ParsedPeriod
  | ParsedDate
  | typeof NO_SUPPORT_RESULT
  | typeof NO_RECURRENCE_FOUND;
