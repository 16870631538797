import { Event } from 'src/_api/types/entities';
import { DateTime } from 'luxon';
import { getTimezone } from '../../_utils/date';

export interface EventsData {
  events: Event[];
}

const OWNER = 'me';
const CALENDAR_ID = 'private';

export const getNotificationsEventsQuery = () => ({
  url: `/${OWNER}/calendars/${CALENDAR_ID}/events`,
  method: 'GET',
  params: {
    expand: true,
    timeMin: DateTime.now()
      .minus({ hour: -new Date().getTimezoneOffset() / 60 })
      .toISO(),
    timeMax: DateTime.now().plus({ day: 8 }).toISO(),
    timeZone: getTimezone(),
  },
});
