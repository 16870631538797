import { createAction } from 'src/_utils/redux';
import { WHITE_LOADING_SCREEN_CLOSE } from '../actionTypes';
import {
  CloseWhiteLoadingScreenAction,
  WhiteLoadingScreenState,
} from '../types';

export const closeWhiteLoadingScreen = createAction<
  CloseWhiteLoadingScreenAction,
  WhiteLoadingScreenState
>(WHITE_LOADING_SCREEN_CLOSE, () => ({
  opened: false,
}));
