import { bindActionCreators } from 'redux';
import { dispatch } from 'src/_redux';
import { addNewEventNotifications } from './addNewEventNotifications';
import { attendeeReactionNewEventNotifications } from './attendeeReactionNewEventNotifications';
import { deleteCancelledNewEventNotifications } from './deleteCancelledNewEventNotifications';
import { deleteNewEventNotifications } from './deleteNewEventNotifications';
import { forceLoadNewEventNotifications } from './forceLoadNewEventNotifications';
import { removeNewEventNotification } from './removeNewEventNotification';

const singleDayModalActions = () =>
  bindActionCreators(
    {
      addNewEventNotifications: addNewEventNotifications.action,
      removeNewEventNotification: removeNewEventNotification.action,
      forceLoadNewEventNotifications: forceLoadNewEventNotifications.action,
      attendeeReactionNewEventNotifications:
        attendeeReactionNewEventNotifications.action,
      deleteCancelledNewEventNotifications:
        deleteCancelledNewEventNotifications.action,
      deleteNewEventNotifications: deleteNewEventNotifications.action,
    },
    dispatch
  );

export default singleDayModalActions;
