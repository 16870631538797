/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useState } from 'react';
import cn from 'classnames';
import Dropdown from 'src/_components/Dropdown';
import { Event } from 'src/_api/types/entities';
import { ArrowDownSmallIcon, ArrowUpSmallIcon } from 'src/_components/Svg';
import '@rambler-components/dropdown/styles.css';
import eventViewActions from 'src/eventView/store/actions';
import DropdownItem from 'src/_components/DropdownItem';

import css from './EventBlock.module.less';

interface EventBlockAttendeeTransparencyProps {
  transp: Event['transparency'];
}

const EventBlockAttendeeTransparency: FC<
  EventBlockAttendeeTransparencyProps
> = ({ transp }) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(transp);

  const handleSelect = (v: Event['transparency']) => {
    setSelected(v);
    setOpened(false);

    eventViewActions().attendeeTransparencyChangeEventView(v);
  };

  return (
    <div>
      <p
        className={cn(css.eventText, css.eventActionText, {
          [css.active]: opened,
        })}
        onClick={() => setOpened(!opened)}
      >
        <strong>Статус</strong>:{' '}
        {transp === 'TRANSPARENT' ? 'Свободен' : 'Занят'}
        {opened ? <ArrowUpSmallIcon /> : <ArrowDownSmallIcon />}
      </p>
      {opened && (
        <Dropdown
          style={{
            width: '370px',
            marginTop: '5px',
            padding: '0px',
            overflow: 'hidden',
          }}
        >
          <DropdownItem
            action={() => handleSelect('OPAQUE')}
            active={selected === 'OPAQUE'}
            withIcon
            title='Занят'
          />
          <DropdownItem
            action={() => handleSelect('TRANSPARENT')}
            active={selected === 'TRANSPARENT'}
            withIcon
            title='Свободен'
          />
        </Dropdown>
      )}
    </div>
  );
};

export default EventBlockAttendeeTransparency;
