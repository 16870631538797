// *modulism-group store-actionTypes
export const ADD_NEW_EVENT_NOTIFICATIONS = 'ADD_NEW_EVENT_NOTIFICATIONS';
export const REMOVE_NEW_EVENT_NOTIFICATION = 'REMOVE_NEW_EVENT_NOTIFICATION';
export const FORCE_LOAD_NEW_EVENT_NOTIFICATIONS =
  'FORCE_LOAD_NEW_EVENT_NOTIFICATIONS';
export const ATTENDEE_REACTION_NEW_EVENT_NOTIFICATIONS =
  'ATTENDEE_REACTION_NEW_EVENT_NOTIFICATIONS';
export const DELETE_CANCELLED_NEW_EVENT_NOTIFICATION =
  'DELETE_CANCELLED_NEW_EVENT_NOTIFICATION';
export const DELETE_NEW_EVENT_NOTIFICATIONS = 'DELETE_NEW_EVENT_NOTIFICATIONS';
