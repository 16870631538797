import { Requests } from 'src/_api/types/requests';
import UploadRequester from '../../requesters/uploadRequester';
import BaseRequester from '../../requesters/baseRequester';

export const loadFileInfo = (params) => {
  const requester = new BaseRequester(params.url);

  return requester.request({
    method: 'HEAD',
    params,
  });
};
