import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { timeToLineMinutes } from './timeline';
import classNames from 'classnames';
import { minutesToTime, timeToMinutes } from 'src/_utils/date';
import { EventCreateFieldsType, SetValueType } from '../../const';

interface ComparingTimeSelectorProps {
  values: EventCreateFieldsType;
  setValue: SetValueType;
  busyParts: [number, number][];
}

const ComparingTimeSelector: FC<ComparingTimeSelectorProps> = ({
  values,
  setValue,
  busyParts,
}) => {
  const {
    timeStart,
    timeEnd,
    dateStart: dateStart1,
    dateEnd: dateEnd1,
  } = values;

  const [dateStart, setDateStart] = useState(dateStart1);
  const [dateEnd, setDateEnd] = useState(dateEnd1);
  useEffect(() => {
    const st = moment(dateStart1, 'DD.MM.YYYY');
    const en = moment(dateEnd1, 'DD.MM.YYYY');

    if (st.format() !== 'Invalid date' && en.format() !== 'Invalid date') {
      setDateStart(dateStart1);
      setDateEnd(dateEnd1);
    }
  }, [dateStart1, dateEnd1]);

  const [localTimeStart, setLocalTimeStart] = useState(timeStart);
  const [localTimeEnd, setLocalTimeEnd] = useState(timeEnd);
  const [localDateStart, setLocalDateStart] = useState(dateStart);
  const [localDateEnd, setLocalDateEnd] = useState(dateEnd);

  const isMouseDown = useRef(''); // -1 - left, 1 - right
  const prevMouseX = useRef(-1);

  const eventTime = useMemo(() => {
    if (!timeStart || !timeEnd) {
      return null;
    }

    const start = moment(localDateStart, 'DD.MM.YYYY');
    const end = moment(localDateEnd, 'DD.MM.YYYY');

    start.set('h', Number(localTimeStart.split(':')[0]));
    start.set('m', Number(localTimeStart.split(':')[1]));

    end.set('h', Number(localTimeEnd.split(':')[0]));
    end.set('m', Number(localTimeEnd.split(':')[1]));

    return {
      start,
      end,
      lineStart: timeToLineMinutes(start.format(), dateStart, 7 * 24 * 60),
      lineEnd: timeToLineMinutes(end.format(), dateStart, 7 * 24 * 60),
    };
  }, [localDateStart, localDateEnd, localTimeStart, localTimeEnd]);

  const isColliding = useMemo(() => {
    if (!eventTime) return false;

    let res = false;
    for (let i = 0; i < busyParts.length; i++) {
      if (
        (eventTime.lineStart <= busyParts[i][0] &&
          eventTime.lineEnd >= busyParts[i][0]) ||
        (eventTime.lineStart <= busyParts[i][1] &&
          eventTime.lineEnd >= busyParts[i][1]) ||
        (eventTime.lineStart >= busyParts[i][0] &&
          eventTime.lineEnd <= busyParts[i][1])
      ) {
        res = true;

        break;
      }
    }

    return res;
  }, [eventTime, busyParts]);

  useEffect(() => {
    setLocalDateStart(dateStart);
    setLocalDateEnd(dateEnd);
    if (timeStart) setLocalTimeStart(timeStart);
    if (timeEnd) setLocalTimeEnd(timeEnd);
  }, [dateStart, dateEnd, timeStart, timeEnd]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (localTimeStart !== timeStart) {
        let hours: string | number = localTimeStart.split(':')[0];
        let minutes = Number(localTimeStart.split(':')[1]);
        const diff = minutes % 5;
        if (diff >= 3) {
          minutes += 5 - diff;
        } else {
          minutes -= diff;
        }

        if (minutes >= 60) {
          hours = Number(hours) + 1;
          minutes = 0;
        }

        let newTime = `${hours}:${minutes > 9 ? minutes : '0' + minutes}`;

        if (timeToMinutes(newTime) == timeToMinutes(localTimeEnd)) {
          newTime = minutesToTime(timeToMinutes(localTimeEnd) - 5);
          setLocalTimeStart(newTime);
        }

        setValue('timeStart', newTime);
      }

      if (localTimeEnd !== timeEnd) {
        let hours: string | number = localTimeEnd.split(':')[0];
        let minutes = Number(localTimeEnd.split(':')[1]);
        const diff = minutes % 5;
        if (diff >= 3) {
          minutes += 5 - diff;
        } else {
          minutes -= diff;
        }

        if (minutes >= 60) {
          hours = Number(hours) + 1;
          minutes = 0;
        }

        let newTime = `${hours}:${minutes > 9 ? minutes : '0' + minutes}`;

        if (timeToMinutes(newTime) == timeToMinutes(localTimeStart)) {
          newTime = minutesToTime(timeToMinutes(localTimeStart) + 5);
          setLocalTimeEnd(newTime);
        }

        setValue('timeEnd', newTime);
      }

      if (localDateStart !== dateStart) setValue('dateStart', localDateStart);

      if (localDateEnd !== dateEnd) setValue('dateEnd', localDateEnd);
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [localTimeStart, localTimeEnd, localDateStart, localDateEnd]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  const onMouseDown = (type: 'left' | 'right' | 'mid') => {
    isMouseDown.current = type;
  };

  const onMouseUp = () => {
    isMouseDown.current = '';
  };

  const onMouseMove = (e) => {
    if (prevMouseX.current == -1 || !isMouseDown.current) {
      prevMouseX.current = e.clientX;

      return;
    }

    const diff = -(prevMouseX.current - e.clientX);
    prevMouseX.current = e.clientX;

    if (isMouseDown.current === 'mid') {
      const start = moment(eventTime.start).toDate();
      start.setMinutes(Number(start.getMinutes()) + diff);
      const end = moment(eventTime.end).toDate();
      end.setMinutes(Number(end.getMinutes()) + diff);

      setLocalTimeStart(
        `${
          start.getHours() >= 10 ? start.getHours() : '0' + start.getHours()
        }:${
          start.getMinutes() >= 10
            ? start.getMinutes()
            : '0' + start.getMinutes()
        }`,
      );
      setLocalTimeEnd(
        `${end.getHours() >= 10 ? end.getHours() : '0' + end.getHours()}:${
          end.getMinutes() >= 10 ? end.getMinutes() : '0' + end.getMinutes()
        }`,
      );

      return;
    }

    const time =
      isMouseDown.current === 'left'
        ? moment(eventTime.start).toDate()
        : moment(eventTime.end).toDate();
    time.setMinutes(Number(time.getMinutes()) + diff);

    if (isMouseDown.current === 'left') {
      let newTime = `${
        time.getHours() >= 10 ? time.getHours() : '0' + time.getHours()
      }:${
        time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes()
      }`;

      if (timeToMinutes(newTime) > timeToMinutes(localTimeEnd)) {
        newTime = localTimeStart;
      }

      setLocalTimeStart(newTime);
    } else {
      let newTime = `${
        time.getHours() >= 10 ? time.getHours() : '0' + time.getHours()
      }:${
        time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes()
      }`;

      if (timeToMinutes(newTime) < timeToMinutes(localTimeStart)) {
        newTime = localTimeEnd;
      }

      setLocalTimeEnd(newTime);
    }
  };

  return (
    <div
      className={classNames('comparing-time-selector', {
        colliding: isColliding,
      })}
      style={{
        left: eventTime.lineStart - 1,
        width: eventTime.lineEnd - eventTime.lineStart + 1,
      }}
      onMouseDown={() => onMouseDown('mid')}
    >
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
          onMouseDown('left');
        }}
        className='comparing-time-selector-left'
      />
      <div
        onMouseDown={(e) => {
          e.stopPropagation();
          onMouseDown('right');
        }}
        className='comparing-time-selector-right'
      />
    </div>
  );
};
export default ComparingTimeSelector;
