import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from 'src/_api/api';
import { setAddresbookContactsApi } from '../store/actions/setAddressbookContacts';
import { LOAD_ADDRESSBOOK_API } from '../store/actionTypes';
import { LoadAddressbookContactsApiAction } from '../store/types';
import addressbookActions from '../store/actions';
import { CStoreState } from '../../_redux/types';
import { getFormattedContacts } from './utils';

function* fetchAddressbookContacts(action: LoadAddressbookContactsApiAction) {
  const { lookup } = action.payload;
  const { query } = action.payload;
  try {
    if (!query) {
      const cacheItems = yield select(
        (s: CStoreState) => s.addressbook.cache[lookup],
      );
      if (cacheItems) {
        yield put(
          setAddresbookContactsApi.action({
            contacts: cacheItems,
            status: 'success',
          }),
        );

        return;
      }
    }
    yield put(
      setAddresbookContactsApi.action({
        status: 'loading',
      }),
    );

    yield put(addressbookActions().unsetSelectedContact());

    const { data } = yield call(api('getAddreebookContacts'), {
      params: {
        lookup,
        search_by: 'all_fields',
        ...(query && { query }),
      },
    });

    const items = data?.items || [];

    let next = data.next;

    // const MAX_PAGES = 0;
    const MAX_PAGES = 5;
    // TODO: refactor this shit (бэк пока не может по кайфу юзеров отдавать с пагинацией)
    for (let i = 0; i < MAX_PAGES; i++) {
      if (!next) break;
      const { data } = yield call(api('getAddreebookContacts'), {
        params: {
          lookup,
          search_by: 'all_fields',
          ...(query && { query }),
        },
        headers: { next },
      });

      if (data?.items) {
        items.push(...data.items);
      }

      next = data.next;
    }

    yield put(
      setAddresbookContactsApi.action({
        contacts: getFormattedContacts(items),
        status: 'success',
        ...(!query && {
          cache: {
            [lookup]: getFormattedContacts(items),
          },
        }),
      }),
    );
  } catch (e) {
    yield put(
      setAddresbookContactsApi.action({
        status: 'error',
        contacts: [],
      }),
    );
  }
}

export function* fetchAddressbookContactsSaga(): Generator {
  yield fetchAddressbookContacts({
    type: LOAD_ADDRESSBOOK_API,
    payload: {
      lookup: 'people',
    },
  });

  yield takeLatest(LOAD_ADDRESSBOOK_API, fetchAddressbookContacts);
}
