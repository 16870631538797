import moment from 'moment';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { addEvent } from 'src/eventsDisplay/store/actions/addEvent';
import { forceLoadEvents } from 'src/eventsDisplay/store/actions/forceLoadEvents';
import api from 'src/_api/api';
import { transformCalDavDate } from 'src/_utils/caldav';
import { getCalendarFullMonthDays } from 'src/_utils/date';
import { resetEventCreateEdit } from '../store/actions/resetEventCreateEdit';
import { requestEventCreateFail } from '../store/actions/requestEventCreateFail';
import { requestEventCreateSuccess } from '../store/actions/requestEventCreateSuccess';
import { EVENT_CREATE_REQUEST } from '../store/actionTypes';
import { RequestEventCreateAction } from '../store/types';
import { closeFloatingModal } from 'src/floatingModal/store/actions/closeFloatingModal';
import controlsSelectedMonthSelector from 'src/controls/store/selectors/controlsSelectedMonthSelector';
import { addSnack } from '../../_redux/slices/snackbar';
import calendarApi from '../../services/calendar';

export function* sendEventCreateRequest(
  action: RequestEventCreateAction,
): Generator {
  const selectedMonth = (yield select(
    controlsSelectedMonthSelector,
  )) as unknown as Date;
  const days = getCalendarFullMonthDays(selectedMonth);

  try {
    const { data } = (yield call(api('eventCreate'), {
      url: {
        owner: 'me',
        calendarId: 'private',
      },
      body: {
        events: [action.payload],
      },
    })) as any;

    const eventStart = new Date(
      transformCalDavDate(action.payload.start.value),
    );
    const eventEnd = new Date(
      transformCalDavDate(
        action.payload.end?.value
          ? action.payload.end.value
          : action.payload.start.value,
      ),
    );

    if (action.payload.recurrence) {
      yield put(forceLoadEvents.action(true));
    } else if (
      moment(eventStart).isBetween(
        days[0],
        days[days.length - 1],
        null,
        '[]',
      ) ||
      moment(eventEnd).isBetween(days[0], days[days.length - 1], null, '[]')
    ) {
      yield put(
        addEvent.action({
          ...data,
          ...action.payload,
        }),
      );
    }

    yield put(requestEventCreateSuccess.action());
    yield put(resetEventCreateEdit.action());
    yield put(closeFloatingModal.action());
    yield put(
      addSnack({
        message: 'Событие успешно создано',
        type: 'success',
      }),
    );
    yield put(calendarApi.util.invalidateTags(['notification-events']));
  } catch (e) {
    yield put(
      addSnack({
        message: 'Не удалось создать событие — попробуйте ещё раз',
        type: 'error',
      }),
    );

    yield put(requestEventCreateFail.action());
  }
}

export function* handleEventCreateRequestSaga(): Generator {
  yield takeEvery(EVENT_CREATE_REQUEST, sendEventCreateRequest);
}
