import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const calendarList = (data: Requests['CalendarList']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'CalendarList'>({
    additionalUrl: `/${data.url.owner}/calendars`,
    method: 'GET',
  });
};
