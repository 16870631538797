import { FC } from 'react';
import { AddressbookContact } from 'src/_api/types/mailId/entities';
import { Avatar } from './avatar';

import './styles.less';

interface Props {
  contact: AddressbookContact;
  onContactClicked: (contact: AddressbookContact) => void;
}

export const MailingContactOverview: FC<Props> = ({
  contact,
  onContactClicked,
}) => (
  <div
    className='mailing-contact-overview__root'
    onClick={() => onContactClicked(contact)}
  >
    <div className={'mailing-contact-overview__avatar_wrapper'}>
      <Avatar contact={contact} />
    </div>
    <div className={'mailing-contact-overview__display-name'}>
      <span>{contact?.display_name}</span>
      <span className={'mailing-contact-overview__email'}>{contact.email}</span>
    </div>
  </div>
);
