// *modulism-group rrule-getRruleHumanString
import moment from 'moment';
import {
  monthsForRecurrenceHelp,
  weekDaysForRecurrenceHelp,
} from '../../_constants';
import { declOfNum } from '../declOfNum';
import { parseRruleData } from './parse';

import { ParsedDate, ParsedPeriod, ParsedWeekly } from './types';
import {
  generateDateRule,
  generatePeriodRule,
  generateWeekRule,
} from './generateRule';

moment.locale('ru');

export const getRruleHumanString = (params: {
  recurrence?: string[];
  start: string;
  tense: 'present' | 'future';
  data?: Record<string, any>;
  withCount?: boolean;
}) => {
  const withCount =
    typeof params.withCount === 'boolean' ? params.withCount : true;
  const _data = params.recurrence
    ? parseRruleData(params.recurrence)
    : params.data;

  switch (_data.type) {
    case 'DATE': {
      const data = _data as ParsedDate;

      if (!data.until) {
        return '';
      }

      const date =
        data.monthDay ||
        Number(moment(params.start, 'DD.MM.YYYY').format('DD'));

      const rule = generateDateRule({
        start: params.start,
        date,
        untilDate: data.until,
        interval: data.selectedMonths?.length ? 1 : data.ndate,
        selectedMonths: data.selectingMonths ? data.selectedMonths : undefined,
      });

      const resCount = withCount && data.until ? rule.count() : 0;

      return (
        (params.tense === 'present' ? 'Повторяется ' : 'Будет повторяться ') +
        ((data.ndate === 1 && !data.selectingMonths) ||
        (data.selectingMonths && !data.selectedMonths.length)
          ? 'ежемесячно '
          : '') +
        date +
        ' числа ' +
        (!data.selectingMonths && data.ndate !== 1
          ? `каждого ${data.ndate} месяца `
          : '') +
        (data.selectedMonths?.length
          ? `в ${data.selectedMonths
              .map((n) => monthsForRecurrenceHelp[n - 1])
              .join(data.selectedMonths?.length > 2 ? ', ' : ' и ')} `
          : '') +
        (data.until
          ? `до ${moment(data.until, 'DD.MM.YYYY').format('Do MMMM YYYY г.')}`
          : '') +
        (withCount && data.until
          ? ` — ${resCount} ${declOfNum(resCount, [
              'повтор',
              'повтора',
              'повторов',
            ])} `
          : '')
      ).trimEnd();
    }
    case 'PERIOD': {
      const data = _data as ParsedPeriod;

      if (!data.until) {
        return '';
      }

      const rule = generatePeriodRule({
        start: params.start,
        untilDate: data.until,
        interval: data.count,
      });

      const resCount = withCount && data.until ? rule.count() : 0;

      return (
        (params.tense === 'present' ? 'Повторяется ' : 'Будет повторяться ') +
        (data.count === 1
          ? 'каждый день '
          : `каждые ${data.count} ${declOfNum(data.count, [
              'день',
              'дня',
              'дней',
            ])} `) +
        (data.until
          ? `до ${moment(data.until, 'DD.MM.YYYY').format('Do MMMM YYYY г.')}`
          : '') +
        (withCount && data.until
          ? ` — ${resCount} ${declOfNum(resCount, [
              'повтор',
              'повтора',
              'повторов',
            ])}`
          : '')
      ).trimEnd();
    }
    case 'WEEK': {
      const data = _data as ParsedWeekly;

      if (!data.until) {
        return '';
      }

      const rule = generateWeekRule({
        start: params.start,
        untilDate: data.until,
        selectedDays: data.selectedDays,
        selectedWeeks: data.selectedWeeks,
        selectedMonths: data.selectedMonths,
      });

      const resCount = withCount && data.until ? rule.count() : 0;

      return (
        (params.tense === 'present' ? 'Повторяется ' : 'Будет повторяться ') +
        (data.selectedDays?.length > 1
          ? `каждую неделю по ${data.selectedDays
              .map((n) => weekDaysForRecurrenceHelp[n].short)
              .join(data.selectedDays.length > 2 ? ', ' : ' и ')} `
          : `по ${weekDaysForRecurrenceHelp[data.selectedDays[0]]?.long} `) +
        (data.selectedWeeks.length
          ? `каждую ${data.selectedWeeks
              .map((n) => (n === -1 ? 'последнюю' : n))
              .join(data.selectedWeeks.length > 2 ? ', ' : ' и ')} неделю `
          : '') +
        (data.selectedMonths.length
          ? `в ${data.selectedMonths
              .map((n) => monthsForRecurrenceHelp[n - 1])
              .join(data.selectedMonths.length > 2 ? ', ' : ' и ')} `
          : '') +
        (data.until
          ? `до ${moment(data.until, 'DD.MM.YYYY').format('Do MMMM YYYY г.')}`
          : '') +
        (withCount && data.until
          ? ` — ${resCount} ${declOfNum(resCount, [
              'повтор',
              'повтора',
              'повторов',
            ])} `
          : '')
      ).trimEnd();
    }
    default:
      return '';
  }
};
