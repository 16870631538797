import { select } from 'redux-saga/effects';
import api from 'src/_api/api';
import { CStoreState } from '../../_redux/types';
import { Upload } from '../../_api/types/entities';
import { setAttachiInfoAction } from '../store/actions/setAttachiInfoAction';
import { dispatch } from '../../_redux';

export function* loadFileInfoSaga(): Generator {
  const attachi = yield select(
    (s: CStoreState) => s.eventCreateEdit.fieldsData.attachi
  );

  if (!attachi) return;

  const files = Object.keys(attachi).map(
    (key) => attachi[key]
  ) as Array<Upload>;

  if (Array.isArray(files) && files.length > 0) {
    for (const f of files) {
      if (f.downloadUrl.startsWith('attach://')) continue;

      api('loadFileInfo')({ url: f.downloadUrl }).then((response) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cl = Number(response.headers['content-length']);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (cl) {
          dispatch(
            setAttachiInfoAction.action({
              downloadUrl: f.downloadUrl,
              size: cl,
            })
          );
        }
      });
    }
  }
}
