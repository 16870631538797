import moment from 'moment';
import { Task } from 'redux-saga';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { forceLoadEvents } from 'src/eventsDisplay/store/actions/forceLoadEvents';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { setEventView } from 'src/eventView/store/actions/setEventView';
import { forceLoadNewEventNotifications } from 'src/newEventNotifications/store/actions/forceLoadNewEventNotifications';
import { closeWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/closeWhiteLoadingScreen';
import { openWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/openWhiteLoadingScreen';
import api from 'src/_api/api';
import { Event } from 'src/_api/types/entities';
import { transformCalDavDate, transformDateAndTime } from 'src/_utils/caldav';
import { EVENT_VIEW_ATTENDEE_TRANSPARENCY_CHANGE } from '../store/actionTypes';
import selectEventViewEvent from '../store/selectors/selectEventViewEvent';
import { AttendeeTransparencyChangeEventViewAction } from '../store/types';
import { addSnack } from '../../_redux/slices/snackbar';

function* whiteLoadingScreenDelay() {
  yield delay(1000);
  yield put(openWhiteLoadingScreen.action());
}

export function* proccessEventAttendeeTransparencyChange(
  transp: Event['transparency']
): Generator {
  const event = (yield select(selectEventViewEvent)) as SelectedEvent;

  const whiteLoadingScreenTimeout = (yield fork(
    whiteLoadingScreenDelay
  )) as unknown as Task;
  let events: Event[] = [];
  try {
    const { data }: any = yield call(api('eventGet'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        eventId: event.id,
      },
      headers: {},
    });

    events = data.events;
  } catch (e) {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Не удалось отредактировать событие',
        type: 'error',
      })
    );

    return;
  }

  const masterEvent = events.find((d) => !d.recurrenceId);

  const sourceRecurenceId = event.source.recurrenceId;
  const isTypeDate = masterEvent.start.type === 'DATE';

  let currentOverridingEvent: Event | null = null;
  let currentOverridingEventIndex = -1;
  if (events.length > 1 && sourceRecurenceId) {
    const recurIdTimeStartUtc =
      isTypeDate || !sourceRecurenceId
        ? undefined
        : moment(transformCalDavDate(sourceRecurenceId.value)).utc();
    if (recurIdTimeStartUtc) {
      for (const i in events) {
        if (!events[i].recurrenceId) continue;
        const recurIdTimeStartUtcFormatted = transformDateAndTime(
          recurIdTimeStartUtc
            .tz(events[i].recurrenceId.timeZone)
            .format('DD.MM.YYYY'),
          recurIdTimeStartUtc
            .tz(events[i].recurrenceId.timeZone)
            .format('HH:mm')
        );
        if (recurIdTimeStartUtcFormatted === events[i].recurrenceId.value) {
          currentOverridingEvent = { ...events[i] };
          currentOverridingEventIndex = Number(i);
          break;
        }
      }
    }
  }

  if (currentOverridingEvent) {
    events[currentOverridingEventIndex] = {
      ...currentOverridingEvent,
      transparency: transp,
    };
  } else {
    const masterEventIndex = events.findIndex((d) => !d.recurrenceId);
    events[masterEventIndex] = {
      ...masterEvent,
      transparency: transp,
    };
  }

  try {
    yield call(api('eventUpdate'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        eventId: masterEvent.id,
      },
      headers: {},
      body: {
        events: [...events],
      },
    });

    yield put(
      setEventView.action({
        event: {
          ...event,
          source: {
            ...event.source,
            transparency: transp,
          },
        },
      })
    );
    yield put(forceLoadEvents.action(true));
    yield put(forceLoadNewEventNotifications.action());
  } catch (e) {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Не удалось отредактировать событие',
        type: 'error',
      })
    );
  } finally {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
  }
}

export function* handleEventAttendeeTransparencyChangeSaga(): Generator {
  yield takeEvery(
    EVENT_VIEW_ATTENDEE_TRANSPARENCY_CHANGE,
    (a: AttendeeTransparencyChangeEventViewAction) =>
      proccessEventAttendeeTransparencyChange(a.payload)
  );
}
