/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { CloseSmallIcon } from 'src/_components/Svg';
import eventViewActions from '../../store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import IconButton from 'src/_components/IconButton';
import { shallowEqual, useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import { CUSTOM_EVENTS, EVENT_STATUS, MOBILE_MAX_WIDTH } from 'src/_constants';
import {
  parseRruleData,
  NO_SUPPORT_RESULT,
  getRruleHumanString,
} from 'src/_utils/recurrence';
import EventBlockOrganizerActions from './organizerActions';
import { AttachiBlock } from './attachiBlock';

import EventBlockAttendeeActions from './attendeeActions';
import EventBlockAttendees from './attendees';
import EventBlockAttendeeTransparency from './attendeeTransparency';

import '@rambler-components/tooltip/styles.css';
import '@rambler-components/dropdown/styles.css';
import '@rambler-components/button/styles.css';
import { ALARM_OPTIONS } from '../../../eventCreateEdit/const';

import css from './EventBlock.module.less';
import cn from 'classnames';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';
import { wrapLinksInAnchorTags } from 'src/_utils/dom';

moment.locale('ru');

interface EventViewBlockProps {
  withClose: boolean;
  width?: string;
  withoutShadow?: boolean;
}

export const MAX_EVENT_DESCRIPTION_LENGTH = 221;

const EventViewBlock: FC<EventViewBlockProps> = ({
  withClose,
  width,
  withoutShadow,
}) => {
  const { event, profileEmail } = useSelector(
    (s: CStoreState) => ({
      event: s.eventView.event,
      profileEmail: s.apiCommonData.profile?.info?.email.toLowerCase(),
    }),
    shallowEqual,
  );
  const isOrganizer = useMemo(() => {
    const userUri = `mailto:${profileEmail}`;

    return (
      event &&
      ((event.source.organizer &&
        event.source.organizer.uri.toLowerCase() === userUri) ||
        !event.source.organizer)
    );
  }, [event]);

  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [attendeesExpanded, setAttendeesExpanded] = useState(false);
  const { width: windowWidth } = useWindowSize();

  const alarmOption = useMemo(() => {
    const alarm = event?.alarms?.at(0);

    const index = ALARM_OPTIONS.findIndex(
      (e) => e.value === alarm?.trigger?.relative,
    );

    if (index === ALARM_OPTIONS.length - 1) return '';

    return ALARM_OPTIONS[index]?.option;
  }, [event?.alarms]);

  const recurrenceString = useMemo(() => {
    if (!event) return '';
    if (event.source.recurrence && event.source.recurrence.length) {
      const data = parseRruleData(event.source.recurrence);
      const start = moment(event.start).format('DD.MM.YYYY');

      switch (data.type) {
        case 'DATE': {
          return getRruleHumanString({
            start,
            tense: 'present',
            withCount: false,
            data: {
              type: 'DATE',
              ndate: data.ndate,
              selectingMonths: data.selectingMonths,
              selectedMonths: data.selectedMonths,
              monthDay:
                data.monthDay ||
                Number(moment(start, 'DD.MM.YYYY').format('DD')),
              until: data.until,
            },
          });
        }
        case 'PERIOD': {
          return getRruleHumanString({
            start,
            tense: 'present',
            withCount: false,
            data: {
              type: 'PERIOD',
              count: data.count,
              until: data.until,
            },
          });
        }
        case 'WEEK': {
          return getRruleHumanString({
            start,
            tense: 'present',
            withCount: false,
            data: {
              type: 'WEEK',
              selectedDays: data.selectedDays,
              selectedWeeks: data.selectedWeeks,
              selectedMonths: data.selectedMonths,
              until: data.until,
            },
          });
        }
        case NO_SUPPORT_RESULT.type: {
          return '';
        }
      }
    }
  }, [event]);

  useEffect(() => {
    if (!event) {
      setDescriptionExpanded(false);
      setAttendeesExpanded(false);
    }
  }, [event]);

  if (!event) return null;

  const expandDesciption = () => {
    setDescriptionExpanded(true);
    setTimeout(() => {
      const ev = new Event(CUSTOM_EVENTS.floatingModalRecalculate, {
        bubbles: true,
      });
      window.dispatchEvent(ev);
    });
  };

  const isLongDescription: boolean =
    event.description?.length > MAX_EVENT_DESCRIPTION_LENGTH + 20;

  const eventDescription =
    isLongDescription && !descriptionExpanded
      ? event.description.slice(0, MAX_EVENT_DESCRIPTION_LENGTH) + '...'
      : event.description;

  return (
    <div
      className={cn(css.eventViewBlock)}
      style={{
        width: windowWidth <= MOBILE_MAX_WIDTH ? '100vw' : width || '450px',
        boxShadow: withoutShadow ? 'unset' : undefined,
        overflowY:
          window.innerHeight > 800
            ? descriptionExpanded || attendeesExpanded
              ? 'auto'
              : 'visible'
            : 'visible',
      }}
    >
      <div
        className={cn(
          css.wrapper,
          windowWidth <= MOBILE_MAX_WIDTH
            ? 'hide-scrollbar'
            : 'custom-scroll-bar',
        )}
      >
        <div className={css.eventViewWindowHeader}>
          <h3>
            {event.source.status === EVENT_STATUS.cancelled ? 'Отменено: ' : ''}
            {event?.summary}
          </h3>
          {withClose && (
            <div className={css.eventViewWindowHeader__closeIc}>
              <IconButton
                icon={<CloseSmallIcon />}
                onClick={() => {
                  eventViewActions().setEventView({ event: null });
                  floatingModalActions().closeFloatingModal();
                }}
              />
            </div>
          )}
        </div>
        <div>
          <div style={{ marginBlock: 15 }}>
            <p className={css.eventStrongText}>
              {!!event &&
                `${moment(event.date).format('DD MMMM')}, ${
                  event.type === 'wholeDay'
                    ? 'Весь день'
                    : `${moment(event.start).format('HH:mm')} - ${moment(
                        event.end,
                      ).format('HH:mm')}`
                }`}
            </p>
            {recurrenceString && (
              <p className={css.eventViewRecurrence}>{recurrenceString}</p>
            )}
          </div>
          {!!event && !!event.description && (
            <p className={css.eventViewDescription}>
              <span
                dangerouslySetInnerHTML={{
                  __html: wrapLinksInAnchorTags(eventDescription),
                }}
              />
              {isLongDescription && !descriptionExpanded && (
                <span
                  className={css.eventViewExpandDescription}
                  onClick={expandDesciption}
                >
                  Развернуть
                </span>
              )}
            </p>
          )}
          {event.attendee?.length > 1 ||
          event.location ||
          event?.source?.alarms?.length ||
          event.source?.attach ? (
            <div className={css.eventInfoBlock}>
              <EventBlockAttendees
                attendees={event.attendee || []}
                expanded={attendeesExpanded}
                setExpand={setAttendeesExpanded}
                organizer={event.source.organizer}
              />
              {!!event.location && (
                <div>
                  <p className={css.eventStrongText}>Место проведения</p>
                  <p
                    className={css.eventText}
                    dangerouslySetInnerHTML={{
                      __html: wrapLinksInAnchorTags(event.location),
                    }}
                  />
                </div>
              )}
              {alarmOption && (
                <div>
                  <p className={css.eventStrongText}>Напоминание</p>
                  <p className={css.eventText}>За {alarmOption} до события</p>
                </div>
              )}
              {!!(
                isOrganizer &&
                event.attendee &&
                event.attendee.length &&
                event.source.transparency &&
                event.source.transparency === 'TRANSPARENT'
              ) && <p className={css.eventText}>Статус участников: Свободен</p>}
              {!isOrganizer && (
                <div>
                  <EventBlockAttendeeTransparency
                    transp={event.source.transparency || 'OPAQUE'}
                  />
                </div>
              )}
              {event.source?.attach && (
                <div>
                  <AttachiBlock
                    a={event.source.attach}
                    ai={event.attachiInfo || []}
                  />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          {isOrganizer ? (
            <EventBlockOrganizerActions event={event} />
          ) : (
            <EventBlockAttendeeActions event={event} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventViewBlock;
