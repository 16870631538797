import { FC, useState } from 'react';
import { printSize } from 'src/_utils/size';
import UploadFile from './UploadFile';
import { Tick, TickUp } from 'src/_components/Svg';
import classnames from 'classnames';
import ButtonWithIcon from 'src/_components/IconButton';
import css from './Upload.module.less';

interface UploadsProps {
  removeFileHandler: (v: string) => void;
  uploads: any[];
}

const Uploads: FC<UploadsProps> = ({ uploads, removeFileHandler }) => {
  const [isOpened, setOpened] = useState<boolean>(true);

  const togglerMessage = !isOpened
    ? 'Свернуть'
    : `Показать все ${uploads.length}  ${
        uploads.length in [3, 4] ? 'файла' : 'файлов'
      }`;

  return (
    <div
      className={classnames(css.uploads, { [css.uploadsHidden]: !isOpened })}
    >
      <div className={css.items}>
        <div className={css.files}>
          {uploads
            .filter((u, i) => !isOpened || i < 3)
            .map(({ type, size, name, loadedPercent, id, downloadUrl }) => (
              <UploadFile
                key={id}
                onClick={() => removeFileHandler(id)}
                loadedPercent={loadedPercent}
                type={type}
                name={name}
                size={printSize(size)}
                downloadUrl={downloadUrl}
              />
            ))}
        </div>
      </div>
      {uploads.length > 3 ? (
        <div className={css.head}>
          <span className={css.heading} onClick={() => setOpened(!isOpened)}>
            <span className={css.count}>{togglerMessage}</span>
            <ButtonWithIcon
              onClick={() => setOpened(!isOpened)}
              className={css.button}
              style={{
                display: 'inline-block',
                marginLeft: '5px',
              }}
              icon={!isOpened ? <TickUp /> : <Tick />}
            />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Uploads;
