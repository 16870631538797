import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_MODE_ALL } from '../actionTypes';
import { ControlsState, SelectControlsModeAllAction } from '../types';

export const selectControlsModeAll = createAction<
  SelectControlsModeAllAction,
  ControlsState
>(SELECT_CONTROLS_MODE_ALL, (state) => ({
  ...state,
  selectedDateRange: null,
  mode: 'all',
}));
