import { FC, useCallback, useRef } from 'react';
import { AttendeesField } from './AttendeesField';
import EventCreateTitle from './Title';
import EventCreateRange from './Range';
import EventCreateRecurrence from './Recurrency';
import EventCreateRoom from './Room';
import EventCreateDescription from './Description';
import EventCreateReminder from './Reminder';
import EventCreateTransparency from './Transparency';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import EventCreateComparing from '../Comparing';
import { AttachIcon } from 'src/_components/Svg';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';

import { EventCreateFieldsType, SetValueType } from '../../const';

import css from './Fields.module.less';
import { EVENT_CREATE_EDIT_ERRORS } from '../../constants';

interface Props {
  values: EventCreateFieldsType;
  setValue: SetValueType;
  errors: {
    [key: string]: string[];
  };
}

const EventCreateFields: FC<Props> = ({ values, errors, setValue }) => {
  const { fieldsHidden, isComparing } = useSelector((s: CStoreState) => ({
    fieldsHidden: s.eventCreateEdit.fieldsHidden,
    isComparing: s.eventCreateEdit.comparingCalendarsData.isComparing,
  }));

  const {
    attendees,
    alarms,
    dateEnd,
    dateStart,
    description,
    recurrence,
    room,
    title,
    timeEnd,
    timeStart,
    transparency,
    wholeDay,
  } = values;

  const fileInput = useRef(null);
  const attachFileHandler = () => {
    if (fileInput.current.value !== '') {
      fileInput.current.value = '';
    }
    fileInput.current.click();
  };

  const onToggleWholeDay = (checked: boolean) => {
    setValue('wholeDay', checked);

    if (checked) {
      setValue('timeStart', null);
      setValue('timeEnd', null);

      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'timeEnd',
        error: EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'],
      });
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'timeStart',
        error: EVENT_CREATE_EDIT_ERRORS['noTimeStart'],
      });
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'timeEnd',
        error: EVENT_CREATE_EDIT_ERRORS['noTimeEnd'],
      });
    }
  };

  const setRecurrence = useCallback(
    (recurrence) => setValue('recurrence', recurrence),
    [setValue],
  );

  return (
    <div>
      {isComparing ? (
        <EventCreateComparing values={values} setValue={setValue} />
      ) : (
        <AttendeesField
          attendees={attendees}
          onChange={(attendees) => setValue('attendees', attendees)}
        />
      )}
      <div className={css.titleAndRange}>
        <div>
          <EventCreateTitle
            title={title}
            errors={errors['title']}
            onChange={(title) => setValue('title', title)}
          />
        </div>
        <div>
          <EventCreateRange
            dateStart={dateStart}
            timeStart={timeStart}
            dateEnd={dateEnd}
            timeEnd={timeEnd}
            wholeDay={wholeDay}
            setValue={setValue}
          />
        </div>
      </div>
      <div style={{ maxWidth: isComparing ? '637px' : undefined }}>
        <EventCreateRecurrence
          recurrence={recurrence}
          setRecurrence={setRecurrence}
          wholeDay={wholeDay}
          setWholeDay={onToggleWholeDay}
          dateStart={dateStart}
        />
        <EventCreateRoom
          room={room}
          setRoom={(room) => setValue('room', room)}
        />
        {/*<EventCreateJazzLink />*/}
        {!fieldsHidden && (
          <>
            <EventCreateDescription
              description={description}
              setDescription={(description) =>
                setValue('description', description)
              }
            />
            <EventCreateReminder
              alarms={alarms}
              setAlarms={(alarms) => setValue('alarms', alarms)}
            />
            <EventCreateTransparency
              transparency={transparency}
              setTransparency={(transparency) =>
                setValue('transparency', transparency)
              }
            />
          </>
        )}
        {isComparing && (
          <div
            onClick={attachFileHandler}
            className={css.eventCreateButtonBlue}
            style={{ marginTop: 20 }}
          >
            <AttachIcon />
            <span>Прикрепить файл</span>
          </div>
        )}
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          type='file'
          onChange={() => {
            const files = Array.prototype.slice.call(fileInput.current.files);
            eventCreateEditActions().addAttachi(files);
          }}
          multiple
        />
      </div>
    </div>
  );
};

export default EventCreateFields;
