import { getFirstDayInMonth } from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_NEXT_DAY } from '../actionTypes';
import { ControlsState, SelectControlsNextDayAction } from '../types';

export const selectControlsNextDay = createAction<
  SelectControlsNextDayAction,
  ControlsState
>(SELECT_CONTROLS_NEXT_DAY, (state) => {
  const curr = new Date(state.selectedDateRange[0]);

  curr.setDate(curr.getDate() + 1);

  return {
    ...state,
    selectedDateRange: [new Date(curr), new Date(curr)],
    selectedMonth: getFirstDayInMonth(curr),
  };
});
