import { FC, useRef } from 'react';
import cn from 'classnames';

import { SelectedEvent } from 'src/eventsDisplay/store/types';
import eventViewActions from 'src/eventView/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { HelpFillIcon, InfoFillIcon } from 'src/_components/Svg';

import './styles.less';
import {
  EVENT_STATUS,
  TABLET_MAX_WIDTH,
  USER_PART_STATUS,
} from 'src/_constants';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import selectFloatingModalOpened from 'src/floatingModal/store/selectors/selectFloatingModalOpened';
import selectEventViewEvent from 'src/eventView/store/selectors/selectEventViewEvent';
import { isDatesEqual } from 'src/_utils/date';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';

interface DayWeekHeaderEventProps {
  event: SelectedEvent;
  type: 'day' | 'week';
  onClick?: (ev: string) => void;
}

const DayWeekHeaderEvent: FC<DayWeekHeaderEventProps> = ({
  event,
  type,
  onClick,
}) => {
  const isDayMode = useSelector((s: CStoreState) => s.controls.mode === 'day');
  const viewEvent = useSelector(selectEventViewEvent);
  const openedFloatingModal = useSelector(selectFloatingModalOpened);
  const { width } = useWindowSize();
  const attendees = [...event.attendee]
    .sort((a) => (a.uri === event.source.organizer?.uri ? -1 : 0))
    .map((at) => at.name || at.uri.replace('mailto:', ''));
  const ref = useRef<HTMLDivElement>();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const eventDate = new Date(event.date);
  eventDate.setHours(0, 0, 0, 0);
  const isPast = today > eventDate;

  const maxAttendees = type === 'day' && !isDayMode ? 1 : 3;

  const needAction =
    event.id !== 'SHADOW' &&
    event.source.status !== EVENT_STATUS.cancelled &&
    event.userPartStatus === USER_PART_STATUS.needsAction;

  const isTentative = event.userPartStatus === USER_PART_STATUS.tentative;

  return (
    <div
      className={cn('week-header-item-event', {
        shadow: event.id === 'SHADOW',
        past: isPast,
        'partstat-need-action': needAction,
        cancelled: event.source.status === EVENT_STATUS.cancelled,
        tentative: isTentative,
        day: type === 'day',
        base:
          !needAction &&
          !isPast &&
          event.source.status !== EVENT_STATUS.cancelled,
        viewing:
          viewEvent?.source.etag === event.source.etag &&
          isDatesEqual(viewEvent.date, event.date) &&
          openedFloatingModal == FLOATING_MODAL_IDS['eventView'],
      })}
      ref={ref}
      onClick={
        onClick
          ? () => onClick(event.id)
          : () => {
              eventViewActions().setEventView({ event });
              floatingModalActions().openFloatingModal({
                opened: FLOATING_MODAL_IDS['eventView'],
                target: ref.current,
                closeOnScroll: true,
                center: width <= TABLET_MAX_WIDTH,
              });
            }
      }
    >
      {needAction ? (
        <div className='week-header-item-icon'>
          <InfoFillIcon />
        </div>
      ) : isTentative ? (
        <div className='week-header-item-icon'>
          <HelpFillIcon />
        </div>
      ) : (
        ''
      )}
      <div className='week-header-item-event-text'>
        <span className='week-header-item-event-title'>
          {event.source.status === EVENT_STATUS.cancelled ? 'Отменено: ' : ''}
          {event.summary}
          {type === 'day' && (event.location || attendees.length) ? ',' : ''}
        </span>
        {type === 'day' && event.location ? (
          <span className='week-header-item-event-location'>
            {event.location}
            {attendees.length ? ',' : ''}
          </span>
        ) : (
          ''
        )}
        {type === 'day' && attendees.length ? (
          <span className='week-header-item-event-attendees'>
            {attendees.slice(0, maxAttendees).join(', ') + ' '}
            {attendees.length > maxAttendees
              ? `+${attendees.length - maxAttendees}`
              : ''}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default DayWeekHeaderEvent;
