import { Routes, Route } from 'react-router-dom';
import pageCalendar from 'src/pageCalendar';
import NotFound404 from './NotFound404';
import pageContacts from 'src/addressbook/pages';
import { PrintEvent } from 'src/eventView/components/PrintEvent';


const AppRoutes = () => (
  <>
    <Routes>
      <Route path='*' element={<NotFound404 />} />
      <Route path='/calendar/print' element={<PrintEvent />} />
      <Route path='/calendar'>{pageCalendar.map((m) => m)}</Route>
      <Route path='/contacts'>{pageContacts.map((m) => m)}</Route>
    </Routes>
  </>
);

export default AppRoutes;
