import { createAction } from 'src/_utils/redux';
import { SET_PROFILE_API_COMMON_DATA } from '../actionTypes';
import { ApiCommonDataState, SetProfileApiCommonDataAction } from '../types';

export const setProfileApiCommonData = createAction<
  SetProfileApiCommonDataAction,
  ApiCommonDataState
>(SET_PROFILE_API_COMMON_DATA, (state, p) => ({
  contacts: state.contacts,
  profile: p,
}));
