import { createAction } from 'src/_utils/redux';
import { SET_CONTACTS_API_COMMON_DATA } from '../actionTypes';
import { ApiCommonDataState, SetContactsApiCommonDataAction } from '../types';

export const setContactsApiCommonData = createAction<
  SetContactsApiCommonDataAction,
  ApiCommonDataState
>(SET_CONTACTS_API_COMMON_DATA, (state, p) => ({
  contacts: p,
  profile: state.profile,
}));
