import { call, take } from 'redux-saga/effects';
import { FORCE_LOAD_NEW_EVENT_NOTIFICATIONS } from '../store/actionTypes';
import fetchNotifyEvents from './fetchNotifyEvents';

export function* forceLoadNotifyEvents(): Generator {
  while (true) {
    yield take(FORCE_LOAD_NEW_EVENT_NOTIFICATIONS);

    yield call(fetchNotifyEvents);
  }
}
