import { FC, useRef } from 'react';
import cn from 'classnames';

import moment from 'moment';
import Participants from '../participants';

import { SelectedEvent } from 'src/eventsDisplay/store/types';
import eventViewActions from 'src/eventView/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import './styles.less';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import { EVENT_STATUS } from 'src/_constants';

interface Props {
  event: SelectedEvent;
}

const EventView: FC<Props> = ({ event }) => {
  const selectedViewEvent = useSelector((s: CStoreState) => s.eventView.event);
  const summaryRef = useRef();
  const ref = useRef();

  const summary =
    event.source.status === EVENT_STATUS.cancelled
      ? `Отменено: ${event.summary}`
      : event.summary;

  return (
    <div
      ref={ref}
      className={cn('new-event__block', {
        cancelled: event.source.status === EVENT_STATUS.cancelled,
        active: event.source.status !== EVENT_STATUS.cancelled,
      })}
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        eventViewActions().setEventView({ event });
        floatingModalActions().openFloatingModal({
          opened: FLOATING_MODAL_IDS['eventView'],
          target: ref?.current,
          fixedLeft: 280,
          closeOnScroll: true,
        });
      }}
    >
      <div
        className={cn('new-event__summary', {
          selected: selectedViewEvent?.id === event.id,
        })}
        ref={summaryRef}
      >
        {summary.length > 50 ? summary.slice(0, 50) + '...' : summary}
      </div>
      <div className='new-event__date'>
        {!!event &&
          `${moment(event.date).format('DD MMMM')}, ${
            event.type === 'wholeDay'
              ? 'Весь день'
              : `${moment(event.start).format('HH:mm')} - ${moment(
                  event.end,
                ).format('HH:mm')}`
          }`}
      </div>
      {!!event.location && (
        <div className='new-event__location'>
          {event.location.length > 50
            ? event.location.slice(0, 50) + '...'
            : event.location}
        </div>
      )}
      {event.attendee?.length > 0 && (
        <Participants
          isCancelled={event.source.status === EVENT_STATUS.cancelled}
          attendees={event.attendee}
          organizer={event.source.organizer}
        />
      )}
    </div>
  );
};

export default EventView;
