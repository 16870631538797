import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { calendarIdentificationHeaders } from '../../../_api/constants';

export const calendarBaseQuery = fetchBaseQuery({
  baseUrl: '/api/calendar/v1',
  prepareHeaders: (headers) => {
    Object.keys(calendarIdentificationHeaders).forEach((key) =>
      headers.set(key, calendarIdentificationHeaders[key]),
    );

    return headers;
  },
});
