import axios, { AxiosPromise } from 'axios';
import { Requests } from '../types/mailId/requests';

type IRequest<KEY extends keyof Requests> = {
  additionalUrl?: string;
  params?: { [x: string]: unknown };
  data?: Requests[KEY]['params']['body'];
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
};

class MailIdRequester {
  url: string = '';

  constructor(url: string) {
    this.url = url;
  }

  request<KEY extends keyof Requests>({
    additionalUrl = '',
    params = {},
    data,
    method,
  }: IRequest<KEY>): AxiosPromise<Requests[KEY]['response']> {
    return axios({
      ...params,
      method,
      url: this.url + additionalUrl,
      data,
    });
  }
}

export default MailIdRequester;
