import Loader from '@rambler-components/loader';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import '@rambler-components/loader/styles.css';
import { ComponentClass, FunctionComponent } from 'react';

export type UniversalComponentType =
  | FunctionComponent<any>
  | ComponentClass<any>;

const pageCalendarWrapper = (Component: UniversalComponentType) => () => {
  const loading = useSelector((s: CStoreState) => s.eventsDisplay.loading);

  if (loading)
    return (
      <Loader
        size='medium'
        style={{
          margin: '100px auto',
        }}
      />
    );

  return <Component />;
};

export default pageCalendarWrapper;
