import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const calendarCreate = (data: Requests['CalendarCreate']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'CalendarCreate'>({
    additionalUrl: `/${data.url.owner}/calendars`,
    data: data.body,
    method: 'POST',
  });
};
