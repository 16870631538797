import { FC } from 'react';

const FileTypeColorPalette = {
  txt: '#536E7B',
  jpg: '#AFB513',
  jpeg: '#AFB513',
  mp3: '#9572CF',
  doc: '#3E4EB8',
  docx: '#3E4EB8',
  png: '#F1524B',
  wav: '#451FA3',
  flv: '#C4105A',
  swf: '#F26091',
  pdf: '#E7372D',
  gif: '#63BC66',
  psd: '#1194F6',
  zip: '#757575',
  xls: '#348F37',
  xlsx: '#348F37',
  bmp: '#00BCD6',
  ai: '#FF9900',
  '7z': '#000000',
  ppt: '#B71C1C',
  pptx: '#B71C1C',
  tif: '#2962FF',
  cdr: '#9BCE5F',
  rar: '#9D1BB2',
  eps: '#7A5547',
};

export const CornerIcon: FC<{ fileType?: string }> = ({ fileType }) => (
  <svg
    width='15'
    height='20'
    viewBox='0 0 15 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 0H15L0 15V0Z' fill='white' />
    <path
      d='M15 0V10C15 12.7614 12.7614 15 10 15H0L15 0Z'
      fill={FileTypeColorPalette[fileType] || '#757575'}
    />
    <path d='M0 15H10V15C10 17.7614 7.76142 20 5 20H0V15Z' fill='#C1D8FF' />
  </svg>
);
