import { CSSProperties, FC } from 'react';
import cn from 'classnames';

interface DayWeekHeaderItemProps {
  isActive?: boolean;
  isPast?: boolean;
  weekDay?: string;
  date?: string;
  style?: CSSProperties;
  highlightToday?: boolean;
}

const DayWeekHeaderItem: FC<DayWeekHeaderItemProps> = ({
  isActive,
  isPast,
  weekDay,
  date,
  style,
  highlightToday,
}) => {
  const withTitle = !!(weekDay && date);

  return (
    <div
      className={cn('day-week-header-item', {
        active: highlightToday && isActive,
        past: isPast,
      })}
      style={style}
    >
      {!!withTitle && (
        <div className='day-week-header-item-header'>
          <p>
            <span>{weekDay}</span>,<span>{date}</span>
          </p>
        </div>
      )}
      <div className='day-week-header-item-text'>
        {!withTitle && 'Весь день'}
      </div>
    </div>
  );
};

export default DayWeekHeaderItem;
