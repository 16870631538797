// *modulism-group store-actionTypes
export const EVENT_VIEW_SET = 'EVENT_VIEW_SET';
export const EVENT_VIEW_SET_RAW_DATA = 'EVENT_VIEW_SET_RAW_DATA';
export const EVENT_VIEW_DELETE_ACTION = 'EVENT_VIEW_DELETE_ACTION';
export const EVENT_VIEW_DELETE_CANCELLED_ACTION =
  'EVENT_VIEW_DELETE_CANCELLED_ACTION';
export const EVENT_VIEW_EDIT_ACTION = 'EVENT_VIEW_EDIT_ACTION';
export const EVENT_VIEW_COPY_ACTION = 'EVENT_VIEW_COPY_ACTION';
export const EVENT_VIEW_PRINT_ACTION = 'EVENT_VIEW_PRINT_ACTION';
export const EVENT_VIEW_ATTENDEE_REACTION = 'EVENT_VIEW_ATTENDEE_REACTION';
export const EVENT_VIEW_ATTENDEE_TRANSPARENCY_CHANGE =
  'EVENT_VIEW_ATTENDEE_TRANSPARENCY_CHANGE';

export const EVENT_LOAD_FILE_INFO = 'EVENT_LOAD_FILE_INFO';
