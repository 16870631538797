import { useRef, useMemo, useState, FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';

import { useAppDispatch } from '../../../_redux/hooks';
import { addSnack } from '../../../_redux/slices/snackbar';
import { CStoreState } from 'src/_redux/types';

import { isTimeBefore } from 'src/_utils/date';
import { transformDateAndTime } from 'src/_utils/caldav';
import { useWindowSize } from '../../../_utils/hooks/useWindowSize';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import NotificationBlock from '../../../_components/NotificationBlock';
import { CUSTOM_EVENTS, USER_PART_STATUS } from 'src/_constants';
import { EVENT_CREATE_EDIT_ERRORS } from 'src/eventCreateEdit/constants';
import { AttachIcon, CompareIcon, MoreIcon } from 'src/_components/Svg';
import { Upload } from '../Attachments';
import { FieldsData } from 'src/eventCreateEdit/store/types';
import { Event as CEvent } from 'src/_api/types/entities';

import cn from 'classnames';
import Button from '@rambler-components/button';
import '@rambler-components/button/styles.css';
import { CalendarTooltip } from '../../../_components/CalendarTooltip';

import css from './Buttons.module.less';
import { NO_SUPPORT_RESULT, parseRruleData } from '../../../_utils/recurrence';
import { EventCreateFieldsType } from '../../const';

const uploadsToArray = (obj) => Object.keys(obj).map((key) => obj[key]);
const checkAttahiUploaded = (uploads: { [id: number]: Upload }) =>
  Object.keys(uploads).find((i) => uploads[i].loadedPercent !== 100);

interface EventCreateButtonsProps {
  values: EventCreateFieldsType;
  isNearBottom: boolean;
  hasScrollbar: boolean;
}

const EventCreateButtons: FC<EventCreateButtonsProps> = ({
  values,
  isNearBottom,
  hasScrollbar,
}) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const {
    attachi,
    loading,
    hasErrors,
    mode,
    editData,
    profileEmail,
    fieldsHidden,
    isComparing,
    isEdited,
  } = useSelector(
    (state: CStoreState) => ({
      attachi: state.eventCreateEdit.fieldsData.attachi,
      loading: state.eventCreateEdit.requestLoading,
      hasErrors: !!Object.entries(
        state.eventCreateEdit.fieldsData.errors,
      ).filter((er) => er[1].length).length,
      mode: state.eventCreateEdit.mode,
      editData: state.eventCreateEdit.editData,
      profileEmail: state.apiCommonData.profile?.info?.email.toLowerCase(),
      fieldsHidden: state.eventCreateEdit.fieldsHidden,
      isComparing: state.eventCreateEdit.comparingCalendarsData.isComparing,
      isEdited: state.eventCreateEdit.isEdited,
    }),
    shallowEqual,
  );
  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const fileInput = useRef(null);
  const attachFileHandler = () => {
    if (fileInput.current.value !== '') fileInput.current.value = '';
    //activeField && trigger('setState', '', { activeField: null });
    fileInput.current.click();
  };
  const fieldsEmpty = useMemo(
    () =>
      !values.attendees.length &&
      !values.title &&
      !values.timeStart &&
      !values.timeEnd &&
      !values.wholeDay &&
      (!values.recurrence ||
        (values.recurrence && !values.recurrence.length)) &&
      !values.room &&
      !values.description,
    [values],
  );

  const mainBtnTitle = useMemo(() => {
    if (mode === 'create') {
      if (width >= 400) return 'Добавить в календарь';

      return 'Добавить';
    }
    if (width < 400) return 'Сохранить';

    return 'Сохранить изменения';
  }, [mode, width]);

  const create = () => {
    const {
      dateStart,
      dateEnd,
      wholeDay,
      room,
      title,
      timeStart,
      timeEnd,
      description,
      recurrence,
      alarms,
    } = values;

    if (attachi && checkAttahiUploaded(attachi)) {
      dispatch(
        addSnack({
          message: 'Вложения еще не загружены"',
          type: 'error',
        }),
      );

      return;
    }

    const endDate = moment(dateEnd, 'DD.MM.YYYY');
    if (wholeDay) endDate.add(1, 'd');

    const rrule = recurrence && parseRruleData(recurrence);

    const noNeedToSendRec =
      recurrence && rrule?.type === NO_SUPPORT_RESULT.type;

    if (processErrors(noNeedToSendRec)) return;

    const isSameTime =
      !wholeDay && dateStart === dateEnd && timeStart === timeEnd;

    // Добавляем организатора в участники
    const attendees = [...values.attendees];
    //Если в списке больше 1го участника, отличного от создателя, И
    //сам создатель отсутствует в списке, И
    //режим CREATE, то добавляем
    if (
      //attendees.filter(at => at.uri.toLowerCase() !== `mailto:${profileEmail}`).length > 0 &&
      attendees.filter(
        (at) => at.uri.toLowerCase() === `mailto:${profileEmail}`,
      ).length === 0 &&
      mode === 'create'
    )
      attendees.push({ uri: `mailto:${profileEmail}` });

    const organizerIndex = attendees.findIndex(
      (at) => at.uri.toLowerCase() === `mailto:${profileEmail}`,
    );

    if (organizerIndex > -1)
      attendees[organizerIndex].status = USER_PART_STATUS.accepted;

    const timeZone = wholeDay
      ? undefined
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

    const organizer =
      mode === 'edit'
        ? editData.masterEvent.organizer || attendees.length
          ? { uri: `mailto:${profileEmail}` }
          : null
        : attendees.length
          ? { uri: `mailto:${profileEmail}` }
          : null;

    const timeEndCode = transformDateAndTime(
      endDate.format('DD.MM.YYYY'),
      timeEnd,
      !timeZone,
    );

    const resEventData = {
      summary:
        title.trim() || (mode === 'create' ? 'Новое событие' : 'Без названия'),
      attendee: attendees,
      attach:
        attachi && Object.keys(attachi).length
          ? uploadsToArray(attachi).map((a) => ({
              url: a.key ? `attach://${a.key}` : a.downloadUrl,
              format: a.type,
              name: a.name,
              size: a.size,
            }))
          : undefined,
      start: {
        value: transformDateAndTime(dateStart, timeStart, !timeZone),
        type: wholeDay ? 'DATE' : 'DATE-TIME',
        timeZone,
      },
      end: isSameTime
        ? undefined
        : {
            value: timeEndCode,
            type: wholeDay ? 'DATE' : 'DATE-TIME',
            timeZone,
          },
      location: room || undefined,
      recurrence: noNeedToSendRec ? undefined : recurrence,
      description,
      transparency: values.transparency || undefined,
      organizer,
      alarms,
    } as CEvent;

    if (mode === 'create') {
      eventCreateEditActions().requestEventCreate(resEventData);

      return;
    }

    eventCreateEditActions().requestEventEdit(resEventData);
  };

  const processErrors = (noNeedToSendRec: boolean): boolean => {
    const { dateStart, dateEnd, wholeDay, timeStart, timeEnd } = values;
    const startDate = moment(dateStart, 'DD.MM.YYYY');
    const endDate = moment(dateEnd, 'DD.MM.YYYY');
    if (wholeDay) endDate.add(1, 'd');

    const addErr = eventCreateEditActions().addFieldsErrorEventCreateEdit;
    const errors = {
      dateEnd: [],
      timeStart: [],
      timeEnd: [],
      recurrence: [],
    };

    if ((!timeEnd || !timeStart) && !wholeDay) {
      if (!timeStart)
        errors['timeStart'].push(EVENT_CREATE_EDIT_ERRORS['noTimeStart']);

      if (!timeEnd)
        errors['timeEnd'].push(EVENT_CREATE_EDIT_ERRORS['noTimeEnd']);
    }

    if (endDate.isBefore(startDate))
      errors['dateEnd'].push(
        EVENT_CREATE_EDIT_ERRORS['dateEndBeforeDateStart'],
      );

    if (
      !wholeDay &&
      timeEnd &&
      timeStart &&
      isTimeBefore(timeEnd, timeStart) &&
      dateStart === dateEnd
    )
      errors['timeEnd'].push(
        EVENT_CREATE_EDIT_ERRORS['timeEndBeforeTimeStart'],
      );

    if (noNeedToSendRec)
      errors['recurrence'].push(EVENT_CREATE_EDIT_ERRORS['recurrenceWeek']);

    const errorsEntries = Object.entries(errors);
    const filteredErrors = errorsEntries.filter((er) => er[1].length);

    if (filteredErrors.length) {
      filteredErrors.forEach((er) => {
        addErr({
          field: er[0] as keyof Omit<FieldsData, 'errors'>,
          errors: er[1],
        });
      });

      return true;
    }

    return false;
  };

  const showFields = () => {
    eventCreateEditActions().showFieldsEventCreateEdit();
    setTimeout(() => {
      const ev = new Event(CUSTOM_EVENTS.floatingModalRecalculate, {
        bubbles: false,
      });
      window.dispatchEvent(ev);
    });
  };

  const startComparing = () => {
    floatingModalActions().toggleCentered(true);
    eventCreateEditActions().calendarsComparing({
      status: 'START',
      attendees: values.attendees,
    });
    floatingModalActions().manageBackgroundFloatingModal(true);
    showFields();
    const ev = new Event(CUSTOM_EVENTS.floatingModalFullScreen, {
      bubbles: true,
    });
    window.dispatchEvent(ev);
  };

  const close = () => {
    setShowConfirmClose(false);
    floatingModalActions().closeFloatingModal();
    setTimeout(() => {
      eventCreateEditActions().resetEventCreateEdit();
    });
  };

  const stopComparing = () => (isEdited ? setShowConfirmClose(true) : close());

  return (
    <div
      className={cn(css.eventCreateButtons, {
        [css.scrolled]: hasScrollbar && !isNearBottom,
      })}
    >
      <Button
        style={{
          borderRadius: '25px',
        }}
        disabled={loading || hasErrors || fieldsEmpty}
        onClick={!hasErrors && create}
      >
        {mainBtnTitle}
      </Button>
      {isComparing ? (
        <Button
          style={{
            borderRadius: '25px',
            marginLeft: '10px',
          }}
          type='secondary'
          onClick={stopComparing}
        >
          {showConfirmClose && (
            <NotificationBlock
              title={`Отменить ${
                mode === 'edit' ? 'редактирование' : 'создание'
              } события?`}
              options={[
                {
                  title: 'Да, отменить',
                  action: close,
                },
                {
                  title: 'Нет, остаться',
                  action: () => setShowConfirmClose(false),
                },
              ]}
              positions={{
                left: 0,
                top: -110,
                width: 320,
              }}
            />
          )}
          Отменить
        </Button>
      ) : (
        ''
      )}
      {!isComparing ? (
        <CalendarTooltip
          label='Перейти к сравнению календарей'
          position='top'
          isAlwaysCentered
        >
          <div
            className={css.eventCreateButtonsCompare}
            onClick={startComparing}
          >
            <CompareIcon />
          </div>
        </CalendarTooltip>
      ) : (
        ''
      )}
      <div
        className={cn(css.eventCreateButtonBlueButtons, {
          [css.fieldsHidden]: fieldsHidden,
        })}
      >
        {!fieldsHidden ? (
          !isComparing && (
            <div
              onClick={attachFileHandler}
              className={css.eventCreateButtonBlue}
            >
              <AttachIcon />
              <span>Прикрепить файл</span>
            </div>
          )
        ) : (
          <div className={css.eventCreateButtonBlue} onClick={showFields}>
            <MoreIcon />
            <span>Все параметры</span>
          </div>
        )}
      </div>
      <input
        ref={fileInput}
        style={{ display: 'none' }}
        type='file'
        onChange={() => {
          const files = Array.prototype.slice.call(fileInput.current.files);
          eventCreateEditActions().addAttachi(files);
        }}
        multiple
      />
    </div>
  );
};

export default EventCreateButtons;
