import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const eventOverride = (data: Requests['EventOverride']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'EventOverride'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}/events/${data.url.eventId}/override`,
    method: 'PUT',
    data: data.body,
  });
};
