import { useEffect } from 'react';
import { getFirstDayInMonth } from '../_utils/date';
import controlsActions from '../controls/store/actions';
import { isSSR } from '../scripts/idHelper/getter';
import { TABLET_MAX_WIDTH } from '../_constants';

const NotFound404 = () => {
  useEffect(() => {
    if (!isSSR && window.innerWidth <= TABLET_MAX_WIDTH) {
      const today = new Date();
      controlsActions().controlsInit({
        selectedMonth: getFirstDayInMonth(today),
        selectedDateRange: [today, today],
        mode: 'day',
        page: 'calendar',
      });
    } else {
      const today = new Date();
      controlsActions().controlsInit({
        selectedMonth: getFirstDayInMonth(today),
        selectedDateRange: null,
        mode: 'month',
        page: 'calendar',
      });
    }
  }, []);

  return <></>;
};

export default NotFound404;
