import { IS_CORP } from '../../../_constants';
import CorpHeader from './CorpHeader';
import DefaultHeader from './DefaultHeader';

const Header = () => {
  if (IS_CORP) {
    return <CorpHeader />;
  }

  return <DefaultHeader />;
};

export default Header;
