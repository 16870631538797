import EventViewBlock from '../EventBlock';
import './styles.less';
import FloatingModal from 'src/floatingModal/component';
import eventViewActions from 'src/eventView/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';

const EventViewWindow = () => (
  <FloatingModal
    id={FLOATING_MODAL_IDS['eventView']}
    onClose={() => eventViewActions().setEventView({ event: null })}
  >
    <EventViewBlock withClose />
  </FloatingModal>
);

export default EventViewWindow;
