import { FC, ReactNode } from 'react';
import TimeDivider from '../TimeDivider';
import TimeLineWrapper from '../TimeLineWrapper';

import './styles.less';

interface WeekProps {
  items: JSX.Element[];
  isCurrentWeek: boolean;
  children: ReactNode;
}

export const Week: FC<WeekProps> = ({ items, isCurrentWeek, children }) => (
  <div className='calendar-week'>
    <>{items.map((item) => item)}</>
    <TimeLineWrapper>
      {children}
      <TimeDivider show={isCurrentWeek} />
    </TimeLineWrapper>
  </div>
);
