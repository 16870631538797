import moment from 'moment';
import { put, select, takeEvery } from 'redux-saga/effects';
import { openEventCreateWindow } from 'src/eventCreateEdit/store/actions/openEventCreateWindow';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { closeFloatingModal } from 'src/floatingModal/store/actions/closeFloatingModal';
import { openFloatingModal } from 'src/floatingModal/store/actions/openFloatingModal';
import { setEventView } from '../store/actions/setEventView';
import { EVENT_VIEW_COPY_ACTION } from '../store/actionTypes';
import { attachToUploads } from '../utils';
import selectEventViewEvent from '../store/selectors/selectEventViewEvent';

export function* copyEvent(): Generator {
  const event = (yield select(selectEventViewEvent)) as SelectedEvent;

  yield put(closeFloatingModal.action());
  yield put(setEventView.action({ event: null }));
  yield put(
    openEventCreateWindow.action({
      attendees: event.attendee.map((at) => ({
        uri: at.uri,
      })),
      title: event.summary + ' (копия)',
      dateStart: moment(event.start).format('DD.MM.YYYY'),
      timeStart: moment(event.start).format('HH:mm'),
      dateEnd: moment(event.end).format('DD.MM.YYYY'),
      timeEnd: moment(event.end).format('HH:mm'),
      wholeDay: event.type == 'wholeDay',
      room: event.location,
      description: event.description,
      attachi: attachToUploads(event.source.attach || [], []),
      alarms: event.alarms,
    }),
  );
  yield put(
    openFloatingModal.action({
      opened: FLOATING_MODAL_IDS['eventCreate'],
      target: null,
    }),
  );
}

export function* handleEventCopySaga(): Generator {
  yield takeEvery(EVENT_VIEW_COPY_ACTION, copyEvent);
}
