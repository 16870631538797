import { createAction } from 'src/_utils/redux';
import { UNSET_SELECTED_CONTACT } from '../actionTypes';
import { AddressbookState, UnsetSelectedAction } from '../types';

export const unsetSelectedContact = createAction<
  UnsetSelectedAction,
  AddressbookState
>(UNSET_SELECTED_CONTACT, (state) => ({
  ...state,
  selectedContact: undefined,
}));
