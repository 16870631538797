import { FC, MouseEventHandler } from 'react';
import { EventAttendee } from 'src/_api/types/entities';
import { CloseSmallIcon } from 'src/_components/Svg';

import '@rambler-components/tooltip/styles.css';
import { CalendarTooltip } from '../../../../_components/CalendarTooltip';

interface SelectedEventProps {
  name?: string;
  uri: string;
  onRemove: (v: string) => void;
  selectForEdit: (v: EventAttendee) => void;
}

const SelectedValue: FC<SelectedEventProps> = ({
  name,
  uri,
  onRemove,
  selectForEdit,
}) => {
  const email = uri.replace('mailto:', '');

  const onEdit: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    selectForEdit({
      uri,
      name,
    });
  };

  const handleOnRemoveClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onRemove(uri);
  };

  return (
    <CalendarTooltip
      label={email.length > 30 ? email.slice(0, 27) + '...' : email}
      position='top'
      autoPosition
    >
      <div className='event-create-suggester-selected-item' onClick={onEdit}>
        <span>{name || email}</span>
        <div
          className={'event-create-suggester-selected-item-space'}
          onClick={onEdit}
        />
        <div
          className='event-create-suggester-selected-item-remove'
          onClick={handleOnRemoveClick}
        >
          <CloseSmallIcon />
        </div>
      </div>
    </CalendarTooltip>
  );
};

export default SelectedValue;
