import { takeEvery } from 'redux-saga/effects';
import requestToDeleteCancelledEvent from 'src/_redux/commonSagas/requestToDeleteCanelledEvent';
import { DELETE_CANCELLED_NEW_EVENT_NOTIFICATION } from '../store/actionTypes';
import { DeleteCancelledNewEventNotificationsAction } from '../store/types';

export function* handleDeleteCancelledNewEvent(): Generator {
  yield takeEvery(
    DELETE_CANCELLED_NEW_EVENT_NOTIFICATION,
    (a: DeleteCancelledNewEventNotificationsAction) =>
      requestToDeleteCancelledEvent(a.payload, 'newEvents')
  );
}
