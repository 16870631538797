import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_LOAD } from '../actionTypes';
import { EventsDisplayState, LoadEventsAction } from '../types';

export const loadEvents = createAction<LoadEventsAction, EventsDisplayState>(
  EVENTS_DISPLAY_LOAD,
  (state) => ({
    ...state,
    loading: true,
  })
);
