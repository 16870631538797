import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { createAction } from 'src/_utils/redux';
import { FLOATING_MODAL_OPEN } from '../actionTypes';
import { FloatingModalState, OpenFloatingModalAction } from '../types';

export const openFloatingModal = createAction<
  OpenFloatingModalAction,
  FloatingModalState
>(FLOATING_MODAL_OPEN, (state, p) =>
  (!state.opened || state.opened === p.opened) &&
  state.opened !== FLOATING_MODAL_IDS['eventCreate']
    ? {
        opened: p.opened,
        target: p.target,
        fixedLeft: p.fixedLeft || 0,
        closeOnScroll: p.closeOnScroll || false,
        withBackground: false,
        center: p.center,
      }
    : state,
);
