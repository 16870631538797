// *modulism-group function
import { delay } from 'src/_utils/delay';
import requests from './requests';
import { rejectAfterDelay } from './utils';

type REQUESTS = typeof requests;

const fetchWithRetryAndDelay =
  <T extends keyof REQUESTS>(
    request: REQUESTS[T],
    retryCount = 2,
    delayMs = 600000,
  ): REQUESTS[T] =>
  async (...args) => {
    for (let i = 0; i <= retryCount; i++) {
      try {
        return await Promise.race([
          request.apply(args),
          rejectAfterDelay(delayMs),
        ]);
      } catch (err) {
        if (i === retryCount || err?.message !== 'timeout')
          return Promise.reject(err);
        await delay(delayMs);
      }
    }
  };

interface APIOptions {
  retry?: {
    retryCount?: number;
    delayMs?: number;
  };
}

function api<T extends keyof REQUESTS>(
  request: T,
  options?: APIOptions,
): REQUESTS[T] {
  if (options && options.retry) {
    const { retryCount = 2, delayMs = 600000 } = options.retry;

    return fetchWithRetryAndDelay(requests[request], retryCount, delayMs);
  }

  return requests[request];
}

export default api;
