import { FC, RefObject, useRef } from 'react';
import cn from 'classnames';
import moment from 'moment';

import './styles.less';
import { useClickOutside } from '../../../_utils/hooks/useClickOutside';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import eventViewActions from 'src/eventView/store/actions';
import floatingModalActions from 'src/floatingModal/store/actions';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';

/*

!! Высота !! (Нужна для регулировки позиции)
ПРИ ИЗМЕНЕНИИ ВЫСОТЫ ДРОПДАУНА ИЛИ ЕГО ЭЛЕМЕНТОВ -- КОРРЕКТИРУЙТЕ ВЫСОТУ ОКНА В ФАЙЛЕ ./constants.ts

*/

interface EventsDropDownProps {
  isOpened: boolean;
  left: string;
  events: SelectedEvent[];
  top?: string;
  onClose?: () => void;
  ignoreElRefs?: RefObject<HTMLDivElement>[];
}

const EventsDropDown: FC<EventsDropDownProps> = ({
  isOpened,
  onClose,
  left,
  top,
  events,
  ignoreElRefs,
}) => {
  const ref = useClickOutside<HTMLDivElement>(
    () => isOpened && onClose && onClose(),
    ignoreElRefs,
  );
  const eRefs = useRef<HTMLDivElement[]>([]);

  return (
    <div
      className={cn('events-dropdown', {
        opened: isOpened,
      })}
      style={{
        left,
        top,
      }}
      ref={ref}
    >
      {events.map((ev, i) => (
        <div
          key={`events-dropdown-event-${i}`}
          ref={(el) => (eRefs.current[i] = el)}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            eventViewActions().setEventView({ event: ev });
            floatingModalActions().openFloatingModal({
              opened: FLOATING_MODAL_IDS['eventView'],
              target: eRefs.current[i],
              closeOnScroll: true,
            });
          }}
        >
          <span>
            {ev.type === 'wholeDay' ? 'день' : moment(ev.start).format('HH:mm')}
          </span>
          <span>{ev.summary}</span>
        </div>
      ))}
    </div>
  );
};

export default EventsDropDown;
