// *modulism-group store-reducer
import { getFirstDayInMonth } from 'src/_utils/date';
import { ControlsState } from './types';
import { selectControlsMonth } from './actions/selectControlsMonth';
import { selectControlsDay } from './actions/selectControlsDay';
import { selectControlsModeMonth } from './actions/selectControlsModeMonth';
import { selectControlsModeWeek } from './actions/selectControlsModeWeek';
import { selectControlsModeDay } from './actions/selectControlsModeDay';
import { selectControlsModeAll } from './actions/selectControlsModeAll';
import { selectControlsPage } from './actions/selectControlsPage';
import { selectControlsNextWeek } from './actions/selectControlsNextWeek';
import { selectControlsPrevWeek } from './actions/selectControlsPrevWeek';
import { selectControlsPrevDay } from './actions/selectControlsPrevDay';
import { selectControlsNextDay } from './actions/selectControlsNextDay';
import { createReducer } from 'src/_utils/redux';
import { controlsInit } from './actions/controlsInit';
import { selectControlsTodayDay } from './actions/selectControlsTodayDay';
import { deleteSessionSetLoadingControls } from './actions/deleteSessionSetLoadingControls';
import { openDayControls } from './actions/openControlsDay';

const initialState: ControlsState = {
  selectedMonth: getFirstDayInMonth(new Date(1)),
  selectedDateRange: null,
  mode: 'month',
  page: 'calendar',
  deleteSessionLoading: false,
};

export default createReducer(initialState, [
  selectControlsMonth,
  selectControlsDay,
  selectControlsPage,
  selectControlsModeMonth,
  selectControlsModeWeek,
  selectControlsModeDay,
  selectControlsModeAll,
  selectControlsNextWeek,
  selectControlsPrevWeek,
  selectControlsPrevDay,
  selectControlsNextDay,
  selectControlsTodayDay,
  deleteSessionSetLoadingControls,
  openDayControls,
  controlsInit,
]);
