import { call, put } from 'redux-saga/effects';
import api from 'src/_api/api';
import { loadEventsFail } from '../store/actions/loadEventsFail';
import { setCalendars } from '../store/actions/setCalendars';
import { addSnack } from '../../_redux/slices/snackbar';
import { getRegistrationLink } from '../../_utils/auth';
import { getRamblerIdHelper } from '../../scripts/idHelper/getter';
import { IS_CORP } from '../../_constants';

function* fetchCalendars(param) {
  try {
    const { data } = yield call(api('calendarList'), param);
    yield put(setCalendars.action(data.items));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      if (IS_CORP) {
        window.location.href = `${process.env.AUTH_URL}?return=${window.location.origin}`;

        return;
      }
      const hostname = window?.location?.hostname || '';
      if (hostname.endsWith('.ru')) {
        window.location.href = getRegistrationLink();
      } else {
        const ramblerIdHelper = yield getRamblerIdHelper();
        yield ramblerIdHelper.redirectToAuth({
          back: window.location.href,
        });
      }
    }
    yield put(
      addSnack({
        message: 'Не удалось загрузить список календарей - ошибка сервера',
        type: 'error',
      }),
    );
    yield put(loadEventsFail.action());
  }
}

export default fetchCalendars;
