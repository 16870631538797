import { createAction } from 'src/_utils/redux';
import { CONTROLS_INIT } from '../actionTypes';
import { ControlsInitAction, ControlsState } from '../types';

export const controlsInit = createAction<ControlsInitAction, ControlsState>(
  CONTROLS_INIT,
  (state, p) => ({
    ...p,
    deleteSessionLoading: state.deleteSessionLoading,
  }),
);
