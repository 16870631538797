import {
  getDateWeekRange,
  getDateWeekRangeForOneMonth,
  getFirstDayInMonth,
} from 'src/_utils/date';
import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_TODAY_DAY } from '../actionTypes';
import { ControlsState, SelectControlsTodayDayAction } from '../types';

export const selectControlsTodayDay = createAction<
  SelectControlsTodayDayAction,
  ControlsState
>(SELECT_CONTROLS_TODAY_DAY, (state) => {
  const today = new Date();

  let range: [Date, Date] = [today, today];
  if (state.mode === 'week') {
    const preRange = getDateWeekRange(today);
    range = getDateWeekRangeForOneMonth(preRange[0], today.getMonth());
  }

  return {
    ...state,
    selectedDateRange: range,
    selectedMonth: getFirstDayInMonth(today),
  };
});
