/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useState } from 'react';
import { Dropdown, DropdownWrapper } from '@rambler-components/dropdown';

import { ArrowDownSmallIcon } from 'src/_components/Svg';
import { CUSTOM_EVENTS } from 'src/_constants';

import '@rambler-components/dropdown/styles.css';
import './styles.less';
import DropdownItem from 'src/_components/DropdownItem';

interface Props {
  transparency: 'OPAQUE' | 'TRANSPARENT';
  setTransparency: (transparent: 'OPAQUE' | 'TRANSPARENT') => void;
}

const EventCreateTransparency: FC<Props> = ({
  transparency,
  setTransparency,
}) => {
  const [opened, setOpened] = useState(false);
  const statuses = {
    OPAQUE: 'Занят',
    TRANSPARENT: 'Свободен',
  };
  const list = ['OPAQUE', 'TRANSPARENT'] as (typeof transparency)[];

  useEffect(() => {
    window.addEventListener(CUSTOM_EVENTS.scrollEventCreateEditBody, () =>
      setOpened(false),
    );
  }, []);

  const handleChange = (v: typeof transparency) => {
    setOpened(false);

    setTransparency(v);
  };

  return (
    <div className='event-create-transparency'>
      <DropdownWrapper>
        <div
          className='event-create-transparency-value'
          onClick={() => setOpened((prev) => !prev)}
        >
          <span>Статус участников: {statuses[transparency]}</span>
          <ArrowDownSmallIcon />
        </div>
        <Dropdown
          isOpen={opened}
          onClose={() => setOpened(false)}
          className={'event-create-transparency-dropdown'}
        >
          {list.map((r, i) => (
            <DropdownItem
              key={`event-create-select-transparency-${i}`}
              action={() => handleChange(r)}
              withIcon
              className='event-create-transparency-select-item'
              active={r === transparency}
              title={statuses[r]}
            />
          ))}
        </Dropdown>
      </DropdownWrapper>
    </div>
  );
};

export default EventCreateTransparency;
