import { createAction } from 'src/_utils/redux';
import { DELETE_CANCELLED_NEW_EVENT_NOTIFICATION } from '../actionTypes';
import {
  NewEventsState,
  DeleteCancelledNewEventNotificationsAction,
} from '../types';

export const deleteCancelledNewEventNotifications = createAction<
  DeleteCancelledNewEventNotificationsAction,
  NewEventsState
>(DELETE_CANCELLED_NEW_EVENT_NOTIFICATION);
