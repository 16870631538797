import { FC, useEffect, useState } from 'react';
import Checkbox from '@rambler-components/checkbox';

import '@rambler-components/checkbox/styles.css';
import '@rambler-components/select/styles.css';
import WeekType from './weekType';
import PeriodType from './periodType';
import DateType from './dateType';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import FieldErrors from '../../FieldErrors';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { parseRruleData } from 'src/_utils/recurrence';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import { EVENT_CREATE_EDIT_ERRORS } from 'src/eventCreateEdit/constants';
import EventCreateWholeDay from '../WholeDay';
import { CUSTOM_EVENTS, MOBILE_MAX_WIDTH } from 'src/_constants';
import { useWindowSize } from '../../../../_utils/hooks/useWindowSize';
import Select from '@rambler-components/select';

import './styles.less';

interface Props {
  recurrence: null | string[];
  setRecurrence: (recurrence: null | string[]) => void;
  wholeDay: boolean;
  setWholeDay: (wholeDay: boolean) => void;
  dateStart: string;
}

const EventCreateRecurrence: FC<Props> = ({
  recurrence,
  setRecurrence,
  wholeDay,
  setWholeDay,
  dateStart,
}) => {
  const { opened, mode, errors, isEdit, isEditingSingle, fieldsHidden } =
    useSelector((s: CStoreState) => ({
      opened: s.floatingModal.opened === FLOATING_MODAL_IDS['eventCreate'],
      mode: s.eventCreateEdit.mode,
      errors: s.eventCreateEdit.fieldsData['errors']['recurrence'],
      isEdit: s.eventCreateEdit.mode === 'edit',
      isEditingSingle: s.eventCreateEdit.editData?.recurrenceType === 'single',
      fieldsHidden: s.eventCreateEdit.fieldsHidden,
    }));
  const [withRec, setWithRec] = useState(!!recurrence);
  const [notSupportedRec, setNotSupportedRec] = useState(false);
  const [type, setType] = useState<'week' | 'period' | 'date' | null>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!recurrence) {
      setType('week');

      return;
    }
    setWithRec(true);
    const recData = parseRruleData(recurrence);
    switch (recData.type) {
      case 'PERIOD':
        setType('period');
        break;
      case 'WEEK':
        setType('week');
        break;
      case 'DATE':
        setType('date');
        break;
      case 'NO_SUPPORT':
        setType('week');
        if (mode === 'edit') {
          setNotSupportedRec(true);
        }
    }
  }, [mode]);

  useEffect(() => {
    if (!withRec) {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'recurrence',
        error: EVENT_CREATE_EDIT_ERRORS['recurrenceWeek'],
      });

      setRecurrence(null);
    }
  }, [withRec]);

  const onTypeChange = (val: 'week' | 'period' | 'date') => {
    setRecurrence(null);
    eventCreateEditActions().removeFieldsErrorEventCreateEdit({
      field: 'recurrence',
      error: EVENT_CREATE_EDIT_ERRORS['recurrenceWeek'],
    });

    setType(val);
  };

  const handleWithRect = () => {
    setWithRec(!withRec);
    if (fieldsHidden) {
      eventCreateEditActions().showFieldsEventCreateEdit();
    }
    setTimeout(() => {
      const ev = new Event(CUSTOM_EVENTS.floatingModalRecalculate, {
        bubbles: false,
      });
      window.dispatchEvent(ev);
    });
  };

  return (
    <>
      <div className='event-create-reccurency'>
        <EventCreateWholeDay wholeDay={wholeDay} setWholeDay={setWholeDay} />
        <Checkbox
          disabled={isEditingSingle || notSupportedRec}
          checked={withRec}
          onChange={handleWithRect}
        >
          {width <= MOBILE_MAX_WIDTH ? 'Повторять' : 'Повторять событие'}
        </Checkbox>
      </div>
      {notSupportedRec && (
        <div className='event-create-reccurency-no-support'>
          Периодичность повторения события можно изменить только через
          приложение, в котором оно было создано
        </div>
      )}
      {withRec && !notSupportedRec && (
        <div className='event-create-reccurency-block'>
          {!isEditingSingle && (
            <div className='event-create-reccurency-types'>
              {width <= MOBILE_MAX_WIDTH ? (
                <Select
                  style={{ width: '100%' }}
                  value={type}
                  options={[
                    {
                      value: 'week',
                      label: 'По дням недели',
                    },
                    {
                      value: 'period',
                      label: 'С периодичностью',
                    },
                    {
                      value: 'date',
                      label: 'По дате',
                    },
                  ]}
                  onChange={onTypeChange}
                />
              ) : (
                <>
                  <p
                    onClick={() => onTypeChange('week')}
                    className={type === 'week' ? 'selected' : ''}
                  >
                    По дням недели
                  </p>
                  <p
                    onClick={() => onTypeChange('period')}
                    className={type === 'period' ? 'selected' : ''}
                  >
                    С периодичностью
                  </p>
                  <p
                    onClick={() => onTypeChange('date')}
                    className={type === 'date' ? 'selected' : ''}
                  >
                    По дате
                  </p>
                </>
              )}
            </div>
          )}
          {type === 'week' ? (
            <WeekType
              start={dateStart}
              opened={opened}
              recurrence={recurrence}
              setRecurrence={setRecurrence}
              isEdit={isEdit}
              onlyDisplay={isEditingSingle}
              wholeDay={wholeDay}
            />
          ) : type === 'period' ? (
            <PeriodType
              start={dateStart}
              opened={opened}
              recurrence={recurrence}
              setRecurrence={setRecurrence}
              isEdit={isEdit}
              onlyDisplay={isEditingSingle}
              wholeDay={wholeDay}
            />
          ) : type === 'date' ? (
            <DateType
              start={dateStart}
              opened={opened}
              recurrence={recurrence}
              setRecurrence={setRecurrence}
              isEdit={isEdit}
              onlyDisplay={isEditingSingle}
              wholeDay={wholeDay}
            />
          ) : (
            ''
          )}
        </div>
      )}
      <FieldErrors fieldKey={'recurrence'} errors={errors} />
    </>
  );
};

export default EventCreateRecurrence;
