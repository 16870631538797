import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const eventList = (data: Requests['EventList']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'EventList'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}/events`,
    method: 'GET',
    params: {
      expand: data.url.expand,
      timeMin: data.url.timeMin,
      timeMax: data.url.timeMax,
      timeZone: data.url.timeZone,
      fields: data.url.fields,
    },
  });
};
