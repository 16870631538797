import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_EDIT_EVENT_ATTENDEES } from '../actionTypes';
import {
  EventsDisplayState,
  EditEventAttendeesEventsDisplayAction,
} from '../types';

export const editEventAttendees = createAction<
  EditEventAttendeesEventsDisplayAction,
  EventsDisplayState
>(EVENTS_DISPLAY_EDIT_EVENT_ATTENDEES, (state, payload) => {
  const events = [...state.events];
  events[payload.index].attendee = payload.attendees;

  return {
    ...state,
    events,
  };
});
