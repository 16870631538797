import { AddressbookContact } from 'src/_api/types/mailId/entities';

export const getFormattedContacts = (contacts: AddressbookContact[]) =>
  contacts
    .filter((contact) => contact.email)
    .map((contact) => {
      let displayName = contact?.lastname || '';
      if (contact?.firstname) {
        if (!displayName) {
          displayName = contact?.firstname;
        } else {
          displayName += ` ${contact?.firstname}`;
        }
      }
      if (!displayName) {
        displayName = contact.email;
      }

      return {
        ...contact,
        display_name: displayName,
      };
    });
