import { Task } from 'redux-saga';
import { put, call, fork, delay, cancel } from 'redux-saga/effects';
import { deleteEvent } from 'src/eventsDisplay/store/actions/deleteEvent';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import eventViewActions from 'src/eventView/store/actions';
import { closeFloatingModal } from 'src/floatingModal/store/actions/closeFloatingModal';
import { deleteNewEventNotifications } from 'src/newEventNotifications/store/actions/deleteNewEventNotifications';
import { closeWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/closeWhiteLoadingScreen';
import { openWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/openWhiteLoadingScreen';
import api from 'src/_api/api';
import { addSnack } from '../slices/snackbar';
import calendarApi from '../../services/calendar';

export function* whiteScreenTimeout() {
  yield delay(1000);
  yield put(openWhiteLoadingScreen.action());
  yield put(
    addSnack({
      message: 'Событие удаляется...',
    }),
  );
}

function* requestToDeleteCancelledEvent(
  event: SelectedEvent,
  type: 'eventView' | 'newEvents',
): Generator {
  if (type === 'eventView') {
    yield put(closeFloatingModal.action());
  }

  const whiteLoadingScreenTimeout = (yield fork(
    whiteScreenTimeout,
  )) as unknown as Task;
  try {
    yield call(api('eventDelete'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        eventId: event.id,
      },
      headers: {},
    });

    yield put(deleteEvent.action(event.id));
    yield put(deleteNewEventNotifications.action(event.id));

    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Событие удалено',
        type: 'success',
      }),
    );
    yield put(calendarApi.util.invalidateTags(['notification-events']));
  } catch (e) {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
    yield put(
      addSnack({
        message: 'Не удалось удалить событие',
        type: 'error',
        actionButtons: [
          {
            title: 'Повторить',
            onClick: () => {
              if (type === 'eventView')
                eventViewActions().deleteCancelledEventView();
            },
          },
        ],
      }),
    );
  } finally {
    yield put(closeWhiteLoadingScreen.action());
  }
}

export default requestToDeleteCancelledEvent;
