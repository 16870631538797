import ControlsWrapper from 'src/controls/components/Wrapper';
import EventCreateWindow from 'src/eventCreateEdit/components/Window';
import EventViewWindow from 'src/eventView/components/Window';
import WhiteLoadingScreen from 'src/whiteLoadingScreen/component';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';
import { TopLine } from 'src/_components';
import { useLocation } from 'react-router-dom';
import { dinamicRoutes } from 'src/_api/constants';

const Layout = (props) => {
  const profile = useSelector((s: CStoreState) => s.apiCommonData.profile);

  const location = useLocation();

  if (!profile) {
    return <></>;
  }

  if (location.pathname.includes(dinamicRoutes.print('calendar'))) {
    return <>{props.children}</>;
  }

  return (
    <>
      <TopLine />
      <WhiteLoadingScreen />
      <ControlsWrapper>{props.children}</ControlsWrapper>
      <EventCreateWindow />
      <EventViewWindow />
    </>
  );
};

export default Layout;
