export const ALARM_KEY = 'alarm';

type AlarmStorage = {
  closed: Array<string>;
};

export const getAlarmStorage = (): AlarmStorage => {
  try {
    const storage = JSON.parse(localStorage.getItem(ALARM_KEY));
    if (!Array.isArray(storage.closed)) storage.closed = [];

    return storage;
  } catch (e) {
    return {
      closed: [],
    };
  }
};

const setAlarmStorage = (s: AlarmStorage) => {
  localStorage.setItem(ALARM_KEY, JSON.stringify(s));
};

export const markAlarmClosed = (eventId: string) => {
  const storage = getAlarmStorage();

  if (!storage.closed.find((id) => id === eventId)) {
    storage.closed.push(eventId);
    setAlarmStorage(storage);
  }
};

export const removeUnusedAlarms = (eventIds: Array<string>) => {
  const storage = getAlarmStorage();

  const closed = storage.closed.filter((i) => eventIds.find((j) => i === j));

  setAlarmStorage({
    closed,
  });
};
