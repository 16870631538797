import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import store from '../_redux';
import { ErrorBoundary } from '../_components';
import AppRoutes from './routes';
import Layout from './layout';
import Notifications from 'src/_components/Notifications';
import Snackbar from 'src/_components/Snackbar';
import Alarms from 'src/_components/Alarms';

import { history } from 'src/_constants';

import '../_styles/index.less';
import SentryModule from 'src/modules/sentry';

SentryModule.init();

const Application = () => (
  <ErrorBoundary>
    <Provider store={store}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <HistoryRouter history={history}>
        <Layout>
          <Notifications />
          <Alarms />
          <Snackbar />
          <AppRoutes />
        </Layout>
      </HistoryRouter>
    </Provider>
  </ErrorBoundary>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Application />);
