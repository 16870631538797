import { FC, useRef, useState } from 'react';
import moment from 'moment';
import { Event } from 'src/_api/types/entities';
import Dropdown from 'src/_components/Dropdown';
import { transformCalDavDate } from 'src/_utils/caldav';

interface ComparingEventProps {
  left: number;
  width: number;
  top: number;
  events: Event[] | null;
}

const ComparingEvent: FC<ComparingEventProps> = ({
  left,
  width,
  top,
  events,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>();
  const isClickable =
    (width - 3 < 50 && events?.length) ||
    (width - 3 > 50 && events?.length > 1);

  const openDropDown = () => {
    if (!isClickable) return;
    setDropdownOpen(true);
  };

  return (
    <div
      className='comparing-event'
      ref={ref}
      style={{
        left,
        width,
        top,
      }}
    >
      <div className={isClickable ? 'clickable' : ''} onClick={openDropDown}>
        {width < 50 ? (
          ''
        ) : (
          <>
            {events?.length > 1 ? (
              <p className='comparing-event-main-text' style={{ margin: 0 }}>
                {events.length} события
              </p>
            ) : (
              <>
                <p className='comparing-event-main-text'>
                  {events?.[0]?.summary}
                </p>
                <p className='comparing-event-secondary-text'>
                  {events?.[0]?.location}
                </p>
              </>
            )}
          </>
        )}
        {isClickable && dropdownOpen && (
          <Dropdown
            close={() => setDropdownOpen(false)}
            className='comparing-event-dropdown custom-scroll-bar'
            style={{
              top: ref.current?.getBoundingClientRect().bottom,
              left: ref.current?.getBoundingClientRect().left,
            }}
            ignoreButton={ref}
          >
            {events.map((ev, i) => (
              <div
                className='comparing-event-dropdown-item'
                key={`comparing-event-dropdown-item-${ev.id}-${i}`}
              >
                <p className='comparing-event-dropdown-item-title'>
                  {ev.summary}
                </p>
                <p className='comparing-event-dropdown-item-time'>
                  {ev.start.type === 'DATE'
                    ? 'На весь день'
                    : `${moment(transformCalDavDate(ev.start.value)).format(
                        'HH:mm',
                      )}-${moment(transformCalDavDate(ev.end.value)).format(
                        'HH:mm',
                      )}`}
                </p>
                {ev.location && (
                  <p className='comparing-event-dropdown-item-location'>
                    {ev.location}
                  </p>
                )}
              </div>
            ))}
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default ComparingEvent;
