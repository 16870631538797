import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import api from 'src/_api/api';
import { setContactsApiCommonData } from '../store/actions/setContacts';
import { LOAD_CONTACTS_API_COMMON_DATA } from '../store/actionTypes';
import { CStoreState } from '../../_redux/types';
import { IS_PUBLIC } from '../../_constants';

function* fetchContacts() {
  try {
    const storageContacts = yield select(
      (s: CStoreState) => s.apiCommonData.contacts,
    );

    if (Array.isArray(storageContacts) && storageContacts.length) {
      return;
    }

    yield put(setContactsApiCommonData.action('loading'));
    let contactsResponse = {} as any;
    let employeesResponse = {} as any;
    yield all(
      [
        (function* () {
          try {
            contactsResponse = yield call(api('getContacts'));
          } catch (e) {
            console.error(e);
          }
        })(),
        !IS_PUBLIC &&
          (function* () {
            try {
              employeesResponse = yield call(api('getEmployees'));
            } catch (e) {
              console.error(e);
            }
          })(),
      ].filter((el) => !!el),
    );

    const personalContacts =
      contactsResponse?.data?.result?.addressbook?.contacts?.map((c) => ({
        ...c,
        contactType: 'personal',
      })) || [];

    const employees =
      employeesResponse?.data?.users
        ?.filter((c) => c.status === 'active')
        .map((c) => ({
          ...c,
          contactType: 'employee',
        })) || [];

    yield put(
      setContactsApiCommonData.action([...personalContacts, ...employees]),
    );
  } catch (e) {
    yield put(setContactsApiCommonData.action('error'));
  }
}

export function* loadContactsApiCommonDataSaga(): Generator {
  yield takeLatest(LOAD_CONTACTS_API_COMMON_DATA, fetchContacts);
}
