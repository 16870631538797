import { createAction } from 'src/_utils/redux';
import { EVENT_ATTACHI_SET_INFO } from '../actionTypes';
import { EventCreateEditState, SetAttachInfoAction } from '../types';

export const setAttachiInfoAction = createAction<
  SetAttachInfoAction,
  EventCreateEditState
>(EVENT_ATTACHI_SET_INFO, (state, attachInfo) => {
  if (!state?.fieldsData?.attachi) return state;

  const attachi = state.fieldsData.attachi;
  const attachKey = Object.keys(attachi).find(
    (key) => attachi[key].downloadUrl === attachInfo.downloadUrl
  );

  if (!attachKey) return state;

  const attach = {
    ...attachi[attachKey],
    ...attachInfo,
  };

  return {
    ...state,
    fieldsData: {
      ...state.fieldsData,
      attachi: {
        ...state.fieldsData.attachi,
        [attachKey]: attach,
      },
    },
  };
});
