import { WRAPPER_CLASS_NAME } from 'src/_constants';

// *modulism-group scrollContent
export const scrollContent = (pixels: number) => {
  const element = document.getElementsByClassName(WRAPPER_CLASS_NAME);

  if (element.length) {
    element[0].scrollTop = pixels;
  }
};
