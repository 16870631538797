import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import Checkbox from '@rambler-components/checkbox';
import DateInput from 'src/_components/DateInput';
import MultiSelect from 'src/_components/MultiSelect';
import { PlusIcon } from 'src/_components/Svg';
import {
  EVENT_CREATE_EDIT_ERRORS,
  months,
  weeks,
} from 'src/eventCreateEdit/constants';
import eventCreateEditActions from 'src/eventCreateEdit/store/actions';
import {
  parseRruleData,
  generateWeekRule,
  ParsedWeekly,
  getRruleHumanString,
} from 'src/_utils/recurrence';
import { useSelector } from 'react-redux';
import { CStoreState } from 'src/_redux/types';

const options = [
  {
    value: 'MO',
    dropdownTitle: 'Понедельник',
    selectTitle: 'ПН',
  },
  {
    value: 'TU',
    dropdownTitle: 'Вторник',
    selectTitle: 'ВТ',
  },
  {
    value: 'WE',
    dropdownTitle: 'Среда',
    selectTitle: 'СР',
  },
  {
    value: 'TH',
    dropdownTitle: 'Четверг',
    selectTitle: 'ЧТ',
  },
  {
    value: 'FR',
    dropdownTitle: 'Пятница',
    selectTitle: 'ПТ',
  },
  {
    value: 'SA',
    dropdownTitle: 'Суббота',
    selectTitle: 'СБ',
  },
  {
    value: 'SU',
    dropdownTitle: 'Воскресенье',
    selectTitle: 'ВС',
  },
];

const sortDays = (v: string[]) => {
  const mapped: [string, number][] = v.map((d) => [
    d,
    options.findIndex((opt) => opt.value === d),
  ]);

  return mapped.sort((a, b) => a[1] - b[1]).map((b) => b[0]);
};

interface WeekTypeProps {
  start: string;
  opened: boolean;
  recurrence: string[] | null;
  setRecurrence: (recurrence: null | string[]) => void;
  isEdit: boolean;
  onlyDisplay: boolean;
  wholeDay: boolean;
}

const WeekType: FC<WeekTypeProps> = ({
  start,
  opened,
  recurrence,
  setRecurrence,
  isEdit,
  onlyDisplay,
  wholeDay,
}) => {
  const errors = useSelector(
    (s: CStoreState) => s.eventCreateEdit.fieldsData['errors']['recurrence'],
  );
  const [tillDate, setTillDate] = useState<string>('');
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);
  const [selectedMonths, setSelectedMonths] = useState<number[]>([]);

  const [selectingWeeks, setSelectingWeeks] = useState(false);
  const [selectingMonths, setSelectingMonths] = useState(false);

  useEffect(() => {
    if (!recurrence || !opened || !isEdit) return;
    const data = parseRruleData(recurrence) as ParsedWeekly;

    setTillDate(data.until || '');
    setSelectedDays(data.selectedDays || []);
    setSelectedWeeks(data.selectedWeeks || []);
    setSelectingWeeks(data.selectingWeeks || false);
    setSelectedMonths(data.selectedMonths || []);
    setSelectingMonths(data.selectingMonths || false);
  }, [opened]);

  useEffect(() => {
    if (!opened) return;
    const startWeekDay = options[moment(start, 'DD.MM.YYYY').weekday()];
    let isError = true;
    if (!selectedDays.length) {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'dateStart',
        error: EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence'],
      });

      return;
    }
    for (const i in selectedDays) {
      if (selectedDays[i] === startWeekDay.value) {
        isError = false;
        break;
      }
    }

    if (isError) {
      eventCreateEditActions().addFieldsErrorEventCreateEdit({
        field: 'dateStart',
        errors: [EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence']],
      });
    } else {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'dateStart',
        error: EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence'],
      });
    }

    return () => {
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'dateStart',
        error: EVENT_CREATE_EDIT_ERRORS['dateStartRecurrence'],
      });
    };
  }, [start, selectedDays]);

  useEffect(() => {
    if (selectedDays.length || !selectedWeeks.length)
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'recurrence',
        error: EVENT_CREATE_EDIT_ERRORS['reccurencyWeekDays'],
      });

    const rule = generateWeekRule({
      start,
      untilDate: tillDate,
      selectedDays,
      selectedWeeks,
      selectedMonths,
      wholeDay,
    });

    setRecurrence(rule.toString().split('\n'));
  }, [tillDate, selectedDays, selectedWeeks, selectedMonths, wholeDay]);

  const handleChange = (i: number, type: 'months' | 'weeks') => {
    const arr = type === 'months' ? selectedMonths : selectedWeeks;
    const index = arr.findIndex((f) => f === i);
    const tmp = [...arr];
    if (index > -1) tmp.splice(index, 1);
    else tmp.push(i);

    if (type === 'months') setSelectedMonths(tmp);
    else setSelectedWeeks(tmp);
  };

  const handleClickWeekDaySelector = () => {
    if (errors.includes(EVENT_CREATE_EDIT_ERRORS['recurrenceWeek']))
      eventCreateEditActions().removeFieldsErrorEventCreateEdit({
        field: 'recurrence',
        error: EVENT_CREATE_EDIT_ERRORS['recurrenceWeek'],
      });
  };

  return (
    <div
      className='event-create-reccurency-type-block'
      style={{
        marginTop: onlyDisplay ? '0px' : undefined,
      }}
    >
      {!onlyDisplay && (
        <>
          <div className='event-create-reccurency-type-head'>
            <MultiSelect
              placeholder='Выберите дни недели'
              options={options}
              values={selectedDays}
              onChange={(days) => setSelectedDays(sortDays(days))}
              onClick={handleClickWeekDaySelector}
              isError={errors.includes(
                EVENT_CREATE_EDIT_ERRORS['recurrenceWeek'],
              )}
            />
            <span>до</span>
            <DateInput
              value={tillDate}
              minDate={moment(start, 'DD.MM.YYYY').toDate()}
              initOpenMonth={start}
              onChange={setTillDate}
              style={{
                width: '150px',
              }}
              closeOnScroll='.event-create-edit-anchor'
            />
          </div>
          {selectingWeeks ? (
            <div className='event-create-reccurency-type-subselect'>
              <div className='event-create-reccurency-type-subselect-head'>
                <span>Повторять по неделям</span>
                <span
                  onClick={() => {
                    setSelectingWeeks(false);
                    setSelectedWeeks([]);
                  }}
                >
                  Отменить
                </span>
              </div>
              <div className='event-create-reccurency-weeks-select'>
                {weeks.map((week, i) => (
                  <div key={`event-create-reccurency-weeks-select-${i}`}>
                    <Checkbox
                      checked={selectedWeeks.includes(week.value)}
                      onChange={() => handleChange(week.value, 'weeks')}
                    >
                      {week.title}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              className='event-create-reccurency-type-select'
              onClick={() => setSelectingWeeks(true)}
            >
              <PlusIcon />
              <span>Выбрать недели повторения</span>
            </div>
          )}
          {selectingMonths ? (
            <div className='event-create-reccurency-type-subselect'>
              <div className='event-create-reccurency-type-subselect-head'>
                <span>Повторять по месяцам</span>
                <span
                  onClick={() => {
                    setSelectingMonths(false);
                    setSelectedMonths([]);
                  }}
                >
                  Отменить
                </span>
              </div>
              <div className='event-create-reccurency-months-select'>
                {months.map((month, i) => (
                  <div key={`event-create-reccurency-months-select-month-${i}`}>
                    <Checkbox
                      checked={selectedMonths.includes(i + 1)}
                      onChange={() => handleChange(i + 1, 'months')}
                    >
                      {month}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              className='event-create-reccurency-type-select'
              onClick={() => setSelectingMonths(true)}
            >
              <PlusIcon />
              <span>Выбрать месяцы</span>
            </div>
          )}
        </>
      )}
      {!!selectedDays.length && (
        <p
          className='event-create-reccurency-block-help-text'
          style={{
            marginTop: onlyDisplay ? '0px' : undefined,
          }}
        >
          {getRruleHumanString({
            start,
            tense: onlyDisplay ? 'present' : 'future',
            data: {
              type: 'WEEK',
              selectedDays,
              selectedWeeks,
              selectedMonths,
              until: tillDate,
            },
          })}
        </p>
      )}
    </div>
  );
};

export default WeekType;
