// Высота окна (подробнее читать в файле ./index.tsx)
export const EVENTS_DROPDOWN_HEIGHTS = {
  1: 60,
  2: 90,
  3: 120,
  4: 150,
  5: 180,
  6: 210,
  7: 240,
  8: 270,
  9: 300,
  10: 300,
};
