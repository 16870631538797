// *modulism-group router
import { Route } from 'react-router-dom';
import CalendarModeDay from './components/Day';
import CalendarModeMonth from './components/Month';
import CalendarModeWeek from './components/Week';

export default [
  <Route
    key={'calendar-route-1'}
    path='month'
    element={<CalendarModeMonth />}
  />,
  <Route key={'calendar-route-2'} path='week' element={<CalendarModeWeek />} />,
  <Route key={'calendar-route-3'} path='day' element={<CalendarModeDay />} />,
];
