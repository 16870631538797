import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_REQUEST_FAIL } from '../actionTypes';
import { EventCreateEditState, RequestEventCreateFailAction } from '../types';

export const requestEventCreateFail = createAction<
  RequestEventCreateFailAction,
  EventCreateEditState
>(EVENT_CREATE_REQUEST_FAIL, (state) => ({
  ...state,
  requestLoading: false,
}));
