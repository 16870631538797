import { RRule, rrulestr } from 'rrule';
import moment from 'moment/moment';
import { NO_RECURRENCE_FOUND, NO_SUPPORT_RESULT, ParseDRRule } from './types';

export const parseRruleData = (recurrence: string[]): ParseDRRule => {
  if (!Array.isArray(recurrence)) {
    return NO_RECURRENCE_FOUND;
  }

  const rec = [...recurrence].filter((el) => !el.includes('EXDATE'));
  if (rec[0]) {
    rec[0] = rec[0].replace(';VALUE=DATE', '');
  }
  const rruleParsedObj = rrulestr(rec.join('\n'));

  if (rruleParsedObj.origOptions.freq === RRule.DAILY) {
    // Period
    const parsedUntil = rruleParsedObj.origOptions.until;
    const until = parsedUntil
      ? moment(parsedUntil).utc().format('DD.MM.YYYY')
      : undefined;
    const count = Number(rruleParsedObj.origOptions.interval) || 1;

    return {
      type: 'PERIOD',
      until,
      count,
    };
  }

  if (
    rruleParsedObj.origOptions.freq === RRule.MONTHLY &&
    rruleParsedObj.origOptions.bymonthday
  ) {
    // Daily
    const parsedUntil = rruleParsedObj.origOptions.until;
    const until = parsedUntil
      ? moment(parsedUntil).utc().format('DD.MM.YYYY')
      : undefined;

    return {
      type: 'DATE',
      until,
      monthDay: rruleParsedObj.options.bymonthday?.at(1),
      ndate: 1,
      selectedMonths: rruleParsedObj.options.bymonth,
      selectingMonths: !!rruleParsedObj.options.bymonth,
    };
  }

  if (
    (rruleParsedObj.origOptions.freq === RRule.WEEKLY ||
      rruleParsedObj.origOptions.freq === RRule.MONTHLY) &&
    !rruleParsedObj.origOptions.bymonthday &&
    rruleParsedObj.origOptions.byweekday
  ) {
    // Week
    const until = rruleParsedObj.origOptions.until
      ? moment(rruleParsedObj.origOptions.until).utc().format('DD.MM.YYYY')
      : undefined;

    const byweekday = rruleParsedObj.origOptions.byweekday;
    let byDay: string[];
    if (typeof byweekday === 'string') {
      byDay = [byweekday];
    } else {
      if (Array.isArray(byweekday)) {
        byDay = byweekday.map((byweekday) => byweekday.toString());
      } else if (byweekday) {
        byDay = [byweekday.toString()];
      }
    }

    const bySetPos =
      typeof rruleParsedObj.origOptions.bysetpos === 'number'
        ? [rruleParsedObj.origOptions.bysetpos]
        : rruleParsedObj.origOptions.bysetpos;
    const byMonth =
      typeof rruleParsedObj.origOptions.bymonth === 'number'
        ? [rruleParsedObj.origOptions.bymonth]
        : rruleParsedObj.origOptions.bymonth;

    const selectedDays = byDay || [];

    let selectedMonths = [];
    let selectingMonths = false;
    let selectedWeeks = [];
    let selectingWeeks = false;

    if (bySetPos) {
      selectedWeeks = bySetPos;
      selectingWeeks = true;
    }
    if (byMonth) {
      selectedMonths = byMonth || [];
      selectingMonths = true;
    }

    return {
      type: 'WEEK',
      until,
      selectedDays,
      selectedMonths,
      selectingMonths,
      selectedWeeks,
      selectingWeeks,
    };
  }

  return NO_SUPPORT_RESULT;
};
