// *modulism-group store-actionTypes
export const SELECT_CONTROLS_MONTH = 'SELECT_CONTROLS_MONTH';
export const SELECT_CONTROLS_DAY = 'SELECT_CONTROLS_DAY';
export const SELECT_CONTROLS_MODE_MONTH = 'SELECT_CONTROLS_MODE_MONTH';
export const SELECT_CONTROLS_MODE_WEEK = 'SELECT_CONTROLS_MODE_WEEK';
export const SELECT_CONTROLS_MODE_DAY = 'SELECT_CONTROLS_MODE_DAY';
export const SELECT_CONTROLS_MODE_ALL = 'SELECT_CONTROLS_MODE_ALL';
export const SELECT_CONTROLS_PAGE = 'SELECT_CONTROLS_PAGE';
export const CONTROLS_INIT = 'CONTROLS_INIT';
export const SELECT_CONTROLS_NEXT_WEEK = 'SELECT_CONTROLS_NEXT_WEEK';
export const SELECT_CONTROLS_PREV_WEEK = 'SELECT_CONTROLS_PREV_WEEK';
export const SELECT_CONTROLS_NEXT_DAY = 'SELECT_CONTROLS_NEXT_DAY';
export const SELECT_CONTROLS_PREV_DAY = 'SELECT_CONTROLS_PREV_DAY';
export const SELECT_CONTROLS_TODAY_DAY = 'SELECT_CONTROLS_TODAY_DAY';
export const DELETE_SESSION_SET_LOADING_CONTROLS =
  'DELETE_SESSION_SET_LOADING_CONTROLS';
export const DELETE_SESSION_CONTROLS = 'DELETE_SESSION_CONTROLS';
export const OPEN_DAY_CONTROLS = 'OPEN_DAY_CONTROLS';
