import { createAction } from 'src/_utils/redux';
import { EVENTS_DISPLAY_LOAD_SUCCESS } from '../actionTypes';
import { EventsDisplayState, LoadEventsSuccessAction } from '../types';

export const loadEventsSuccess = createAction<
  LoadEventsSuccessAction,
  EventsDisplayState
>(EVENTS_DISPLAY_LOAD_SUCCESS, (state, payload) => {
  const shadowEvent = state.events.find((e) => e.id === 'SHADOW');

  return {
    calendars: state.calendars,
    events: shadowEvent ? [...payload, shadowEvent] : payload,
    loading: false,
  };
});
