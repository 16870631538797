// *modulism-group store-reducer
import { createReducer } from 'src/_utils/redux';
import { EventViewState } from './types';
import { setEventView } from './actions/setEventView';
import { setRawDataEventView } from './actions/setRawDataEventView';

export const initialState: EventViewState = {
  event: null,
};

export default createReducer(initialState, [setEventView, setRawDataEventView]);
