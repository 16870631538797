import { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import selectProfileEmail from 'src/_apiCommonData/store/selectors/selectProfileEmail';
import { NextSmallIcon, PrevousSmallIcon } from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import ComparingBusyMarker from './busyMarker';
import ComparingTimeSelector from './timeSelector';
import { EventCreateFieldsType, SetValueType } from '../../const';
import { Heading } from './heading';
import { Grid } from './grid';

moment.locale('ru');

export const timeToLineMinutes = (
  time: string,
  start: string,
  offset: number = 0,
) => {
  const date = moment(new Date(time));
  const startDate = moment(start, 'DD.MM.YYYY');

  return offset + date.diff(startDate, 'minutes');
};

interface ComparingTimelineProps {
  values: EventCreateFieldsType;
  setValue: SetValueType;
  emails: string[];
  hoveredAttendee: number;
  setHoveredAttendee: (i: number) => void;
  setSelectedView: (d: [string, string]) => void;
}

const ComparingTimeline: FC<ComparingTimelineProps> = ({
  values,
  setValue,
  emails,
  hoveredAttendee,
  setHoveredAttendee,
  setSelectedView,
}) => {
  const {
    timeStart,
    timeEnd,
    dateStart: dateStart1,
    dateEnd: dateEnd1,
  } = values;

  const { busy, loading, organizerBusyEvents } = useSelector(
    (state: CStoreState) => ({
      busy: Object.entries(state.eventCreateEdit.comparingCalendarsData.busy),
      loading:
        state.eventCreateEdit.comparingCalendarsData.status === 'LOADING',
      organizerBusyEvents:
        state.eventCreateEdit.comparingCalendarsData.organizerBusyEvents,
    }),
    shallowEqual,
  );
  const profileEmail = useSelector(selectProfileEmail);

  const [dateStart, setDateStart] = useState(dateStart1);
  const [dateEnd, setDateEnd] = useState(dateEnd1);
  useEffect(() => {
    const st = moment(dateStart1, 'DD.MM.YYYY');
    const en = moment(dateEnd1, 'DD.MM.YYYY');

    if (st.format() !== 'Invalid date' && en.format() !== 'Invalid date') {
      setDateStart(dateStart1);
      setDateEnd(dateEnd1);
    }
  }, [dateStart1, dateEnd1]);

  const { hours, eventStartDiff, start, end } = useMemo(() => {
    const start = moment(dateStart, 'DD.MM.YYYY');
    start.subtract('w', 1);
    const end = moment(dateEnd, 'DD.MM.YYYY');
    end.add('w', 1);

    const eventStart = moment(dateStart, 'DD.MM.YYYY');
    const eventStartDiff = eventStart.diff(start, 'minutes');

    const hours = (end.diff(start, 'days') + 1) * 24;
    const minutes = hours * 60;

    return {
      hours,
      minutes,
      eventStartDiff,
      start: start.format('DD.MM.YYYY'),
      end: end.format('DD.MM.YYYY'),
    };
  }, [dateStart, dateEnd]);

  const [offset, setOffset] = useState(eventStartDiff + 60 * 8);

  useEffect(() => {
    const st = moment(start, 'DD.MM.YYYY');
    st.add(offset, 'minutes');
    const en = moment(start, 'DD.MM.YYYY');
    en.add(offset, 'minutes');
    en.add(14, 'hours');

    setSelectedView([st.format('DD.MM.YYYY'), en.format('DD.MM.YYYY')]);
  }, [offset, start]);

  const eventTime = useMemo(() => {
    if (!timeStart || !timeEnd) {
      return null;
    }

    const start = moment(dateStart, 'DD.MM.YYYY');
    const end = moment(dateEnd, 'DD.MM.YYYY');

    start.set('h', Number(timeStart.split(':')[0]));
    start.set('m', Number(timeStart.split(':')[1]));

    end.set('h', Number(timeEnd.split(':')[0]));
    end.set('m', Number(timeEnd.split(':')[1]));

    return {
      start: timeToLineMinutes(start.format(), dateStart),
      end: timeToLineMinutes(end.format(), dateStart),
    };
  }, [dateStart, dateEnd, timeStart, timeEnd]);

  const { resBusy: busyItems, busyParts } = useMemo(() => {
    const resBusy = [];
    const resBusyParts = [];

    let withBusy = false;

    const busy1 = [...busy].sort((a) => (a[0] === profileEmail ? -1 : 1));

    busy1.forEach(([email, d]) => {
      const emailIndex = emails.findIndex((e) => e === email);

      const top = emailIndex * 60 + 4;

      d.forEach((b, i) => {
        withBusy = true;
        const left = timeToLineMinutes(b.start, start);
        const right = timeToLineMinutes(b.end, start);
        const width = right - left;

        if (resBusyParts.length) {
          let check = false;
          for (let i = 0; i < resBusyParts.length; i++) {
            if (
              (resBusyParts[i][0] <= left && resBusyParts[i][1] >= right) ||
              (resBusyParts[i][0] >= left && resBusyParts[i][0] <= right) ||
              (resBusyParts[i][1] >= left && resBusyParts[i][1] <= right)
            ) {
              check = true;
              resBusyParts[i][0] =
                left < resBusyParts[i][0] ? left : resBusyParts[i][0];
              resBusyParts[i][1] =
                right > resBusyParts[i][1] ? right : resBusyParts[i][1];
            }
          }
          if (!check) {
            resBusyParts.push([left, right]);
          }
        } else {
          resBusyParts.push([left, right]);
        }

        resBusy.push({
          top,
          left,
          width,
          events: email === profileEmail ? organizerBusyEvents[i].events : null,
        });
      });
    });

    if (!withBusy) {
      return {
        resBusy,
        busyParts: resBusyParts,
      };
    }

    return {
      resBusy,
      busyParts: resBusyParts,
    };
  }, [busy, organizerBusyEvents]);

  useEffect(() => {
    setOffset(eventStartDiff + 60 * 8);
  }, [eventStartDiff]);

  return (
    <div
      className={cn('comparing-timeline', {
        loading: loading,
      })}
      style={{
        marginLeft: offset * -1,
      }}
    >
      {eventTime && (
        <ComparingTimeSelector
          busyParts={busyParts}
          values={values}
          setValue={setValue}
        />
      )}
      <div className='comparing-timeline-header'>
        <div
          className={cn('comparing-timeline-header-left', {
            disabled: !offset,
          })}
        >
          <div
            onClick={() => {
              offset && setOffset(offset < 60 * 4 ? 0 : offset - 60 * 4);
            }}
          >
            <PrevousSmallIcon />
          </div>
        </div>
        <Heading hours={hours} start={start} end={end} />
        <div
          className={cn('comparing-timeline-header-right', {
            disabled: offset === 60 * (hours - 14),
          })}
        >
          <div
            onClick={() => {
              setOffset(
                offset + 60 * 4 > 60 * (hours - 14)
                  ? 60 * (hours - 14)
                  : offset + 60 * 4,
              );
            }}
          >
            <NextSmallIcon />
          </div>
        </div>
        <div className='comparing-free-line' />
        {busyParts.map((v, i) => (
          <ComparingBusyMarker
            key={`ComparingBusyMarker-${i}`}
            left={v[0]}
            width={v[1] - v[0]}
          />
        ))}
      </div>
      <div className='comparing-timeline-grid-wrapper'>
        <Grid
          hours={hours}
          emailsLength={emails.length}
          hoveredAttendee={hoveredAttendee}
          setHoveredAttendee={setHoveredAttendee}
          busyItems={busyItems}
        />
      </div>
    </div>
  );
};

export default ComparingTimeline;
