// *modulism-group constants
export const EVENT_CREATE_EDIT_ERRORS = {
  noTitle: 'Вы не указали название',
  noTimeStart: 'Укажите время начала события',
  noTimeEnd: 'Укажите время окончания события',
  noTimeStartEnd: 'Укажите время начала и окончания события.',
  dateEndBeforeDateStart: 'Событие не может кончаться раньше, чем началось',
  timeEndBeforeTimeStart: 'Событие не может кончаться раньше, чем началось',
  incorrectTimeStart: 'Время указано некорректно',
  incorrectTimeEnd: 'Время указано некорректно',
  incorrectDateStart: 'Дата указана некорректно',
  incorrectDateEnd: 'Дата указана некорректно',
  attendeesIncorrect: 'Участники указаны некорректно',
  incorrectUser: 'Вы являетесь организатором',
  recurrenceWeek: 'Не указан период повторения',
  dateStartRecurrence:
    'Дата начала не соответствует правилу повторения события',
  attendeeLimit: 'Превышено число получателей',
};

export const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const weeks = [
  {
    title: '1-я',
    value: 1,
  },
  {
    title: '2-я',
    value: 2,
  },
  {
    title: '3-я',
    value: 3,
  },
  {
    title: '4-я',
    value: 4,
  },
  {
    title: 'Последняя в месяце',
    value: -1,
  },
];
