import BaseRequester from 'src/_api/requesters/baseRequester';
import { Requests } from 'src/_api/types/requests';

export const calendarDelete = (data: Requests['CalendarDelete']['params']) => {
  const requester = new BaseRequester('/api/calendar/v1');

  return requester.request<'CalendarDelete'>({
    additionalUrl: `/${data.url.owner}/calendars/${data.url.calendarId}`,
    method: 'DELETE',
  });
};
