import { createAction } from 'src/_utils/redux';
import { SELECT_CONTROLS_MODE_MONTH } from '../actionTypes';
import { ControlsState, SelectControlsModeMonthAction } from '../types';

export const selectControlsModeMonth = createAction<
  SelectControlsModeMonthAction,
  ControlsState
>(SELECT_CONTROLS_MODE_MONTH, (state) => ({
  ...state,
  selectedMonth: new Date(state.selectedMonth),
  selectedDateRange: null,
  mode: 'month',
}));
