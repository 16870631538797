import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_EDIT_RESET } from '../actionTypes';
import { initialState } from '../reducer';
import { EventCreateEditState, ResetEventCreateEditAction } from '../types';

export const resetEventCreateEdit = createAction<
  ResetEventCreateEditAction,
  EventCreateEditState
>(EVENT_CREATE_EDIT_RESET, () => ({
  ...initialState,
  fieldsData: {
    ...initialState.fieldsData,
    attachi: {}, //Почему то аттачи не удалялись в новом окне
  },
}));
