import { FC } from 'react';
import cn from 'classnames';

import './styles.less';
import { Avatar } from './avatar';
import Button from '@rambler-components/button';
import { MailIcon, PlusIcon } from 'src/_components/Svg';
import { AddressbookContact } from '../../../../_api/types/mailId/entities';
import Tooltip from '@rambler-components/tooltip';

interface Props {
  contact: AddressbookContact;
  onCreateEventClick: () => void;
}

const Contact: FC<Props> = ({ contact, onCreateEventClick }) => (
  <div className='addressbook__contact__wrapper'>
    <div
      className='addressbook__contact__content'
      style={{
        marginBottom: 40,
      }}
    >
      <Avatar contact={contact} />
      <div className='addressbook__contact__header-info'>
        <div className='addressbook__contact__name'>
          {contact.firstname || contact.lastname ? (
            <span>
              {contact.lastname} {contact.firstname}
            </span>
          ) : (
            <span> {contact?.display_name}</span>
          )}
          <span className='addressbook__contact__name-email'>
            {contact?.email}
          </span>
        </div>
        <div className='addressbook__contact__controls'>
          <Tooltip label='Написать' position='top'>
            <a
              href={`https://mail.corp.rambler.ru/compose?user=${contact.email}`}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                className='addressbook__contact__control'
                isRound
                isMobile
                type='secondary'
              >
                <MailIcon />
              </Button>
            </a>
          </Tooltip>
          <Button
            className={cn(
              'addressbook__contact__control',
              'addressbook__contact__control__add-event',
            )}
            onClick={onCreateEventClick}
            isRound
            icon={<PlusIcon />}
            iconPosition='left'
            isMobile
            type='secondary'
          >
            Событие
          </Button>
        </div>
      </div>
    </div>
    {contact.title && (
      <div className='addressbook__contact__content'>
        <span className='addressbook__contact__title'>Должность</span>
        <span>{contact.title}</span>
      </div>
    )}
    {contact.department && (
      <div className='addressbook__contact__content'>
        <span className='addressbook__contact__title'>Отдел</span>
        <span>{contact.department}</span>
      </div>
    )}
    {contact.company && (
      <div className='addressbook__contact__content'>
        <span className='addressbook__contact__title'>Организация</span>
        <span>{contact.company}</span>
      </div>
    )}
    {contact.email && (
      <div className='addressbook__contact__content'>
        <span className='addressbook__contact__title'>Эл. почта</span>
        <span>{contact.email}</span>
      </div>
    )}
  </div>
);

export default Contact;
