import { createAction } from 'src/_utils/redux';
import { EVENT_VIEW_ATTENDEE_TRANSPARENCY_CHANGE } from '../actionTypes';
import {
  AttendeeTransparencyChangeEventViewAction,
  EventViewState,
} from '../types';

export const attendeeTransparencyChangeEventView = createAction<
  AttendeeTransparencyChangeEventViewAction,
  EventViewState
>(EVENT_VIEW_ATTENDEE_TRANSPARENCY_CHANGE);
