import MailIdRequester from 'src/_api/requesters/mailIdRequester';
import { Requests } from 'src/_api/types/mailId/requests';
import { ADDRESBOOK_URL } from '../../../addressbook/consts';

export const getAddreebookContacts = (
  params: Requests['AddressbookContactsGet']['params'],
) => {
  const requester = new MailIdRequester(ADDRESBOOK_URL);

  return requester.request<'AddressbookContactsGet'>({
    additionalUrl: '/search',
    params,
    method: 'GET',
  });
};
