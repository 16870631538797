import './styles.less';
import { CSSProperties, FC, MutableRefObject, ReactNode } from 'react';

interface IconButtonProps {
  onClick?: () => void;
  icon: ReactNode;
  style?: CSSProperties;
  isActive?: boolean;
  ref?: MutableRefObject<HTMLDivElement>;
  size?: number;
  withBackground?: boolean;
  className?: string;
}

const IconButton: FC<IconButtonProps> = ({
  onClick,
  icon,
  style,
  isActive,
  ref,
  size,
  withBackground,
  className,
}) => (
  <div
    className={`flex-center clickable icon-button ${isActive ? 'active' : ''} ${
      className || ''
    }`}
    onClick={onClick}
    style={{
      ...style,
      width: size ? `${size}px` : undefined,
      height: size ? `${size}px` : undefined,
      borderRadius: '50%',
      backgroundColor: withBackground ? 'white' : undefined,
    }}
    ref={ref}
  >
    {icon}
  </div>
);

export default IconButton;
