import { createAction } from 'src/_utils/redux';
import { DELETE_NEW_EVENT_NOTIFICATIONS } from '../actionTypes';
import { NewEventsState, DeleteNewEventNotificationsAction } from '../types';

export const deleteNewEventNotifications = createAction<
  DeleteNewEventNotificationsAction,
  NewEventsState
>(DELETE_NEW_EVENT_NOTIFICATIONS, (s, p) => ({
  events: s.events.filter((e) => e.event.id !== p),
}));
