import { FC, ReactNode } from 'react';
//import { isIE11 } from 'src/_utils/browser';

/**
 * TODO:
 * use the same component for both
 * UploadFile and AttachmentItem
 */

interface UploadFileProps {
  children: ReactNode;
  name?: string;
  downloadUrl?: string;
  className: string;
  linkClassName: string;
}

export const UploadFileWrapper: FC<UploadFileProps> = ({
  children,
  name,
  downloadUrl,
  className,
  linkClassName,
}) => {
  const targetAttr = {}; //isIE11 ? { target: '_blank' } : {};

  if (downloadUrl) {
    return (
      <div className={className}>
        <a
          className={linkClassName}
          href={downloadUrl}
          download={name}
          {...targetAttr}
        >
          {children}
        </a>
      </div>
    );
  }

  return <div className={className}>{children}</div>;
};
