import React, { useLayoutEffect, useMemo, useRef } from 'react';

import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import selectMonthEvents from 'src/eventsDisplay/store/selectors/monthEventsSelector';
import moment from 'moment';
import css from './styles.module.less';
import {
  parseRruleData,
  getRruleHumanString,
  NO_SUPPORT_RESULT,
} from 'src/_utils/recurrence';

export const PrintEvent = () => {
  const location = useLocation();
  const ref = useRef();

  const searchParams = new URLSearchParams(location.search);

  const eventId = searchParams.get('eventId');
  const eventDate = searchParams.get('date');

  const monthEvents = useSelector(selectMonthEvents);

  const filtredEvents =
    monthEvents &&
    Object.values(monthEvents)
      .reduce(
        (accum, currentValue) =>
          currentValue.length ? [...accum, ...currentValue] : [...accum],
        [],
      )
      .filter(
        (ev) => ev?.id === eventId && ev.date.toDateString() === eventDate,
      );



    const eventForPrint = filtredEvents[0];
    

  const recurrenceString = useMemo(() => {
    if (!eventForPrint) return '';
    if (
      eventForPrint.source.recurrence &&
      eventForPrint.source.recurrence.length
    ) {
      const data = parseRruleData(eventForPrint.source.recurrence);
      const start = moment(eventForPrint.start).format('DD.MM.YYYY');

      switch (data.type) {
        case 'DATE': {
          return getRruleHumanString({
            start,
            tense: 'present',
            withCount: false,
            data: {
              type: 'DATE',
              ndate: data.ndate,
              selectingMonths: data.selectingMonths,
              selectedMonths: data.selectedMonths,
              monthDay:
                data.monthDay ||
                Number(moment(start, 'DD.MM.YYYY').format('DD')),
              until: data.until,
            },
          });
        }
        case 'PERIOD': {
          return getRruleHumanString({
            start,
            tense: 'present',
            withCount: false,
            data: {
              type: 'PERIOD',
              count: data.count,
              until: data.until,
            },
          });
        }
        case 'WEEK': {
          return getRruleHumanString({
            start,
            tense: 'present',
            withCount: false,
            data: {
              type: 'WEEK',
              selectedDays: data.selectedDays,
              selectedWeeks: data.selectedWeeks,
              selectedMonths: data.selectedMonths,
              until: data.until,
            },
          });
        }
        case NO_SUPPORT_RESULT.type: {
          return '';
        }
      }
    }
  }, [eventForPrint]);

  useLayoutEffect(() => {
    console.log(ref.current);
    setTimeout(() => {
      if (ref.current && eventForPrint) {
        console.log(eventForPrint);
        console.log(Object.keys(eventForPrint).length);
        window.print();
      }
    }, 500);
  }, [eventForPrint && Object.keys(eventForPrint).length]);

  if (!eventForPrint) {
    return null;
  }

  return (
    <div className={css.printPage} ref={ref}>
      <div className={css.printContent}>
        <div className={css.title}>{eventForPrint.summary}</div>
        <div className={css.divider} />
        <div className={css.rowWrapper}>
          <div className={css.printRow}>
            <div className={css.rowName}>Дата и время</div>
            <div className={css.rowContentWrapper}>
              <div className={css.rowContent}>
                {' '}
                {!!eventForPrint &&
                  `${moment(eventForPrint.date).format('DD MMMM')}, ${
                    eventForPrint.type === 'wholeDay'
                      ? 'Весь день'
                      : `${moment(eventForPrint.start).format(
                          'HH:mm',
                        )} - ${moment(eventForPrint.end).format('HH:mm')}`
                  }`}
              </div>
              {eventForPrint?.isRecurrence && (
                <div className={css.rowContent}> {recurrenceString}</div>
              )}
            </div>
          </div>

          <div className={css.printRow}>
            <div className={css.rowName}>Организатор</div>
            <div className={css.rowContent}>
              {eventForPrint?.source.organizer?.uri.split(':')[1]}
            </div>
          </div>
          <div className={css.printRow}>
            <div className={css.rowName}>Участники</div>
            <div className={css.rowContentWrapper}>
              {eventForPrint.attendee.map(
                (att) =>
                  att.uri !== eventForPrint?.source.organizer?.uri && (
                    <div className={css.rowContent}>
                      {att.uri.split(':')[1]}
                    </div>
                  ),
              )}
            </div>
          </div>
          <div className={css.printRow}>
            <div className={css.rowName}>Место проведения</div>
            <div className={css.rowContent}>{eventForPrint?.location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
