import { FC } from 'react';
import css from './NotificationBlock.module.less';

interface NotificationBlockProps {
  title: string;
  options: {
    title: string;
    action: () => void;
  }[];
  positions: {
    top?: number;
    left?: number;
    right?: number;
    width?: number;
    gap?: number;
    padding?: number;
  };
}

const NotificationBlock: FC<NotificationBlockProps> = ({
  title,
  options,
  positions,
}) => (
  <div className={css.notificationBlock} style={positions}>
    <p className={css.notificationBlockTitle}>{title}</p>
    <div>
      {options.map((op, i) => (
        <p
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            op.action();
          }}
          key={`notifictation-option-${i}`}
        >
          {op.title}
        </p>
      ))}
    </div>
  </div>
);

export default NotificationBlock;
