import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_EDIT_CALENDARS_COMPARING } from '../actionTypes';
import {
  EventCreateEditState,
  CalendarsComparingEventCreateEditAction,
} from '../types';

export const calendarsComparing = createAction<
  CalendarsComparingEventCreateEditAction,
  EventCreateEditState
>(EVENT_CREATE_EDIT_CALENDARS_COMPARING, (state, p) => {
  switch (p.status) {
    case 'START':
      return {
        ...state,
        comparingCalendarsData: {
          isComparing: true,
          organizerBusyEvents: {},
          status: 'LOADING',
          busy: {},
          dataToProcess: {
            attendees:
              p.attendees ||
              state.comparingCalendarsData.dataToProcess.attendees,
            dateStart:
              p.dateStart ||
              state.comparingCalendarsData.dataToProcess.dateStart,
            dateEnd:
              p.dateEnd || state.comparingCalendarsData.dataToProcess.dateEnd,
          },
        },
      };
    case 'SUCCESS':
      return {
        ...state,
        comparingCalendarsData: {
          isComparing: true,
          organizerBusyEvents: p.organizerBusyEvents,
          status: 'LOADED',
          busy: p.data,
          dataToProcess: state.comparingCalendarsData.dataToProcess,
        },
      };
    case 'FAIL':
      return {
        ...state,
        comparingCalendarsData: {
          isComparing: true,
          organizerBusyEvents: {},
          status: 'FAILED',
          busy: {},
          dataToProcess: state.comparingCalendarsData.dataToProcess,
        },
      };
    case 'REDO':
      if (!state.comparingCalendarsData.isComparing) {
        return state;
      }

      return {
        ...state,
        comparingCalendarsData: {
          isComparing: true,
          status: 'LOADING',
          organizerBusyEvents: state.comparingCalendarsData.organizerBusyEvents,
          busy: state.comparingCalendarsData.busy,
          dataToProcess: {
            attendees:
              p.attendees ||
              state.comparingCalendarsData.dataToProcess.attendees,
            dateStart:
              p.dateStart ||
              state.comparingCalendarsData.dataToProcess.dateStart,
            dateEnd:
              p.dateEnd || state.comparingCalendarsData.dataToProcess.dateEnd,
          },
        },
      };
    case 'STOP':
      return {
        ...state,
        comparingCalendarsData: {
          isComparing: false,
          status: 'LOADING',
          organizerBusyEvents: [],
          busy: {},
          dataToProcess: state.comparingCalendarsData.dataToProcess,
        },
      };
  }
});
