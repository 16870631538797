import { createAction } from 'src/_utils/redux';
import { EVENT_CREATE_EDIT_SHOW_FIELDS } from '../actionTypes';
import {
  EventCreateEditState,
  ShowFieldsEventCreateEditAction,
} from '../types';

export const showFieldsEventCreateEdit = createAction<
  ShowFieldsEventCreateEditAction,
  EventCreateEditState
>(EVENT_CREATE_EDIT_SHOW_FIELDS, (state) => ({
  ...state,
  fieldsHidden: false,
  fieldsData: {
    ...state.fieldsData,
    alarms: [...state.fieldsData.alarms]
  },
}));
