import moment from 'moment';
import { Task } from 'redux-saga';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { openEventEditWindow } from 'src/eventCreateEdit/store/actions/openEventEditWindow';
import { SelectedEvent } from 'src/eventsDisplay/store/types';
import { FLOATING_MODAL_IDS } from 'src/floatingModal/constants';
import { closeFloatingModal } from 'src/floatingModal/store/actions/closeFloatingModal';
import { openFloatingModal } from 'src/floatingModal/store/actions/openFloatingModal';
import { closeWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/closeWhiteLoadingScreen';
import { openWhiteLoadingScreen } from 'src/whiteLoadingScreen/store/actions/openWhiteLoadingScreen';
import api from 'src/_api/api';
import { Event } from 'src/_api/types/entities';
import { transformCalDavDate, transformDateAndTime } from 'src/_utils/caldav';
import { setEventView } from '../store/actions/setEventView';
import { EVENT_VIEW_EDIT_ACTION } from '../store/actionTypes';
import selectEventViewEvent from '../store/selectors/selectEventViewEvent';
import { EditEventViewAction } from '../store/types';
import { attachToUploads } from '../utils';
import { addSnack } from '../../_redux/slices/snackbar';
import { loadFileInfoSaga } from '../../eventCreateEdit/sagas/loadFileInfo.saga';
import { TABLET_MAX_WIDTH } from '../../_constants';

function* whiteLoadingScreenDelay() {
  yield delay(1000);
  yield put(openWhiteLoadingScreen.action());
}

export function* openEventEditor(action: EditEventViewAction): Generator {
  const event = (yield select(selectEventViewEvent)) as SelectedEvent;

  const whiteLoadingScreenTimeout = (yield fork(
    whiteLoadingScreenDelay,
  )) as Task;
  let events: Event[] = [];
  try {
    const { data }: any = yield call(api('eventGet'), {
      url: {
        owner: 'me',
        calendarId: 'private',
        eventId: event.id,
      },
      headers: {},
    });

    events = data.events;
  } catch (e) {
    yield put(
      addSnack({
        message: 'Не удалось загрузить событие',
        type: 'error',
      }),
    );

    return;
  } finally {
    yield cancel(whiteLoadingScreenTimeout);
    yield put(closeWhiteLoadingScreen.action());
  }

  const masterEvent = events.find((d) => !d.recurrenceId);

  const sourceRecurenceId = event.source.recurrenceId;
  const isTypeDate = masterEvent.start.type === 'DATE';

  let masterEventTimeStart = '';
  let masterEventTimeEnd: string | Date = '';
  if (masterEvent.start.type === 'DATE') {
    masterEventTimeStart = transformCalDavDate(masterEvent.start.value);
    if (masterEvent.end?.value) {
      masterEventTimeEnd = masterEvent.end?.value;
    } else {
      masterEventTimeEnd = new Date(
        new Date(masterEventTimeStart).getTime() + 86400 * 1000,
      );
    }
  } else {
    if (!masterEvent.start.timeZone && !masterEvent.start.value.includes('Z')) {
      // проверка плавающей даты
      masterEventTimeStart = transformCalDavDate(masterEvent.start.value);
      if (masterEvent.end?.value) {
        masterEventTimeEnd = masterEvent.end?.value;
      } else {
        masterEventTimeEnd = new Date(
          new Date(masterEventTimeStart).getTime() + 86400 * 1000,
        );
      }
    } else if (masterEvent.start.timeZone) {
      masterEventTimeStart = moment(masterEvent.start.value)
        .tz(masterEvent.start.timeZone)
        .format()
        .slice(0, -6);
      masterEventTimeEnd = masterEvent.end?.value
        ? moment(masterEvent.end.value)
            .tz(masterEvent.start.timeZone)
            .format()
            .slice(0, -6)
        : masterEventTimeStart;
    } else if (masterEvent.start.value.includes('Z')) {
      masterEventTimeStart = moment(masterEvent.start.value)
        .utc()
        .format()
        .slice(0, -1);
      masterEventTimeEnd = masterEvent.end?.value
        ? moment(masterEvent.end.value).utc().format().slice(0, -1)
        : masterEventTimeStart;
    }
  }

  let recurIdTime = '';

  if (sourceRecurenceId?.value) {
    recurIdTime = transformCalDavDate(sourceRecurenceId.value);
  } else if (masterEvent.start.type === 'DATE') {
    recurIdTime = transformCalDavDate(event.source.start.value);
  } else {
    if (!masterEvent.start.timeZone && !masterEvent.start.value.includes('Z')) {
      // проверка плавающей даты
      recurIdTime = transformCalDavDate(event.source.start.value);
    } else if (masterEvent.start.timeZone) {
      recurIdTime = moment(event.start)
        .tz(masterEvent.start.timeZone)
        .format()
        .slice(0, -6);
    } else if (masterEvent.start.value.includes('Z')) {
      recurIdTime = moment(event.start).utc().format().slice(0, -1);
    }
  }

  let currentOverridingEvent: Event | null = null;
  if (
    events.length > 1 &&
    sourceRecurenceId &&
    action.payload.recurrenceType === 'single'
  ) {
    const recurIdTimeStartUtc =
      isTypeDate || !sourceRecurenceId
        ? undefined
        : moment(transformCalDavDate(sourceRecurenceId.value)).utc();

    if (recurIdTimeStartUtc) {
      for (const i in events) {
        if (!events[i].recurrenceId) continue;
        const recurIdTimeStartUtcFormatted = transformDateAndTime(
          recurIdTimeStartUtc
            .tz(events[i].recurrenceId.timeZone)
            .format('DD.MM.YYYY'),
          recurIdTimeStartUtc
            .tz(events[i].recurrenceId.timeZone)
            .format('HH:mm'),
        );
        if (recurIdTimeStartUtcFormatted === events[i].recurrenceId.value) {
          currentOverridingEvent = { ...events[i] };
          break;
        }
      }
    }
  }

  const fieldEndTime = moment(
    action.payload.recurrenceType === 'all' ? masterEventTimeEnd : event.end,
  );
  if (isTypeDate) fieldEndTime.subtract(1, 'd');

  const fieldStartTime = moment(
    action.payload.recurrenceType === 'all'
      ? masterEventTimeStart
      : event.start,
  );

  yield put(closeFloatingModal.action());
  yield put(setEventView.action({ event: null }));
  yield put(
    openEventEditWindow.action({
      recurrenceType: action.payload.recurrenceType,
      masterEvent,
      events: events || [],
      currentOverridingEvent,
      recurrenceId: masterEvent.recurrence
        ? {
            value: transformDateAndTime(
              moment(recurIdTime).format('DD.MM.YYYY'),
              !isTypeDate ? moment(recurIdTime).format('HH:mm') : undefined,
            ),
            type: isTypeDate ? 'DATE' : masterEvent.start.type,
            timeZone: masterEvent.start.timeZone || undefined,
          }
        : undefined,
      fieldsData: {
        attachi: event.source?.attach?.length
          ? attachToUploads(event.source.attach, [])
          : undefined,
        attendees: event.attendee,
        title: event.summary,
        dateStart: moment(fieldStartTime).format('DD.MM.YYYY'),
        timeStart: isTypeDate ? null : moment(fieldStartTime).format('HH:mm'),
        dateEnd: fieldEndTime.format('DD.MM.YYYY'),
        timeEnd: isTypeDate ? null : moment(fieldEndTime).format('HH:mm'),
        wholeDay: isTypeDate,
        room: event.location,
        description: event.description,
        transparency: event.source.transparency,
        recurrence: masterEvent.recurrence || null,
        alarms: event.alarms,
      },
    }),
  );
  yield loadFileInfoSaga();
  const width = window.innerWidth;
  yield put(
    openFloatingModal.action({
      opened: FLOATING_MODAL_IDS['eventCreate'],
      target: null,
      center: width <= TABLET_MAX_WIDTH,
    }),
  );
}

export function* handleEventViewEditSaga(): Generator {
  yield takeEvery(EVENT_VIEW_EDIT_ACTION, openEventEditor);
}
