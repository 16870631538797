// *modulism-group root-reducer
import eventsReducer from 'src/eventsDisplay/store/reducer';
import controlsReducer from 'src/controls/store/reducer';
import eventCreateReducer from 'src/eventCreateEdit/store/reducer';
import eventViewReducer from 'src/eventView/store/reducer';
import apiCommonData from 'src/_apiCommonData/store/reducer';
import floatingModalReducer from 'src/floatingModal/store/reducer';
import singleDayModalReducer from 'src/singleDayModal/store/reducer';
import whiteLoadingScreenReducer from 'src/whiteLoadingScreen/store/reducer';
import newEventNotificationsReducer from 'src/newEventNotifications/store/reducer';
import snackbarReducer from './slices/snackbar';
import notificationReducer from './slices/notification';
import addressbookReducer from 'src/addressbook/store/reducer';

const rootReducer = {
  controls: controlsReducer,
  eventsDisplay: eventsReducer,
  eventCreateEdit: eventCreateReducer,
  eventView: eventViewReducer,
  apiCommonData: apiCommonData,
  floatingModal: floatingModalReducer,
  singleDayModal: singleDayModalReducer,
  whiteLoadingScreen: whiteLoadingScreenReducer,
  newEventNotifications: newEventNotificationsReducer,
  snackbar: snackbarReducer,
  notification: notificationReducer,
  addressbook: addressbookReducer,
};

export default rootReducer;
