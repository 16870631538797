/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import { AttachIcon, DownloadIcon, Tick, TickUp } from 'src/_components/Svg';
import { isValidURL } from '../../../_utils/isValidURL';
import css from './EventBlock.module.less';

export const AttachiBlock = (props: {
  a: Array<any>;
  ai: Array<{ url: string; name?: string; size?: number }>;
}) => {
  const [showAll, setShowAll] = useState(false);
  const findInfo = (url: string) => props.ai.find((i) => i.url === url);

  return (
    <div className={css.eventViewAttachi}>
      <h3>Прикрепленные файлы</h3>
      <ul>
        {props.a
          .filter(
            (a, i) => i < (!showAll && props.a.length > 3 ? 3 : props.a.length),
          )
          .map((at, i) => (
            <li key={i}>
              <a href={isValidURL(at.url) ? at.url : ''} download>
                <AttachIcon />
                <div className={css.attachName}>
                  {at.name ||
                    findInfo(at.url)?.name ||
                    decodeURI(at.url.split('/')[at.url.split('/').length - 1])}
                </div>
                <DownloadIcon className={css.attachDownloadIcon} />
              </a>
            </li>
          ))}
      </ul>
      {props.a.length > 3 ? (
        <div onClick={() => setShowAll(!showAll)} className={css.showAll}>
          <span className={css.count}>
            {!showAll
              ? `Показать все ${props.a.length} ${
                  props.a.length in [3, 4] ? 'файла' : 'файлов'
                }`
              : 'Свернуть'}
          </span>
          {!showAll ? <Tick /> : <TickUp />}
        </div>
      ) : null}
    </div>
  );
};
