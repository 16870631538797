import Loader from '@rambler-components/loader';
import { FC, MutableRefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import controlsActions from 'src/controls/store/actions';
import Dropdown from 'src/_components/Dropdown';
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon,
  AvatarIcon,
  CopyIcon,
} from 'src/_components/Svg';
import { CStoreState } from 'src/_redux/types';
import { copyTextToClipboard } from 'src/_utils/copyTextToClipboard';

import './styles.less';

interface ProfileAnchorProps {
  setShowDropdown: (v: boolean) => void;
  showDropdown: boolean;
  ref: MutableRefObject<HTMLDivElement>;
}

export const ProfileAnchor: FC<ProfileAnchorProps> = ({
  setShowDropdown,
  showDropdown,
  ref,
}) => (
  <div onClick={() => setShowDropdown(!showDropdown)} ref={ref}>
    <div
      className='flex-center'
      style={{
        marginRight: '5px',
      }}
    >
      <AvatarIcon />
    </div>
    {showDropdown ? <ArrowUpSmallIcon /> : <ArrowDownSmallIcon />}
  </div>
);

interface ProfileDropdownProps {
  show: boolean;
  ref: MutableRefObject<HTMLDivElement>;
}

export const ProfileDropdown: FC<ProfileDropdownProps> = ({ show, ref }) => {
  const { profile, exitLoading } = useSelector((s: CStoreState) => ({
    profile: s.apiCommonData.profile,
    exitLoading: s.controls.deleteSessionLoading,
  }));
  const [copied, setCopied] = useState(false);
  if (!show) return null;

  const copyEmail = () => {
    copyTextToClipboard(profile?.info?.email || '');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  return (
    <Dropdown
      style={{
        top: '50px',
        right: '20px',
        backgroundColor: 'white',
        padding: '20px',
        width: '320px',
        borderRadius: '15px',
      }}
    >
      <div className='profile' ref={ref}>
        <div className='avatar'>
          <AvatarIcon />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <p className='name'>{profile?.display?.display_name}</p>
          <div className='email'>
            {copied ? (
              <p>Скопировано</p>
            ) : (
              <>
                <p>{profile?.info?.email}</p>
                <div onClick={copyEmail} className='copy-icon flex-center'>
                  <CopyIcon />
                </div>
              </>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '15px',
            }}
          >
            <p
              className={exitLoading ? 'exiting' : 'exit'}
              onClick={() => controlsActions().deleteSessionControls()}
            >
              {exitLoading ? 'Выполняется выход' : 'Выход'}
            </p>
            {exitLoading && <Loader size='small' />}
          </div>
        </div>
      </div>
    </Dropdown>
  );
};
